import {ADD_TOAST_MESSAGE} from 'vuex-toast'
// import {getTime, addMinutes, isAfter} from 'date-fns'

const root = {root: true}

const disconnectEcho = ($echo) => {
  if ($echo) {
    $echo.disconnect()
  }
}

export const state = () => ({
  token: undefined,
  loggedIn: true,
  strategy: 'local',
  redirect: undefined,
  busy: false,
})

export const getters = {
  token: (state) => state.token,
  isLoggedIn: (state) => Boolean(state.token),
}

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
}

export const actions = {
  // set up token, header and cookie
  SET_AUTH({commit, dispatch}, {token, shouldVerify = false}) {
    commit('SET_TOKEN', token)
    this.$axios.setAuthHeader(token)
    this.$authUtils.setToken(token)
  },
  // when logout or invalid token
  CLEAR_AUTH({commit, dispatch}) {
    commit('SET_TOKEN', null)
    this.$axios.setAuthHeader(false)
    commit('user/SET', null, root)
    this.$authUtils.removeAuth()
    disconnectEcho(this.$echo)
  },

  async LOGIN({commit, dispatch}, params) {
    try {
      const data = await this.$axios.$post('/auth/login', params)

      if (data) {
        await dispatch('SET_AUTH', {token: data.access_token})

        commit('user/SET', data.user, root)

        await dispatch('authorPlan/GET', {}, root)

        this.$i18n.setLocale(data.user.lang_code)
      }
      return data
    } catch (err) {
      await dispatch('CLEAR_AUTH')
    }
  },

  async LOGOUT({commit, dispatch}) {
    try {
      await this.$axios.$post('/auth/logout/')
    } catch (err) {
    } finally {
      if (this.$cookies.get('requisites_modal')) {
        this.$cookies.remove('requisites_modal')
      }
      await dispatch('CLEAR_AUTH')
    }
  },

  async RESET_PWD({commit, dispatch}, data) {
    const res = await this.$axios.$post('/auth/new-password', data)

    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: this.$i18n.t('authPages.passwordChanged'),
        type: 'success',
        dismissAfter: 4000,
      },
      root
    )
    return res
  },

  async REQUEST_PWD_RESET({commit, dispatch}, params) {
    await this.$axios.$post('/auth/password-reset', params)

    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: this.$i18n.t('authPages.letterWasSent'),
        type: 'success',
        dismissAfter: 4000,
      },
      root
    )
  },
}
