const TOKEN_NAME = 'jwt-token'
const getAuthTokenFromLS = () => {
  if (!process.client) {
    console.warn(`LocalStorage is not exist on server side, therefore token won't return`)
    return undefined
  }
  return localStorage.getItem(TOKEN_NAME)
}

export default ({app, store}, inject) => {
  const setToken = (token) => {
    if (!token) return

    const opts = {path: '/', maxAge: 100000000}
    app.$cookies.set(TOKEN_NAME, token, opts)
    if (process.client) {
      localStorage.setItem(TOKEN_NAME, token)
    }
  }
  const getToken = () => {
    const cookiejwt = app.$cookies.get(TOKEN_NAME)
    return app.$cookies.get(TOKEN_NAME) || getAuthTokenFromLS()
  }
  const removeAuth = () => {
    ;['user', TOKEN_NAME, 'expires'].map((v) => app.$cookies.remove(v))
    if (process.client) {
      localStorage.removeItem(TOKEN_NAME)
    }
  }

  const setUser = (user) => {
    if (user) {
      const opts = {path: '/', maxAge: 100000000}
      app.$cookies.set('user', JSON.stringify(user), opts)
      if (process.client) {
        localStorage.setItem('user', JSON.stringify(user))
      }
      return
    }
    app.$cookies.remove('user')
    if (process.client) {
      localStorage.removeItem('user')
    }
  }

  const authUtils = {
    setUser,
    setToken,
    getToken,
    removeAuth,
  }

  inject('getAuthTokenFromLS', getAuthTokenFromLS)
  inject('authUtils', authUtils)
}
