const PWD_LENGTH = 15

function generate(length = PWD_LENGTH) {
  return Array(length)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*')
    .map((x) => x[Math.floor(Math.random() * x.length)])
    .join('')
}

export default {
  generate,
}
