<template>
  <Speakers
    v-if="show"
    class="webinar-speakers"
    :speakers="displaySpeakers"
    :editable="editable"
    @create="onChange($event, 'create')"
    @edit="onChange($event, 'update')"
    @delete="onChange($event, 'delete')"
  />
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import Speakers from '@/components/Speakers'

export default {
  components: {
    Speakers,
  },
  props: {
    webinar: {
      type: Object,
      default: () => ({}),
    },
    editable: Boolean,
  },

  computed: {
    ...mapGetters({
      speakers: 'webinars/speakers',
    }),
    displaySpeakers() {
      if (!this.webinar) return

      return this.speakers.map((s) => this.formatData(s))
    },
    show() {
      return this.editable ? true : this.speakers.length > 0
    },
  },
  methods: {
    ...mapActions({
      create: 'webinars/createSpeaker',
      update: 'webinars/updateSpeaker',
      delete: 'webinars/removeSpeaker',
    }),
    formatData(data, decode = true) {
      if (decode) {
        return {
          ...data,
          full_name: data.name,
          title: data.name,
          photo: data.avatar,
        }
      }

      return {
        ...data,
        name: data.title,
        avatar: data.photoFile,
      }
    },

    async onChange(speaker = {}, action = 'create') {
      if (!this.webinar || !this.webinar.id || !this[action]) return

      const id = action === 'create' ? this.webinar.id : speaker.id

      speaker = this.formatData(speaker, false)

      if (!(speaker.avatar instanceof File)) {
        delete speaker.avatar
      }

      await this[action]({id, params: speaker})
    },
  },
}
</script>
