<template>
  <div class="bot-card--grid">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BotCardGrid',
}
</script>

<style lang="scss">
.bot-card--grid {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0fr));
}
</style>
