export const ROLES = {
  author: 'author',
  student: 'student',
  partner: 'promo_partner',
  moderator: 'moderator',
  admin: 'admin',
}

export const COURSE_SENSES = {
  TOP250: 'top-250',
  POPULAR: 'popular',
  BRAND_NEW: 'brand-new',
}

export const COURSE_STATUS = {
  declined: 'declined',
  unpublished: 'unpublished',
  draft: 'draft',
  published: 'published',
}

export const COURSE_TYPES = {
  normal: 10,
  marathon: 20,
  individual_marathon: 30,
}

export const USER_STATUS = {
  not_active: 0,
  email_confirmed: 10,
  social_registed: 15,
  phone_confirmed: 20,
  banned: -20,
}

export const NOTIFICATIONS = {
  course_published: 'bluebell.moderator.published.course',
  course_declined: 'bluebell.moderator.declined.course',
  user_created_order: 'system.crm.student.created.order-form',
  user_bought_product: 'system.crm.student.bought.product',
  user_requested_moderation: 'author.send-to-moderation.course',
  chat_new_msg: 'user.send.message',
  chat_new_review: 'author.reviewed.homework',
  chat_student_send_homework: 'student.send-to-review.homework',
  authorRejectHomework: 'bluebell.author.rejected.student_homework',
  authorApproveHomework: 'bluebell.author.approved.student_homework',
  legalDetailsExpires: 'bluebell.author.expire-legal-details',
  legalDetailsApproved: 'bluebell.moderator.approved.legal-details',
  legalDetailsRejected: 'bluebell.moderator.reject.legal-details',
  firstSell: 'gtm.author.product.first-sell',
  authorCompletedImportVkGroups: 'bluebell.system.author.completed-import-vk-groups',
  webinarIsStarted: 'bluebell.system.online_webinar.started',
  webinarIsStartingSoon: 'bluebell.system.online_webinar.starting-soon',
  webinarShouldStart: 'bluebell.system.online_webinar.should-start',
  webinarScreenAlert: 'bluebell.system.online_webinar.start-sharing-media',
}

export const PROFILE_TAB = {
  data: 'profile-data',
  pass: 'profile-password',
  notifications: 'profile-notifications',
  payments: 'profile-payments',
  tariffs: 'plans',
  subs: 'subs',
}

export const COURSE_EDITOR = {
  max_categories: 5,
}

export const MODALS = {
  phone_modal: 'phone_modal',
  addtag_modal: 'addtag_modal',
  upgrade_plan: 'upgrade_plan',
  plan_reminder: 'plan_reminder',
  new_pwd: 'new_pwd',
  legal_details_notifyer: 'legal_details_note',
  promocode: 'promocode',
  reg_phone: 'reg_phone',
}

export const VK_AUTH = process.env.API_BASE_URL + '/social-auth/vkontakte'
