export const PAYMENT_CONST = {
  hasUser: 'has_user',
  tinkoff: 'tinkoff',
  stripe: 'stripe',
  rbk: 'rbk',
  rbkLinked: 'rbk-linked',
  none: 'none',
  free: 'free',
  tinkoffReccurent: 'tinkoff_reccurent',
}
