<template>
  <div class="course-cropper meleton-popup-form-wrapper active">
    <div class="meleton-popup-form-scroller sl--scrollable">
      <div class="meleton-popup meleton-popup--crop">
        <div class="meleton-popup__wrap">
          <a href="#" class="meleton-popup__close" @click="close">
            <Icon src="icons/close.svg" size="s" />
          </a>
          <div class="popup-title">{{ title }}</div>
          <div class="crop__wrapper">
            <div class="crop">
              <VueCropper
                ref="cropper"
                :viewMode="viewMode"
                :src="imgSrc"
                :aspectRatio="rules.aspectRatio"
                :crop="cropData"
                class="crop__module"
                v-bind="$attrs"
                v-on="$listeners"
              />
              <div v-if="cropWidth || cropHeight" class="crop__info">
                {{ cropWidth }} x {{ cropHeight }} px
              </div>
            </div>
          </div>
          <div class="meleton-form__item">
            <button class="btn btn--md btn--block" type="submit" @click="crop">
              {{ $t('common.ok') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {mapActions} from 'vuex'

import VueCropper from 'vue-cropperjs'

const propFile = typeof File !== 'undefined' && File

export default {
  name: 'ImageCropperModal',

  components: {
    VueCropper,
  },

  props: {
    title: {type: String, default: ''},
    imgSrc: {type: String, default: ''},
    imgFile: {type: [Object, propFile], default: null},
    viewMode: {type: [String, Number], default: 3},
    rules: {type: Object, default: () => ({})},
  },

  data() {
    return {
      cropWidth: null,
      cropHeight: null,
    }
  },

  watch: {
    imgSrc() {
      this.$refs.cropper.replace(this.imgSrc)
    },
  },

  methods: {
    ...mapActions({
      toast: ADD_TOAST_MESSAGE,
    }),
    close(e) {
      e.preventDefault()
      this.$emit('close')
    },
    crop() {
      const {minWidth, minHeight, maxWidth, maxHeight} = this.rules

      if (this.cropWidth < minWidth || this.cropHeight < minHeight) {
        this.toast({
          text: `${this.$t('media.minImgSize')}: ${minWidth}x${minHeight}px`,
          type: 'danger',
          dismissAfter: 4000,
        })
        return
      }
      if (this.cropWidth > maxWidth || this.cropHeight > maxHeight) {
        this.toast({
          text: `${this.$t('media.maxImgSize')}: ${maxWidth}x${maxHeight}px`,
          type: 'danger',
          dismissAfter: 4000,
        })
        return
      }
      const img = this.$refs.cropper.getCroppedCanvas({
        fillColor: '#fff',
      })

      img.toBlob((blob) => {
        console.log(this.$refs.cropper)

        const file = new File([blob], 'course_image.jpg', {
          size: blob.size,
          type: 'image/jpg',
        })

        if (!this.imgFile)
          console.warn(
            `ImageCropperModal.vue -- expected imgFile prop. Event "dataUrl" won't be emitted`
          )
        if (this.imgFile) {
          const imgDataUrl = img.toDataURL(this.imgFile.type)
          this.$emit('dataUrl', imgDataUrl)
        }

        this.$emit('cropped', file)
      }, 'image/jpg')
    },
    cropData({detail}) {
      this.cropWidth = Math.round(detail.width)
      this.cropHeight = Math.round(detail.height)
    },
  },
}
</script>

<style lang="scss">
.course-cropper {
  background-color: rgba(0, 0, 0, 0.7);
  .crop {
    &__info {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
.crop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  &__wrapper {
    position: relative;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 30px;
  }
  &__module {
    width: 100%;
    height: 100%;
  }
}
</style>
