<template>
  <PopupWrapper :title="titleText" v-bind="$attrs" @close="onClose">
    <Input
      v-model="bot.key"
      :error="$v.bot.key.$error"
      :label="$t('funnels.botKey')"
      @blur="$v.bot.key.$touch()"
    />
    <Input
      v-model="bot.desc"
      :error="$v.bot.desc.$error"
      :label="$t('funnels.botDesc')"
      @blur="$v.bot.desc.$touch()"
    />
    <!-- <Input
      v-model="bot.name"
      class="mb-2"
      :error="$v.bot.name.$error"
      :errorMessage="botNameErr"
      label="Никнейм бота:"
      @blur="$v.bot.name.$touch()"
    /> -->
    <!-- <TextArea
      v-model="bot.startMessage"
      :error="$v.bot.startMessage.$error"
      :placeholder="$t('funnels.welcomingText')"
      @blur="$v.bot.startMessage.$touch()"
    >
      Приветственное сообщение
    </TextArea> -->
    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSave">{{ btnText }}</Button>
    <Button
      v-if="canDelete"
      custom
      :styles="{
        color: '#fff',
        backgroundColor: '#e33131',
        backgroundHoverColor: '#ea3131',
      }"
      class="mt-1"
      icon="/icons/cross.svg"
      @click="onDelete"
    >
      {{ $t('course.delete') }}
    </Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input} from '@/components/ui'

// const botNameValidator = (str = '') => str.endsWith('_bot') || str.endsWith('Bot')

const defState = () => ({
  key: '',
  // name: '',
  desc: '',
  startMessage: '',
})

export default {
  components: {
    PopupWrapper,
    Button,
    Input,
    // TextArea,
  },
  props: {
    editBot: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    bot: {
      key: {required},
      desc: {required},
      // name: {required, botNameValidator},
      // startMessage: {required},
    },
  },
  data: () => ({
    bot: defState(),
    botId: null,
  }),
  computed: {
    botNameErr() {
      if (!this.$v.bot.name.required) return this.$t('forms.rules.requiredField')
      if (!this.$v.bot.name.botNameValidator) return this.$t('funnels.botNameHint')
      return ''
    },
    isEditing() {
      return !isEmpty(this.editBot)
    },
    btnText() {
      return this.isEditing ? this.$t('course.edit') : this.$t('funnels.addBotShort')
    },
    titleText() {
      return this.isEditing ? this.$t('funnels.editBot') : this.$t('funnels.addBot')
    },
    canDelete() {
      // is editing
      return this.isEditing && this.botId
    },
  },
  watch: {
    editBot(v) {
      this.bot = {
        key: this.editBot.token,
        desc: this.editBot.description,
        // name: this.editBot.user_name,
        // startMessage: this.editBot.start_message,
      }
      this.botId = this.editBot.id
    },
  },
  methods: {
    async onDelete() {
      await this.$store.dispatch('funnelBots/DELETE_TG_BOT', {id: this.botId})
      this.onClose()
    },
    onClose() {
      this.$emit('close')
      this.bot = defState()
      this.botId = null
      this.$v.bot.$reset()
    },
    async onSave() {
      this.$v.bot.$touch()
      if (this.$v.bot.$invalid) return

      const bot = this.bot
      const params = {
        token: bot.key,
        description: bot.desc,
        // user_name: bot.name,
        // start_message: bot.startMessage,
      }

      if (this.isEditing) {
        await this.$store.dispatch('funnelBots/UPDATE_TG_BOT', {params, id: this.botId})
      } else {
        await this.$store.dispatch('funnelBots/CREATE_TG_BOT', {params})
      }
      this.onClose()
    },
  },
}
</script>
