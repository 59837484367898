<template>
  <div class="block-card" :style="color ? {background: color} : {}">
    <div class="block-card__content">
      <img :src="data.image" :alt="data.title" />
      <div class="block-card__text">
        <Typography class="title" :color="dark ? 'dark' : 'light'" variant="int_l">
          {{ data.title }}
        </Typography>

        <Typography
          v-if="data.description"
          class="description"
          variant="paragraph_m"
          :color="dark ? 'grey' : 'light'"
        >
          {{ data.description }}
        </Typography>
      </div>
    </div>

    <Button
      v-if="data.button"
      :href="data.link"
      :target="data.blank ? '_blank' : '_self'"
      rel="noopener"
      :label="data.button.label"
      :color="data.button.color"
      :bgColor="data.button.bg"
      @click="$emit('clicked')"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        link: '',
        blank: false,
        image: '',
        title: '',
        description: '',
        button: {
          bg: '#6666fe',
          color: '#fff',
          label: 'Подписаться',
        },
      }),
    },
    color: {
      type: [String, Boolean],
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.block-card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  border-radius: 24px;
  text-align: left;

  &__content {
    display: flex;
    align-items: center;
  }

  &__text {
    margin-left: 35px;
  }

  .title {
    margin-bottom: 6px;
  }

  .app-btn {
    padding: 0 30px;
  }

  img {
    max-width: 125px;
  }

  @include tablet {
    .title {
      font-size: 16px;
      line-height: 28px;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
    }

    .app-btn {
      padding: 0 40px;
      margin-left: 40px;
    }
  }

  @include media($tablet - 1) {
    padding: 20px 32px 24px;

    .title {
      font-size: 14px;
      line-height: 20px;
    }

    .description {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
