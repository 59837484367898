import {ROLES} from '../consts'

const PageAuthorCourseCreate = () =>
  import(/* webpackChunkName: "PageAuthorCourseCreate" */ '@/pages/author/course/create').then(
    (m) => m.default || m
  )

const PageAuthorSubscriptionCreate = () =>
  import(
    /* webpackChunkName: "PageAuthorSubscriptionCreate" */ '@/pages/author/subscriptions/create'
  ).then((m) => m.default || m)

const PageAuthorSubscriptionEdit = () =>
  import(
    /* webpackChunkName: "PageAuthorSubscriptionEdit" */ '@/pages/author/subscriptions/edit'
  ).then((m) => m.default || m)

export const authorRoutes = [
  {
    path: '/plans',
    component: () =>
      import(/* webpackChunkName: "PagePlans" */ '@/pages/plans').then((m) => m.default || m),
    name: 'plans',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author],
      title: 'landing.tariffs',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/homeworks/',
    component: () =>
      import(/* webpackChunkName: "PageHomeworks" */ '@/pages/homeworks').then(
        (m) => m.default || m
      ),
    name: 'homeworks',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author],
      title: 'course.checkHw',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },

  {
    path: '/homeworks/review/:course/',
    component: () =>
      import(/* webpackChunkName: "PageHomeworksReview" */ '@/pages/homeworks/Review').then(
        (m) => m.default || m
      ),
    name: 'homework.review',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author],
      title: 'course.homeworkShort',
      breadcrumbs: [
        {
          title: 'course.checkHw',
          name: 'homeworks',
        },
      ],
    },
  },
  {
    path: '/author/courses/',
    component: () =>
      import(/* webpackChunkName: "PageAuthorCourses" */ '@/pages/author/courses').then(
        (m) => m.default || m
      ),
    name: 'courses.list',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author],
      title: 'page.authorCourses',
    },
  },
  {
    path: '/author/course/create',
    component: PageAuthorCourseCreate,
    name: 'courses.create',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author, ROLES.admin, ROLES.moderator],
      title: 'page.courseCreation',
      breadcrumbs: [
        {
          title: 'courses.myCourses',
          name: 'courses.list',
        },
      ],
    },
  },
  {
    path: '/author/course/:courseId',
    redirect: {name: 'course.settings'},
    component: PageAuthorCourseCreate,
    name: 'course.view',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author, ROLES.admin, ROLES.moderator],
      title: 'page.editCourse',
      breadcrumbs: [
        {
          title: 'courses.myCourses',
          name: 'courses.list',
        },
      ],
    },
    children: [
      {
        path: 'modules/lessons/:moduleItemId',
        component: () =>
          import(
            /* webpackChunkName: "PageAuthorCourseLessonConstructor" */ '@/pages/author/course/lessonConstructor'
          ).then((m) => m.default || m),
        name: 'course.lesson-constructor',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author, ROLES.admin, ROLES.moderator],
          title: 'page.addCourse',
          breadcrumbs: [
            {
              title: 'courses.myCourses',
              name: 'courses.list',
            },
            {
              title: 'page.editCourse',
              name: 'course.content',
            },
            {
              title: 'page.editLesson',
            },
          ],
        },
      },
      {
        path: 'settings',
        component: () =>
          import(
            /* webpackChunkName: "PageAuthorCourseSettings" */ '@/pages/author/course/settings'
          ).then((m) => m.default || m),
        name: 'course.settings',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author, ROLES.admin, ROLES.moderator],
          title: 'page.courseSettings',
          breadcrumbs: [
            {
              title: 'courses.myCourses',
              name: 'courses.list',
            },
          ],
        },
      },
      {
        path: 'course-content',
        component: () =>
          import(
            /* webpackChunkName: "PageAuthorCourseContent" */ '@/pages/author/course/content'
          ).then((m) => m.default || m),
        name: 'course.content',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author, ROLES.admin, ROLES.moderator],
          title: 'page.courseSettings',
          breadcrumbs: [
            {
              title: 'courses.myCourses',
              name: 'courses.list',
            },
          ],
        },
      },
      {
        path: 'course-bundles',
        component: () =>
          import(
            /* webpackChunkName: "PageAuthorCourseBundles" */ '@/pages/author/course/bundles'
          ).then((m) => m.default || m),
        name: 'course.bundles',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author, ROLES.admin, ROLES.moderator],
          title: 'page.courseSettings',
          breadcrumbs: [
            {
              title: 'courses.myCourses',
              name: 'courses.list',
            },
          ],
        },
      },
      {
        path: 'widgets',
        component: () => import('@/pages/author/course/widgets').then((m) => m.default || m),
        name: 'course.widgets',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author, ROLES.admin, ROLES.moderator],
          title: 'widgets.title',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'students',
        component: () =>
          import(
            /* webpackChunkName: "PageAuthorCourseStudents" */ '@/pages/author/course/students'
          ).then((m) => m.default || m),
        name: 'course.students',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author],
          title: 'profile.students',
          breadcrumbs: [
            {
              title: 'courses.myCourses',
              name: 'courses.list',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/crm/',
    component: () =>
      import(/* webpackChunkName: "PageAuthorCrm" */ '@/pages/author/crm').then(
        (m) => m.default || m
      ),
    name: 'crm',
    meta: {
      title: 'page.orders',
      requiresAuth: true,
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/crm/user/:id',
    component: () =>
      import(/* webpackChunkName: "PageAuthorCrmCard" */ '@/pages/author/crm/UserCard').then(
        (m) => m.default || m
      ),
    name: 'crm.user-card',
    meta: {
      title: 'page.clientApplication',
      requiresAuth: true,
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'CRM',
          name: 'crm',
        },
      ],
    },
  },
  {
    path: '/marketing',
    component: () =>
      import(/* webpackChunkName: "PageMarketing" */ '@/pages/marketing').then(
        (m) => m.default || m
      ),
    redirect: {name: 'author.funnels.list'},
    name: 'author.marketing',
    meta: {
      requiresAuth: true,
      title: 'common.marketing',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
    children: [
      {
        path: 'email-list',
        component: () =>
          import(
            /* webpackChunkName: "PageMarketingEmailList" */ '@/pages/marketing/email-list'
          ).then((m) => m.default || m),
        name: 'author.funnels.emails',
        meta: {
          requiresAuth: true,
          title: 'funnels.emails',
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'funnels-list',
        component: () =>
          import(/* webpackChunkName: "PageFunnelsList" */ '@/pages/marketing/funnels-list').then(
            (m) => m.default || m
          ),
        name: 'author.funnels.list',
        meta: {
          requiresAuth: true,
          title: 'funnels.title',
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'leads-generator',
        component: () => import('@/pages/marketing/leads-generator').then((m) => m.default || m),
        name: 'author.funnels.leads',
        meta: {
          requiresAuth: true,
          title: 'Страница лидогенерации',
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'tg-bots',
        component: () =>
          import(/* webpackChunkName: "PageMarketingTgBots" */ '@/pages/marketing/tg-bots').then(
            (m) => m.default || m
          ),
        name: 'author.funnels.tg',
        meta: {
          requiresAuth: true,
          title: 'page.tgBots',
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'vk-groups',
        component: () =>
          import(
            /* webpackChunkName: "PageMarketingVkGroups" */ '@/pages/marketing/vk-groups'
          ).then((m) => m.default || m),
        name: 'author.funnels.vk',
        meta: {
          requiresAuth: true,
          title: 'page.vk',
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'promo',
        component: () =>
          import(/* webpackChunkName: "PageMarketingPromo" */ '@/pages/marketing/promo').then(
            (m) => m.default || m
          ),
        name: 'author.marketing.promo',
        meta: {
          requiresAuth: true,
          roles: [ROLES.author],
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
    ],
  },
  {
    path: '/widgetswizard/:id/editor',
    component: () => import('@/pages/author/widgetswizard/editor').then((m) => m.default || m),
    name: 'funnels.widget-generator-editor',
    meta: {
      requiresAuth: true,
      title: 'Редактор виджета',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'widgets.title',
          name: 'widgetswizard',
        },
      ],
    },
  },
  {
    path: '/leads-generator/:id/editor',
    component: () => import('@/pages/marketing/leads-generator/editor').then((m) => m.default || m),
    name: 'funnels.leads-generator-editor',
    meta: {
      requiresAuth: true,
      title: 'Редактор страницы',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'leadsPage.nn',
          name: 'author.funnels.leads',
        },
      ],
    },
  },
  {
    path: '/funnels/funnel/:id/funnel-builder',
    component: () =>
      import(/* webpackChunkName: "PageFunnelsBuilder" */ '@/pages/marketing/funnel-builder').then(
        (m) => m.default || m
      ),
    name: 'author.funnels.builder',
    meta: {
      requiresAuth: true,
      title: 'funnels.title',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'funnels.title',
          name: 'author.funnels.list',
        },
      ],
    },
  },
  {
    path: '/marketing/email/:id/email-editor',
    component: () =>
      import(/* webpackChunkName: "PageFunnelsEmailEdit" */ '@/pages/marketing/email-editor').then(
        (m) => m.default || m
      ),
    name: 'marketing.email-editor',
    meta: {
      requiresAuth: true,
      title: 'page.letterEditor',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'funnels.title',
          name: 'author.funnels.emails',
        },
      ],
    },
  },
  {
    path: '/payments',
    component: () =>
      import(/* webpackChunkName: "PageAuthorPayments" */ '@/pages/author/payments').then(
        (m) => m.default || m
      ),
    name: 'payments',
    meta: {
      requiresAuth: true,
      title: 'page.payments',
      roles: [ROLES.author, ROLES.partner],
    },
  },
  {
    path: '/subscriptions',
    component: () =>
      import(/* webpackChunkName: "PageAuthorSubscriptions" */ '@/pages/author/subscriptions').then(
        (m) => m.default || m
      ),
    name: 'subscriptions',
    meta: {
      requiresAuth: true,
      title: 'page.subscriptions',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/subscriptions/create',
    component: PageAuthorSubscriptionCreate,
    name: 'subscriptions.create',
    meta: {
      requiresAuth: true,
      title: 'page.newSubscription',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.newSubscription',
          name: 'subscriptions',
        },
      ],
    },
  },
  {
    path: '/subscriptions/edit/:id',
    component: PageAuthorSubscriptionEdit,
    name: 'subscriptions.edit',
    meta: {
      requiresAuth: true,
      title: 'page.subscriptions',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.subscriptions',
          name: 'subscriptions',
        },
      ],
    },
  },
  {
    path: '/widgetswizard',
    component: () => import('@/pages/author/widgetswizard').then((m) => m.default || m),
    name: 'widgetswizard',
    meta: {
      requiresAuth: true,
      title: 'page.widgetswizard',
      roles: [ROLES.author],
      breadcrumbs: [
        {
          title: 'page.widgetswizard',
          name: 'main',
        },
      ],
    },
  },
]
