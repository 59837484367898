import {
  mergeDeepRight,
  mergeDeepWith,
  equals,
  reject,
  prop,
  map,
  mapObjIndexed,
  isNil,
  isEmpty,
  pipe,
} from 'ramda'

// const compareLeftRight = (l, r) => (l === r ? null : l)
const diff = pipe(
  mergeDeepWith(function (l, r) {
    if (l && typeof l === 'object') return l
    if (Array.isArray(l)) return l
    return equals(l, r) ? null : l
  }),
  reject(isNil)
)

const options = {
  paymentFrequency: [
    {title: 'Месяц', value: 1},
    {title: '3 месяца', value: 3},
    {title: '6 месяцев', value: 6},
    {title: 'Год', value: 12},
  ],
  // type: [
  //   {title: 'Подписка', type: 'sub'},
  //   {title: 'Единоразовая оплата', type: 'single'},
  // ],
  // singleTimeBuying: [
  //   {title: 'Навсегда', value: 0},
  //   {title: 'Неделя', value: 0},
  //   {title: '1 месяц', value: 1},
  //   {title: '3 месяца', value: 3},
  //   {title: '6 месяцев', value: 3},
  //   {title: '12 месяцев', value: 12},
  // ],
}

// map state to api params
const mapSubscriptionState = (sub) => {
  return reject(isNil, {
    is_active: sub.isActive,
    preview_image: sub.previewFile,
    title: sub.title,
    description: sub.description,
    price: sub.price,
    currency: sub.currency,
    term_in_month: sub.paymentFrequency,
    uuid: sub.urlName,
    button_text: sub.buttonText,
    button_color: sub.buttonColor,
    button_text_color: sub.buttonTextColor,
    author_photo: sub.avatarImage,
    author_name: sub.avatarName,
    product_ids: map(prop('id'), sub.selectedProducts),

    is_author_shown: sub.is_author_shown || true,
  })
}

const createFormData = (obj) => {
  return Object.keys(obj).reduce((formData, key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((v, idx) => {
        formData.append(`${key}[${idx}]`, v)
      })
    } else {
      formData.append(key, obj[key])
    }
    return formData
  }, new FormData())
}

// map response to state format
const createSubscriptionState = (data, userName = '') => {
  const defaultState = {
    id: null,
    isActive: false,
    uuid: null,
    product_uid: null,
    previewFile: null,
    previewImg: '',
    title: '',
    // file
    avatarImage: null,
    avatarName: userName,
    // string url
    avatarPreview: '',
    is_author_shown: true,
    description: '',
    buttonText: 'Make subscription',
    buttonColor: '#6666fe',
    buttonTextColor: '#fff',
    price: '',
    currency: {name: 'EUR'},
    paymentFrequency: 1,
    urlName: '',
    selectedProducts: [],
  }

  const setDefaultIfEmpty = mapObjIndexed((val, key) => {
    return isNil(val) ? defaultState[key] : val
  })

  return setDefaultIfEmpty({
    id: data.id,
    isActive: data.is_active,
    uuid: data.uuid,
    product_uid: data.product_uid,
    previewFile: null,
    previewImg: data.preview_image,
    title: data.title,
    description: data.description,

    is_author_shown: true,
    // file
    avatarImage: null,
    avatarName: prop('title', data.author),
    // string url
    avatarPreview: prop('photo', data.author),
    buttonText: data.button_text,
    buttonColor: data.button_color,
    buttonTextColor: data.button_text_color,
    price: data.price,
    currency: data.currency,
    paymentFrequency: data.term_in_month,
    urlName: data.uuid,
    selectedProducts: data.selected_products,
  })
}

const defaultState = () => ({
  inProcess: false,
  isEdited: false,
  initialSubscriptionState: {},
  subscription: {},
})

export const state = () => defaultState()

export const getters = {
  subscription: (state) => state.subscription,
  subscriptionType: (state) => prop('type', state.subscription.type),
  selectorsOptions: (state) => options,
  isActive: (state) => state.subscription.isActive,
  isEdited: (state) => state.isEdited,
  inProcess: (state) => state.inProcess,
}

export const mutations = {
  INIT(state, {mapped}) {
    state.subscription = mapped
    state.initialSubscriptionState = mapped
    state.isEdited = false
  },
  RESET_SUBSCRIPTION(state) {
    state.subscription = mergeDeepRight(state.subscription, state.initialSubscriptionState)
    state.isEdited = false
  },
  RESET_STATE(state) {
    Object.assign(state, defaultState())
  },
  UPDATE_SUBSCRIPTION(state, subscription) {
    state.isEdited = true
    state.subscription = mergeDeepRight(state.subscription, subscription)
  },
  SET_BUTTON_PROCESS(state, payload) {
    state.inProcess = payload
  },
}

export const actions = {
  INIT({commit, rootGetters}, payload = {}) {
    const user = rootGetters['user/getUser']
    const mapped = createSubscriptionState(payload, user.full_name)
    commit('INIT', {mapped})
  },
  UPDATE_SUBSCRIPTION({commit}, subscription) {
    commit('UPDATE_SUBSCRIPTION', subscription)
  },
  RESET_OFFER({commit}) {
    commit('RESET')
  },
  async CREATE_SUBSCRIPTION({commit, dispatch, state}) {
    commit('SET_BUTTON_PROCESS', true)

    // map params from store to api
    const params = createFormData({
      ...mapSubscriptionState(state.subscription),
      is_active: true,
    })

    try {
      const data = await this.$axios.$post('/author/subscriptions', params)

      if (data.id) {
        this.$router.push({name: 'subscriptions.edit', params: {id: data.id}})
      }
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_BUTTON_PROCESS', false)
    }
  },
  async GET_SUBSCRIPTION({dispatch}, id) {
    const data = await this.$axios.$get(`/author/subscriptions/${id}`)

    await dispatch('INIT', data)
  },
  async EDIT_SUBSCRIPTION({commit, dispatch, state}) {
    if (!state.isEdited) return
    commit('SET_BUTTON_PROCESS', true)

    const id = state.subscription.id
    const params = createFormData(
      mapSubscriptionState(diff(state.subscription, state.initialSubscriptionState))
    )

    try {
      const data = await this.$axios.$post(`/author/subscriptions/${id}`, params)
      dispatch('INIT', data)
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_BUTTON_PROCESS', false)
    }
  },
  async CHANGE_SUBSCRIPTION_STATUS({commit, dispatch, rootState, state}, {type, id}) {
    const isActive = type === 'publish' && true
    commit('SET_BUTTON_PROCESS', true)

    try {
      const data = await this.$axios.$post(`/author/subscriptions/${id}`, {is_active: isActive})
      // update list (subscriptions page)
      if (!isEmpty(rootState.subscriptions.list)) {
        commit('subscriptions/UPDATE_LIST', data, {root: true})
      }
      // update one subscription (edit sub page)
      if (!isEmpty(state.subscription)) {
        dispatch('INIT', data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_BUTTON_PROCESS', false)
    }
  },
  async FETCH_CLIENT_SUBSCRIPTION({commit, dispatch}, uuid) {
    const data = await this.$axios.$get(`/subscriptions/${uuid}`)
    await dispatch('INIT', data)
  },
}

export const subscriptionModule = {
  state,
  getters,
  actions,
  mutations,
}
