<template>
  <picture class="async-image">
    <source v-if="srcWebp" :srcset="srcWebp" type="image/webp" />
    <img
      v-if="!disableLazy"
      :data-src="_src"
      :data-srcset="_srcSet"
      :alt="alt"
      :style="styles"
      :class="classess"
      v-bind="$attrs"
      v-on="$listeners"
      @error="handleErr"
    />
    <img
      v-else
      :src="_src"
      :srcset="_srcSet"
      :alt="alt"
      :class="classess"
      :style="styles"
      v-bind="$attrs"
      v-on="$listeners"
      @error="handleErr"
    />
    <div class="async-image__slot"><slot></slot></div>
  </picture>
</template>

<script>
import {type as isType} from 'ramda'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'

const isArray = (xs) => isType(xs) === 'Array'

export default {
  name: 'AsyncImage',
  props: {
    alt: {
      type: String,
      default: '',
    },
    // src prop types:
    // web/fallback prop: String | Array | undefined
    // if Array - first element 1x resolution, second element 2x resolution
    // if string - string with image url
    // if empty will return empty image
    src: {
      type: [Object, Array, String],
      default: () => ({
        webp: '',
        image: '',
      }),
    },
    image: {
      type: String,
      default: null,
    },
    contain: Boolean,
    styles: {
      type: String,
      default: '',
    },
    defaultImg: {
      type: String,
      default: '/assets/default.png',
    },
    disableLazy: Boolean,
  },
  data() {
    return {
      imgErr: false,
    }
  },
  computed: {
    classess() {
      return [
        'async-image__img',
        {'async-image__img--contain': this.contain, lazyload: !this.disableLazy},
      ]
    },
    srcWebp() {
      return this.getUrlsFromSrc(this.src.webp)
    },
    _srcSet() {
      if (!this.imgErr) {
        return this.getUrlsFromSrc(this.src.image || this.src) || this.defaultImg
      }
      return this.defaultImg
    },
    _src() {
      const fb = this.src.image || this.src
      if (isArray(fb)) return fb[0]
      else if (isType(fb) === 'String') return fb
      else return this.defaultImg
    },
  },
  methods: {
    handleErr(e) {
      this.imgErr = true
      this.$emit('imageError', true)
    },
    getUrlsFromSrc(src) {
      if (!src) return false

      if (isArray(src) && src.length > 1) {
        const [x1, x2] = src
        return `${x1} 1x, ${x2} 2x`
      } else if (isArray && src.length === 1) {
        return src[0]
      } else if (isType(src) === 'String') {
        return src
      }
    },
  },
}
</script>

<style lang="scss">
.async-image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: inherit;
  height: 100%;
  width: 100%;
  &__img {
    border-radius: inherit;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.19, 1, 0.22, 1);
    object-fit: cover;
    &--contain {
      object-fit: contain;
    }

    &[lazy='loading'] {
      opacity: 0;
    }

    &[lazy='loaded'] {
      opacity: 1;
    }
  }
  &__slot {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  /* fade image in after load */
  .lazyload,
  .lazyloading {
    opacity: 0;
  }
  .lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
}
</style>
