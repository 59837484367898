<template>
  <div class="flex flex-1 bot-preview">
    <div class="mr-4">
      <Avatar :user="{full_name: data.title, avatar: data.img}" class="bot-preview__img" />
    </div>
    <div class="flex flex-col justify-center bot-preview__texts">
      <Typography variant="int_s" noWrap>{{ data.title }}</Typography>
      <Typography
        :href="data.link"
        :title="data.link"
        component="a"
        target="_blank"
        variant="paragraph_xs"
        color="grey"
        noWrap
        >{{ data.link }}</Typography
      >
    </div>
  </div>
</template>

<script>
import {Typography, Avatar} from '@/components/ui'

export default {
  components: {
    Typography,
    Avatar,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        title: 'Rive School',
        link: '@riveschool',
      }),
    },
  },
}
</script>

<style lang="scss">
.bot-preview {
  overflow: hidden;
  margin-right: 10px;

  &__img {
    border-radius: 50%;
    max-width: 48px;
    max-height: 48px;
    height: 48px;
  }
  &__texts {
    overflow: hidden;
    flex: 1;
  }
}
</style>
