<template>
  <div v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: null, // any
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    href: {
      type: [String, Boolean],
      default: false,
    },
    color: {
      type: String,
      default: 'inherit',
    },
  },
  data() {
    return {
      isActive: this.active,
    }
  },
  watch: {
    active(value) {
      this.isActive = value
    },
  },
}
</script>

<style lang="scss" scoped></style>
