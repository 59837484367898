<template>
  <div class="webinar-status" :class="{dark}">
    <Badge :color="active ? '#70bcb5' : '#d97c7c'" size="8" dot inline :text="innerText" />
  </div>
</template>

<script>
import {Badge} from '@/components/ui'

export default {
  components: {
    Badge,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default() {
        return this.$t('webinars.live')
      },
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    inactiveLabel: {
      type: null,
      default() {
        return this.$t('webinars.notLive')
      },
    },
    dark: Boolean,
  },
  data() {
    return {
      interval: null,
      timer: 0,
      displayTime: '00:00',
    }
  },
  computed: {
    innerText() {
      const time = this.showTime ? this.displayTime : ''
      const activeLabel = this.showLabel ? `${this.label} ${time}` : this.displayTime

      return this.active ? activeLabel : this.inactiveLabel
    },
  },
  watch: {
    active(value) {
      this.handleTimer(value)
    },
  },
  beforeDestroy() {
    clearTimeout(this.interval)
  },
  methods: {
    handleTimer(active) {
      clearTimeout(this.interval)
      this.timer = null
      this.displayTime = '00:00'

      if (active) {
        setTimeout(() => {
          this.interval = setInterval(this.updateTime, 1000)
        }, 1000)
      }
    },
    updateTime(tm) {
      this.timer += 1

      const pad = (value) => ('0' + value).slice(-2)

      const hours = Math.floor(this.timer / 3600)
      const minutes = pad(Math.floor((this.timer - hours * 3600) / 60))
      const seconds = pad(this.timer - (hours * 3600 + minutes * 60))

      let minutesSeconds = `${minutes}:${seconds}`

      this.displayTime = hours ? `${pad(hours)}:${minutesSeconds}` : minutesSeconds
    },
  },
}
</script>

<style lang="scss">
.webinar-status {
  user-select: none;
  color: #fff;

  &.dark {
    .badge-wrapper {
      padding: 16px;
      border-radius: 16px;
      background: rgba($black, 0.4);
      color: #fff;
    }
  }
}
</style>
