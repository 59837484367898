const allPhoneMasksJson = {
  AC: {code: '247', mask: ['####']},
  AD: {code: '376', mask: ['###-###']},
  AE: {code: '971', mask: ['##-###-####']},
  AF: {code: '93', mask: ['##-###-####']},
  AG: {code: '1', mask: ['(268)###-####']},
  AI: {code: '1', mask: ['(264)###-####']},
  AL: {code: '355', mask: ['(###)###-###']},
  AM: {code: '374', mask: ['##-###-###']},
  AN: {code: '599', mask: ['####-####']},
  AO: {code: '244', mask: ['(###)###-###']},
  AQ: {code: '672', mask: ['1##-###']},
  AR: {code: '54', mask: ['###-#-##-###-###-###']},
  AS: {code: '1', mask: ['(684)###-####']},
  AT: {code: '43', mask: ['(###)###-####']},
  AU: {code: '61', mask: ['#-####-####']},
  AW: {code: '297', mask: ['###-####']},
  AZ: {code: '994', mask: ['##-###-##-##']},
  BA: {code: '387', mask: ['##-#####']},
  BB: {code: '1', mask: ['(246)###-####']},
  BD: {code: '880', mask: ['##-###-###']},
  BE: {code: '32', mask: ['(###)###-###']},
  BF: {code: '226', mask: ['##-##-####']},
  BG: {code: '359', mask: ['(###)###-###']},
  BH: {code: '973', mask: ['####-####']},
  BI: {code: '257', mask: ['##-##-####']},
  BJ: {code: '229', mask: ['##-##-####']},
  BM: {code: '1', mask: ['(441)###-####']},
  BN: {code: '673', mask: ['###-####']},
  BO: {code: '591', mask: ['###-###-###-###-###']},
  BR: {code: '55', mask: ['(##)###-###-####']},
  BS: {code: '1', mask: ['(242)###-####']},
  BT: {code: '975', mask: ['##-###-###']},
  BW: {code: '267', mask: ['##-###-###']},
  BY: {code: '375', mask: ['(##)###-##-##']},
  BZ: {code: '501', mask: ['###-####']},
  CA: {code: '1', mask: ['(###)###-####']},
  CD: {code: '243', mask: ['(###)###-###']},
  CF: {code: '236', mask: ['##-##-####']},
  CG: {code: '242', mask: ['##-###-####']},
  CH: {code: '41', mask: ['##-###-####']},
  CI: {code: '225', mask: ['##-###-###']},
  CK: {code: '682', mask: ['##-###']},
  CL: {code: '56', mask: ['#-####-####']},
  CM: {code: '237', mask: ['####-####']},
  CN: {code: '86', mask: ['##-#####-#####']},
  CO: {code: '57', mask: ['###-###-###-###-###']},
  CR: {code: '506', mask: ['####-####']},
  CU: {code: '53', mask: ['###-###-###-###-###']},
  CV: {code: '238', mask: ['(###)##-##']},
  CW: {code: '599', mask: ['###-####']},
  CY: {code: '357', mask: ['##-###-###']},
  CZ: {code: '420', mask: ['(###)###-###']},
  DE: {
    code: '49',
    mask: ['###-###-###-###-###'],
  },
  DJ: {code: '253', mask: ['##-##-##-##']},
  DK: {code: '45', mask: ['##-##-##-##']},
  DM: {code: '1', mask: ['###-###-###-###-###']},
  DO: {code: '1', mask: ['###-###-###-###-###']},
  DZ: {code: '213', mask: ['##-###-####']},
  EC: {code: '593', mask: ['##-###-####']},
  EE: {code: '372', mask: ['####-####', '###-####']},
  EG: {code: '20', mask: ['(###)###-####']},
  ER: {code: '291', mask: ['#-###-###']},
  ES: {code: '34', mask: ['###-###-###-###-###']},
  ET: {code: '251', mask: ['##-###-####']},
  FI: {code: '358', mask: ['(###)###-##-##']},
  FJ: {code: '679', mask: ['##-#####']},
  FK: {code: '500', mask: ['#####']},
  FM: {code: '691', mask: ['###-####']},
  FO: {code: '298', mask: ['###-###']},
  FR: {code: '262', mask: ['(###)###-###']},
  GA: {code: '241', mask: ['#-##-##-##']},
  GD: {code: '1', mask: ['(473)###-####']},
  GE: {code: '995', mask: ['(###)###-###']},
  GF: {code: '594', mask: ['#####-####']},
  GH: {code: '233', mask: ['(###)###-###']},
  GI: {code: '350', mask: ['###-#####']},
  GL: {code: '299', mask: ['##-##-##']},
  GM: {code: '220', mask: ['(###)##-##']},
  GN: {code: '224', mask: ['##-###-###']},
  GQ: {code: '240', mask: ['##-###-####']},
  GR: {code: '30', mask: ['(###)###-####']},
  GT: {code: '502', mask: ['#-###-####']},
  GU: {code: '1', mask: ['(671)###-####']},
  GW: {code: '245', mask: ['#-######']},
  GY: {code: '592', mask: ['###-####']},
  HK: {code: '852', mask: ['####-####']},
  HN: {code: '504', mask: ['####-####']},
  HR: {code: '385', mask: ['##-###-###']},
  HT: {code: '509', mask: ['##-##-####']},
  HU: {code: '36', mask: ['(###)###-###']},
  ID: {
    code: '62',
    mask: ['(###)###-##-###'],
  },
  IE: {code: '353', mask: ['(###)###-###']},
  IL: {code: '972', mask: ['##-###-####']},
  IN: {code: '91', mask: ['(####)###-###']},
  IO: {code: '246', mask: ['###-####']},
  IQ: {code: '964', mask: ['(###)###-####']},
  IR: {code: '98', mask: ['(###)###-####']},
  IS: {code: '354', mask: ['###-####']},
  IT: {code: '39', mask: ['(###)####-###']},
  JM: {code: '1', mask: ['(876)###-####']},
  JO: {code: '962', mask: ['#-####-####']},
  JP: {code: '81', mask: ['##-####-####']},
  KE: {code: '254', mask: ['###-######']},
  KG: {code: '996', mask: ['(###)###-###']},
  KH: {code: '855', mask: ['##-###-###']},
  KI: {code: '686', mask: ['##-###']},
  KM: {code: '269', mask: ['##-#####']},
  KN: {code: '1', mask: ['(869)###-####']},
  KP: {
    code: '850',
    mask: ['####-#############'],
  },
  KR: {code: '82', mask: ['##-###-####']},
  KW: {code: '965', mask: ['####-####']},
  KY: {code: '1', mask: ['(345)###-####']},
  KZ: {code: '7', mask: ['(###)###-##-##']},
  LA: {code: '856', mask: ['(####)###-###']},
  LB: {code: '961', mask: ['##-###-###', '#-###-###']},
  LC: {code: '1', mask: ['(758)###-####']},
  LI: {code: '423', mask: ['(###)###-####']},
  LK: {code: '94', mask: ['##-###-####']},
  LR: {code: '231', mask: ['##-###-###']},
  LS: {code: '266', mask: ['#-###-####']},
  LT: {code: '370', mask: ['###-###-###-###-###']},
  LU: {code: '352', mask: ['(###)###-###']},
  LV: {code: '371', mask: ['###-###-###-###-###']},
  LY: {code: '218', mask: ['##-###-####']},
  MA: {code: '212', mask: ['##-####-###']},
  MC: {code: '377', mask: ['(###)###-###', '##-###-###']},
  MD: {code: '373', mask: ['####-####']},
  ME: {code: '382', mask: ['##-###-###']},
  MG: {code: '261', mask: ['##-##-#####']},
  MH: {code: '692', mask: ['###-####']},
  MK: {code: '389', mask: ['##-###-###']},
  ML: {code: '223', mask: ['##-##-####']},
  MM: {code: '95', mask: ['##-###-###']},
  MN: {code: '976', mask: ['##-##-####']},
  MO: {code: '853', mask: ['####-####']},
  MP: {code: '1', mask: ['(670)###-####']},
  MQ: {code: '596', mask: ['(###)##-##-##']},
  MR: {code: '222', mask: ['##-##-####']},
  MS: {code: '1', mask: ['(664)###-####']},
  MT: {code: '356', mask: ['####-####']},
  MU: {code: '230', mask: ['###-####']},
  MV: {code: '960', mask: ['###-####']},
  MW: {code: '265', mask: ['#-####-####']},
  MX: {code: '52', mask: ['###-###-###-###-###']},
  MY: {code: '60', mask: ['###-###-####']},
  MZ: {code: '258', mask: ['##-###-###']},
  NA: {code: '264', mask: ['##-###-####']},
  NC: {code: '687', mask: ['##-####']},
  NE: {code: '227', mask: ['##-##-####']},
  NF: {code: '672', mask: ['3##-###']},
  NG: {
    code: '234',
    mask: ['(###)###-####'],
  },
  NI: {code: '505', mask: ['####-####']},
  NL: {code: '31', mask: ['##-###-####']},
  NO: {code: '47', mask: ['(###)##-###']},
  NP: {code: '977', mask: ['##-###-###']},
  NR: {code: '674', mask: ['###-####']},
  NU: {code: '683', mask: ['####']},
  NZ: {code: '64', mask: ['(###)###-###', '##-###-###', '(###)###-####']},
  OM: {code: '968', mask: ['##-###-###']},
  PA: {code: '507', mask: ['###-####']},
  PE: {code: '51', mask: ['###-###-###-###-###']},
  PF: {code: '689', mask: ['##-##-##']},
  PG: {code: '675', mask: ['(###)##-###']},
  PH: {code: '63', mask: ['(###)###-####']},
  PK: {code: '92', mask: ['(###)###-####']},
  PL: {code: '48', mask: ['(###)###-###']},
  PS: {code: '970', mask: ['##-###-####']},
  PT: {code: '351', mask: ['##-###-####']},
  PW: {code: '680', mask: ['###-####']},
  PY: {code: '595', mask: ['###-###-###-###-###']},
  QA: {code: '974', mask: ['####-####']},
  RE: {code: '262', mask: ['#####-####']},
  RO: {code: '40', mask: ['##-###-####']},
  RS: {code: '381', mask: ['##-###-####']},
  RU: {code: '7', mask: ['(###)###-##-##']},
  RW: {code: '250', mask: ['(###)###-###']},
  SA: {code: '966', mask: ['#-####-####']},
  SB: {code: '677', mask: ['###-####']},
  SC: {code: '248', mask: ['#-###-###']},
  SD: {code: '249', mask: ['##-###-####']},
  SE: {code: '46', mask: ['##-###-####']},
  SG: {code: '65', mask: ['####-####']},
  SH: {code: '290', mask: ['####']},
  SI: {code: '386', mask: ['##-###-###']},
  SK: {code: '421', mask: ['(###)###-###']},
  SL: {code: '232', mask: ['##-######']},
  SM: {code: '378', mask: ['####-######']},
  SN: {code: '221', mask: ['##-###-####']},
  SO: {code: '252', mask: ['##-###-###']},
  SR: {code: '597', mask: ['###-####']},
  SS: {code: '211', mask: ['##-###-####']},
  ST: {code: '239', mask: ['##-#####']},
  SV: {code: '503', mask: ['##-##-####']},
  SX: {code: '1', mask: ['(###)###-####']},
  SY: {code: '963', mask: ['##-####-###']},
  SZ: {code: '268', mask: ['##-##-####']},
  TC: {code: '1', mask: ['(###)###-####']},
  TD: {code: '235', mask: ['##-##-##-##']},
  TG: {code: '228', mask: ['##-###-###']},
  TH: {code: '66', mask: ['##-###-####', '##-###-###']},
  TJ: {code: '992', mask: ['##-###-####']},
  TK: {code: '690', mask: ['####']},
  TL: {code: '670', mask: ['###-#####']},
  TM: {code: '993', mask: ['#-###-####']},
  TN: {code: '216', mask: ['##-###-###']},
  TO: {code: '676', mask: ['#####']},
  TR: {code: '90', mask: ['(###)###-####']},
  TT: {code: '1', mask: ['(###)###-####']},
  TV: {code: '688', mask: ['######', '2####']},
  TW: {code: '886', mask: ['#-####-####', '####-####']},
  TZ: {code: '255', mask: ['##-###-####']},
  UA: {code: '380', mask: ['(##)###-##-##']},
  UG: {code: '256', mask: ['(###)###-###']},
  UK: {code: '44', mask: ['##-####-####']},
  US: {code: '1', mask: ['(###)###-####-###-###']},
  UY: {code: '598', mask: ['#-###-##-##']},
  UZ: {code: '998', mask: ['##-###-####']},
  VA: {code: '39', mask: ['#-###-#####']},
  VC: {code: '1', mask: ['(###)###-####']},
  VE: {code: '58', mask: ['###-###-###-###-###']},
  VG: {code: '1', mask: ['(###)###-####']},
  VI: {code: '1', mask: ['(###)###-####']},
  VN: {code: '84', mask: ['(###)####-###']},
  VU: {code: '678', mask: ['##-#####', '#####']},
  WF: {code: '681', mask: ['##-####']},
  WS: {code: '685', mask: ['##-####']},
  YE: {code: '967', mask: ['###-###-###']},
  ZA: {code: '27', mask: ['##-###-####']},
  ZM: {code: '260', mask: ['##-###-####']},
  ZW: {code: '263', mask: ['#-######']},
}

const allPhoneMasks = (iso) => {
  /* if (allPhoneMasksJson[iso]) {
    return allPhoneMasksJson[iso].mask
  } */
  return '###-###-###-###-###-#####'
}

export default allPhoneMasks
