export const state = () => ({
  dialogs: [],
})

export const mutations = {
  setStudentDialogs(state, dialogs) {
    state.dialogs = dialogs
  },
}
export const actions = {
  async fetchStudentDialogs({commit, dispatch}) {
    try {
      const data = await this.$axios.$get('/chat-messages/dialog-list')

      commit('setStudentDialogs', data.data)
    } catch (err) {}
  },
  clearDialogs({commit}) {
    commit('setStudentDialogs', [])
  },
}

export const namespaced = true
