<template>
  <ModalWrapper v-if="show" class="app-confirm-dialog">
    <div class="app-confirm-dialog__container">
      <Component :is="params.content" v-if="contentComponent" v-bind="params.props" />
      <Typography
        v-else-if="params.content"
        component="div"
        variant="h4"
        bottomGutter
        class="flex flex-col flex-1 justify-center"
        v-html="params.content"
      ></Typography>
      <div class="app-confirm-dialog__actions">
        <Button
          class="app-confirm-dialog__actions-btn"
          inverted
          @click="handleButtonClick($event, false)"
        >
          {{ $t(params.button.cancel) }}
        </Button>
        <Button class="app-confirm-dialog__actions-btn" @click="handleButtonClick($event, true)">
          {{ $t(params.button.confirm) }}
        </Button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import {Events} from './events'
import ModalWrapper from '@/components/Modals/ModalWrapper'

/*
 * problem3 how to write translation in frozen object
 */

const defParams = Object.freeze({
  content: `Вы уверены что хотите совершить это действие?`,
  button: {
    confirm: 'common.yes',
    cancel: 'common.no',
  },
  callback: () => {},
})

export default {
  components: {
    ModalWrapper,
  },
  data: () => ({
    show: false,
    params: defParams,
  }),
  computed: {
    contentComponent() {
      return this.params.content && typeof this.params.content === 'object'
    },
  },
  mounted() {
    Events.on('open', this.open)
  },
  beforeDestroy() {
    Events.removeAllListeners('open')
  },
  methods: {
    reset() {
      this.params = defParams
    },
    open(params) {
      this.reset()
      if (!params) this.params = defParams
      else if (typeof params === 'string') {
        this.params = {
          content: params,
          button: defParams.button,
        }
      } else {
        this.params = params
        if (!params.content) this.params.content = defParams.content
        if (!params.button) this.params.button = defParams.button
      }
      this.show = true
    },
    handleButtonClick(event, confirm) {
      this.show = false

      if (this.params.callback) {
        this.params.callback(confirm)
      }
      Events.emit('action', confirm)
    },
  },
}
</script>

<style lang="scss">
.app-confirm-dialog {
  text-align: center;

  &__container {
    margin: 10px;
    min-width: 300px;
    max-width: 320px;
    padding: 32px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 15px 30px 0 #2e3141;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &-btn {
      min-width: 110px;
    }
  }
}
</style>
