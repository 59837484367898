import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import Viewer from 'v-viewer'
import Paginate from 'vuejs-paginate'
import VueTheMask from 'vue-the-mask'
import VueContext from 'vue-context'

Vue.component('ContextMenu', VueContext)
Vue.component('Paginate', Paginate)
Vue.component('InfiniteLoading', InfiniteLoading)
Vue.use(VueTheMask)
Vue.use(Viewer)
