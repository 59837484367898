<template>
  <Component :is="renderComponent" :style="vars" :class="classess" v-bind="$attrs" v-on="$listeners"
    ><slot></slot
  ></Component>
</template>

<script>
const defaultVariantMapping = {
  // headings
  h1: 'h1',
  h2: 'h2',
  h3: 'h3', // 18px
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',

  // paragraph _ size
  paragraph_m: 'p',
  paragraph_s: 'p',
  paragraph_xs: 'p',

  // interactive _ size
  int_xl: 'p', // 30px
  int_l: 'p', // 18px
  int_m: 'p', // 16px
  int_s: 'p', // 14px
  int_xs: 'p', // 12px
}

export default {
  props: {
    variant: {
      type: String,
      default: 'paragraph_s',
      validator: (v) => {
        const keys = Object.keys(defaultVariantMapping)
        return keys.includes(v)
      },
    },
    component: {
      type: String,
      default: undefined,
    },
    bottomGutter: Boolean,
    color: {
      type: String,
      default: 'initial',
      /*   validator: (v) => {
        return [
          'initial',
          'inherit',
          'dark',
          'semidark',
          'light',
          'grey',
          'green',
          'success',
          'error',
          'purple',
        ].includes(v)
      }, */
    },
    noWrap: Boolean,
    breakAll: Boolean,
    display: {
      type: String,
      default: 'initial',
      validator: (v) => ['initial', 'block', 'inline'].includes(v),
    },
  },
  computed: {
    renderComponent() {
      return this.component || defaultVariantMapping[this.variant] || 'span'
    },
    vars() {
      return {
        '--typography-color': this.color,
      }
    },
    classess() {
      return {
        'typography-root': true,
        'typography-color': true,
        [`typography-${this.variant}`]: true,
        //    [`typography-color-${this.color}`]: this.color !== 'initial',
        [`typography-display-${this.display}`]: this.display !== 'initial',
        'typography-gutter': this.bottomGutter,
        'typography-nowrap': this.noWrap,
        'typography-link': this.component === 'a',
        'typography-break-all': this.breakAll,
      }
    },
  },
}
</script>

<style lang="scss">
$fontFamily: 'Roboto', 'Helvetica', sans-serif;
$fontFamilyPar: 'Roboto', 'Helvetica', sans-serif;

.typography-root {
  /* margin: 0; */
  font-family: $fontFamily;
  color: $black;

  &.typography-link {
    &:hover,
    &:focus {
      text-decoration: $purple underline;
    }
  }

  &.typography-color {
    color: var(--typography-color);

    /* &-dark {
      color: $black;
    }
    &-semidark {
      color: $semiDark;
    }
    &-light {
      color: #fff;
    }
    &-grey {
      color: $textGrey;
    }
    &-green,
    &-success {
      color: $green;
    }
    &-purple {
      color: $purpleText;
    }
    &-error {
      color: $error;
    }
    &-purple {
      color: $purple;
    }
    &-inherit {
      color: inherit;
    }*/
  }

  &.typography {
    &-break-all {
      word-break: break-all;
    }
    &-display {
      &-block {
        display: block;
      }
      &-inline {
        display: inline;
      }
    }
    &-gutter {
      margin-bottom: 14px;
    }
    &-nowrap {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-all;
    }
    &-h1 {
      font-size: 30px;
      font-weight: bold;
      line-height: 36px;
    }
    &-h2 {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }
    &-h3 {
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
    }
    &-h4 {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
    }
    &-h5 {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
    }
    &-h6 {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
    }
    &-paragraph_m {
      font-family: $fontFamilyPar;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
    }
    &-paragraph_s {
      font-family: $fontFamilyPar;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    }
    &-paragraph_xs {
      font-family: $fontFamilyPar;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }
    &-int_xl {
      font-size: 30px;
      font-weight: 600;
      line-height: 36px;
    }
    &-int_l {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
    }
    &-int_m {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }
    &-int_s {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    &-int_xs {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}
</style>
