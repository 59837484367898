const middleware = {}

middleware['authorized'] = require('../middleware/authorized.js')
middleware['authorized'] = middleware['authorized'].default || middleware['authorized']

middleware['forbid-authorized'] = require('../middleware/forbid-authorized.js')
middleware['forbid-authorized'] = middleware['forbid-authorized'].default || middleware['forbid-authorized']

middleware['global'] = require('../middleware/global.js')
middleware['global'] = middleware['global'].default || middleware['global']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['role-access'] = require('../middleware/role-access.js')
middleware['role-access'] = middleware['role-access'].default || middleware['role-access']

middleware['valid-plan'] = require('../middleware/valid-plan.js')
middleware['valid-plan'] = middleware['valid-plan'].default || middleware['valid-plan']

export default middleware
