<template>
  <div class="webinar-expired-card">
    <div v-if="webinar" class="webinar-expired-card__inner">
      <WebinarPreview class="webinar-expired-card__preview" icon-size="l" />

      <div class="webinar-expired-card__info">
        <Typography variant="int_s">
          {{ $t('webinars.isOver') }}
        </Typography>

        <WebinarDate :date="webinar.start_date" :duration="webinar.duration" class="mt-1" />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import {WebinarDate, WebinarPreview} from '../atoms'

export default {
  components: {
    WebinarDate,
    WebinarPreview,
  },
  props: {
    webinar: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss">
.webinar-expired-card {
  width: 100%;
  padding: 16px;
  border-radius: 24px;
  border: 1px solid $lightGrey;

  &__inner {
    display: flex;
    align-items: center;

    & + button {
      margin-top: 20px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: pre-line;
  }

  &__preview {
    border-radius: 24px;
    margin-right: 24px;
    width: 85px;
    height: 85px;
    flex-shrink: 0;
  }
}
</style>
