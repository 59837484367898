<template>
  <div class="error-layout">
    <!-- <ErrorPageHeader /> -->

    <div class="error-page">
      <StatusCode>{{ error.statusCode }}</StatusCode>
      <Typography variant="h2">{{ renderMessage }}</Typography>

      <Component :is="renderButton" />
    </div>
  </div>
</template>

<script>
import {prop} from 'ramda'
import {StatusCode, CourseBtn, ErrorPageHeader, WebinarBtn} from '@/components/ErrorLayout'

export default {
  components: {
    StatusCode,
    ErrorPageHeader,
  },
  layout: 'minimal',
  props: ['error'],
  computed: {
    status() {
      return prop('status', this.error) || prop('statusCode', this.error)
    },
    isCustomErrMessage() {
      return prop('customMessage', this.error)
    },
    message() {
      return prop('message', this.error)
    },
    renderMessage() {
      if (this.isCustomErrMessage) {
        return this.message
      }
      const texts = {
        403: this.$t('common.x403'),
        404: this.$t('common.x404'),
        500: this.$t('common.x500'),
      }
      return texts[this.status]
    },
    renderButton() {
      const btns = {
        default: CourseBtn,
        webinars: WebinarBtn,
      }

      const errBtn = prop('errButton', this.error) || 'default'

      return btns[errBtn]
    },
  },
  mounted() {
    console.log(this.error)
  },
}
</script>

<style lang="scss" scoped>
.error-layout {
  user-select: none;
  flex: 1;
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100%;
  flex-flow: column;
  z-index: 10;
  margin-bottom: 32px;

  .error-page {
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex: 1;
    text-align: center;
  }
}
</style>
