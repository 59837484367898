<template>
  <RoundedBlock
    :styles="{backgroundColor: '#f2f5f9'}"
    class="bot-card--add"
    @click="$emit('click')"
  >
    <Icon class="mr-4" src="/icons/bg-plus.svg"></Icon>
    <Typography variant="int_xs"><slot></slot></Typography>
  </RoundedBlock>
</template>

<script>
import {RoundedBlock, Typography} from '~ui'

export default {
  name: 'BotCardAdd',
  components: {
    RoundedBlock,
    Typography,
  },
}
</script>

<style lang="scss">
.bot-card--add {
  user-select: none;
  cursor: pointer;
  max-width: 300px;
  width: 300px;
  max-height: 144px;
  min-height: 112px;
  border-radius: 24px;
  background: #f2f5f9;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
