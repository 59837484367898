import {updateObjectByKey, removeObjectByKey} from '~/utils/index'

export const state = () => ({
  list: [],
  stats: {},
  isLoading: true,
})
export const getters = {
  hasList: (state) => Boolean(state.list.length),
  list: (state) => state.list,
  stats: (state) => state.stats,
  isLoading: (state) => state.isLoading,
}

export const mutations = {
  SET_SUBSCRIPTIONS_LIST(state, payload) {
    state.list = payload
  },
  SET_SUBSCRIPTIONS_STATS(state, payload) {
    state.stats = payload
  },
  SET_SUBS_LOADING(state, payload) {
    state.isLoading = payload
  },
  UPDATE_LIST(state, obj) {
    state.list = updateObjectByKey('id', obj, state.list)
  },
}

export const actions = {
  async FETCH_AUTHOR_SUBS({commit}) {
    commit('SET_SUBS_LOADING', true)
    const data = await this.$axios.$get('/author/subscriptions/list')

    commit('SET_SUBSCRIPTIONS_LIST', data.subscriptions)
    commit('SET_SUBSCRIPTIONS_STATS', data.stats)
    commit('SET_SUBS_LOADING', false)
  },

  async REMOVE_SUBSCRIPTION({commit, state}, id) {
    const data = await this.$axios.$delete(`/author/subscriptions/${id}`)

    commit('SET_SUBSCRIPTIONS_LIST', removeObjectByKey('id', id, state.list))
  },

  async FETCH_USER_SUBS({commit}) {
    commit('SET_SUBS_LOADING', true)
    const data = await this.$axios.$get('/user/recurrent-subscriptions/list')

    commit('SET_SUBSCRIPTIONS_LIST', data)
    commit('SET_SUBS_LOADING', false)
  },

  async CANCEL({commit}, id) {
    const data = await this.$axios.$put(`/user/recurrent-subscriptions/${id}/cancel`)

    commit('UPDATE_LIST', data)
  },
  async RESUME({commit}, id) {
    const data = await this.$axios.$put(`/user/recurrent-subscriptions/${id}/resume`)

    commit('UPDATE_LIST', data)
  },
}

export const subscriptionsModule = {
  state,
  getters,
  actions,
  mutations,
}
