import {prop} from 'ramda'

const PARAM_NAME = 'connect_token'

export default ({app, route}, inject) => {
  const takeConnectToken = prop(PARAM_NAME)

  const saveConnectToken = (tok) => {
    if (!tok) return

    app.$cookies.set(PARAM_NAME, tok)
  }
  const getThenSaveConnectToken = () => saveConnectToken(takeConnectToken(route.query))

  const getConnectToken = () => app.$cookies.get(PARAM_NAME)

  const connectToken = {
    getThenSaveConnectToken,
    getConnectToken,
  }

  inject('connectToken', connectToken)
}
