import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'
import {state} from './state'

export const chatStore = {
  mutations,
  actions,
  getters,
  state,
  namespaced: true,
}

export {mutationTypes as chatMutationTypes, actionTypes as chatActionTypes} from './types'
