<template>
  <div class="webinar-timer" :class="{'webinar-timer--expired': webinarIsEnded}">
    <WebinarExpiredCard v-if="endedAndNotExpired" :webinar="webinar">
      <Button
        v-if="showWatchButton && hasWebinarRecordings"
        :label="$t('webinars.watchRecord')"
        icon="/icons/play.svg"
        block
      />
    </WebinarExpiredCard>

    <Timer
      v-if="notEndedAndNotExpired"
      :date="isRedirectCountdown ? redirectCountdown + 1 : webinarStartDate"
      :title="displayTitle"
      :color="isActive ? 'purple' : 'grey'"
      :showExpired="!isActive"
      :expiredTitle="!isRedirectCountdown && displayExpiredTitle"
      :isSeconds="isRedirectCountdown"
      @timer:expired="onTimerExpired"
    />
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {Timer} from '@/components/ui'
import {WebinarExpiredCard} from '../molecules'

export default {
  components: {Timer, WebinarExpiredCard},
  props: {
    showWatchButton: {
      type: Boolean,
      default: true,
    },
    redirectCountdown: {
      type: [Number, Boolean],
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      webinar: 'webinars/webinar',
      webinarIsEnded: 'webinars/webinarIsEnded',
      webinarShouldStart: 'webinars/webinarShouldStart',
      webinarExpired: 'webinars/webinarExpired',
    }),
    endedAndNotExpired() {
      return this.webinarIsEnded && !this.webinarExpired
    },
    notEndedAndNotExpired() {
      return !this.webinarIsEnded && !this.webinarExpired
    },
    webinarStartDate() {
      return this.webinar.start_date * 1000
    },
    isRedirectCountdown() {
      return Number.isInteger(this.redirectCountdown)
    },
    isActive() {
      return this.webinar.is_active && !this.isRedirectCountdown
    },
    displayTitle() {
      return this.isRedirectCountdown
        ? this.$t('webinars.youWillBeRedirected')
        : this.$t('webinars.toStartWebinar')
    },
    displayExpiredTitle() {
      const shouldStartTitle = this.isAuthor
        ? this.$t('webinars.studentsWaitingForYou')
        : this.$t('webinars.justStarts')

      return this.isActive ? this.$t('webinars.beingHeld') : shouldStartTitle
    },
    hasWebinarRecordings() {
      return this.webinar.record_from_camera_url || this.webinar.record_from_display_url
    },
  },

  methods: {
    onTimerExpired(e) {
      const event = this.redirectCountdown ? 'redirect' : 'expired'
      this.$emit(event, e)
    },
  },
}
</script>

<style lang="scss">
.webinar-timer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lightGrey;
  &--expired {
    align-items: flex-start;
    padding-bottom: 0;
    border-color: transparent;
  }
}
</style>
