<template>
  <div class="webinar-edit">
    <div @click="show = true">
      <slot name="activator"></slot>
    </div>

    <VideoSettingsPopup
      :poptitle="$t('common.settings')"
      :isOpen="show"
      @close="show = false"
      @save="processMediaSettings"
    >
      <template slot="video">
        <slot name="video"></slot>
      </template>
    </VideoSettingsPopup>
  </div>
</template>

<script>
import {VideoSettingsPopup} from '@/components/Popups'

export default {
  components: {
    VideoSettingsPopup,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    processMediaSettings(data) {
      this.show = false
      this.$emit('save', data)
    },
  },
}
</script>
