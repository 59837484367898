<template>
  <PopupWrapper :title="$t('module.settings')" v-bind="$attrs" v-on="$listeners">
    <TabSwitcher class="mb-6" :tabs="tabs" @tab:change="onChangeTab"></TabSwitcher>

    <div v-if="activeTab === 'main'" class="flex flex-col flex-1">
      <Input
        v-model="moduleTitle"
        :label="$t('common.name')"
        :placeholder="$t('module.enterDescription')"
      />
      <PriceToggler
        v-model="price"
        :disabled="!$store.state.user.user.is_legal_filled"
        :currency.sync="currentCurrency"
        :isPaid.sync="isPaid"
        :title="$t('module.showPrice') + ':'"
      ></PriceToggler>
      <Typography
        v-if="!$store.state.user.user.is_legal_filled"
        variant="paragraph_s"
        color="red"
        class="mb-4"
        v-html="$t('payment.pnav')"
      >
      </Typography>
      <ColorPicker v-model="selectedColor" class="sidebar-block widget" />
    </div>

    <div v-if="activeTab === 'anal'" class="flex flex-col flex-1">
      <div class="paragraph-s mb-4">
        {{ $t('common.anpretxt') }} <br /><span class="font-mono text-purple-dark my-1">{{
          moduleData && moduleData.analytics_event_name
        }}</span
        ><br />
        {{ $t('common.anposttxt') }}
        <a class="text-purple hover:underline" href="#">{{ $t('common.ancnt1') }}</a>
        {{ $t('common.and') }}
        <a class="text-purple hover:underline" href="#">{{ $t('common.ancnt2') }}</a>
      </div>
      <Input v-model="ymId" :label="$t('common.ancntlab1')" placeholder="124131231"></Input>
      <Input v-model="gaId" :label="$t('common.ancntlab2')" placeholder="GTM-XXXXXXX"></Input>
    </div>

    <Button @click="updateModule">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {mapActions} from 'vuex'
import {PopupWrapper, ColorPicker} from '@/components/ui'
import TabSwitcher from '~ui/TabSwitcher'

export default {
  components: {
    TabSwitcher,
    PopupWrapper,
    ColorPicker,
    PriceToggler: () => import('../components/PriceToggler'),
  },
  props: {
    moduleData: {type: Object, default: () => ({})},
  },
  data: () => ({
    moduleTitle: '',
    isPaid: true,
    price: 1,
    currentCurrency: '',
    selectedColor: '',
    activeTab: 'main',
    ymId: '',
    gaId: '',
  }),
  computed: {
    tabs() {
      return [
        {
          title: this.$t('common.tilMain'),
          id: 'main',
        },
        {
          title: this.$t('common.tilAnal'),
          id: 'anal',
        },
      ]
    },
  },
  watch: {
    moduleData(v) {
      if (!v) return
      this.init()
    },
  },
  mounted() {
    console.log('refresh')
    this.fetchUser()
  },
  methods: {
    ...mapActions({
      updateCourseModule: 'courseBundles/updateCourseModule',
      fetchUser: 'user/FETCH',
    }),
    init() {
      this.moduleTitle = this.moduleData.title
      this.price = this.moduleData.price
      this.currentCurrency = this.moduleData.currency
      this.selectedColor = this.moduleData.color
      this.isPaid = !this.moduleData.is_free
      this.activeTab = 'main'
      this.gaId = this.moduleData.google_id
      this.ymId = this.moduleData.yandex_id
    },
    async updateModule() {
      const params = {
        title: this.moduleTitle,
        courseModuleId: this.moduleData.id,
        color: this.selectedColor,
        price: this.price,
        currency: this.currentCurrency,
        isFree: !this.isPaid,
        google_id: this.gaId,
        yandex_id: this.ymId,
        courseId: this.$route.params.courseId,
      }

      try {
        await this.updateCourseModule(params)
        this.$emit('close')
      } catch (e) {}
    },
    onChangeTab(tab) {
      this.activeTab = tab.id
    },
  },
}
</script>
