import {registerPlugin} from '@capacitor/core'
import {App} from '@capacitor/app'
import {Browser} from '@capacitor/browser'
import {Device} from '@capacitor/device'
import {Storage} from '@capacitor/storage'
import {SplashScreen} from '@capacitor/splash-screen'
import {Toast} from '@capacitor/toast'

export default ({app, store}, inject) => {
  const Echo = registerPlugin('Echo')

  App.addListener('appStateChange', ({isActive}) => {
    console.log('App state changed. Is active?', isActive)
  })

  const capacitor = {
    App,
    Browser,
    Device,
    Storage,
    Echo,
    SplashScreen,
    Toast,
  }

  inject('capacitor', capacitor)
}
