<template>
  <div class="checkbox privacy-checkbox">
    <label class="checkbox__label">
      <span
        :class="{
          'checkbox-wrap': true,
          'checkbox-err': error,
        }"
      >
        <input
          tabindex="-1"
          name="privacy-checkbox"
          type="checkbox"
          class="checkbox-input"
          :value="value"
          :checked="value"
          @change="$emit('input', $event.target.checked)"
          @blur="$emit('blur')"
        />
        <span class="checkbox-status"></span>
      </span>
      <span class="checbox-label">
        {{ label }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    label: String,
    error: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.privacy-checkbox {
  .checkbox-err {
    border: 1px solid #e33131;
  }
  .checbox-label {
    a {
      text-decoration: underline !important;
    }
  }
}
</style>
