import Vue from 'vue'
import {Capacitor} from '@capacitor/core'
import {CapacitorVideoPlayer} from 'capacitor-video-player'

import Bowser from 'bowser'
import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {registerConfirmDialogPlugin} from '~ui/ConfirmDialog/plugin'
import {ROLES} from '../consts'

// Global mixin to check for current user role
Vue.mixin({
  computed: {
    currUserRole() {
      return this.$store.getters['user/role']
    },
    isStudent() {
      return this.currUserRole === ROLES.student
    },
    isAuthor() {
      return this.currUserRole === ROLES.author
    },
  },
})

export default ({app, store}, inject) => {
  const getBrowser = () => {
    if (process.server && typeof window === 'undefined') return

    return Bowser.getParser(window.navigator.userAgent)
  }

  const isInvalidFileType = (file, type) => {
    if (!file) return true

    const parts = file.name.split('.')
    const fileExt = parts[parts.length - 1]
    const lowerExt = fileExt.toLowerCase()

    const types = {
      image: 'png|jpeg|jpg',
      audio: 'mp3|mpga|wav|mpeg|ogg|m4a|mp4',
      video: 'mp4|avi|mov|m4v|wmv|webm|m4v',
      presentation: 'pptx|ppt|key|pdf|doc|docx',
    }

    const currentType = types[type]
    if (lowerExt.match(currentType) === null) {
      const typesList = currentType.replace(/\|/g, ', ')

      store.dispatch(ADD_TOAST_MESSAGE, {
        text: `Поле должно быть файлом одного из следующих типов: ${typesList}`,
        type: 'danger',
        dismissAfter: 4000,
      })
      return true
    }
    return false
  }

  /**
   * htmlspecialchars - аналог htmlspecialchars + decode если 2 параметр true
   *
   * @param  {string} str  Строка
   * @param  {bool} decode Режим декодирования
   */
  const htmlspecialchars = (str, decode = true) => {
    if (!str || typeof str !== 'string') {
      return str
    }

    const map = [
      ['&', '&amp;'],
      ['<', '&lt;'],
      ['>', '&gt;'],
      ['"', '&#34;'],
      ["'", '&#39;'],
    ]

    map.forEach(([from, to]) => {
      const find = decode ? to : from
      const replace = decode ? from : to

      str = str.replace(new RegExp(find, 'g'), replace)
    })

    return str
  }

  const confirmDialog = registerConfirmDialogPlugin()

  // remember all this PREFIXED WITH $
  inject('isIos', Capacitor.getPlatform() === 'ios')
  // inject('isIos', true)
  inject('confirm', confirmDialog.confirm)
  inject('htmlspecialchars', htmlspecialchars)
  inject('isInvalidFileType', isInvalidFileType)
  inject('getBrowser', getBrowser)
  inject('CapacitorVideoPlayer', CapacitorVideoPlayer)
}
