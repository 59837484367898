import axiosCancel from 'axios-cancel'
import {path, reduce, values, cond, prop, pipe, flatten} from 'ramda'

const propErrors = prop('errors')
const getErrorsFromObject = pipe(propErrors, values, flatten)
const getErrorFromArray = reduce((acc, curr) => [...acc, ...propErrors(curr)], [])
const isArray = (arr = []) => Array.isArray(arr)

const parseErrors = (err) => {
  const errData = path(['response', 'data'], err)

  if (!errData) return false

  const pickError = cond([
    [propErrors, getErrorsFromObject],
    [isArray, getErrorFromArray],
    [() => true, () => false],
  ])

  return pickError(errData)
}

const showToast = (store, {text, type}) => {
  const isRoot = {root: true}

  store.dispatch(
    'SHOW_TOAST',
    {
      text,
      type: type || 'danger',
      dismissAfter: 4000,
    },
    isRoot
  )
}

const showErrorToasts = (store, err) => {
  if (process.server) return

  const parsedErrors = parseErrors(err)
  if (!parsedErrors) return

  parsedErrors.forEach((errMsg) => {
    showToast(store, {text: errMsg})
  })
}

// const show500 = (store, err) => {
//   if (err.response.status === 500) {
//     console.error('500 error ocurred ', err)
//     showToast(store, {text: 'Internal server error'})
//   }
// }

const AXIOS_CONFIG = {
  baseURL: process.env.API_BASE_URL,
  timeout: 60000,
}

/**
 * @param {String} [token]
 */
function setAuthHeader(token) {
  this.setToken(token, 'Bearer')
}

export default ({store, app, redirect, $cookies, $axios, isDev, $sentry}) => {
  if (!process.env.API_BASE_URL) {
    throw Error('Please provide the API_BASE_URL env variable')
  }
  axiosCancel($axios, {
    debug: false, // default
  })

  Object.assign($axios.defaults, AXIOS_CONFIG)

  // console.log('HEADER', localStorage.getItem('jwt-token'))

  $axios.setAuthHeader = setAuthHeader.bind($axios)
  // $axios.setAuthHeader(store.getters['auth/token'])

  if (typeof window !== 'undefined') $axios.setAuthHeader(localStorage.getItem('jwt-token'))
  // language header
  $axios.setHeader('Content-Language', store.$i18n.locale)

  /* $axios.onRequest((config) => {
    $axios.setHeader('Content-Language', store.$i18n.locale)
    return config
  }) */
  /*
  $axios.setBaseURL(process.env.API_BASE_URL.substr(0, process.env.API_BASE_URL.length - 4))
  $axios.$get('/sanctum/csrf-cookie').then((t) => {
    console.log(t)
  })
  $axios.setBaseURL(process.env.API_BASE_URL)
*/
  $axios.onRequest((config) => {
    $axios.setHeader('Content-Language', store.$i18n.locale)

    //   await $axios.get('/csrf-cookie')

    //  $axios.$get('/csrf-cookie').then((t) => {
    //    console.log(t)
    //  })

    // if (!config.headers['X-CSRF-Token'] && token) setCSRFToken().then((response) => config)
    // ;config.headers['X-CSRF-Token'] = token

    return config
  })

  /* const setCSRFToken = () => {
    return $axios.$get('/csrf-cookie')
  } */

  $axios.onError((err) => {
    showErrorToasts(store, err)
    // show500(store, err)
    $sentry.captureException(err)
    // if (!isDev) return Promise.reject(err)

    console.log(
      `[${err.response && err.response.status}] ${err.response && err.response.request.path}`
    )
    console.log(err.response && err.response.data)

    return Promise.reject(err)
  })
}
