import {mapActions, mapGetters} from 'vuex'
import {all} from 'ramda'

export default {
  mounted() {
    this.addParticipant()
  },

  computed: {
    ...mapGetters({
      webinar: 'webinars/webinar',
      webinarIsEnded: 'webinars/webinarIsEnded',
    }),

    isAccessByLink() {
      return this.webinar.access_level === 'link'
    },
    isNotAuthorized() {
      return !this.webinar.is_authorized
    },

    shouldAddParticipant() {
      return all(Boolean, [
        this.isStudent,
        !this.webinarIsEnded,
        this.isAccessByLink,
        this.isNotAuthorized,
      ])
    },
  },

  watch: {
    shouldAddParticipant(modify) {
      this.addParticipant()
    },
  },

  methods: {
    ...mapActions({
      webinarAddParticipant: 'webinars/addParticipant',
    }),

    addParticipant() {
      if (this.webinar.is_password_set) return
      if (this.shouldAddParticipant) {
        this.webinarAddParticipant()
      }
    },
  },
}
