<template>
  <div class="widget sidebar-block">
    <div v-if="title" class="sidebar-block__title">{{ title }}:</div>

    <div v-if="inputText" class="course-image-preview">
      <img
        v-if="!image || image === ''"
        :src="imagePreviewDefault"
        :class="imagePreviewClasses"
        alt=""
      />
      <img v-else :src="image" :class="imagePreviewClasses" alt="" />
      <div class="image-info">
        <h6 class="title">{{ $t('course.sidebar.resolution') }}:</h6>
        <div class="resolution">{{ info }}</div>
      </div>
    </div>
    <div class="input-wrap input-file-wrap">
      <div class="input-type-file-wrapper">
        <label :for="name">
          <span class="btn-input" style="border: none">
            <span v-if="inputText" class="icon">
              <!-- $todo: change way it is used -->
              <!-- <icon style="display: flex; align-items: center;" icon="img" /> -->
            </span>
            <span v-if="inputText" class="title">{{ inputText }}</span>
            <span
              v-else
              class="minsize"
              @mouseover="$emit('mouseover', true)"
              @mouseleave="$emit('mouseleave', true)"
            >
              <img
                v-if="!image || image === ''"
                :src="imagePreviewDefault"
                :class="imagePreviewClasses"
                alt=""
                style="height: auto; max-width: fit-content"
              />

              <img
                v-else
                :src="image"
                :class="imagePreviewClasses"
                alt=""
                style="height: auto; max-width: fit-content"
              />
            </span>
          </span>
        </label>
        <input
          :id="name"
          :ref="name"
          :disabled="disabled"
          :name="name"
          type="file"
          accept="image/x-png,image/jpeg"
          @change="uploadImg"
        />
        <ImageCropperModal
          v-if="showCropper"
          :title="inputText"
          :imgSrc="imgSrc"
          :rules="rules"
          @cropped="save"
          @close="cropperClose"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {mapActions} from 'vuex'

const imagePreviewClassesDefault = 'course-image-preview__img course-img-round'
const imagePreviewClassesSquared = 'course-image-preview__overlay'
const imagePreviewDefault = '/assets/img/posts/empty.svg'
const imagePreviewSquared = '/assets/img/posts/empty-bg.svg'
const bytesToMB = 1048576 // 1024 * 1024 = 1MB

export default {
  name: 'ImageUploadCropper',

  components: {
    ImageCropperModal: () => import('~ui').then((m) => m.ImageCropperModal),
  },

  props: {
    title: {type: String, default: ''},
    image: {type: String, default: ''},
    inputText: {type: String, default: ''},
    name: {type: String, default: ''},
    rules: {
      type: Object,
      default: () => ({
        minWidth: 0,
        minHeight: 0,
        maxWidth: 3000,
        maxHeight: 3000,
        maxSize: NaN,
        aspectRatio: NaN,
      }),
    },
    squaredPreview: {type: Boolean},
    disabled: {type: Boolean},
  },

  data() {
    return {
      imageName: 'default',
      showCropper: false,
      imgSrc: '',
      imageWidth: null,
      imageHeight: null,
    }
  },

  computed: {
    maxSizeMB() {
      return Math.floor(this.rules.maxSize / bytesToMB)
    },
    imagePreviewDefault() {
      return this.squaredPreview ? imagePreviewSquared : imagePreviewDefault
    },
    imagePreviewClasses() {
      return this.squaredPreview ? imagePreviewClassesSquared : imagePreviewClassesDefault
    },
    defaultRule() {
      const {minWidth, minHeight} = this.rules

      return `${this.$t('course.sidebar.resolution')} ${minWidth}x${minHeight} ${this.$t(
        'course.sidebar.resolution'
      )}, ${this.maxSizeMB}MB ${this.$t('course.sidebar.max')}`
    },
    dimentions() {
      return `${this.imageWidth} x ${this.imageHeight} px`
    },
    info() {
      return this.imageWidth ? this.dimentions : this.defaultRule
    },
  },

  watch: {
    image: {
      handler() {
        if (process.server) return

        let width
        let height
        const imageDims = () =>
          new Promise((resolve) => {
            const img = new Image()

            img.onload = function () {
              width = this.width
              height = this.height
              resolve()
            }

            img.src = this.image
          }).then(() => {
            this.imageWidth = width
            this.imageHeight = height
          })

        imageDims()
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      toast: ADD_TOAST_MESSAGE,
    }),
    uploadImg(e) {
      const reader = new FileReader()
      const {maxSize} = this.rules
      const file = e.target.files[0]
      const size = file.size

      this.imageName = file.name

      if (this.$isInvalidFileType(file, 'image')) return

      if (!isNaN(maxSize) && size >= maxSize) {
        this.toast({
          text: `${this.$t('forms.rules.fileMax')} ${this.maxSizeMB}MB`,
          type: 'danger',
          dismissAfter: 4000,
        })
        return
      }

      reader.onload = (event) => {
        this.imgSrc = event.target.result
        this.showCropper = true
      }
      reader.readAsDataURL(file)
    },
    cropperClose() {
      this.$refs[this.name].value = ''
      this.showCropper = false
    },
    save(image) {
      this.$emit('save', {image, name: this.imageName})
      this.cropperClose()
    },
  },
}
</script>

<style lang="scss">
.minsize {
  min-width: 96px;
  min-height: 96px;
  display: block;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}
.course-image-preview {
  margin-top: 25px;
  background-color: #f2f5f9;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
}
.course-image-preview__img {
  max-width: 64px;
  -o-object-fit: contain;
  object-fit: contain;
  display: block;
}
.course-image-preview__overlay {
  height: 64px;
  width: 64px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(150, 162, 180, 0.2);
}
.course-image-preview .image-info {
  margin-left: 20px;
}
.course-image-preview .image-info .title {
  display: block;
  font-weight: 700;
  font-family: sans-serif;
  color: #2e3141;
  margin-bottom: 10px;
}
.course-image-preview .image-info .resolution {
  font-size: 12px;
  color: #8f9aac;
}
.course-img-round {
  border-radius: 100%;
}

.input-type-file-wrapper {
  margin-top: 15px;
  position: relative;
  display: flex;
  width: 100%;
}
.input-type-file-wrapper label {
  width: 100%;
  display: block;
  cursor: pointer;
  white-space: nowrap;
}
.input-type-file-wrapper label .btn-input {
  width: 100%;
  display: inline-flex;
  align-items: center;

  border: 1px solid #ededf2;
  justify-content: center;
  border-radius: 500px;
  cursor: pointer;
  text-align: center;
  padding: 14px 25px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  font-family: sans-serif;
  color: #8f9aac;
  transition: 0.35s all ease;
}
@media only screen and (max-width: 991px) {
  .input-type-file-wrapper label .btn-input {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .input-type-file-wrapper label .btn-input {
    font-size: 12px;
  }
}
.input-type-file-wrapper label .btn-input .icon {
  display: flex;
  align-items: center;
  margin-right: 12px;
}
.input-type-file-wrapper label .btn-input .icon svg {
  height: 14px;
  object-fit: contain;
}
.input-type-file-wrapper label .btn-input .icon svg path {
  fill: #8f9aac;
  transition: 0.25s all ease;
}
.input-type-file-wrapper input[type='file'] {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  pointer-events: all;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
// fix icon on file upload btn
.input-type-file-wrapper {
  label {
    .btn-input {
      .icon {
        span {
          div {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
