<template>
  <div>
    <Typography v-if="label" variant="paragraph_s" color="grey" bottomGutter>{{
      label
    }}</Typography>
    <div
      :class="['meleton-form__input-wrap', 'has-drop', wrapperClass, 'input-phone']"
      :style="wrapperStyles"
    >
      <div
        :class="['phone-select', {'phone-select__active': open, 'app-input__active': selectNumber}]"
      >
        <UISelector
          v-model="phoneCode"
          :options="codes"
          :allow-empty="false"
          :labeled="false"
          :disabled="disabled"
          :small="small"
          :custom-label="searchLabel"
          openDirection="bottom"
          class="phone-select__opener"
          :readonly="readonly"
          @open="open = true"
          @close="open = false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span
                v-if="props.option.country"
                :class="`option__image isoflag ${props.option.country.toLowerCase()}`"
              ></span>
              <span class="option__title">+{{ props.option.code }}</span></span
            >
          </template>
          <template slot="option" slot-scope="props">
            <div class="phone-select__list-item phone-select__list">
              <span
                v-if="props.option.country"
                style="width: 15px !important; height: 15px !important"
                :class="`phone-select__flag isoflag ${props.option.country.toLowerCase()}`"
              ></span
              >&nbsp;
              <span class="phone-select__name truncate">{{ props.option.name }}</span>
              <span class="phone-select__code">+{{ props.option.code }}</span>
            </div>
          </template>
        </UISelector>
      </div>

      <UiInput
        ref="input"
        v-model="phoneValue"
        :readonly="readonly"
        :mask="getIsoMaks(phoneCode.country)"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        hideDetails
        :small="small"
        class="phone-select__textfield"
        @blur="extendedBlur"
        @focus="onFocus"
        @keyup.enter="onEnter"
      ></UiInput>
    </div>
    <Typography v-show="error" variant="paragraph_xs" color="error">
      {{ errorMessage }}
    </Typography>
  </div>
</template>

<script>
import UiInput from '~ui/Input/Input'
import UISelector from '~ui/Selector'

import countriesList from '@/utils/AllCountries'
import allPhoneMasks from '@/utils/AllPhoneMasks'

export default {
  name: 'UiInputPhone',
  components: {UISelector, UiInput},
  props: {
    value: {
      type: [String, Object],
      default: () => '+7 ',
    },
    label: {
      type: [String],
      default: () => '',
    },
    returnObject: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    small: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    placeholder: {
      type: String,
      default: () => '(495) 123-45-67',
    },
    error: {
      type: [Boolean, String],
      default: () => false,
    },
    errorMessage: {
      type: String,
      default: () => '',
    },
    custom: {
      type: Object,
      default: () => ({bgColor: '', borderRadius: '16', margin: ''}),
    },
  },
  data() {
    return {
      open: false,
      selectNumber: false,
      codes: countriesList,
      isoMasks: allPhoneMasks,
      active: false,
    }
  },
  computed: {
    currentMask() {
      return this.getIsoMaks(this.phoneCode.country)
    },
    isActive() {
      return this.focused
    },
    wrapperClass() {
      return [
        {
          'app-input__wrap': true,
          'app-input__flat': this.flat,
          'app-input__active': this.isActive,
          'app-input__disabled': this.disabled,
          'app-input__wrap--error': this.error,
          'app-input-no-icon': !this.$slots.icon && !this.$slots.prepend,
        },
        this.wrapClass,
      ]
    },
    wrapperStyles() {
      const exist = (key) => this.custom[key] || undefined
      const omit = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          const _acc = acc
          if (obj[key] !== undefined) _acc[key] = obj[key]
          return _acc
        }, {})
      }
      return omit({
        '--input-bgcolor': exist('bgColor'),
        '--input-brrad': this.custom.borderRadius + 'px',
        '--input-margin': exist('margin'),
        '--input-height': this.small ? '30px' : 'auto',
        '--input-font-size': this.small ? '10px' : '14px',
        '--input-code-size': this.small ? '90px' : '120px',
        '--input-mr': this.small ? '-14px' : '15px',
        '--input-mt': this.small ? '4px' : '0px',
        '--input-pd': this.small ? '3px 16px' : '14px 16px',
      })
    },

    phoneCode: {
      get() {
        if (this.returnObject) {
          if (
            typeof this.value === 'string' ||
            this.value instanceof String ||
            !Object.entries(this.value).length ||
            !this.value
          ) {
            let country = 'EN'
            let countryName = 'United States'
            let countryCode = '1'

            if (this.$i18n.locale === 'ru') {
              country = 'RU'
              countryName = 'Россия'
              countryCode = '7'
            }

            if (this.$i18n.locale === 'es') {
              country = 'MX'
              countryName = 'Mexico'
              countryCode = '52'
            }

            if (this.$i18n.locale === 'de') {
              country = 'DE'
              countryName = 'German'
              countryCode = '49'
            }

            return {
              value: '',
              masked: '',
              name: countryName,
              country,
              code: countryCode,
              priority: 0,
              areaCodes: null,
            }
          }
          return this.value
        }

        const code = this.value.split(' ', 1).toString().substr(1)
        const countrySet = countriesList.find((e) => e.code === code && !e.priority)
        if (countrySet)
          return {
            name: countrySet.name || '',
            country: countrySet.country || '',
            code,
            priority: countrySet.priority,
            areaCodes: countrySet.areaCodes,
          }
        else
          return {
            name: '',
            country: 'RU',
            code: '7',
            priority: 0,
            areaCodes: null,
          }
      },
      set(value) {
        if (this.returnObject) {
          this.$set(value, 'value', `${this.phoneValue}`)
          this.$set(value, 'masked', `+${value.code} ${this.phoneValue}`)
          this.$emit('input', value)
        } else this.$emit('input', `+${value.code} ${this.phoneValue}`)
      },
    },

    phoneValue: {
      get() {
        if (this.returnObject) {
          if (
            typeof this.value === 'string' ||
            this.value instanceof String ||
            !Object.entries(this.value).length ||
            !this.value
          )
            return ''
          return this.value.value
        }

        if (this.value) return this.value.split(' ').slice(1).join(' ')
        return ''
      },
      set(value) {
        if (this.returnObject) {
          this.phoneCode.value = value
          this.phoneCode.masked = `+${this.phoneCode.code} ${value}`

          this.$emit('input', this.phoneCode)
        } else this.$emit('input', `+${this.phoneCode.code} ${value}`)
      },
    },
  },

  methods: {
    searchLabel(data) {
      return data.name
    },
    getIsoMaks(iso) {
      return this.isoMasks(iso)
    },
    extendedBlur() {
      this.active = false
    },
    onFocus() {
      this.active = true
    },
    onEnter() {
      this.$emit('enter', this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-selector2 .multiselect__tags {
  border: none;
  height: var(--input-height);
  margin-top: var(--input-mt);
}
.multiselect__tags {
  border: none;
}
.input-phone {
  height: var(--input-height);
}

.phone-select {
  flex: 0 0 var(--input-code-size);
  min-width: var(--input-code-size);
  -ms-align-self: stretch;
  align-self: stretch;
  margin-right: var(--input-mr);
  border-right: 1px solid rgba(150, 162, 180, 0.15);
  height: var(--input-height);

  .option__title {
    padding-left: 10px;
    font-size: var(--input-font-size);
  }
}
.phone-select:last-child {
  margin-right: 0;
  border-right: none;
  flex: 1 1 auto;
}
.phone-select__active {
  min-width: 100%;
}
.phone-select__textfield {
  border: none;
  border-radius: 0 2px 2px 0;
  width: 100%;
  font-size: var(--input-font-size);
  outline: 0;
  padding-left: 7px;
}

.app-input__wrap input {
  padding-left: 0 !important;
}

.phone-select .multiselect,
.phone-select .multiselect__input,
.phone-select .multiselect__single {
  /* color: #404040;*/
  font-size: var(--input-font-size);
  font-weight: 400;
  touch-action: manipulation;
}

.phone-select .app-selector2 .multiselect--active .multiselect__tags {
  border: none;
}

.phone-select .app-selector2 .multiselect__content-wrapper .multiselect__option--highlight {
  /* background: #f2f5f9;*/
  color: #fff;
}

.phone-select .app-selector2 .multiselect__content-wrapper {
  border: none;
}
.phone-select .app-selector2 .multiselect__content-wrapper {
  /*background: #f2f5f9;*/
}
.phone-select .multiselect__option {
  padding: 0;
}
.phone-select__opener {
  display: flex;
  position: relative;
  padding: 0px;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.phone-select__opener .phone-select__code {
  /* color: #2e3141;*/
}
.phone-select__arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.787' height='5.009' viewBox='0 0 8.787 5.009'%3E%3Cg id='Group_3993' data-name='Group 3993' transform='translate(-164.451 -86.884)'%3E%3Cg id='arrow-point-to-right' transform='translate(183.117 86.884) rotate(90)' opacity='0.5'%3E%3Cpath id='Path_1770' data-name='Path 1770' d='M4.828,3.958,1.05.18a.615.615,0,0,0-.87.87L3.523,4.393.18,7.736a.615.615,0,0,0,.87.87L4.828,4.828a.615.615,0,0,0,0-.87Z' transform='translate(0 9.879)' fill='%2396a2b4'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}
.phone-select__drop {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  /*background: #f2f5f9;*/
  z-index: 11;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid rgba(150, 162, 180, 0.15);
  max-height: 300px;
  overflow-y: auto;
}
.phone-select.drop-active {
  flex: 1 1 auto;
  border: none;
  margin: 0;
}
.phone-select.drop-active ~ input {
  display: none;
}
.phone-select.drop-active .phone-select__drop {
  display: block;
}
.phone-select.drop-active .phone-select__arrow {
  transform: rotate(180deg);
}

.phone-select__flag {
  margin: 0 10px 0 0;
  border-radius: 50%;
}
.phone-select__flag span {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.phone-select__list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: var(--input-font-size);
  line-height: 16px;
}
.phone-select__list-item {
  padding: 12px 10px;
  display: flex;
  border-radius: 10px;
  align-items: center;
  cursor: pointer;
  background: transparent;
}
.phone-select__list-item:hover {
  color: #fff;
}
.phone-select__name {
  flex: 1 1 auto;
  margin: 0 10px;
}

.phone-select__code {
  text-align: right;
}
.phone-select__search {
  margin: 10px 0;
  border: 1px solid #e4e9ef;
  border-radius: 50px;
  padding: 9px 15px 9px 46px;
  line-height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='11.568' height='11.568' viewBox='0 0 11.568 11.568'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect width='11.568' height='11.568' fill='none'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Repeat_Grid_1' data-name='Repeat Grid 1' clip-path='url(%23clip-path)'%3E%3Cg transform='translate(-95.806 -217.806)'%3E%3Cpath id='magnify-outline' d='M7.3,3a4.3,4.3,0,0,1,3.265,7.093l.178.178h.522l3.305,3.305-.992.992-3.305-3.305v-.522l-.178-.178A4.3,4.3,0,1,1,7.3,3m0,1.322A2.975,2.975,0,1,0,10.271,7.3,2.962,2.962,0,0,0,7.3,4.322Z' transform='translate(92.806 214.806)' fill='rgba(143,154,172,0.5)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: 13px 50%;
  background-repeat: no-repeat;
}
.phone-select__search input {
  font-size: var(--input-font-size);
  line-height: 20px;
  height: 20px;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
}

.phone-select {
  .phone-select__flag {
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
  }
}
.option__image {
  border-radius: 50%;
  width: 15px !important;
  height: 15px !important;
}
.isoflag {
  width: 15px !important;
  height: 15px !important;
}
.isoflag.be {
  width: 18px;
}
.isoflag.ch {
  width: 15px;
}
.isoflag.mc {
  width: 19px;
}
.isoflag.ne {
  width: 18px;
}
.isoflag.np {
  width: 13px;
}
.isoflag.va {
  width: 15px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .isoflag {
    background-size: 5630px 15px;
  }
}
.isoflag.ac {
  height: 10px;
  background-position: 0px 0px;
}
.isoflag.ad {
  height: 14px;
  background-position: -22px 0px;
}
.isoflag.ae {
  height: 10px;
  background-position: -44px 0px;
}
.isoflag.af {
  height: 14px;
  background-position: -66px 0px;
}
.isoflag.ag {
  height: 14px;
  background-position: -88px 0px;
}
.isoflag.ai {
  height: 10px;
  background-position: -110px 0px;
}
.isoflag.al {
  height: 15px;
  background-position: -132px 0px;
}
.isoflag.am {
  height: 10px;
  background-position: -154px 0px;
}
.isoflag.ao {
  height: 14px;
  background-position: -176px 0px;
}
.isoflag.aq {
  height: 14px;
  background-position: -198px 0px;
}
.isoflag.ar {
  height: 13px;
  background-position: -220px 0px;
}
.isoflag.as {
  height: 10px;
  background-position: -242px 0px;
}
.isoflag.at {
  height: 14px;
  background-position: -264px 0px;
}
.isoflag.au {
  height: 10px;
  background-position: -286px 0px;
}
.isoflag.aw {
  height: 14px;
  background-position: -308px 0px;
}
.isoflag.ax {
  height: 13px;
  background-position: -330px 0px;
}
.isoflag.az {
  height: 10px;
  background-position: -352px 0px;
}
.isoflag.ba {
  height: 10px;
  background-position: -374px 0px;
}
.isoflag.bb {
  height: 14px;
  background-position: -396px 0px;
}
.isoflag.bd {
  height: 12px;
  background-position: -418px 0px;
}
.isoflag.be {
  height: 15px;
  background-position: -440px 0px;
}
.isoflag.bf {
  height: 14px;
  background-position: -460px 0px;
}
.isoflag.bg {
  height: 12px;
  background-position: -482px 0px;
}
.isoflag.bh {
  height: 12px;
  background-position: -504px 0px;
}
.isoflag.bi {
  height: 12px;
  background-position: -526px 0px;
}
.isoflag.bj {
  height: 14px;
  background-position: -548px 0px;
}
.isoflag.bl {
  height: 14px;
  background-position: -570px 0px;
}
.isoflag.bm {
  height: 10px;
  background-position: -592px 0px;
}
.isoflag.bn {
  height: 10px;
  background-position: -614px 0px;
}
.isoflag.bo {
  height: 14px;
  background-position: -636px 0px;
}
.isoflag.bq {
  height: 14px;
  background-position: -658px 0px;
}
.isoflag.br {
  height: 14px;
  background-position: -680px 0px;
}
.isoflag.bs {
  height: 10px;
  background-position: -702px 0px;
}
.isoflag.bt {
  height: 14px;
  background-position: -724px 0px;
}
.isoflag.bv {
  height: 15px;
  background-position: -746px 0px;
}
.isoflag.bw {
  height: 14px;
  background-position: -768px 0px;
}
.isoflag.by {
  height: 10px;
  background-position: -790px 0px;
}
.isoflag.bz {
  height: 14px;
  background-position: -812px 0px;
}
.isoflag.ca {
  height: 10px;
  background-position: -834px 0px;
}
.isoflag.cc {
  height: 10px;
  background-position: -856px 0px;
}
.isoflag.cd {
  height: 15px;
  background-position: -878px 0px;
}
.isoflag.cf {
  height: 14px;
  background-position: -900px 0px;
}
.isoflag.cg {
  height: 14px;
  background-position: -922px 0px;
}
.isoflag.ch {
  height: 15px;
  background-position: -944px 0px;
}
.isoflag.ci {
  height: 14px;
  background-position: -961px 0px;
}
.isoflag.ck {
  height: 10px;
  background-position: -983px 0px;
}
.isoflag.cl {
  height: 14px;
  background-position: -1005px 0px;
}
.isoflag.cm {
  height: 14px;
  background-position: -1027px 0px;
}
.isoflag.cn {
  height: 14px;
  background-position: -1049px 0px;
}
.isoflag.co {
  height: 14px;
  background-position: -1071px 0px;
}
.isoflag.cp {
  height: 14px;
  background-position: -1093px 0px;
}
.isoflag.cr {
  height: 12px;
  background-position: -1115px 0px;
}
.isoflag.cu {
  height: 10px;
  background-position: -1137px 0px;
}
.isoflag.cv {
  height: 12px;
  background-position: -1159px 0px;
}
.isoflag.cw {
  height: 14px;
  background-position: -1181px 0px;
}
.isoflag.cx {
  height: 10px;
  background-position: -1203px 0px;
}
.isoflag.cy {
  height: 14px;
  background-position: -1225px 0px;
}
.isoflag.cz {
  height: 14px;
  background-position: -1247px 0px;
}
.isoflag.de {
  height: 12px;
  background-position: -1269px 0px;
}
.isoflag.dg {
  height: 10px;
  background-position: -1291px 0px;
}
.isoflag.dj {
  height: 14px;
  background-position: -1313px 0px;
}
.isoflag.dk {
  height: 15px;
  background-position: -1335px 0px;
}
.isoflag.dm {
  height: 10px;
  background-position: -1357px 0px;
}
.isoflag.do {
  height: 13px;
  background-position: -1379px 0px;
}
.isoflag.dz {
  height: 14px;
  background-position: -1401px 0px;
}
.isoflag.ea {
  height: 14px;
  background-position: -1423px 0px;
}
.isoflag.ec {
  height: 14px;
  background-position: -1445px 0px;
}
.isoflag.ee {
  height: 13px;
  background-position: -1467px 0px;
}
.isoflag.eg {
  height: 14px;
  background-position: -1489px 0px;
}
.isoflag.eh {
  height: 10px;
  background-position: -1511px 0px;
}
.isoflag.er {
  height: 10px;
  background-position: -1533px 0px;
}
.isoflag.es {
  height: 14px;
  background-position: -1555px 0px;
}
.isoflag.et {
  height: 10px;
  background-position: -1577px 0px;
}
.isoflag.eu {
  height: 14px;
  background-position: -1599px 0px;
}
.isoflag.fi {
  height: 12px;
  background-position: -1621px 0px;
}
.isoflag.fj {
  height: 10px;
  background-position: -1643px 0px;
}
.isoflag.fk {
  height: 10px;
  background-position: -1665px 0px;
}
.isoflag.fm {
  height: 11px;
  background-position: -1687px 0px;
}
.isoflag.fo {
  height: 15px;
  background-position: -1709px 0px;
}
.isoflag.fr {
  height: 14px;
  background-position: -1731px 0px;
}
.isoflag.ga {
  height: 15px;
  background-position: -1753px 0px;
}
.isoflag.gb {
  height: 10px;
  background-position: -1775px 0px;
}
.isoflag.gd {
  height: 12px;
  background-position: -1797px 0px;
}
.isoflag.ge {
  height: 14px;
  background-position: -1819px 0px;
}
.isoflag.gf {
  height: 14px;
  background-position: -1841px 0px;
}
.isoflag.gg {
  height: 14px;
  background-position: -1863px 0px;
}
.isoflag.gh {
  height: 14px;
  background-position: -1885px 0px;
}
.isoflag.gi {
  height: 10px;
  background-position: -1907px 0px;
}
.isoflag.gl {
  height: 14px;
  background-position: -1929px 0px;
}
.isoflag.gm {
  height: 14px;
  background-position: -1951px 0px;
}
.isoflag.gn {
  height: 14px;
  background-position: -1973px 0px;
}
.isoflag.gp {
  height: 14px;
  background-position: -1995px 0px;
}
.isoflag.gq {
  height: 14px;
  background-position: -2017px 0px;
}
.isoflag.gr {
  height: 14px;
  background-position: -2039px 0px;
}
.isoflag.gs {
  height: 10px;
  background-position: -2061px 0px;
}
.isoflag.gt {
  height: 13px;
  background-position: -2083px 0px;
}
.isoflag.gu {
  height: 11px;
  background-position: -2105px 0px;
}
.isoflag.gw {
  height: 10px;
  background-position: -2127px 0px;
}
.isoflag.gy {
  height: 12px;
  background-position: -2149px 0px;
}
.isoflag.hk {
  height: 14px;
  background-position: -2171px 0px;
}
.isoflag.hm {
  height: 10px;
  background-position: -2193px 0px;
}
.isoflag.hn {
  height: 10px;
  background-position: -2215px 0px;
}
.isoflag.hr {
  height: 10px;
  background-position: -2237px 0px;
}
.isoflag.ht {
  height: 12px;
  background-position: -2259px 0px;
}
.isoflag.hu {
  height: 10px;
  background-position: -2281px 0px;
}
.isoflag.ic {
  height: 14px;
  background-position: -2303px 0px;
}
.isoflag.id {
  height: 14px;
  background-position: -2325px 0px;
}
.isoflag.ie {
  height: 10px;
  background-position: -2347px 0px;
}
.isoflag.il {
  height: 15px;
  background-position: -2369px 0px;
}
.isoflag.im {
  height: 10px;
  background-position: -2391px 0px;
}
.isoflag.in {
  height: 14px;
  background-position: -2413px 0px;
}
.isoflag.io {
  height: 10px;
  background-position: -2435px 0px;
}
.isoflag.iq {
  height: 14px;
  background-position: -2457px 0px;
}
.isoflag.ir {
  height: 12px;
  background-position: -2479px 0px;
}
.isoflag.is {
  height: 15px;
  background-position: -2501px 0px;
}
.isoflag.it {
  height: 14px;
  background-position: -2523px 0px;
}
.isoflag.je {
  height: 12px;
  background-position: -2545px 0px;
}
.isoflag.jm {
  height: 10px;
  background-position: -2567px 0px;
}
.isoflag.jo {
  height: 10px;
  background-position: -2589px 0px;
}
.isoflag.jp {
  height: 14px;
  background-position: -2611px 0px;
}
.isoflag.ke {
  height: 14px;
  background-position: -2633px 0px;
}
.isoflag.kg {
  height: 12px;
  background-position: -2655px 0px;
}
.isoflag.kh {
  height: 13px;
  background-position: -2677px 0px;
}
.isoflag.ki {
  height: 10px;
  background-position: -2699px 0px;
}
.isoflag.km {
  height: 12px;
  background-position: -2721px 0px;
}
.isoflag.kn {
  height: 14px;
  background-position: -2743px 0px;
}
.isoflag.kp {
  height: 10px;
  background-position: -2765px 0px;
}
.isoflag.kr {
  height: 14px;
  background-position: -2787px 0px;
}
.isoflag.kw {
  height: 10px;
  background-position: -2809px 0px;
}
.isoflag.ky {
  height: 10px;
  background-position: -2831px 0px;
}
.isoflag.kz {
  height: 10px;
  background-position: -2853px 0px;
}
.isoflag.la {
  height: 14px;
  background-position: -2875px 0px;
}
.isoflag.lb {
  height: 14px;
  background-position: -2897px 0px;
}
.isoflag.lc {
  height: 10px;
  background-position: -2919px 0px;
}
.isoflag.li {
  height: 12px;
  background-position: -2941px 0px;
}
.isoflag.lk {
  height: 10px;
  background-position: -2963px 0px;
}
.isoflag.lr {
  height: 11px;
  background-position: -2985px 0px;
}
.isoflag.ls {
  height: 14px;
  background-position: -3007px 0px;
}
.isoflag.lt {
  height: 12px;
  background-position: -3029px 0px;
}
.isoflag.lu {
  height: 12px;
  background-position: -3051px 0px;
}
.isoflag.lv {
  height: 10px;
  background-position: -3073px 0px;
}
.isoflag.ly {
  height: 10px;
  background-position: -3095px 0px;
}
.isoflag.ma {
  height: 14px;
  background-position: -3117px 0px;
}
.isoflag.mc {
  height: 15px;
  background-position: -3139px 0px;
}
.isoflag.md {
  height: 10px;
  background-position: -3160px 0px;
}
.isoflag.me {
  height: 10px;
  background-position: -3182px 0px;
}
.isoflag.mf {
  height: 14px;
  background-position: -3204px 0px;
}
.isoflag.mg {
  height: 14px;
  background-position: -3226px 0px;
}
.isoflag.mh {
  height: 11px;
  background-position: -3248px 0px;
}
.isoflag.mk {
  height: 10px;
  background-position: -3270px 0px;
}
.isoflag.ml {
  height: 14px;
  background-position: -3292px 0px;
}
.isoflag.mm {
  height: 14px;
  background-position: -3314px 0px;
}
.isoflag.mn {
  height: 10px;
  background-position: -3336px 0px;
}
.isoflag.mo {
  height: 14px;
  background-position: -3358px 0px;
}
.isoflag.mp {
  height: 10px;
  background-position: -3380px 0px;
}
.isoflag.mq {
  height: 14px;
  background-position: -3402px 0px;
}
.isoflag.mr {
  height: 14px;
  background-position: -3424px 0px;
}
.isoflag.ms {
  height: 10px;
  background-position: -3446px 0px;
}
.isoflag.mt {
  height: 14px;
  background-position: -3468px 0px;
}
.isoflag.mu {
  height: 14px;
  background-position: -3490px 0px;
}
.isoflag.mv {
  height: 14px;
  background-position: -3512px 0px;
}
.isoflag.mw {
  height: 14px;
  background-position: -3534px 0px;
}
.isoflag.mx {
  height: 12px;
  background-position: -3556px 0px;
}
.isoflag.my {
  height: 10px;
  background-position: -3578px 0px;
}
.isoflag.mz {
  height: 14px;
  background-position: -3600px 0px;
}
.isoflag.na {
  height: 14px;
  background-position: -3622px 0px;
}
.isoflag.nc {
  height: 10px;
  background-position: -3644px 0px;
}
.isoflag.ne {
  height: 15px;
  background-position: -3666px 0px;
}
.isoflag.nf {
  height: 10px;
  background-position: -3686px 0px;
}
.isoflag.ng {
  height: 10px;
  background-position: -3708px 0px;
}
.isoflag.ni {
  height: 12px;
  background-position: -3730px 0px;
}
.isoflag.nl {
  height: 14px;
  background-position: -3752px 0px;
}
.isoflag.no {
  height: 15px;
  background-position: -3774px 0px;
}
.isoflag.np {
  height: 15px;
  background-position: -3796px 0px;
}
.isoflag.nr {
  height: 10px;
  background-position: -3811px 0px;
}
.isoflag.nu {
  height: 10px;
  background-position: -3833px 0px;
}
.isoflag.nz {
  height: 10px;
  background-position: -3855px 0px;
}
.isoflag.om {
  height: 10px;
  background-position: -3877px 0px;
}
.isoflag.pa {
  height: 14px;
  background-position: -3899px 0px;
}
.isoflag.pe {
  height: 14px;
  background-position: -3921px 0px;
}
.isoflag.pf {
  height: 14px;
  background-position: -3943px 0px;
}
.isoflag.pg {
  height: 15px;
  background-position: -3965px 0px;
}
.isoflag.ph {
  height: 10px;
  background-position: -3987px 0px;
}
.isoflag.pk {
  height: 14px;
  background-position: -4009px 0px;
}
.isoflag.pl {
  height: 13px;
  background-position: -4031px 0px;
}
.isoflag.pm {
  height: 14px;
  background-position: -4053px 0px;
}
.isoflag.pn {
  height: 10px;
  background-position: -4075px 0px;
}
.isoflag.pr {
  height: 14px;
  background-position: -4097px 0px;
}
.isoflag.ps {
  height: 10px;
  background-position: -4119px 0px;
}
.isoflag.pt {
  height: 14px;
  background-position: -4141px 0px;
}
.isoflag.pw {
  height: 13px;
  background-position: -4163px 0px;
}
.isoflag.py {
  height: 11px;
  background-position: -4185px 0px;
}
.isoflag.qa {
  height: 8px;
  background-position: -4207px 0px;
}
.isoflag.re {
  height: 14px;
  background-position: -4229px 0px;
}
.isoflag.ro {
  height: 14px;
  background-position: -4251px 0px;
}
.isoflag.rs {
  height: 14px;
  background-position: -4273px 0px;
}
.isoflag.ru {
  height: 14px;
  background-position: -4295px 0px;
}
.isoflag.rw {
  height: 14px;
  background-position: -4317px 0px;
}
.isoflag.sa {
  height: 14px;
  background-position: -4339px 0px;
}
.isoflag.sb {
  height: 10px;
  background-position: -4361px 0px;
}
.isoflag.sc {
  height: 10px;
  background-position: -4383px 0px;
}
.isoflag.sd {
  height: 10px;
  background-position: -4405px 0px;
}
.isoflag.se {
  height: 13px;
  background-position: -4427px 0px;
}
.isoflag.sg {
  height: 14px;
  background-position: -4449px 0px;
}
.isoflag.sh {
  height: 10px;
  background-position: -4471px 0px;
}
.isoflag.si {
  height: 10px;
  background-position: -4493px 0px;
}
.isoflag.sj {
  height: 15px;
  background-position: -4515px 0px;
}
.isoflag.sk {
  height: 14px;
  background-position: -4537px 0px;
}
.isoflag.sl {
  height: 14px;
  background-position: -4559px 0px;
}
.isoflag.sm {
  height: 15px;
  background-position: -4581px 0px;
}
.isoflag.sn {
  height: 14px;
  background-position: -4603px 0px;
}
.isoflag.so {
  height: 14px;
  background-position: -4625px 0px;
}
.isoflag.sr {
  height: 14px;
  background-position: -4647px 0px;
}
.isoflag.ss {
  height: 10px;
  background-position: -4669px 0px;
}
.isoflag.st {
  height: 10px;
  background-position: -4691px 0px;
}
.isoflag.sv {
  height: 12px;
  background-position: -4713px 0px;
}
.isoflag.sx {
  height: 14px;
  background-position: -4735px 0px;
}
.isoflag.sy {
  height: 14px;
  background-position: -4757px 0px;
}
.isoflag.sz {
  height: 14px;
  background-position: -4779px 0px;
}
.isoflag.ta {
  height: 10px;
  background-position: -4801px 0px;
}
.isoflag.tc {
  height: 10px;
  background-position: -4823px 0px;
}
.isoflag.td {
  height: 14px;
  background-position: -4845px 0px;
}
.isoflag.tf {
  height: 14px;
  background-position: -4867px 0px;
}
.isoflag.tg {
  height: 13px;
  background-position: -4889px 0px;
}
.isoflag.th {
  height: 14px;
  background-position: -4911px 0px;
}
.isoflag.tj {
  height: 10px;
  background-position: -4933px 0px;
}
.isoflag.tk {
  height: 10px;
  background-position: -4955px 0px;
}
.isoflag.tl {
  height: 10px;
  background-position: -4977px 0px;
}
.isoflag.tm {
  height: 14px;
  background-position: -4999px 0px;
}
.isoflag.tn {
  height: 14px;
  background-position: -5021px 0px;
}
.isoflag.to {
  height: 10px;
  background-position: -5043px 0px;
}
.isoflag.tr {
  height: 14px;
  background-position: -5065px 0px;
}
.isoflag.tt {
  height: 12px;
  background-position: -5087px 0px;
}
.isoflag.tv {
  height: 10px;
  background-position: -5109px 0px;
}
.isoflag.tw {
  height: 14px;
  background-position: -5131px 0px;
}
.isoflag.tz {
  height: 14px;
  background-position: -5153px 0px;
}
.isoflag.ua {
  height: 14px;
  background-position: -5175px 0px;
}
.isoflag.ug {
  height: 14px;
  background-position: -5197px 0px;
}
.isoflag.um {
  height: 11px;
  background-position: -5219px 0px;
}
.isoflag.en {
  height: 11px;
  background-position: -5241px 0px;
}
.isoflag.uy {
  height: 14px;
  background-position: -5263px 0px;
}
.isoflag.uz {
  height: 10px;
  background-position: -5285px 0px;
}
.isoflag.va {
  height: 15px;
  background-position: -5307px 0px;
}
.isoflag.vc {
  height: 14px;
  background-position: -5324px 0px;
}
.isoflag.ve {
  height: 14px;
  background-position: -5346px 0px;
}
.isoflag.vg {
  height: 10px;
  background-position: -5368px 0px;
}
.isoflag.vi {
  height: 14px;
  background-position: -5390px 0px;
}
.isoflag.vn {
  height: 14px;
  background-position: -5412px 0px;
}
.isoflag.vu {
  height: 12px;
  background-position: -5434px 0px;
}
.isoflag.wf {
  height: 14px;
  background-position: -5456px 0px;
}
.isoflag.ws {
  height: 10px;
  background-position: -5478px 0px;
}
.isoflag.xk {
  height: 15px;
  background-position: -5500px 0px;
}
.isoflag.ye {
  height: 14px;
  background-position: -5522px 0px;
}
.isoflag.yt {
  height: 14px;
  background-position: -5544px 0px;
}
.isoflag.za {
  height: 14px;
  background-position: -5566px 0px;
}
.isoflag.zm {
  height: 14px;
  background-position: -5588px 0px;
}
.isoflag.zw {
  height: 10px;
  background-position: -5610px 0px;
}
.isoflag {
  width: 20px;
  height: 20px;
  background-image: url('/assets/img/isoflags/flags.png');
  background-repeat: no-repeat;
  background-color: #dbdbdb;
  background-position: 20px 0;
  display: inline-block;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .isoflag {
    background-image: url('/assets/img/isoflags/flags.png');
  }
}
.isoflag.np {
  background-color: transparent;
}

.app-input__wrap .app-input__wrap {
  background: none !important;
  border: none !important;
}
.app-input__wrap .app-input__wrap--error {
  border: 1px solid #e33131 !important;
  margin: 0 !important;
}
</style>
