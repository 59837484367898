<template>
  <UiTooltip
    ref="tippy"
    containerClass="app-dropdown-menu"
    :content="''"
    theme="dark"
    external
    interactive
    :arrow="arrow"
    placement="bottom"
    trigger="click"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot></slot>

    <div slot="content" class="app-dropdown-menu__list">
      <div
        v-for="(menu, id) in menuItems"
        :key="id"
        class="app-dropdown-menu__list__item"
        @click.stop.prevent="emit(menu.action)"
      >
        <Icon class="app-dropdown-menu__list__item-icn" :src="menu.icn"></Icon>
        <Typography>{{ menu.title }}</Typography>
      </div>
    </div>
  </UiTooltip>
</template>

<script>
import {UiTooltip} from '~ui'

export default {
  components: {
    UiTooltip,
  },
  props: {
    arrow: {
      type: [Boolean, Number],
    },
    menuItems: {
      type: Array,
      default() {
        return [
          {
            action: 'edit',
            title: this.$t('course.edit'),
            icn: 'icons/pencil.svg',
          },
          {
            action: 'delete',
            title: this.$t('course.delete'),
            icn: 'icons/close.svg',
          },
        ]
      },
    },
  },
  methods: {
    emit(name) {
      this.$emit(name)

      if (this.$refs.tippy) {
        this.$refs.tippy.hide()
      }
    },
  },
}
</script>

<style lang="scss">
.app-dropdown-menu {
  margin-left: 12px;

  &__list {
    display: flex;
    flex-flow: column;

    &__item {
      padding: 16px 14px;
      display: flex;
      &:hover {
        cursor: pointer;
        background-color: #2e3141;
        svg {
          fill: #fff !important;
        }
      }
      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &-icn {
        margin-right: 10px;
      }
    }
  }
}
</style>
