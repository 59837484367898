import {propOr, type} from 'ramda'
import {mutationTypes, actionTypes} from './types'

// get messages list from api inside data or in nested prop
const getMessages = (obj) => (type(obj) === 'Array' ? obj : propOr([], 'messages', obj))

// map raw api data to store format
const mapToState = (rawData) => {
  return {
    messages: getMessages(rawData.data),
    links: rawData.links,
    meta: rawData.meta,
  }
}

export const actions = {
  // create new message
  async [actionTypes.POST_MESSAGE]({commit, dispatch}, payload = {}) {
    const {webinar, params} = payload

    try {
      const data = await this.$axios.$post(`/online-webinar/${webinar}/messages`, params)

      dispatch(actionTypes.INSERT_MESSAGE, data)
    } catch (err) {}
  },
  // edit message by provided id
  async [actionTypes.EDIT_MESSAGE]({commit, state}, {msgId, params}) {
    try {
      const data = await this.$axios.$patch(`/online-webinar/messages/${msgId}`, params)

      commit(mutationTypes.EDIT_MESSAGE, data)
    } catch (err) {}
  },
  // delete message by provided id
  async [actionTypes.DELETE_MESSAGE]({commit, state}, {msgId}) {
    try {
      await this.$axios.$delete(`/online-webinar/messages/${msgId}`)

      commit(mutationTypes.DELETE_MESSAGE, {id: msgId})
    } catch (err) {}
  },
  // get first page of webinar chat messages
  async [actionTypes.FETCH_WEBINAR_MESSAGES]({commit, dispatch}, {webinarId}) {
    commit(mutationTypes.SET_LOADING, true)
    const url = `/online-webinar/${webinarId}/messages` // /?sort=-created_at
    try {
      const data = await this.$axios.$get(url)

      commit(mutationTypes.SET, mapToState(data))
    } catch (err) {
    } finally {
      commit(mutationTypes.SET_LOADING, false)
    }
  },
  // get first page of homework chat messages
  async [actionTypes.FETCH_HOMEWORK_MESSAGES]({commit, dispatch}, {chatId}) {
    const url = `/chat-messages/student-courses/${chatId}/list`
    commit(mutationTypes.SET_LOADING, true)
    try {
      const {data} = await this.$axios.get(url)

      commit(mutationTypes.SET, mapToState(data))
      commit(mutationTypes.SET_LOADING, false)
      return data
    } catch (err) {}
  },
  // get next page of chat messages
  async [actionTypes.FETCH_MORE_MESSAGES]({state, commit, getters}) {
    if (!getters.hasMore) return

    const data = await this.$axios.$get(state.links.next)
    const newMessages = getMessages(data.data) || []
    const newData = {
      ...data,
      data: {
        ...data.data,
        messages: [...state.messages, ...newMessages],
      },
    }

    commit(mutationTypes.SET, mapToState(newData))
    return {
      prevMessages: state.messages,
      messages: newMessages,
    }
  },

  // $FIXME: should refact this action
  [actionTypes.UPDATE_HOMEWORK_MESSAGE]({commit, state}, payload) {
    const newData = [...state.messages]
    const msgIndex = newData.findIndex((v) => v.student_homework.id === payload.id)
    newData[msgIndex] = {
      ...newData[msgIndex],
      student_homework: payload,
    }
    commit(mutationTypes.UPDATE_MESSAGE_LIST, newData)
  },

  [actionTypes.INSERT_MESSAGE]({commit}, message) {
    commit(mutationTypes.INSERT, message)
  },

  [actionTypes.RESET_CHAT]({commit}) {
    commit(mutationTypes.RESET_MESSAGES)
  },

  // $TODO: REFACT TO SEPARATE STORE
  async [actionTypes.FETCH_STUDENT_PROGRESS]({commit, dispatch}, studentCourseId) {
    try {
      const data = await this.$axios.$get(`/student-courses/${studentCourseId}/progress`)

      commit(mutationTypes.SET_PROGRESS, data)
    } catch (err) {}
  },
}
