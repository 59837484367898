<template>
  <span class="badge-wrapper" :class="{inline}" :style="vars">
    <div v-if="$slots.default" :class="['badge', badgeClass]">
      <slot></slot>
    </div>

    <div v-if="show" :class="['badge', badgeClass]">
      <span v-if="value">{{ value }}</span>
      <Icon v-if="icon" :src="icon" />
    </div>

    <div v-if="text">
      <span class="ml-s" v-html="text || ''"></span>
    </div>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    text: {
      type: [String, Number, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    color: {
      type: [String],
      default: '#6666fe',
    },
    bold: {
      type: Boolean,
      default: true,
    },
    border: {
      type: [String, Boolean],
      default: false,
    },
    borderRadius: {
      type: [String],
      default: null,
    },
    padding: {
      type: String,
      default: '0',
    },
    size: {
      type: [String, Number],
      default: 18,
    },
    left: Boolean,
    bottom: Boolean,
    offset: {
      type: [String, Number],
      default: 0,
    },
    inline: Boolean,

    /* just dot (like you have notifications) */
    dot: Boolean,

    /* if value 0 - hide */
    hideEmpty: {
      type: Boolean,
      default: true,
    },
    badgeClass: {
      type: [Object, Array, String],
      default: () => [],
    },
  },
  computed: {
    show() {
      return this.hideEmpty && !this.dot ? !!this.value : true
    },
    vars() {
      const {size, color, left, bottom, border, bold} = this

      /* get offset value */
      const {x, y} = this.getOffset()

      return {
        '--badge-fw': bold ? 'bold' : 'normal',
        '--badge-size': Number.isInteger(+size) ? size + 'px' : size,
        '--badge-color': color,
        '--badge-border': border ? `${bold ? 2 : 1}px solid ${border}` : '',
        '--badge-top': bottom ? '100%' : 0,
        '--badge-right': left ? '100%' : `${-size}px`,
        '--badge-offset': `${x},${y}`,
        '--badge-margin': `${x} ${y}`,
        '--badge-padding': this.padding,
        '--badge-border-radius': this.borderRadius,
      }
    },
  },
  methods: {
    getOffset() {
      const {offset} = this

      let [x, y] = String(offset).split(' ')

      if (!y) y = x

      if (!isNaN(+x)) {
        x += 'px'
        y += 'px'
      }

      return {x, y}
    },
  },
}
</script>

<style lang="scss">
.badge {
  border-radius: var(--badge-border-radius, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: var(--badge-size, 18px);
  height: var(--badge-size, 18px);
  background: var(--badge-color);
  border: var(--badge-border, none);
  color: #fff;
  font-weight: var(--badge-fw, normal);
  font-size: 11px;
  padding: var(--badge-padding, 0);
}

.badge-wrapper {
  display: block;
  position: relative;
  /* width: 100%; */
  /* height: 100%; */

  &.inline {
    display: inline-flex;
    align-items: center;

    .badge {
      margin: var(--badge-margin, 5px);
    }
  }

  &:not(.inline) {
    .badge {
      position: absolute;
      top: var(--badge-top, 0);
      right: var(--badge-right, 0);
      transform: translate(var(--badge-offset));
    }
  }
}
</style>
