<template>
  <div class="flex items-center">
    <Avatar class="mr-2 flex-grow-0 flex-shrink-0" :user="user" :size="size" light />

    <div v-if="$slots.description" class="flex flex-col">
      <Typography variant="int_s" :noWrap="truncate">
        {{ user.full_name || user.email }}
      </Typography>

      <slot name="description"></slot>
    </div>

    <Typography v-else variant="int_s" :noWrap="truncate">
      {{ user.full_name || user.email }}
    </Typography>
  </div>
</template>

<script>
import {Avatar} from '../ui'

export default {
  components: {
    Avatar,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: 'xs',
    },
    truncate: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
