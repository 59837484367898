import {ADD_TOAST_MESSAGE} from 'vuex-toast'

const root = {root: true}

export const namespaced = true
export const state = () => ({
  bundles: null,
})

export const getters = {
  bundles: (state) => state.bundles,
}

export const mutations = {
  setBundles(state, payload) {
    state.bundles = payload
  },

  addCoursePackage(state, payload) {
    state.bundles.packages.push(payload)
  },

  addModulePackage(state, payload) {
    state.bundles.modules = [...state.bundles.modules, payload]
  },

  removeCoursePackage(state, payload) {
    const packages = state.bundles.packages
    const newPackages = packages.filter(({id}) => id !== payload)

    state.bundles.packages = newPackages
  },

  updatePackage(state, payload) {
    console.log('UPDATE', payload)
    const packages = state.bundles.packages
    const packageToUpdate = packages.findIndex(({id}) => id === payload.id)

    state.bundles.packages[packageToUpdate] = payload
  },

  changeCourseModule(state, payload) {
    const modules = state.bundles.modules
    const moduleToUpdate = modules.findIndex(({id}) => id === payload.id)

    state.bundles.modules[moduleToUpdate] = payload
  },
}

export const actions = {
  async fetchBundles({commit, dispatch}, courseId) {
    const response = await this.$axios.get(`/courses/${courseId}/contents`)

    commit('setBundles', response.data)
  },

  async createBundle({commit, dispatch}, payload) {
    const {courseId, params} = payload
    const response = await this.$axios.post(`/courses/${courseId}/packs/`, params)

    commit('addCoursePackage', response.data)
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: this.$i18n.t('toast.packageCreated'),
        type: 'success',
        dismissAfter: 3000,
      },
      root
    )
  },

  async updateBundle({commit, dispatch}, payload) {
    const {packageId, params, courseId} = payload

    console.log('UPDBUNDL', `/courses/packs/${packageId}/`, params)

    const response = await this.$axios.put(`/courses/packs/${packageId}/`, params)

    commit('updatePackage', response.data)
    await dispatch('fetchBundles', courseId)
  },

  async deleteBundle({commit, dispatch}, bundleId) {
    try {
      await this.$axios.delete(`/courses/packs/${bundleId}`)

      commit('removeCoursePackage', bundleId)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.packageDeleted'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async switchCourseModule({commit, dispatch}, courseModuleId) {
    await this.$axios.put(`/courses/modules/${courseModuleId}/switch-active`)
  },

  async updateCourseModule({commit, dispatch}, data) {
    const {google_id, yandex_id} = data
    const params = {
      title: data.title,
      color: data.color,
      price: data.price,
      is_free: data.isFree,
      currency: data.currency,
      is_active: true,
      is_visible: true,
      google_id,
      yandex_id,
    }

    const response = await this.$axios.patch(`/courses/modules/${data.courseModuleId}`, params)
    commit('changeCourseModule', response.data)
    await dispatch('fetchBundles', data.courseId)
  },
}
