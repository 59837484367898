const wordsDeclension = (num, one, two, five) => {
  num = Math.abs(num) % 100
  if ((num > 4 && num < 21) || (num %= 10) > 4 || num === 0) {
    return five
  }
  if (num > 1) return two
  return one
}

export default wordsDeclension
