<template>
  <PopupWrapper v-bind="$attrs" @close="onClose">
    <Toggle
      :is-checked="analiticsSettings.isAddTags"
      class="mb-3"
      is-static-text
      fontSize="14px"
      static-text="Добавить теги"
      @update:isChecked="analiticsSettings.isAddTags = !analiticsSettings.isAddTags"
    ></Toggle>
    <div v-if="analiticsSettings.isAddTags">
      <UiTagsSelector v-model="analiticsSettings.tags" label=""></UiTagsSelector>
    </div>

    <Toggle
      :is-checked="analiticsSettings.isSubControl"
      is-static-text
      fontSize="14px"
      class="mb-3"
      static-text="Отслеживать подписку через аналитику"
      @update:isChecked="analiticsSettings.isSubControl = !analiticsSettings.isSubControl"
    ></Toggle>
    <div v-if="analiticsSettings.isSubControl">
      <Typography variant="paragraph_xs" color="grey" class="mb-1">
        Укажите Ваши счетчики для Google Аналитики или Яндекс Метрики и установите цель с помощью
        события subscription_ml_1 для отслеживания подписок. Посмотрите наши руководства по
        настройке Google Аналитики и Яндекс Метрики</Typography
      >
      <Input
        v-model="analiticsSettings.isSubControlYM"
        label="Счетчик Яндекс метрики"
        placeholder="14231638"
        class="my-1"
      />
      <Input
        v-model="analiticsSettings.isSubControlGA"
        label="Счетчик Google аналитики"
        placeholder="UA-XXXXXXXX-X"
        class="my-1"
      />
    </div>

    <Toggle
      :is-checked="analiticsSettings.isFbPixel"
      is-static-text
      fontSize="14px"
      class="mb-3"
      static-text="Facebook pixel"
      @update:isChecked="analiticsSettings.isFbPixel = !analiticsSettings.isFbPixel"
    ></Toggle>
    <div v-if="analiticsSettings.isFbPixel">
      <Typography variant="paragraph_xs" color="grey" class="mb-1">
        Укажите ID вашего Facebook Pixel. При клике на кнопку любого из мессенджеров мы отправим
        событие 'Lead' с параметрами content_name: Designer и content_category: riveoness_ml_1.
        Смотрите подробное руководство по настройке.</Typography
      >
      <Input
        v-model="analiticsSettings.isFbPixelValue"
        placeholder="123456789012345"
        class="my-1"
      />
    </div>

    <Toggle
      :is-checked="analiticsSettings.isVkPixel"
      is-static-text
      fontSize="14px"
      class="mb-3"
      static-text="VK pixel"
      @update:isChecked="analiticsSettings.isVkPixel = !analiticsSettings.isVkPixel"
    ></Toggle>
    <div v-if="analiticsSettings.isVkPixel">
      <Typography variant="paragraph_xs" color="grey" class="mb-1">
        Для использования пикселя необходимо указать его уникальный код. При нажатии на любую из
        кнопок, мы отправляем событие 'Lead' для дополнительной сегментации посетителей. Узнайте
        больше о том, как правильно установить пиксель на мини лендинг и проверить его работу в
        руководстве.</Typography
      >
      <Input
        v-model="analiticsSettings.isVkPixelValue"
        placeholder="VK-RTRG-381782-3nh8w"
        class="my-1"
      />
    </div>

    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSave">{{ btnText }}</Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input} from '@/components/ui'
import Toggle from '~ui/Input/Toggle'
import UiTagsSelector from '~ui/TagsSelector'

const defState = () => ({
  isAddTags: false,
  isSubControl: false,
  isFbPixel: false,
  isVkPixel: false,
  isFbPixelValue: '',
  isVkPixelValue: '',
  isSubControlYM: '',
  isSubControlGA: '',
  tags: [],
})

export default {
  components: {
    UiTagsSelector,
    Toggle,
    PopupWrapper,
    Button,
    Input,
  },
  mixins: [validationMixin],

  data: () => ({
    analiticsSettings: defState(),
  }),
  computed: {
    btnText() {
      return 'Сохранить'
    },
  },

  methods: {
    onClose() {
      this.$emit('close')
      this.analiticsSettings = defState()
      this.$v.analiticsSettings.$reset()
    },
    async onSave() {
      this.$v.analiticsSettings.$touch()
      if (this.$v.analiticsSettings.$invalid) return

      const params = {}

      let data = await this.$axios.$post('/author/lead-pages', params)
      // await this.$store.dispatch('funnelLeads/CREATE_TG_BOT', {params})
      this.onClose()
    },
  },
}
</script>
