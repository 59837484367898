<template>
  <div v-if="loading && $isIos" class="loading-page">
    <Spinner></Spinner>
  </div>
</template>

<script>
import Spinner from '@/components/ui/Spinner'

export default {
  components: {Spinner},
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
.loading-page {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  background: #fff;
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;

  img {
    margin: 0 auto;
    display: inline;
  }
}
</style>
