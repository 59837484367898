import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {uniq} from 'ramda'

const root = {root: true}

export const state = () => ({
  courses: [{loading: true}],
  courseLanding: {},
  createdCourseId: null,
  coursesAllCategories: [],
})

export const getters = {
  coursesAllCategories: (state) => state.coursesAllCategories.map(({name}) => name),
  getCourses: (state) => state.courses,
  getCourseLanding: (state) => state.courseLanding,
  createdCourseId: (state) => state.createdCourseId,
}

export const mutations = {
  setCourses(state, payload) {
    state.courses = uniq(payload || [])
  },
  addCourse(state, payload) {
    state.courses = [payload, ...state.courses]
  },
  setCreatedCourseId(state, payload) {
    state.createdCourseId = payload
  },
  setCourseLanding(state, payload) {
    state.courseLanding = payload
  },
  setCoursesAllCategories(state, payload) {
    state.coursesAllCategories = payload
  },
}

export const actions = {
  async fetchAuthorPublishedCourses({commit, dispatch}) {
    try {
      const data = await this.$axios.$get('/author/courses?filter[status]=published')

      commit('setCourses', data)
      return data
    } catch (err) {}
  },
  async fetchAuthorCourses({commit, dispatch}) {
    try {
      const data = await this.$axios.$get('/author/courses')

      commit('setCourses', data)
      return data
    } catch (err) {}
  },

  async fetchUserCourses({commit, dispatch}) {
    try {
      const data = await this.$axios.$get('/student-courses/list')

      commit('setCourses', data)
    } catch (err) {}
  },

  async addCourse({commit, dispatch}, payload) {
    commit('setCreatedCourseId', 'temp')
    try {
      const data = await this.$axios.$post('/courses', {
        title: payload,
      })

      commit('setCreatedCourseId', data.id)
      commit('addCourse', data)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.courseCreated'),
          type: 'success',
          dismissAfter: 1000,
        },
        root
      )
    } catch (err) {}
  },

  async deleteCourse({commit, dispatch}, courseId) {
    try {
      await this.$axios.$delete(`/courses/${courseId}`)
    } catch (err) {}
  },

  async fetchAllCategories({commit, dispatch}) {
    try {
      const data = await this.$axios.$get('/tags/list')

      commit('setCoursesAllCategories', data)
    } catch (err) {}
  },
}
