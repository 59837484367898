export default function ({route, store, redirect}) {
  const user = store.getters['user/getUser']
  if (user.current_role !== 'author') return true

  const redir = () => {
    if (route.name === 'plans') {
      //
    } else return redirect({name: 'plans'})
  }

  const plan = store.getters['authorPlan/isActive']

  if (!plan) {
    return redir()
  }
}
