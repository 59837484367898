<template>
  <div class="create-button-popup-wrapper">
    <div v-if="$slots.activator" @click="showPopup = true">
      <slot name="activator"></slot>
    </div>

    <PopupWrapper :title="poptitle" :isOpen="showPopup" v-bind="$attrs" @close="onClose">
      <Input
        v-model="form.text"
        :label="$t('popups.btnText') + ':'"
        :placeholder="$t('popups.btnText') + ':'"
        :error="$v.form.text.$error"
        :errorMessage="$t('forms.rules.requiredField')"
      />

      <ColorPicker v-model="form.color" preset="mini" showFallback class="mb-2">
        <Typography slot="title" variant="paragraph_xs" color="grey" class="mb-1">
          {{ $t('popups.btnColor') }}:
        </Typography>

        <Typography
          v-if="$v.form.color.$error"
          slot="error"
          variant="paragraph_xs"
          color="error"
          class="mt-xs"
        >
          {{ $t('popups.selectBtnColor') }}
        </Typography>
      </ColorPicker>

      <div class="input-group mb-2">
        <Typography variant="paragraph_xs" color="grey" class="mb-1"
          >{{ $t('popups.textColor') }}:</Typography
        >

        <Toggle
          :isChecked="!isDarkTextColor"
          :color="form.color"
          @update:isChecked="updateTextColor"
        >
          <Icon
            slot="icon"
            src="icons/text-icon.svg"
            size="xs"
            :fill="isDarkTextColor ? DARK_TEXT_COLOR : LIGHT_TEXT_COLOR"
          ></Icon>
        </Toggle>
      </div>

      <template v-if="isWebinar">
        <Selector
          :value="formTypeValue"
          class="mb-2"
          open-direction="bottom"
          :options="TYPES"
          :error="$v.form.type.$error"
          :errorMessage="$t('forms.rules.requiredField')"
          @input="(type) => type && (form.type = type.value)"
        >
          <template slot="label">
            <div class="mb-1">{{ $t('popups.btnType') }}:</div>
          </template>
        </Selector>

        <CourseProductSelect
          v-if="isProductType"
          ref="courseProductSelect"
          :value="formProductValue"
          inline
          @course:select="(id) => (form.course_id = id)"
          @product:select="(product) => product && (form.product_id = product.id)"
        />

        <Input
          v-if="!isProductType"
          v-model="form.url"
          :label="$t('popups.link') + ':'"
          :error="$v.form.url.$error"
          :errorMessage="urlError"
        >
          <Icon slot="icon" src="icons/link-icon.svg" size="s" />
        </Input>
      </template>
      <Button
        class="mt-auto"
        :icon="isEditMode ? '' : 'icons/plus-in-circle.svg'"
        :loading="loading"
        @click="submit"
      >
        {{ isEditMode ? $t('common.save') : poptitle }}
      </Button>
    </PopupWrapper>
  </div>
</template>

<script>
import {required, url} from 'vuelidate/lib/validators'
import {validationMixin} from 'vuelidate'

import {Selector, ColorPicker, PopupWrapper, Toggle} from '@/components/ui'
import CourseProductSelect from '@/components/CourseProductSelect'

const LINK_TYPE = 'link'
const PRODUCT_TYPE = 'product'

const DARK_TEXT_COLOR = '#2e3141'
const LIGHT_TEXT_COLOR = '#ffffff'

export default {
  components: {
    Selector,
    Toggle,
    ColorPicker,
    CourseProductSelect,
    PopupWrapper,
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: [Object, Boolean],
      default: () => false,
    },
    poptitle: {
      type: String,
      default() {
        return this.$t('webinars.addBtn')
      },
    },
    isOpen: Boolean,
    variant: {
      type: String,
      default: 'webinar',
      validator: (v) => ['webinar', 'simple'].includes(v),
    },
  },
  validations() {
    let form = {
      text: {required},
      color: {required},
    }

    if (this.variant === 'simple') {
      return {form}
    } else {
      form = {
        ...form,
        type: {required},
      }
      if (!this.isProductType) {
        Object.assign(form, {url: {url, required}})
      }
      return {form}
    }
  },
  data() {
    return {
      loading: false,
      showPopup: this.isOpen,
      form: {},
      isDarkTextColor: false,
      DARK_TEXT_COLOR,
      LIGHT_TEXT_COLOR,
    }
  },
  computed: {
    TYPES() {
      return [
        {title: this.$t('popups.link'), value: LINK_TYPE},
        {title: this.$t('popups.product'), value: PRODUCT_TYPE},
      ]
    },
    isWebinar() {
      return this.variant === 'webinar'
    },
    isSimple() {
      return this.variant === 'simple'
    },
    formProductValue() {
      const {course_id, product_id} = this.form || {}

      return {
        course_id,
        product_id,
      }
    },
    formTypeValue() {
      return this.TYPES.find((t) => t.value === this.form.type)
    },
    isProductType() {
      return this.form.type === PRODUCT_TYPE
    },
    urlError() {
      if (!this.$v.form.url.$dirty) return

      if (!this.$v.form.url.url) {
        return this.$t('popups.incorrectUrl')
      }

      return this.$t('forms.rules.requiredField')
    },
    isEditMode() {
      return this.data && Object.keys(this.data).length
    },
  },
  watch: {
    data(e) {
      this.reset()
    },
    isOpen(e) {
      this.showPopup = e
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    updateTextColor() {
      this.isDarkTextColor = !this.isDarkTextColor

      this.form.text_color = this.isDarkTextColor ? DARK_TEXT_COLOR : LIGHT_TEXT_COLOR
    },
    reset() {
      this.form = this.isEditMode
        ? {...this.data}
        : {
            text: this.$t('minilanding.subscribe'),
            text_color: LIGHT_TEXT_COLOR,
            color: '',
            type: LINK_TYPE,
            url: '',
            course_id: null,
            product_id: null,
          }

      if (this.isEditMode && this.data.product) {
        this.form.product_id = this.data.product.id
      }

      if (this.form.text_color) {
        this.isDarkTextColor = this.form.text_color.toLowerCase() === DARK_TEXT_COLOR
      }

      this.$v.$reset()
    },

    validate() {
      this.$v.$touch()

      let errors = this.$v.$invalid

      if (this.isProductType) {
        errors |= !this.$refs.courseProductSelect.touch()
      }

      return !errors
    },

    async submit() {
      if (!this.validate()) return

      this.loading = true

      try {
        if (this.$listeners.save) {
          /* TODO: remove */
          if (!this.isProductType) {
            delete this.form.product_id
            delete this.form.course_id
          } else {
            delete this.form.url
          }

          await this.$listeners.save(this.form, this.isEditMode)
        }

        this.onClose()
      } catch (err) {
      } finally {
        this.loading = false
      }
    },

    onClose() {
      if (this.$slots.activator) {
        this.showPopup = false
      }

      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.create-button-popup-wrapper {
  .app-input label {
    margin-bottom: 10px !important;
  }
}
</style>
