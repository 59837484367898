<template>
  <div class="widget-search-wrapper">
    <SelectedItem v-if="isSelected && !multiple" :item="selectedItem" @deselect="deselectItem" />

    <Input
      v-show="!isSelected || multiple"
      ref="widget-search-input"
      v-model="searchText"
      :loading="loading"
      :placeholder="$t('widgets.searchProduct') + '…'"
      @input="onSearch"
      @change="onSearchChange"
      @focus="setActive(true)"
      @blur="onSearchBlur"
    >
      <Icon slot="icon" src="/icons/search-loopa.svg" />
    </Input>

    <!-- list of selected products when multiple: true -->
    <ProductList
      v-if="multiple && (filteredMultiple.packs.length || filteredMultiple.modules.length)"
      :products="[filteredMultiple]"
      :packs="filteredMultiple.packs"
      :modules="filteredMultiple.modules"
      removable
      @remove="removeFromSaved"
    />

    <Transition name="fade">
      <ProductList
        v-show="isInputActive"
        :products="filteredProducts"
        :emptyLabel="emptyLabel"
        @select="selectItem"
        @mousedown.native.prevent="onMousedown"
        @mouseup.native="onMouseUp"
      />
    </Transition>
  </div>
</template>

<script>
/**
 * ProductSelect - select продукта (пакет.модуль) курса.
 *
 * Возможные варианты выбора:
 * 1 курс - 1 продукт
 * 1 курс - Много продуктов
 * Много курсов - Много продуктов
 */
import {filter, includes, toLower, isEmpty} from 'ramda'

const byTitle = (search) => (val = {}) => val.title && includes(search, toLower(val.title))
const filterProducts = (search, data) => filter(byTitle(search), data)

export default {
  name: 'ProductSelect',
  components: {
    ProductList: () => import('./ProductList'),
    SelectedItem: () => import('./SelectedItem'),
  },
  props: {
    value: {
      type: [Object, Array, String],
      default: () => [],
    },
    /**
     * Array of objects
     * It must respect following structure
     * @example [{modules: [], packs: [], courseTitle?: '' }]
     * @type {Array}
     */
    data: {
      type: Array,
      default: () => [
        {
          modules: [],
          packs: [],
        },
      ],
      required: true,
    },
    loading: Boolean,
    /**
     * Allows to select multiple products if true
     * @default false
     * @type {Boolean}
     */
    multiple: Boolean,
    multipleCourses: {
      type: Boolean,
      default: true,
    },
    /**
     * Label when nothing searched
     * @default false
     * @type {String}
     */
    emptyLabel: {
      type: String,
      default() {
        return this.$t('widgets.nothingHave')
      },
    },
    /**
     * Either use internal searching or disable to make async search
     * @default true
     * @type {Boolean}
     */
    internalSearch: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    isInputActive: false,
    selectedItem: {},
    mousedown: false,
    searchText: '',
    savedItems: {},
  }),
  computed: {
    isSelected() {
      return this.selectedItem && this.selectedItem.id
    },
    filteredMultiple: {
      /* TODO: мрачная херня, переписать multiple опцию в целом. В частности , в компонент ProductList добавить возможность приема целого списка продуктов без разделения на пакеты и модули */
      get() {
        if (!this.savedItems) return {}

        const accumInitial = {
          packs: [],
          modules: [],
        }

        let filtered = accumInitial

        const courseIds = Object.keys(this.savedItems)

        if (courseIds.length) {
          courseIds.forEach((courseId) => {
            const products = [...this.savedItems[courseId]]

            filtered = products.reduce((acc, product) => {
              const type = product.product_type === 'CourseModule' ? 'modules' : 'packs'
              const duplicate = acc[type] && acc[type].find((e) => e.id === product.id)
              acc[type] = duplicate ? acc[type] : [...(acc[type] || []), product]
              return acc
            }, accumInitial)
          })
        }

        return filtered
      },
      set(data) {
        const isArray = Array.isArray(data)
        const isEmpty = !data || (isArray ? !data.length : !Object.keys(data).length)

        if (isEmpty) return

        const products = isArray ? data : [...data.modules, ...data.packs]

        if (products.length) {
          this.savedItems = products.reduce((products, product) => {
            products[product.course_id] = products[product.course_id] || []
            products[product.course_id].push(product)
            return products
          }, {})
        }
      },
    },
    filteredProducts() {
      // disable internal filtering. return input as is.
      if (!this.internalSearch) return this.data

      // $TODO: search inside all products
      const {packs, modules} = this.data[0]
      // const modules = this.data.modules
      if (isEmpty(packs) && isEmpty(modules)) return []

      if (!this.searchText.length) {
        return this.data
      } else {
        const m = filterProducts(this.searchText, modules)
        const p = filterProducts(this.searchText, packs)

        if (isEmpty(p) && isEmpty(m)) return []

        return [
          {
            packs: p,
            modules: m,
          },
        ]
      }
    },
  },
  watch: {
    value(v) {
      this.setValue(v)
    },
  },
  mounted() {
    this.setValue(this.value)

    if (this.multiple) {
      this.$emit('update', this.filteredMultiple, this.savedItems)
    }
  },
  methods: {
    onMousedown() {
      this.mousedown = true
    },

    onMouseUp() {
      this.mousedown = false
    },

    onSearchBlur() {
      if (this.mousedown) {
        this.mousedown = false
      } else {
        this.setActive(false)
        return
      }

      if (isEmpty(this.selectedItem)) {
        this.setActive(false)
      }
    },

    selectItem(item) {
      this.selectedItem = item
      this.setActive(false)

      if (this.multiple) {
        this.addToSaved(item)
        this.$emit('update', this.filteredMultiple, this.savedItems)
      } else {
        this.$emit('select', item)
      }
    },

    addToSaved(item) {
      const prevState = {...this.savedItems}

      let courseProducts = this.savedItems[item.course_id] || []

      courseProducts = [...courseProducts, item]

      this.savedItems = {
        ...(this.multipleCourses && prevState),
        [item.course_id]: courseProducts,
      }

      this.$refs['widget-search-input'].setBlur()
    },

    removeFromSaved(item) {
      this.savedItems[item.course_id] = this.savedItems[item.course_id].filter(
        (i) => i.id !== item.id
      )

      this.$emit('update', this.filteredMultiple, this.savedItems)
    },

    deselectItem() {
      this.$emit('select', {})
      this.selectedItem = {}
    },

    onSearch(val) {
      this.$emit('input', val)
    },
    onSearchChange(val) {
      this.$emit('search', val)
    },
    setActive(b) {
      this.isInputActive = b

      if (b) this.$emit('open')
    },

    setValue(value) {
      if (this.multiple) {
        this.filteredMultiple = value
      } else {
        this.selectedItem = value
      }
    },
  },
}
</script>

<style lang="scss">
.widget-search-wrapper {
  position: relative;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 16px;
  color: #2e3141;
  transition: 0.2s ease;
  transition-property: background-color, border;
  will-change: background-color, border;

  .app-input__wrap {
    margin-bottom: 0;
  }

  .app-input svg {
    fill: $black;
  }
}
</style>
