import {prop, length} from 'ramda'
import {removeObjectByKey, updateObjectByKey} from '@/utils'

const SET_LOG_LIST = 'SET_LOG_LIST'
const SET_META = 'SET_META'
const INSERT_LOG = 'INSERT_LOG'
const DELETE_LOG_ITEM = 'DELETE_LOG_ITEM'
const FETCH_LOG = 'FETCH_LOG'
const FETCH_MORE_LOGS = 'FETCH_MORE_LOGS'
const ADD_LOG = 'ADD_LOG'
const ADD_TASK_RESULT = 'ADD_TASK_RESULT'
const ADD_LOG_NOTIFICATION = 'ADD_LOG_NOTIFICATION'

export const state = () => ({
  data: [],
  meta: {},
  links: {},
})

export const getters = {
  userLog: (state) => state.data,
  logLength: (state) => length(state.data),
  nextLink: (state) => prop('next', state.links),
}

export const mutations = {
  [SET_LOG_LIST](state, payload) {
    state.data = payload
  },
  [SET_META](state, {meta, links} = {}) {
    state.meta = meta
    state.links = links
  },

  [INSERT_LOG](state, logItem) {
    state.data = [logItem, ...state.data]
  },
}

export const actions = {
  UPDATE_LOG_LIST({commit, state}, logItem) {
    if (state.data.length) {
      commit(SET_LOG_LIST, [logItem, ...state.data])
    }
  },
  async [FETCH_LOG]({commit, dispatch}, id) {
    try {
      const data = await this.$axios.$get(`/crm/users/${id}/activity-log`)

      commit(SET_LOG_LIST, data.data)
      commit(SET_META, {
        meta: data.meta,
        links: data.links,
      })
    } catch (err) {}
  },
  async [FETCH_MORE_LOGS]({commit, getters, state}) {
    const data = await this.$axios.$get(getters.nextLink)
    commit(SET_LOG_LIST, [...state.data, ...data.data])
    commit(SET_META, {
      meta: data.meta,
      links: data.links,
    })
  },

  async [ADD_TASK_RESULT]({commit, dispatch, state}, payload = {}) {
    const {id, message} = payload

    try {
      const data = await this.$axios.$post(`/crm/users/activity-log/complete-task/${id}`, {
        message,
      })

      commit(SET_LOG_LIST, updateObjectByKey('id', data, state.data))
    } catch (err) {}
  },

  async [ADD_LOG]({state, commit, dispatch}, payload = {}) {
    const {id, message, due_date} = payload

    try {
      const data = await this.$axios.$post(`/crm/users/${id}/activity-log/post-task`, {
        message,
        due_date,
      })

      commit(INSERT_LOG, data)
    } catch (err) {}
  },

  async [ADD_LOG_NOTIFICATION]({commit, dispatch, state}, payload = {}) {
    const {id, message} = payload

    try {
      const data = await this.$axios.$post(`/crm/users/${id}/activity-log/post-note`, {
        message,
      })

      commit(INSERT_LOG, data)
    } catch (err) {}
  },
  async [DELETE_LOG_ITEM]({commit, state}, {id} = {}) {
    try {
      await this.$axios.delete(`/crm/activity-log/${id}`)

      commit(SET_LOG_LIST, removeObjectByKey('activity_log_id', id, state.data))
    } catch (err) {}
  },
}

export const logModule = {
  state,
  getters,
  actions,
  mutations,
}
