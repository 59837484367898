<template>
  <div class="product-select">
    <!-- course select -->
    <div class="product-select__row">
      <Typography
        :variant="inline ? 'paragraph_xs' : 'h4'"
        :color="inline ? 'grey' : 'dark'"
        :class="inline ? 'mb-xs' : 'mb-1'"
        >{{ $t('crm.selectCourse') }}</Typography
      >

      <!-- course sub-label slot -->
      <div v-if="$slots.courseSubLabel">
        <slot name="courseSubLabel"></slot>
      </div>

      <CourseSelect
        :courseId="initialCourseId"
        variant="default"
        :placeholder="$t('course.settings.enterName')"
        @course:id="onCourseSelect"
      />

      <Typography
        v-if="submitErrors.course"
        variant="paragraph_xs"
        color="error"
        class="product-select__error"
      >
        {{ submitErrors.course }}
      </Typography>
    </div>

    <!-- product select -->
    <div class="product-select__row">
      <Typography
        :variant="inline ? 'paragraph_xs' : 'h4'"
        :color="inline ? 'grey' : 'dark'"
        :class="inline ? 'mb-xs' : 'mb-1'"
        >{{ $t('widgets.chooseProduct') }}</Typography
      >

      <!-- product sub-label slot -->
      <div v-if="$slots.productSubLabel">
        <slot name="productSubLabel"></slot>
      </div>

      <ProductSelect
        :value="selectedProduct"
        :loading="courseDataLoading"
        :data="[courseProducts]"
        :multiple="multiple"
        :multiple-courses="multipleCourses"
        @open="fetchProductList"
        @select="onProductSelect"
        @update="onProductsUpdate"
      />

      <Typography
        v-if="submitErrors.product"
        variant="paragraph_xs"
        color="error"
        class="product-select__error"
      >
        {{ submitErrors.product }}
      </Typography>
    </div>
  </div>
</template>

<script>
/**
 * CourseProductSelect
 *
 * Варианты селекта (опция multiple):
 * 1 курс - 1 продукт
 * 1 курс - Много продуктов
 * Много курсов - Много продуктов
 *
 * Варианты загрузки данных:
 * Передавать course_id, product_id (single instance)
 * Передавать сохраненный интсанс продукт(а/ов) (packs/modules)
 */
import CourseSelect from './CourseSelect'
import ProductSelect from './ProductSelect'

export default {
  name: 'CourseProductSelect',
  components: {CourseSelect, ProductSelect},
  props: {
    value: null,
    inline: Boolean, // paragraph titles
    multiple: Boolean,
    multipleCourses: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      courseId: null,
      initialCourseId: null,
      initialProductId: null,
      courseProducts: {
        modules: [],
        packs: [],
      },
      submitErrors: {
        course: false,
        product: false,
      },
      selectedProduct: this.value,
      courseDataLoading: false,
    }
  },
  watch: {
    value(newValue) {
      this.fetchProduct(newValue)
    },
  },

  mounted() {
    this.fetchProduct()
  },

  methods: {
    onCourseSelect(id) {
      this.courseId = id

      this.submitErrors.course = null
      this.$emit('course:select', id)

      if (id !== this.initialCourseId) {
        this.selectedProduct = null
        this.$emit('product:select', null)
      }
    },

    onProductSelect(data) {
      this.selectedProduct = data
      this.submitErrors.product = null

      this.$emit('product:select', data)
    },

    onProductsUpdate(selected, used) {
      if (!this.multiple) return

      this.$emit('products:update', selected, used)

      this.selectedProduct = selected[selected.length - 1]
      this.submitErrors.product = null
    },

    async fetchProduct(value = this.value) {
      if (value) {
        if (value.course_id) {
          this.initialCourseId = value.course_id

          if (value.product_id) {
            this.initialProductId = value.product_id

            try {
              await this.fetchProductList(this.initialCourseId)

              const {modules, packs} = this.courseProducts
              const product = [...modules, ...packs].find((p) => p.id === this.initialProductId)

              if (product) {
                this.selectedProduct = product
              }
            } catch (err) {
              console.log(err)
            }
          } else {
            this.selectedProduct = value
          }
        }
      }
    },

    async fetchProductList(id = this.courseId) {
      if (!id) return

      this.courseDataLoading = true

      try {
        this.courseProducts = await this.$axios.$get(`/author/courses/${id}/products-list`)
      } catch {
      } finally {
        this.courseDataLoading = false
      }
    },

    validate() {
      if (this.multiple) {
        return (
          this.selectedProduct &&
          (this.selectedProduct.modules.length || this.selectedProduct.packs.length)
        )
      }

      return this.selectedProduct && this.selectedProduct.id
    },

    touch() {
      const courseInvalid = !this.courseId
      const productInvalid = !this.validate()

      const errors = courseInvalid || productInvalid

      this.submitErrors = {
        course: courseInvalid && this.$t('forms.rules.requiredField'),
        product: productInvalid && this.$t('forms.rules.requiredField'),
      }

      return !errors
    },

    reset() {
      this.submitErrors = {}
      this.courseId = null
    },
  },
}
</script>

<style lang="scss">
.product-select {
  &__row {
    margin-bottom: 20px;
  }

  &__error {
    margin-top: 6px;
  }

  .widget-search-wrapper {
    margin: 0;
  }
}
</style>
