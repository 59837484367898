import Vue from 'vue'
import {reject, isNil} from 'ramda'
import {ADD_TOAST_MESSAGE} from 'vuex-toast'
const tus = require('tus-js-client')

const root = {root: true}

export const namespaced = true

export const state = () => ({
  courseLesson: null,
  loaders: [],
})

export const getters = {
  lessonAccessOpts: (state) => state.courseLesson?.access_rules,
  courseLesson: (state) => state.courseLesson,
}

export const mutations = {
  UPDATE_ACCESS_RULES(state, payload) {
    Object.assign(state.courseLesson?.access_rules, payload)
  },
  set(state, payload) {
    state.courseLesson = payload
  },

  clear(state, payload) {
    state.courseLesson = null
  },

  setModuleItemTitle(state, payload) {
    state.courseLesson.title = payload
  },

  setModuleItemIsDefaultTitle(state, payload) {
    state.courseLesson.is_default_title = payload
  },

  setModuleItemDuration(state, payload) {
    state.courseLesson.duration = payload
  },

  addModuleItemContent(state, payload) {
    state.courseLesson.contents = [...state.courseLesson.contents, payload]
  },

  addContentItemFile(state, payload) {
    const items = state.courseLesson.contents

    const contentItem = items.filter((item) => {
      return item.id === payload.id
    })

    contentItem[0].file = payload.file
  },

  addContentDocFile(state, payload) {
    const items = state.courseLesson.contents

    const contentItem = items.filter((item) => {
      return item.id === payload.id
    })

    contentItem[0].files = payload.files
  },

  removeContentItemFile(state, payload) {
    const items = state.courseLesson.contents

    const contentItem = items.filter((item) => {
      return item.id === payload.moduleContentId
    })

    contentItem[0].file = []
  },

  removeContentDocFile(state, payload) {
    const items = state.courseLesson.contents

    const contentItem = items.filter((item) => {
      return item.id === payload.moduleContentId
    })

    contentItem[0].files = contentItem[0].files.filter(({id}) => id !== payload.fileId)
  },

  removeModuleItemContent(state, payload) {
    const items = state.courseLesson.contents

    const newItems = items.filter((item) => {
      return item.id !== payload
    })

    state.courseLesson.contents = newItems
  },

  updateModuleItemVideo(state, payload) {
    const items = state.courseLesson.contents
    const videoModuleItem = items.find(({id}) => id === payload.id)

    videoModuleItem.file = payload.file
  },

  updateModuleItemDirection(state, payload) {
    const items = state.courseLesson.contents

    const direction = parseInt(payload.direction)
    const itemId = payload.lessonContentId

    const foundIndex = items.findIndex(({id}) => id === itemId)
    const foundItem = items[foundIndex]

    const newIndex = foundIndex + direction

    if (typeof items[newIndex] === 'undefined') {
      return
    }

    const prevOrNext = items[newIndex] // need to check if next item exists, if not skeep next steps

    foundItem.item_order = prevOrNext.item_order
    prevOrNext.item_order = foundItem.item_order - direction

    items[newIndex] = foundItem
    items[foundIndex] = prevOrNext

    Vue.set(state.courseLesson.contents, newIndex, foundItem)
    Vue.set(state.courseLesson.contents, foundIndex, prevOrNext)
  },

  setTextLesson(state, payload) {
    state.textLesson = payload
  },

  setupLoader(state, payload) {
    const newLoader = {
      id: payload,
      progress: 0,
    }

    state.loaders = [...state.loaders, newLoader]
  },

  removeLoader(state, payload) {
    state.loaders = state.loaders.filter(({id}) => id !== payload)
  },

  setLoaderProgress(state, payload) {
    const items = state.loaders
    const currentLoader = items.find(({id}) => id === payload.id)

    currentLoader.progress = payload.progress
  },

  increaseHwScore(state) {
    state.courseLesson.homework.scores_amount += 1
  },

  decreaseHwScore(state) {
    const score = state.courseLesson.homework.scores_amount

    if (score <= 0) {
      return
    }
    state.courseLesson.homework.scores_amount -= 1
  },

  updateHwItems(state, payload) {
    if (state.courseLesson.homework.contents && state.courseLesson.homework.contents.length !== 0) {
      state.courseLesson.homework.contents = [...state.courseLesson.homework.contents, payload]
    } else {
      state.courseLesson.homework.contents.push(payload)
    }
  },

  removeHomeworkItemFile(state, payload) {
    const items = state.courseLesson.homework.contents
    const homeworkItem = items.filter((item) => {
      return item.id === payload.hwItem
    })

    homeworkItem[0].files = homeworkItem[0].files.filter(({id}) => id !== payload.hwFileId)
  },

  removeHomeworkItemContent(state, payload) {
    const items = state.courseLesson.homework.contents

    const newItems = items.filter((item) => {
      return item.id !== payload
    })

    state.courseLesson.homework.contents = newItems
  },

  toggleHomework(state, payload) {
    state.courseLesson.is_homework_enabled = payload
  },
  changeHwContent(state, payload) {
    state.courseLesson.homework = payload
  },
  addHomeworkFile(state, payload) {
    const items = state.courseLesson.homework.contents

    const contentItem = items.filter((item) => {
      return item.id === payload.id
    })

    contentItem[0].files = payload.files
  },

  updateHomeworkItemDirection(state, payload) {
    const items = state.courseLesson.homework.contents

    const direction = parseInt(payload.direction)
    const itemId = payload.lessonContentId

    const foundIndex = items.findIndex(({id}) => id === itemId)
    const foundItem = items[foundIndex]

    const newIndex = foundIndex + direction

    if (typeof items[newIndex] === 'undefined') {
      return
    }

    const prevOrNext = items[newIndex] // need to check if next item exists, if not skeep next steps

    foundItem.item_order = prevOrNext.item_order
    prevOrNext.item_order = foundItem.item_order - direction

    items[newIndex] = foundItem
    items[foundIndex] = prevOrNext

    Vue.set(state.courseLesson.homework.contents, newIndex, foundItem)
    Vue.set(state.courseLesson.homework.contents, foundIndex, prevOrNext)
  },
}
export const actions = {
  async fetchCourseLesson({commit, dispatch}, lessonId) {
    try {
      const response = await this.$axios.get(`/courses/lessons/${lessonId}`)

      commit('set', response.data)
    } catch (err) {}
  },

  async createLessonItem({commit, dispatch}, data) {
    try {
      const response = await this.$axios.post(`/courses/lessons/${data.lessonId}/contents`, {
        type: data.lessonType,
      })

      commit('addModuleItemContent', response.data)
    } catch (err) {}
  },

  async deleteLessonItem({commit, dispatch}, data) {
    try {
      if (data.type === 'lesson') {
        await this.$axios.delete(`/courses/lessons/contents/${data.moduleItemId}`)

        commit('removeModuleItemContent', data.moduleItemId)
      } else {
        await this.$axios.delete(`/courses/homeworks/contents/${data.moduleItemId}`)

        commit('removeHomeworkItemContent', data.moduleItemId)
      }

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.deleted'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async changeLessonItemPosition({commit, dispatch}, data) {
    try {
      await this.$axios.put(`/courses/lessons/contents/${data.lessonContentId}/item-order`, {
        direction: data.direction,
      })

      commit('updateModuleItemDirection', data)
    } catch (err) {}
  },

  async uploadModuleItemFile({commit, dispatch}, data) {
    try {
      const formData = new FormData()

      formData.append('file', data.file)
      formData.append('name', data.file.name)
      formData.append('type', data.file.type)
      formData.append('extension', data.file.extension)
      formData.append('collection', data.collection)
      const response = await this.$axios.post(
        `/courses/lessons/contents/${data.moduleItemId}/upload-file`,
        formData,
        {
          timeout: null,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          requestId: data.moduleItemId,
          onUploadProgress(progressEvent) {
            dispatch(
              'filesUploader/updateUploader',
              {
                id: data.moduleItemId,
                progressEvent,
              },
              root
            )
          },
        }
      )

      commit('addContentItemFile', response.data)

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.fileUploaded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async uploadModuleDocumentFile({commit, dispatch}, data) {
    try {
      const formData = new FormData()
      const moduleItemId = data.moduleItemId
      const id = data.id

      formData.append('file', data.file)
      formData.append('name', data.file.name)
      formData.append('type', data.file.type)
      formData.append('extension', data.file.extension)
      formData.append('collection', data.collection)

      const response = await this.$axios.post(
        `/courses/lessons/contents/${moduleItemId}/upload-file`,
        formData,
        {
          timeout: null,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          requestId: id,
          onUploadProgress(progressEvent) {
            dispatch(
              'filesUploader/updateDocumentUploader',
              {
                id,
                moduleItemId,
                progressEvent,
              },
              root
            )
          },
        }
      )

      commit('addContentDocFile', response.data)

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.fileUploaded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  uploadVideo({commit, dispatch}, data) {
    try {
      /* const extension = data.file.name.split('.').pop()

      const response = await this.$axios.post(
       `/courses/${data.courseId}/upload-vimeo`,
       {
         size: data.file.size,
         extension,
       },
       {
         timeout: null,
       }
     )

     const successParams = {
       link: response.data.vimeo_link,
       collection: 'files',
       moduleItemId: data.moduleItemId,
     } */

      let responseData = null
      let statusIsSaved = true

      commit('setupLoader', data.moduleItemId)

      const options = {
        endpoint: 'https://videos.meleton.ru/upload/',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 10 * 1024 * 1024,
        metadata: {
          filename: data.file.name,
          filetype: data.file.type,
        },
        onError: (error) => console.log('Failed because: ' + error),
        onSuccess: () => {
          setTimeout(() => {
            commit('updateModuleItemVideo', responseData)
            commit('removeLoader', data.moduleItemId)
          }, 5000)
        },

        onProgress: (bytesUploaded, bytesTotal) => {
          const uploadPercentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0)
          const payload = {
            id: data.moduleItemId,
            progress: uploadPercentage,
          }
          commit('setLoaderProgress', payload)
          if (statusIsSaved) {
            statusIsSaved = false
            const videoLink = `https://videos.meleton.ru/stream/${
              upload.url.match(/([\d\w]){32,}$/i)[0]
            }.m3u8`
            this.$axios
              .post(
                `/courses/lessons/contents/${data.moduleItemId}/upload-file`,
                {
                  video_url: videoLink,
                },
                {
                  timeout: null,
                }
              )
              .then((response) => {
                responseData = response.data
              })
              .catch((e) => {
                console.log(e)
              })
          }
        },
      }

      const upload = new tus.Upload(data.file, options)
      if (upload && typeof upload.findPreviousUploads === 'function') {
        upload.findPreviousUploads().then(function (previousUploads) {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // upload.start()
        })
      }
      /* const upload = new tus.Upload(data.file, {
        uploadUrl: response.data.upload_link,
        chunkSize: 128 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: data.file.name,
          filetype: data.file.type,
        },
        onError(error) {
          console.log('Failed because: ' + error)
        },
        onProgress(bytesUploaded, bytesTotal) {
          const uploadPercentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          const payload = {
            id: data.moduleItemId,
            progress: uploadPercentage,
          }

          commit('setLoaderProgress', payload)
        },
        onSuccess: () => {
          const onFinish = async (data) => {
            const response = await this.$axios.post(
              `/courses/lessons/contents/${data.moduleItemId}/upload-file`,
              {
                video_url: data.link,
              },
              {
                timeout: null,
              }
            )

            commit('updateModuleItemVideo', response.data)
            commit('removeLoader', data.moduleItemId)
          }

          onFinish(successParams)
        },
      }) */

      upload.start()
    } catch (err) {
      console.log(err)
    }
  },

  async deleteModuleItemFile({commit, dispatch}, data) {
    try {
      await this.$axios.delete(`/medias/${data.fileId}`)

      commit('removeContentItemFile', data)
    } catch (err) {}
  },

  async deleteModuleDocFile({commit, dispatch}, data) {
    try {
      await this.$axios.delete(`/medias/${data.fileId}`)

      commit('removeContentDocFile', data)
    } catch (err) {}
  },

  async updateModuleLessonItem({commit, dispatch, state}, courseLessonId) {
    try {
      const homeworkContent = state.courseLesson.homework.contents
        ? state.courseLesson.homework.contents.filter((contentItem) => contentItem.type === 'text')
        : null

      const params = {
        duration: state.courseLesson.duration,
        title: state.courseLesson.title,
        is_checking_needed: false,
        lesson_contents: state.courseLesson.contents.filter(
          (contentItem) => contentItem.type === 'text'
        ),
        homework_contents: homeworkContent,
        scores_amount: state.courseLesson.homework.scores_amount,
      }

      await this.$axios.$patch(`/courses/lessons/${courseLessonId}`, params)
    } catch (err) {}
  },

  async uploadVideoFromInput({commit, dispatch}, data) {
    try {
      const response = await this.$axios.post(
        `/courses/lessons/contents/${data.moduleItemId}/upload-file`,
        {
          video_url: data.link,
        },
        {
          timeout: null,
        }
      )

      commit('updateModuleItemVideo', response.data)
    } catch (err) {}
  },

  // Homework actions
  async createHomeworkItem({commit, dispatch}, data) {
    try {
      const response = await this.$axios.post(`/courses/homeworks/${data.homeworkId}/contents`, {
        type: data.homeworkItemType,
      })

      commit('updateHwItems', response.data)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.homeworkAdded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async uploadHomeworkFile({commit, dispatch}, data) {
    try {
      const formData = new FormData()

      formData.append('file', data.file)
      formData.append('name', data.file.name)
      formData.append('type', data.file.type)
      formData.append('extension', data.file.extension)
      formData.append('collection', data.collection)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: null,
      }

      const response = await this.$axios.post(
        `/courses/homeworks/contents/${data.homeworkContentId}/upload-file`,
        formData,
        config
      )

      commit('addHomeworkFile', response.data)

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.fileUploaded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async deleteHomeworkFile({commit, dispatch}, data) {
    try {
      await this.$axios.$delete(`/medias/${data.hwFileId}`)

      commit('removeHomeworkItemFile', data)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.fileDeleted'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async switchHomework({commit, dispatch}, lessonId) {
    try {
      const response = await this.$axios.patch(`/courses/lessons/${lessonId}/switch-homework`)

      if (response.data.homework) {
        commit('changeHwContent', response.data.homework)
      } else {
        commit('changeHwContent', null)
      }
    } catch (err) {}
  },

  async changeLessonAccess({commit, dispatch}, data) {
    console.log(data)
    const params = {
      opening_type: data.openingType,
      closing_type: data.closingType,
      closing_date: data.closingDate,
      opening_date: data.openingDate,
      days_before_closing: data.closingDays,
    }
    try {
      await this.$axios.patch(`/courses/lessons/${data.lessonId}/change-access`, params)

      commit(
        'UPDATE_ACCESS_RULES',
        reject(isNil, {
          ...params,
          closing_date: data.closingRawDate,
          opening_date: data.openingRawDate,
        })
      )
      // if (this.$route.name === 'course.lesson-constructor') return
      // await dispatch('fetchCourseLesson', data.lessonId)
    } catch (err) {}
  },
}
