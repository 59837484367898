export const actions = {
  async upload({commit}, params) {
    try {
      const data = await this.$axios.$post('/medias/upload', params)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },
}
