import {ROLES} from '~/consts'

const PageRegister = () =>
  import(/* webpackChunkName: "PageRegister" */ '@/pages/register').then((m) => m.default || m)
const PageLogin = () =>
  import(/* webpackChunkName: "PageLogin" */ '@/pages/login/index.vue').then((m) => m.default || m)
const PagePwdReset = () =>
  import(/* webpackChunkName: "PagePwdReset" */ '@/pages/auth/password-reset').then(
    (m) => m.default || m
  )
const PageForgetPwd = () =>
  import(/* webpackChunkName: "PageForgetPwd" */ '@/pages/auth/forget-password').then(
    (m) => m.default || m
  )
const PageSocialVerify = () =>
  import(/* webpackChunkName: "PageSocialVerify" */ '@/pages/auth/social-verify').then(
    (m) => m.default || m
  )
const PageEmailConfirmation = () =>
  import(/* webpackChunkName: "PageEmailConfirmation" */ '@/pages/auth/email-confirmation').then(
    (m) => m.default || m
  )

export const authRoutes = [
  {
    path: '/create-account',
    redirect: {name: 'register.author'},
  },
  {
    path: '/register',
    redirect: {name: 'register.author'},
    name: 'registration',
  },
  {
    path: '/trial/',
    component: () => import('@/pages/auth/trial').then((m) => m.default || m),
    name: 'Trial',
    meta: {
      guest: true,
      role: 'author',
    },
  },

  {
    path: '/register/author',
    component: PageRegister,
    name: 'register.author',
    meta: {
      guest: true,
      role: 'author',
    },
  },
  {
    path: '/register/student',
    component: PageRegister,
    name: 'register.student',
    meta: {
      guest: true,
      role: 'student',
    },
  },
  {
    path: '/login',
    component: PageLogin,
    name: 'login',
    meta: {
      guest: true,
    },
  },
  {
    path: '/auth/password-reset/',
    component: PagePwdReset,
    name: 'password-reset',
  },
  {
    path: '/auth/forget-password',
    component: PageForgetPwd,
    name: 'forget-password',
    meta: {
      guest: true,
    },
  },
  {
    path: '/auth/social-verify',
    component: PageSocialVerify,
    name: 'auth.verify',
  },
  {
    path: '/auth/email-confirmation/:token/',
    component: PageEmailConfirmation,
    name: 'auth.email-confirmation',
  },
]
