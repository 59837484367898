<template>
  <div class="add-employee-popup-wrapper">
    <div v-if="$slots.activator" @click="showPopup = true">
      <slot name="activator"></slot>
    </div>

    <PopupWrapper :title="poptitle" :isOpen="showPopup" v-bind="$attrs" @close="onClose">
      <ImageUploadPreviewBlock
        v-model="edited.photo"
        :label="uploadLabel"
        :image="imagePreview"
        :error="$v.edited.photo.$error"
        @dataUrl="imageDataUrl"
      />

      <!-- name -->
      <Input
        v-model="edited.title"
        :label="title + ':'"
        :placeholder="title && $t('forms.labels.enter') + ' ' + title.toLowerCase()"
        :error="$v.edited.title.$error"
      />

      <!-- position -->
      <Input
        v-if="position"
        v-model="edited.position"
        :label="position + ':'"
        :placeholder="position && $t('forms.labels.enter') + ' ' + position.toLowerCase()"
        :error="position && $v.edited.position.$error"
      />

      <!-- description -->
      <TextArea
        v-if="description"
        v-model="edited.description"
        :error="description && $v.edited.description.$error"
        autoHeight
        rows="1"
      >
        {{ description }}:
      </TextArea>

      <Button class="mt-auto" :loading="inProgress" @click="onSave">{{ $t('common.save') }}</Button>

      <ImageCropperModal
        v-if="showCropper"
        :imgSrc="edited.photo"
        :rules="photoRules"
        :title="$t('webinars.uploadPic')"
        @cropped="cropperDone"
        @close="cropperClose"
      />
    </PopupWrapper>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import {PopupWrapper, TextArea} from '@/components/ui'

export default {
  components: {
    PopupWrapper,
    TextArea,
    ImageUploadPreviewBlock: () => import('../components/ImageUploadPreviewBlock'),
    ImageCropperModal: () => import('~ui').then((m) => m.ImageCropperModal),
  },
  mixins: [validationMixin],
  validations() {
    const edited = {
      title: {required},
      photo: {required},
    }

    if (this.position) {
      Object.assign(edited, {position: {required}})
    }

    if (this.description) {
      Object.assign(edited, {description: {required}})
    }

    return {edited}
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        id: null,
        title: '',
        position: '',
        description: '',
        photo: '',
      }),
    },
    poptitle: {
      type: String,
      default() {
        return this.$t('webinars.speakers.teacher')
      },
    },
    title: {
      type: [String],
      default() {
        return this.$t('forms.labels.name')
      },
    },
    position: {
      type: [String, Boolean],
      default() {
        return this.$t('webinars.speakers.occupation')
      },
    },
    description: {
      type: [String, Boolean],
      default() {
        return this.$t('webinars.description')
      },
    },
    isOpen: Boolean,
    uploadLabel: Boolean,
  },
  data() {
    return {
      edited: {
        id: null,
        title: '',
        position: '',
        description: '',
        photo: '',
        preview: '',
        photoFile: null,
      },
      showCropper: false,
      photoRules: {
        minWidth: 200,
        minHeight: 200,
        maxWidth: 1500,
        maxHeight: 1500,
        aspectRatio: 1,
      },
      inProgress: false,
      showPopup: this.isOpen,
    }
  },
  computed: {
    imagePreview() {
      const preview = this.edited.preview
      const photo = this.edited.photo

      return preview || photo || ''
    },
  },
  watch: {
    data(v) {
      if (v) {
        this.edited = {...v}
      }
    },
    isOpen(e) {
      this.showPopup = e
    },
  },
  methods: {
    imageDataUrl(data) {
      this.edited.photo = data
      this.edited.preview = data

      if (data) {
        this.showCropper = true
      }
    },
    cropperClose() {
      this.showCropper = false
    },
    cropperDone(imageFile) {
      this.edited.photoFile = imageFile
      this.getPhotoPreview(imageFile)
      this.cropperClose()
    },
    getPhotoPreview(imageFile) {
      const reader = new FileReader()

      reader.onload = (event) => {
        this.edited.preview = event.target.result
      }
      reader.readAsDataURL(imageFile)
    },
    onClose() {
      this.showPopup = false
      this.edited = {}
      this.$emit('close')
      this.$v.$reset()
    },
    async onSave() {
      this.$v.$touch()

      if (this.$v.edited.$invalid) return

      this.inProgress = true

      try {
        // this.$emit('save', this.edited)
        if (this.$listeners.save) {
          await this.$listeners.save(this.edited)
        }

        this.onClose()
      } catch (err) {
      } finally {
        this.inProgress = false
      }
    },
  },
}
</script>
