<template>
  <PopupWrapper :title="titleText" v-bind="$attrs" @close="onClose">
    <Input
      v-model="lead.name"
      :error="$v.lead.name.$error"
      :label="$t('subscribe.headerName')"
      @blur="$v.lead.name.$touch()"
    />

    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSave">{{ btnText }}</Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input} from '@/components/ui'

const defState = () => ({
  name: '',
})

export default {
  components: {
    PopupWrapper,
    Button,
    Input,
  },
  mixins: [validationMixin],
  props: {
    editLead: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    lead: {
      name: {required},
    },
  },
  data: () => ({
    lead: defState(),
  }),
  computed: {
    leadNameErr() {
      if (!this.$v.name.name.required) return this.$t('forms.rules.requiredField')
      return ''
    },
    isEditing() {
      return !isEmpty(this.editLead)
    },
    btnText() {
      return this.isEditing ? this.$t('leadsPage.edit') : this.$t('leadsPage.addPage')
    },
    titleText() {
      return this.isEditing ? this.$t('leadsPage.editPage') : this.$t('leadsPage.addPage')
    },
    canDelete() {
      // is editing
      return this.isEditing && this.botId
    },
  },
  watch: {
    editLead(v) {
      this.lead = {
        name: this.editLead.name,
      }
      this.leadId = this.editLead.id
    },
  },
  methods: {
    async onDelete() {
      await this.$store.dispatch('funnelsLeads/DELETE_TG_BOT', {id: this.botId})
      this.onClose()
    },
    onClose() {
      this.$emit('close')
      this.lead = defState()
      this.leadId = null
      this.$v.lead.$reset()
    },
    async onSave() {
      this.$v.lead.$touch()
      if (this.$v.lead.$invalid) return

      const lead = this.lead
      const params = {
        title: lead.name,
      }

      if (this.isEditing) {
        // await this.$store.dispatch('funnelsLeads/UPDATE_TG_BOT', {params, id: this.leadId})
      } else {
        let data = await this.$axios.$post('/author/lead-pages', params)
        this.$router.push(`/leads-generator/${data.id}/editor`)

        // await this.$store.dispatch('funnelLeads/CREATE_TG_BOT', {params})
      }
      this.onClose()
    },
  },
}
</script>
