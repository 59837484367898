import {prop, propEq, find} from 'ramda'

export const createHtml = (domString) => {
  const t = document.createElement('div')
  t.innerHTML = domString
  return t.firstChild
}

export const qs = (selector = '') => {
  const el = document.querySelector(selector)

  if (el) {
    return {
      el,
      on: (evName, cb, ...args) => {
        el.addEventListener(evName, cb, ...args)
      },
    }
  }

  return {
    get el() {
      if (!el) return false
      else return el
    },
    on: () => ({}),
  }
}

export const findPropByProp = ({take, by, val, from = []} = {}) =>
  prop(take, find(propEq(by, val), from))
