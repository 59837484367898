<template>
  <div class="webinars-list">
    <div class="webinars-list-grid">
      <WebinarCard create />

      <WebinarCard
        v-for="(webinar, i) in webinars"
        :key="i"
        :webinar="webinar"
        :isAuthorList="true"
        @webinar:open="goToGuestRoom"
      >
      </WebinarCard>
    </div>

    <WebinarEdit />
    <WebinarDelete />
  </div>
</template>

<script>
import {WebinarCard, WebinarEdit, WebinarDelete} from '@/features/Webinars/components'

export default {
  components: {
    WebinarCard,
    WebinarEdit,
    WebinarDelete,
  },
  props: {
    webinars: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToGuestRoom(id) {
      this.$router.push({name: 'author.webinars.guest-room', params: {id}})
    },
  },
}
</script>

<style src="@/features/Webinars/styles/webinars-list.scss" lang="scss"></style>
