var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"speaker-item",class:{create: _vm.create},on:{"click":_vm.onCardClick}},[(_vm.create)?_c('div',{staticClass:"flex items-center justify-start"},[_c('Button',{attrs:{"size":"50","rounded":"","icon":"/icons/plus-in-circle.svg","iconSize":"m","custom":"","styles":{
        iconColor: '#96a2b4',
        backgroundColor: '#fff',
        iconHoverColor: '#fff',
        backgroundHoverColor: '#6666fe',
      }}}),_vm._v(" "),_c('Typography',{staticClass:"ml-1",attrs:{"variant":"int_s","color":"grey"}},[_vm._v(_vm._s(_vm.$t('webinars.speakers.add')))])],1):_c('div',{staticClass:"flex items-center justify-between"},[_c('UserGroupAvatar',{attrs:{"user":_vm.speaker,"size":"s"}},[_c('span',{attrs:{"slot":"description"},slot:"description"},[_vm._v(_vm._s(_vm.speaker.description))])]),_vm._v(" "),(_vm.editable)?_c('DropdownMenu',{on:{"edit":function($event){return _vm.$emit('edit')},"delete":function($event){return _vm.$emit('delete')}}},[_c('Button',{attrs:{"size":"40","rounded":"","icon":"/icons/icon-cog.svg","iconSize":"s","custom":"","styles":{
          iconColor: '#96a2b4',
          backgroundColor: '#f2f5f9',
          iconHoverColor: '#fff',
          backgroundHoverColor: '#6666fe',
        }}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }