import {pick, length} from 'ramda'

const len = (obj) => length(Object.values(obj))

export default ({app, route}, inject) => {
  const getUtmFromQuery = () =>
    pick(
      [
        'utm_campaign',
        'utm_content',
        'utm_dev',
        'utm_group',
        'utm_medium',
        'utm_source',
        'utm_term',
      ],
      route.query
    )

  const saveUtm = (data) => {
    if (!len(data)) return

    const existed = app.$cookies.get('utmTags')

    let decomposited = {}

    if (existed)
      for (let inx in existed) {
        for (let i in existed[inx]) {
          decomposited[i] = existed[inx][i]
        }
      }
    if (data)
      for (let i in data) {
        decomposited[i] = data[i]
      }

    let newUtmData = Object.keys(decomposited).map(function (key) {
      let obj = {}
      obj[key] = decomposited[key]
      return obj
    })

    if (Array.isArray(newUtmData)) {
      app.$cookies.set('utmTags', newUtmData)
      return newUtmData
    }
    app.$cookies.set('utmTags', [data])
  }

  const getUtmFromCookie = () => {
    return app.$cookies.get('utmTags')
  }

  const removeUtmFromCookie = () => {
    return app.$cookies.remove('utmTags')
  }

  const getAndSaveUtm = () => saveUtm(getUtmFromQuery())

  const utm = {
    getAndSaveUtm,
    getUtmFromCookie,
    removeUtmFromCookie,
  }
  inject('utm', utm)
}
