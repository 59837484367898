const AGENCY_RU = '/docs/oferta.pdf'
const AGENCY_EN = '/docs/oferta-en.pdf'
const PRIVACY_POLICY_RU = '/docs/privacy-policy.pdf'
const PRIVACY_POLICY_EN = '/docs/privacy-policy-en.pdf'
const USER_AGREEMENT_RU = '/docs/user-agreement.pdf'
const USER_AGREEMENT_EN = '/docs/user-agreement-en.pdf'
const LICENCE_EN = '/docs/licence-en.pdf'
const LICENCE_RU = '/docs/licence.pdf'
const OFERTA_RP_RU = '/docs/oferta-rp.pdf'
const OFERTA_RP_EN = '/docs/oferta-rp-en.pdf'

const AGENCY = {
  us: AGENCY_EN,
  ru: AGENCY_RU,
}
const PRIVACY_POLICY = {
  us: PRIVACY_POLICY_EN,
  ru: PRIVACY_POLICY_RU,
}
const USER_AGREEMENT = {
  us: USER_AGREEMENT_EN,
  ru: USER_AGREEMENT_RU,
}
const LICENCE = {
  us: LICENCE_EN,
  ru: LICENCE_RU,
}

const OFERTA_RP = {
  us: OFERTA_RP_EN,
  ru: OFERTA_RP_RU,
}

export default ({app, route, i18n}, inject) => {
  const docWithLocale = (data) => {
    const lang = i18n.locale
    return data[lang] || data.us
  }

  const docs = {
    get ofertaRp() {
      return docWithLocale(OFERTA_RP)
    },
    get agencyAgreement() {
      return docWithLocale(AGENCY)
    },
    get privacyPolicy() {
      return docWithLocale(PRIVACY_POLICY)
    },
    get userAgreement() {
      return docWithLocale(USER_AGREEMENT)
    },
    get licence() {
      return docWithLocale(LICENCE)
    },
  }

  inject('docs', docs)
}
