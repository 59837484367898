<template>
  <PopupWrapper v-bind="$attrs" v-on="$listeners">
    <Input
      v-model="inputVal"
      :placeholder="inputLabel"
      :error="$v.inputVal.$error"
      :errorMessage="$t('forms.rules.requiredField')"
      flat
      @keydown.enter="onSave"
    ></Input>
    <Button class="simple-popup-submit" block @click="onSave">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

export default {
  components: {
    PopupWrapper: () => import('../ui/PopupWrapper'),
    Input: () => import('../ui/Input'),
    Button: () => import('../ui/Button'),
  },
  mixins: [validationMixin],
  validations: {
    inputVal: {
      required,
    },
  },
  props: {
    defaultValue: {type: String, default: ''},
    inputLabel: {type: String, default: ''},
  },
  data: () => ({
    inputVal: '',
  }),
  watch: {
    defaultValue: {
      handler(value) {
        this.inputVal = value
      },
      immediate: true,
    },
    isOpen: {
      handler() {
        this.$v.$reset()
      },
      immediate: true,
    },
  },
  methods: {
    onSave() {
      const v = this.inputVal
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.$emit('save', v)
      this.$emit('close')
      this.inputVal = ''
    },
  },
}
</script>

<style lang="scss">
.simple-popup-submit {
  margin-top: 30px;
  width: 100%;
}
</style>
