<template>
  <PopupWrapper class="video-settings-popup" :isOpen="isOpen" v-bind="$attrs" v-on="$listeners">
    <div
      v-if="!hasPermissions"
      class="video-settings-popup__overlay flex items-center justify-center"
    >
      <RoundedBlock>
        <Typography variant="paragraph_s">
          {{ $t('webinars.giveAccess') }}
        </Typography>
      </RoundedBlock>
    </div>
    <div class="video-settings-video-container">
      <video
        ref="previewVideo"
        class="video-settings__video"
        :controls="false"
        :muted="true"
      ></video>
    </div>

    <!-- Video -->
    <div class="input-group mt-3">
      <div class="flex items-center justify-between mb-1 flex-wrap">
        <div class="flex items-center justify-start flex-1">
          <Icon src="icons/video-ico.svg" size="mm" class="mr-4 pointerless" />
          <Typography variant="int_l">{{ $t('webinars.videDevice') }}</Typography>
        </div>
        <span class="link-style" @click="refresh('camera')">{{ $t('common.refresh') }}</span>
      </div>

      <Selector
        v-model="camera"
        :options="cameras"
        :disabled="!hasPermissions || !camera"
        label="label"
        :allow-empty="false"
        @input="updateInputDevices"
      />
    </div>

    <!-- Audio -->
    <div class="input-group mt-3">
      <div class="flex items-center justify-between mb-1 flex-wrap">
        <div class="flex items-center justify-start flex-1">
          <Icon src="icons/microphone.svg" size="mm" class="mr-4 pointerless" />
          <Typography variant="int_l">{{ $t('audioDevice.giveAccess') }}</Typography>
        </div>
        <span class="link-style" @click="refresh('speaker')">{{ $t('common.refresh') }}</span>
      </div>

      <Selector
        v-model="microphone"
        :options="microphones"
        :disabled="!hasPermissions || !microphone"
        label="label"
        :allow-empty="false"
        @input="updateInputDevices"
      />
    </div>

    <!-- Quality -->
    <div class="input-group mt-3">
      <div class="flex items-center justify-start mb-1">
        <Icon src="icons/icon-cog.svg" size="mm" class="mr-4 pointerless" />
        <Typography variant="int_l">{{ $t('webinars.quality') }}</Typography>
      </div>

      <Selector
        :value="resolution"
        :options="resolutions"
        :disabled="!hasPermissions"
        :allow-empty="false"
        @input="updateResolution"
      />
    </div>

    <Button class="mt-3" @click="convertAndSendConstraints">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {mapActions} from 'vuex'
import {PopupWrapper, Selector, RoundedBlock} from '~ui'
import {propEq} from 'ramda'
import {DEFAULT_CONSTRAINTS, RESOLUTIONS} from '@/features/Webinars/model/mediaConstraints'

export default {
  components: {
    Selector,
    PopupWrapper,
    RoundedBlock,
  },

  props: ['isOpen'],

  data() {
    return {
      stream: null,
      cameras: [],
      camera: null,
      microphones: [],
      microphone: null,
      speakers: [],
      speaker: null,
      resolutions: RESOLUTIONS,
      resolution: RESOLUTIONS[2],
      $video: null,
      constraints: DEFAULT_CONSTRAINTS,
      hasPermissions: false,
      cameraSettings: {},
    }
  },
  computed: {
    allowedResolutions() {
      return this.resolutions
    },
  },
  watch: {
    isOpen(open) {
      if (!open) {
        this.stopTracks()

        return
      }

      this.listen()
      this.useDevices()

      if (this.includeFilters) {
        this.setFilter()
      }
    },
  },

  methods: {
    ...mapActions({
      showToast: 'SHOW_TOAST',
    }),
    stopTracks() {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => track.stop())
      }
    },
    listen() {
      if (navigator && navigator.mediaDevices) {
        navigator.mediaDevices.addEventListener('devicechange', this.useDevices)

        this.$once('hook:beforeDestroy', () => {
          if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.removeEventListener('devicechange', this.useDevices)
          }
        })
      }
    },

    async useDevices({tm = 0} = {}) {
      this.stopTracks()
      try {
        const stream = await navigator.mediaDevices.getUserMedia(this.constraints)
        this.hasPermissions = !!stream
        this.setStream(stream)
        this.setDevices(stream, tm)
      } catch (err) {
        this.handleDeviceErrors(err)
        throw err
      }
    },
    handleDeviceErrors(err) {
      if (err instanceof window.OverconstrainedError) {
        return this.showToast({
          type: 'danger',
          text: this.$t('webinars.noAccess'),
        })
      } else {
        return this.showToast({
          type: 'danger',
          text: this.$t('webinars.giveAccessToCamera'),
        })
      }
    },

    /* single ? */
    async setDevices(stream, tm = 0) {
      const devices = (await navigator.mediaDevices.enumerateDevices()) || []

      this.cameras = devices.filter((d) => d.kind === 'videoinput') || []
      this.microphones = devices.filter((d) => d.kind === 'audioinput') || []
      this.speakers = devices.filter((d) => d.kind === 'audiooutput') || []

      setTimeout(() => {
        if (this.cameras.length > 0) {
          this.camera = this.camera || this.cameras[0]
          this.cameraSettings = stream
            .getTracks()
            .find(propEq('label', this.camera.label))
            .getSettings()
        }

        if (this.microphones.length > 0) {
          this.microphone = this.microphone || this.microphones[0]
        }

        if (this.speakers.length > 0) {
          this.speaker = this.speaker || this.speakers[0]
        }
      }, tm)
    },

    async updateResolution(resolution) {
      const setDefault = () => {
        this.resolution = RESOLUTIONS[2]
        this.constraints = {
          video: {...DEFAULT_CONSTRAINTS.video},
          audio: {...this.constraints.audio},
        }

        this.useDevices()
      }
      if (!resolution) {
        setDefault()

        return
      }

      try {
        this.resolution = resolution
        this.constraints = {
          audio: {...this.constraints.audio},
          video: {...this.constraints.video, ...resolution.value},
        }

        await this.useDevices()
      } catch (error) {
        console.error(error)
        setDefault()
      }
    },

    async updateInputDevices(device) {
      if (!device) return

      const setDefault = () => {
        if (device.kind === 'audioinput') {
          this.microphone = this.microphones[0]
          this.constraints.audio.deviceId.exact = this.microphone.deviceId

          this.useDevices()
        }
      }

      const videoSource = this.camera && this.camera.deviceId
      const audioSource = this.microphone && this.microphone.deviceId

      try {
        this.constraints = {
          audio: {
            ...this.constraints.audio,
            deviceId: audioSource ? {exact: audioSource} : undefined,
          },
          video: {
            ...this.constraints.video,
            deviceId: videoSource ? {exact: videoSource} : undefined,
          },
        }

        await this.useDevices()
      } catch (error) {
        setDefault()

        console.error(error)
      }
    },

    setStream(stream) {
      if (!stream) return

      this.$video = this.$refs.previewVideo

      if (!this.$video) return
      this.$video.removeAttribute('srcObject')
      this.$video.removeAttribute('src')

      this.stream = stream

      this.$video.muted = true
      this.$video.volume = 0
      this.$video.srcObject = stream

      this.$video.play()
    },

    async refresh(device) {
      try {
        if ([device] in this) {
          this[device] = null

          await this.useDevices({tm: 0})
        }
      } catch (error) {
        if (error.constraint === 'width') {
          this.updateResolution()
        }

        console.warn(error)
      }
    },

    convertAndSendConstraints() {
      const {camera, speaker, microphone, resolution} = this

      this.$emit('save', {
        speaker,
        microphone,
        camera,
        resolution: resolution.value,
        audio: {
          ...DEFAULT_CONSTRAINTS.audio,
        },
        video: {
          deviceId: camera.deviceId,
          ...resolution.value,
        },
      })
    },
  },
}
</script>

<style lang="scss">
.video-settings-popup {
  position: relative;
  &__overlay {
    z-index: 10000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: 24px;
    background-color: rgba(30, 0, 0, 0.55);
    overflow: hidden;
    padding: 20px;
  }

  .video-settings-video-container {
    position: relative;
    border-radius: 16px;
    max-height: 200px;
    height: 200px;
    width: 100%;
    overflow: hidden;
  }
  .video-settings__video {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    &::-webkit-media-controls-enclosure {
      display: none !important;
    }
  }

  .scrollable {
    @include media($tablet) {
      padding: 46px 25px 60px;
    }
  }
}
</style>
