const tus = require('tus-js-client')

const root = {root: true}

export const namespaced = true
export const state = () => ({
  course: {},
})

export const mutations = {
  setCourse(state, payload) {
    state.course = payload
  },

  updateCourseInfo(state, payload) {
    state.course = {...state.course, ...payload}
  },

  addCourseTeacher(state, payload) {
    state.course.teachers.push(payload)
  },

  updateCourseTeacher(state, payload) {
    const id = payload.id
    const teacherIndex = state.course.teachers.findIndex((teacher) => teacher.id === id)

    state.course.teachers[teacherIndex] = payload
  },

  deleteCourseTeacher(state, payload) {
    const id = payload.id
    const teacherIndex = state.course.teachers.findIndex((teacher) => teacher.id === id)

    state.course.teachers.splice(teacherIndex, 1)
  },

  updateCourseDescription(state, payload) {
    state.course.description = payload
  },

  updateVideoLink(state, payload) {
    state.course.description_video = payload
  },

  updateImgLink(state, payload) {
    state.course.description_img = payload
  },

  updateBgImageLink(state, payload) {
    state.course.landing_background = payload
  },
}

export const actions = {
  // TODO - it is duplicate action, same action stays in courseItem - refactor
  async fetchCourseData({commit, dispatch}, data) {
    const res = await this.$axios.$get(`/courses/${data.courseId}`, {
      params: data.queryParams,
    })

    commit('setCourse', res)
    return res
  },

  async updateCourseFromLanding({commit, dispatch}, data) {
    console.log('updateCourseFromLanding')
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const formData = new FormData()

    if (data.description) formData.append('description', data.description)

    if (data.action !== 'landing.background.update') {
      if (data.fileType) formData.append('description_file_type', data.fileType)
      else formData.append('description_file_type', 'description.file')
    }

    formData.append('action', data.action)

    if (data.fileType === 'description.video') {
      if (data.vimeoUrl) formData.append('video_url', data.vimeoUrl)
    } else {
      if (data.file) {
        formData.append('file', data.file)
        if (data.file.name) formData.append('name', data.file.name)
        if (data.file.type) formData.append('type', data.file.type)
        if (data.file.extension) formData.append('extension', data.file.extension)
      }

      if (data.collection) formData.append('collection', data.collection)
    }

    const response = await this.$axios.post(
      `/courses/${data.courseId}/description`,
      formData,
      config
    )

    commit('updateCourseInfo', response.data)
  },

  // TODO - create separate action for all video uploads now TUS code is duplicated
  uploadDescriptionVideo({commit, dispatch}, data) {
    let responseData = null
    let statusIsSaved = true
    console.log('DDATA', data)
    try {
      commit('setupLoader', data.moduleItemId)
      const options = {
        endpoint: 'https://videos.meleton.ru/upload/',
        retryDelays: [0, 3000, 5000, 10000, 20000],
        chunkSize: 10 * 1024 * 1024,
        metadata: {
          filename: data.file.name,
          filetype: data.file.type,
        },
        onError: (error) => console.log('Failed because: ' + error),
        onSuccess: () => {
          setTimeout(() => {
            commit('updateModuleItemVideo', responseData)
            commit('removeLoader', data.moduleItemId)
          }, 5000)
        },

        onProgress: (bytesUploaded, bytesTotal) => {
          const uploadPercentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0)
          const payload = {
            id: data.moduleItemId,
            progress: uploadPercentage,
          }
          commit('setLoaderProgress', payload)

          if (statusIsSaved) {
            statusIsSaved = false
            const videoLink = `https://videos.meleton.ru/stream/${
              upload.url.match(/([\d\w]){32,}$/i)[0]
            }.m3u8`
            this.$axios
              .post(
                `/courses/${data.moduleItemId}/description`, //  /courses/lessons/contents/${data.moduleItemId}/upload-file
                {
                  video_url: videoLink,
                  action: 'course.description.update',
                  description_file_type: 'description.video',
                },
                {
                  timeout: null,
                }
              )
              .then((response) => {
                responseData = response.data
              })
              .catch((e) => {
                console.log(e)
              })
          }
        },
      }
      const upload = new tus.Upload(data.file, options)
      if (upload && typeof upload.findPreviousUploads === 'function') {
        upload.findPreviousUploads().then(function (previousUploads) {
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          // upload.start()
        })
      }
      /*
      const extension = data.file.name.split('.').pop()

      const response = await this.$axios.post(`/courses/${data.courseId}/upload-vimeo`, {
        size: data.file.size,
        extension,
      })

      const successParams = {
        link: response.data.vimeo_link,
        collection: 'files',
        id: data.courseId,
      }

      commit('courseLesson/setupLoader', data.courseId, root)

      const upload = new tus.Upload(data.file, {
        uploadUrl: response.data.upload_link,
        chunkSize: 5 * 1024 * 1024,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: data.file.name,
          filetype: data.file.type,
        },
        onError(error) {
          console.log('Failed because: ' + error)
        },
        onProgress(bytesUploaded, bytesTotal) {
          const uploadPercentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
          const payload = {
            id: data.courseId,
            progress: uploadPercentage,
          }

          commit('courseLesson/setLoaderProgress', payload, root)
        },
        onSuccess() {
          const onFinish = (data) => {
            commit('updateVideoLink', data.link)

            commit('courseLesson/removeLoader', data.id, root)
          }

          onFinish(successParams)
        },
      }) */

      upload.start()
    } catch (err) {}
  },
  async sendTeacher({state, commit, dispatch}, data) {
    const courseId = state.course.id
    const formData = new FormData()

    formData.append('title', data.title)
    formData.append('position', data.position)
    formData.append('description', data.description)
    if (data.photoFile && data.photoFile !== '') {
      formData.append('photo', data.photoFile)
      formData.append('name', data.photoFile.name)
      formData.append('type', data.photoFile.type)
      formData.append('extension', data.photoFile.extension)
    }

    if (data.id) {
      const response = await this.$axios.post(`/courses/teachers/${data.id}/update`, formData)

      commit('updateCourseTeacher', {
        ...response.data,
        preview: data.preview,
      })
    } else {
      const response = await this.$axios.post(`/courses/${courseId}/teachers`, formData)

      commit('addCourseTeacher', {...response.data, preview: data.preview})
    }
  },
  async deleteTeacher({state, commit, dispatch}, id) {
    try {
      await this.$axios.delete(`/courses/teachers/${id}`)

      commit('deleteCourseTeacher', {id})
    } catch (err) {}
  },
}
