import {load} from './util'

export const productRoutes = [
  {
    path: '/payment/success',
    component: load('payment/SuccessPage'),
    name: 'payment.success',
    meta: {
      title: 'page.paymentSuccess',
    },
  },
  {
    path: '/payment/:uid/:orderFormId?/:openPaymentWidget?',
    component: load('payment/PayPage'),
    name: 'payment.pay',
    meta: {
      title: 'page.coursePayment',
    },
  },
  {
    path: '/list23576',
    component: load('payment/complete/PayComplete23576'),
    name: 'pay.complete1',
    meta: {
      title: 'page.paymentSuccess',
    },
  },
  {
    path: '/list42342',
    component: load('payment/complete/PayComplete42342'),
    name: 'pay.complete2',
    meta: {
      title: 'page.paymentSuccess',
    },
  },
]
