<template>
  <div class="webinar-schedule-card" :class="{add}">
    <div v-if="add" class="webinar-schedule-card__inner" @click="$emit('schedule-card:add')">
      <Icon src="icons/input-date-plus.svg" size="l" />
      <Typography variant="int_s" color="grey">{{ $t('webinars.addDate') }}</Typography>
    </div>

    <div v-else class="webinar-schedule-card__inner">
      <BoxedIcon
        v-tippy
        :content="$t('course.edit')"
        size="m"
        src="/icons/settings.svg"
        class="webinar-schedule-card__edit"
        @click="$emit('schedule-card:edit')"
      ></BoxedIcon>

      <WebinarDate :data="item" large />
    </div>
  </div>
</template>

<script>
import {WebinarDate} from '../atoms'

export default {
  components: {
    WebinarDate,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    add: Boolean,
  },
}
</script>

<style lang="scss">
.webinar-schedule-card {
  border-radius: 16px;
  border: 1px solid $lightGrey;
  position: relative;

  &.add {
    background: $lightGrey;

    .webinar-schedule-card__inner {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .app-icon {
        margin-bottom: 8px;
      }
    }
  }

  &__inner {
    padding: 20px 24px;
    width: 100%;
    height: 100%;
  }

  &__edit {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
</style>
