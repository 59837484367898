<template>
  <div class="checkbox privacy-checkbox">
    <label class="checkbox__label">
      <span
        :class="{
          'checkbox-wrap': true,
          'checkbox-err': error,
        }"
      >
        <input
          name="privacy-checkbox"
          type="checkbox"
          class="checkbox-input"
          :value="value"
          :checked="value"
          @change="$emit('input', $event.target.checked)"
          @blur="$emit('blur')"
        />
        <span class="checkbox-status"></span>
      </span>
      <span class="checbox-label">
        {{ $t('company.privacy.iAgreeWith') }}
        <a :href="docs.agencyAgreement" target="_blank" rel="noopener noreferrer">
          {{ $t('company.privacy.offer') }}
        </a>
        {{ $t('common.and') }}
        <!-- <a :href="docs.privacyPolicy" target="_blank" rel="noopener noreferrer">{{
          $t('company.privacy.personalDataProcessingPolicy')
        }}</a>-->
        <a href="/licence-agreement">{{ $t('company.privacy.personalDataProcessingPolicy') }}</a>
      </span>
    </label>
    <Typography v-show="error" variant="paragraph_xs" color="error">
      {{ $t('forms.rules.requiredField') }}
    </Typography>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    error: Boolean,
  },
  computed: {
    docs() {
      return this.$docs
    },
  },
}
</script>

<style lang="scss" scoped>
.privacy-checkbox {
  .checkbox-err {
    border: 1px solid #e33131;
  }
  .checbox-label {
    a {
      text-decoration: underline !important;
    }
  }
}
</style>
