<template>
  <Component
    :is="isTag"
    :href="href ? href : '#'"
    :target="target && target"
    :style="btnVars"
    :class="btnClass"
    v-bind="$attrs"
    :disabled="disabled"
    v-on="$listeners"
    @click="$event.target.blur()"
  >
    <div v-if="loading" class="app-btn__loader">
      <Spinner :margin="0" :color="inverted ? '#6666fe' : '#fff'" :width="32" :height="32" />
    </div>

    <div class="app-btn__content">
      <div v-if="icon" class="icon">
        <Icon
          :src="icon"
          :size="iconSize"
          :white="iconWhite && !disabled"
          :svgClass="iconSvgClass"
          :opacity="styles.iconOpacity"
        />
      </div>
      <span v-if="label">{{ label }}</span>
      <slot v-else></slot>
      <slot v-if="$slots.append" name="append"></slot>
    </div>
  </Component>
</template>

<script>
export default {
  name: 'UiButton',
  components: {
    Spinner: () => import('@/components/ui/Spinner'),
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'xs',
    },
    iconSvgClass: {
      type: String,
      default: 'app-btn__default-icon-svg',
    },
    iconWhite: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: Boolean,
    block: Boolean,
    disabled: Boolean,
    height: {
      type: [Number, String],
      default: '48',
    },
    width: {
      type: [String],
      default: '',
    },
    minWidth: {
      type: [String],
      default: '',
    },
    minHeight: {
      type: [String],
      default: '',
    },
    color: {
      type: [String],
      default: '',
    },
    bgColor: {
      type: [String],
      default: '',
    },
    boxShadow: {
      type: [String],
      default: 'none',
    },
    borderRadius: {
      type: [String, Number],
      default: '',
    },
    fontSize: {
      type: String,
      default: '12px',
    },
    fontWeight: {
      type: String,
      default: '',
    },
    inverted: Boolean,
    custom: Boolean,
    rounded: Boolean,
    size: {
      type: [String, Number, Boolean],
      default: false,
    },
    styles: {
      type: Object,
      default: () => ({
        color: '',
        hoverColor: '',
        backgroundColor: '',
        backgroundHoverColor: '',
        iconColor: '',
        iconHoverColor: '',
        iconOpacity: '',
        height: '',
        fontSize: '',
        fontWeight: '',
        border: '',
        borderColor: '',
        borderRadius: '',
        width: '',
      }),
    },
    isActive: Boolean,
    appendClassess: {
      type: [Array, Object],
      default: () => [],
    },
  },
  computed: {
    isTag() {
      // backward compatability
      if (this.href) return 'a'

      const tags = {
        href: 'a',
        button: 'button',
        label: 'label',
      }

      return tags[this.tag]
    },
    btnClass() {
      return [
        {
          'app-btn': true,
          'app-btn__block': this.block,
          'app-btn__inverted': this.inverted && !this.custom,
          'app-btn__disabled': this.disabled,
          'app-btn__loading': this.loading,
          'app-btn__custom': this.custom,
          'app-btn__rounded': this.rounded,
          'app-btn__is-active': this.isActive && this.custom,
        },
        ...this.appendClassess,
      ]
    },
    btnVars() {
      const st = this.styles
      const brrad = st.borderRadius || this.borderRadius

      const width = this.size ? this.size + 'px' : st.width || this.width
      const height = this.size ? this.size + 'px' : (st.height || this.height) + 'px'

      return {
        '--btn-width': width,
        '--btn-min-width': this.minWidth || '80px',
        '--btn-min-height': this.minHeight || '32px',
        '--btn-height': height,
        '--btn-border': st.border,
        '--btn-brrad': brrad && brrad + 'px',
        '--btn-border-color': st.borderColor,
        '--btn-color': st.color || this.color,
        '--btn-hover-color': st.hoverColor,
        '--btn-bg-color': st.backgroundColor || this.bgColor,
        '--btn-hover-bg-color': st.backgroundHoverColor,
        '--btn-icon-color': st.iconColor,
        '--btn-icon-hover-color': st.iconHoverColor,
        '--btn-fsize': st.fontSize || this.fontSize,
        '--btn-fweight': st.fontWeight || this.fontWeight,
        '--btn-box-shadow': this.boxShadow,
      }
    },
  },
}
</script>

<style lang="scss">
.app-btn {
  position: relative;
  font-size: var(--btn-fsize, 14px);
  font-weight: var(--btn-fweight, 600);
  color: var(--btn-color, #fff);
  background: var(--btn-bg-color, #6666fe);
  box-shadow: var(--btn-box-shadow);
  height: var(--btn-height, 48px);
  min-height: var(--btn-min-height, 32px);
  max-height: var(--btn-height, 48px);
  min-width: var(--btn-min-width);
  width: var(--btn-width);
  cursor: pointer;
  padding: 0 16px;
  border-radius: var(--btn-brrad, 24px);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: initial;
  transition-property: color, background-color;
  transition: 0.15s ease;
  outline: none;
  overflow: hidden;
  e &:hover,
  &:active,
  &:focus {
    outline: none;
    color: var(--btn-color, #fff);
    box-shadow: 0 2px 10px 0 var(--btn-bg-color, #6666fe);

    .app-btn__default-icon-svg {
      opacity: 1;
    }
  }

  .app-btn__default-icon-svg {
    fill: #fff;
  }

  &__inverted {
    color: var(--btn-color, #2e3141);
    background-color: var(--btn-bg-color, #fff);
    border: 1px solid #f2f5f9;

    .app-btn__default-icon-svg {
      fill: #96a2b4;
    }

    &:hover,
    &:active,
    &:focus {
      color: var(--btn-color, #fff) !important;
      background-color: var(--btn-bg-color, #6666fe);
      border-color: var(--btn-bg-color, #6666fe);
      box-shadow: 0 2px 10px 0 var(--btn-bg-color, #6666fe);
      .app-btn__default-icon-svg {
        fill: #fff;
      }
    }
  }

  &__block {
    width: 100%;
  }

  &__disabled {
    cursor: default;
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.26) !important;

    .icon {
      .app-icon {
        cursor: default;
      }
      svg {
        fill: rgba(0, 0, 0, 0.26) !important;
      }
    }

    &:hover,
    &:active {
      box-shadow: none;
    }
  }

  .app-btn__content {
    display: flex;
    justify-content: center;
    width: 100%;

    > .icon {
      display: inline-flex;
      margin-right: 8px;
      align-items: center;
    }
  }

  &__loading {
    pointer-events: none;
    .app-btn__content {
      opacity: 0;
    }
  }

  & .app-btn__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__custom {
    color: var(--btn-color, #fff);
    background-color: var(--btn-bg-color, #f2f5f9);
    border: var(--btn-border);
    border-color: var(--btn-border-color);

    &:hover,
    &:active,
    &:focus {
      color: var(--btn-hover-color, $white);
      background-color: var(--btn-hover-bg-color, $purple);
      box-shadow: 0 0 2px var(--btn-bg-color, transparent);

      .app-btn__default-icon-svg {
        fill: var(--btn-icon-hover-color, #fff);
      }
    }

    .app-btn__default-icon-svg {
      fill: var(--btn-icon-color, #fff);
    }
  }

  &__rounded {
    padding: 0;
    border-radius: 50%;
    min-height: var(--btn-width, 48px);
    min-width: var(--btn-width, 48px);

    .icon {
      margin-right: 0 !important;
    }
  }

  &__is-active {
    color: var(--btn-hover-color, $white);
    background-color: var(--btn-hover-bg-color, $purple);
    .app-btn__default-icon-svg {
      fill: var(--btn-icon-hover-color, #fff);
    }
  }
}
</style>
