<template>
  <PopupWrapper :title="$t('minilanding.courseDesc')" v-bind="$attrs" @close="onClose">
    <ContentTabs v-slot="{activeTab}" @content="content = $event">
      <VideoTab v-if="activeTab === 'video'" v-model="videoUrl" />
      <ImageUploadPreviewBlock v-if="activeTab === 'image'" v-model="imgFile" :image="media" />
    </ContentTabs>

    <div class="course-part">
      <div class="course-part__label">{{ $t('webinars.description') }}:</div>
      <ClientOnly>
        <TextEditor v-model="description" />
      </ClientOnly>
    </div>

    <Button :loading="saving" @click="onSave">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
  components: {
    TextEditor: () => import('@/features/TextEditor'),
    PopupWrapper: () => import('@/components/ui/PopupWrapper'),
    ContentTabs: () => import('./ContentTabs'),
    VideoTab: () => import('./VideoTab'),
    ImageUploadPreviewBlock: () => import('../components/ImageUploadPreviewBlock'),
  },
  props: {
    media: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    imgFile: '',
    content: 'video',
    videoUrl: '',
    saving: false,
  }),
  computed: {
    description: {
      get() {
        return this.$store.state.coursePage.course.description
      },
      set(v) {
        this.updateCourseDescription(v)
      },
    },
  },
  methods: {
    ...mapActions({
      updateCourseFromLanding: 'coursePage/updateCourseFromLanding',
    }),
    ...mapMutations({
      updateCourseDescription: 'coursePage/updateCourseDescription',
    }),
    async onSave() {
      // this.videoUrl = this.$store.state.coursePage.course.description_video

      console.log(this.videoUrl, this.imgFile)

      const video = this.videoUrl
        ? {
            vimeoUrl: this.videoUrl,
            fileType: 'description.video',
          }
        : null
      const image = this.imgFile
        ? {
            file: this.imgFile,
            fileType: 'description.file',
          }
        : null

      let media = null

      if (this.content === 'video') {
        if (video) media = video
      } else if (image) media = image

      const params =
        video || image
          ? {
              courseId: this.$route.params.courseId,
              action: 'course.description.update',
              description: this.description,
              ...media,
            }
          : {
              courseId: this.$route.params.courseId,
              action: 'course.description.update',
              description: this.description,
            }

      this.saving = true
      try {
        console.log('save here')

        await this.updateCourseFromLanding(params)
        this.onClose()
      } catch (err) {
        this.saving = false
      } finally {
        this.saving = false
      }
    },
    onClose() {
      this.$emit('close')
      this.imgFile = null
    },
  },
}
</script>
