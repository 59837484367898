<template>
  <PopupWrapper :title="titleText" v-bind="$attrs" @close="onClose">
    <Input
      v-model="title"
      :label="$t('common.name')"
      :placeholder="$t('funnels.name')"
      flat
      :error="$v.title.$error"
      :errorMessage="$t('forms.rules.requiredField')"
    ></Input>
    <Input
      v-model="description"
      :label="$t('webinars.description')"
      :placeholder="$t('funnels.description')"
      flat
      :error="$v.description.$error"
      :errorMessage="$t('forms.rules.requiredField')"
    ></Input>

    <div class="flex flex-1 items-end">
      <Button icon="/icons/plus-in-circle.svg" block @click="editFunnel">{{ btnText }}</Button>
    </div>
  </PopupWrapper>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {isEmpty} from 'ramda'

export default {
  components: {
    PopupWrapper: () => import('@/components/ui/PopupWrapper'),
  },
  mixins: [validationMixin],
  props: {
    poptitle: {
      type: String,
      default() {
        return this.$t('funnels.add')
      },
    },
    editFunnelData: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    title: {
      required,
    },
    description: {
      required,
    },
  },
  data: () => ({
    title: '',
    description: '',
    funnelId: -1,
  }),
  computed: {
    isEditing() {
      return !isEmpty(this.editFunnelData)
    },
    btnText() {
      return this.isEditing ? this.$t('funnels.save') : this.$t('funnels.add')
    },
    titleText() {
      return this.isEditing ? this.$t('funnels.edit') : this.$t('funnels.add')
    },
  },
  watch: {
    editFunnelData(v) {
      this.title = this.editFunnelData.title
      this.description = this.editFunnelData.description
      this.funnelId = this.editFunnelData.id
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.title = ''
      this.description = ''
      this.$v.$reset()
    },
    editFunnel() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$emit('funnel', {
        title: this.title,
        description: this.description,
        id: this.funnelId,
      })
      this.onClose()
    },
  },
}
</script>
