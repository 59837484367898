<template>
  <div
    class="round-info-block"
    :style="{
      '--icon-block-color': iconBlockColor,
      '--icon-block-active-color': iconBlockActiveColor,
    }"
  >
    <div class="text-grey-700 text-xs mb-3"><slot name="label"></slot></div>
    <div :class="rootClass">
      <div class="round-info-icon-square">
        <Icon :src="iconSrc" white />
      </div>
      <div class="flex items-center relative flex-1 font-semibold overflow-hidden">
        <slot name="text"></slot>
      </div>
      <div v-if="!hideToggles" class="pointer ml-4 color-grey">
        <span v-if="!isActive" @click="toggle">{{ $t('common.show') }}</span>
        <Icon v-else src="/icons/cross.svg" :fill="crossColor" size="xs" @click="toggle" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    hideToggles: Boolean,
    iconSrc: {
      type: String,
      default: 'icons/profile-user.svg',
    },
    iconBlockColor: {
      type: String,
      default: '#959bcb',
    },
    iconBlockActiveColor: {
      type: String,
      default: '#f4faf9',
    },
    crossColor: {
      type: String,
      default: '#b4dcd8',
    },
  },
  data: () => ({}),
  computed: {
    rootClass() {
      return {
        'round-info-block-cont': true,
        'round-info-block-active': this.isActive,
      }
    },
  },
  methods: {
    toggle() {
      this.$emit('update:isActive', !this.isActive)
    },
  },
}
</script>

<style>
.round-info-block-cont {
  @apply mr-4 flex p-2 pr-4 bg-grey-100 rounded-2xl items-center;
}
.round-info-block {
  @apply flex flex-col w-full mb-4 min-w-min max-w-full md:w-72;
}
.round-info-block-active {
  background-color: var(--icon-block-active-color, #f4faf9);
}
.round-info-icon-square {
  background-color: var(--icon-block-color, #959bcb);

  @apply mr-3 w-8 h-8 rounded-xl flex items-center justify-center flex-shrink-0;
}
.color-grey {
  color: #a9a8a8;
}
</style>
