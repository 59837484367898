export const namespaced = true

export const state = () => ({
  userNotificationsList: [],
  userNotificationsCount: 0,
})

export const getters = {
  list: (state) => state.userNotificationsList,
  count: (state) => state.userNotificationsCount,
}

export const mutations = {
  setList(state, payload) {
    state.userNotificationsList = payload
  },

  updateList(state, payload) {
    state.userNotificationsList = [payload, ...state.userNotificationsList]
    const newList = state.userNotificationsList

    state.userNotificationsList = Array.from(new Set(newList.map(({id}) => id)), (id) =>
      newList.find((item) => item.id === id)
    )
  },

  setCount(state, payload) {
    state.userNotificationsCount = payload
  },

  incrCount(state) {
    state.userNotificationsCount += 1
  },
}

export const actions = {
  async fetchNotifications({commit, dispatch, rootGetters}) {
    if (!rootGetters['user/isPhoneVerified']) {
      return
    }

    try {
      const data = await this.$axios.$get('/user/notifications/list')

      data && commit('setList', data)
    } catch (error) {}
  },

  async readNotifications({commit, dispatch}) {
    try {
      await this.$axios.put('/user/notifications/read-all')
    } catch (error) {}
  },
  insertNotification({commit, dispatch}, payload) {
    commit('updateList', payload)
    commit('incrCount')
  },
}
