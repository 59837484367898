<template>
  <header class="error-page-head">
    <NuxtLink :to="{name: 'main'}" class="block">
      <Icon src="icons/back-to-arrow.svg" size="l" />
      <h4 class="block-title">{{ $t('courses.returnToMainPage') }}</h4>
    </NuxtLink>
    <NuxtLink v-if="!isLoggedIn" :to="{name: 'login'}" class="block">
      <Icon src="icons/enter-icon.svg" size="s" style="height: 13px" />
      <span class="block-title">{{ $t('forms.login') }}</span>
    </NuxtLink>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
}
</script>

<style lang="scss" scoped>
.error-page-head {
  padding: 30px 80px;
  border-bottom: solid 1px #96a2b4;
  display: flex;
  justify-content: space-between;
  background-color: #f2f5f9;
  z-index: 10;

  @media (max-width: 480px) {
    padding: 16px;
  }

  .block {
    display: flex;
    justify-content: center;
    align-items: center;
    &-title {
      font-size: 14px;
      margin-left: 10px;
      color: #2e3041;
    }
    span.block-title {
      font-size: 14px;
    }
  }
}
</style>
