<template>
  <div class="speakers-list">
    <div class="speakers-list__head">
      <Typography variant="h2">{{ $t('webinars.speakers.all') }}</Typography>
    </div>
    <div class="speakers-list__list">
      <TransitionGroup name="slide-right">
        <Speaker
          v-for="(s, i) in speakers"
          :key="i"
          :speaker="s"
          :editable="editable"
          @edit="showPopup(false, s)"
          @delete="removeSpeaker(s)"
        >
        </Speaker>
      </TransitionGroup>

      <Speaker v-if="editable" create @speaker:create="showPopup" />
    </div>

    <AddEmployeePopup
      :poptitle="poptitle"
      :title="$t('webinars.speakers.name')"
      :description="$t('webinars.speakers.desc')"
      :position="false"
      :data="speaker"
      :isOpen="popupIsOpen"
      @save="sendSpeaker"
      @close="onPopupClose"
    />
  </div>
</template>

<script>
/*
    TODO: Add pluralization for major instance name && combine with teachers list template
  */
import Speaker from './Speaker'
import {AddEmployeePopup} from '@/components/Popups'

export default {
  components: {
    Speaker,
    AddEmployeePopup,
  },
  props: {
    speakers: {
      type: Array,
      default: () => [],
    },
    editable: Boolean,
  },
  data() {
    return {
      speaker: {},
      isCreate: false,
      popupIsOpen: false,
      poptitle: this.$t('webinars.speakers.add'),
    }
  },
  methods: {
    showPopup(create = true, data = {}) {
      this.speaker = data

      this.poptitle = create ? this.$t('webinars.speakers.add') : this.$t('webinars.speakers.one')
      this.popupIsOpen = true
      this.isCreate = create
    },
    sendSpeaker(data) {
      const action = this.isCreate ? 'create' : 'edit'

      this.$emit(action, data)
    },
    removeSpeaker(data) {
      this.$emit('delete', data)
    },
    onPopupClose() {
      this.speaker = {}
      this.popupIsOpen = false
      this.isCreate = false
    },
  },
}
</script>

<style lang="scss">
.speakers-list {
  &__head {
    margin-bottom: 24px;
  }

  .speaker-item:not(:first-child),
  .speaker-item.create {
    margin-top: 16px;
  }
}
</style>
