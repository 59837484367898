import Vue from 'vue'
// import { createRouter as createVueRouter, createWebHistory } from '@ionic/vue-router';
import Router from 'vue-router'
import {authRoutes} from './authRoutes'
import {commonRoutes} from './commonRoutes'
import {landingRoutes} from './landingRoutes'
import {studentRoutes} from './studentRoutes'
import {authorRoutes} from './authorRoutes'
import {productRoutes} from './productRoutes'
import {webinarsRoutes} from './webinarsRoutes'

Vue.use(Router)

export function createRouter() {
  const router = new Router({
    mode: 'history',
    routes: [
      ...commonRoutes,
      ...studentRoutes,
      ...authorRoutes,
      ...authRoutes,
      ...landingRoutes,
      ...productRoutes,
      ...webinarsRoutes,
    ],
    linkActiveClass: 'nuxt-link-active',
  })
  router.afterEach((to, from) => {
    if (process.client) {
      if (to.matched.some(({meta}) => meta.disableScroll)) return
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  })

  return router
}
