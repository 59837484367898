<template>
  <div>
    <div class="webinar-buttons">
      <!-- created -->
      <div
        v-for="btn in buttons"
        v-if="editable || btn.is_show"
        :key="btn.id"
        class="webinar-button"
      >
        <div class="webinar-button__button">
          <!-- button -->
          <Button
            :styles="{backgroundColor: btn.color, color: btn.text_color}"
            :class="{inactive: !btn.is_show, light: isDarkButton(btn)}"
            @click="openButtonUrl(btn)"
          >
            <span class="button-title">{{ btn.text }}</span>
          </Button>

          <!-- settings (edit/delete) -->
          <Transition name="slow-fade" mode="out-in">
            <DropdownMenu
              v-if="editable && btn.is_show"
              class="webinar-button__settings"
              @edit="setState(btn)"
              @delete="removeButton(btn)"
            >
              <Icon
                src="icons/icon-cog.svg"
                size="xs"
                fab
                :fill="isDarkButton(btn) ? '#96a2b4' : 'white'"
              />
            </DropdownMenu>
          </Transition>
        </div>

        <!-- toggle visibility -->
        <Toggle
          v-if="editable"
          class="webinar-button__toggle"
          :isChecked="btn.is_show"
          :checkedText="$t('webinars.hideBtn')"
          :uncheckedText="$t('webinars.showBtn')"
          @update:isChecked="updateButton({id: btn.id, is_show: !btn.is_show})"
        />
      </div>

      <!-- add new -->
      <Button
        v-if="editable"
        class="webinar-buttons_activator"
        :class="buttons.length ? '' : 'mx-auto'"
        :label="$t('webinars.addBtn')"
        icon="icons/plus-in-circle.svg"
        inverted
        @click="showPopup = true"
      />
    </div>

    <CreateButtonPopup
      :data="button"
      :isOpen="showPopup"
      :poptitle="button.id ? $t('webinars.editBtn') : $t('webinars.addBtn')"
      @save="processWebinarButton"
      @close="setState(false)"
    />
  </div>
</template>

<script>
import {Toggle, DropdownMenu} from '@/components/ui'
import {CreateButtonPopup} from '@/components/Popups'

import {mapActions, mapGetters} from 'vuex'

/* TODO: вынести все переменные цвета в defaults (в том числе в попапе - передавать через пропс) */

export default {
  components: {
    Toggle,
    DropdownMenu,
    CreateButtonPopup,
  },
  props: ['webinarId', 'editable'],
  data() {
    return {
      showPopup: false,
      button: {},
    }
  },
  computed: {
    ...mapGetters({
      buttons: 'webinars/buttons',
    }),
  },
  methods: {
    ...mapActions({
      createButton: 'webinars/createButton',
      updateButton: 'webinars/updateButton',
      removeButton: 'webinars/removeButton',
    }),

    isDarkButton(btn) {
      return btn.text_color === '#2e3141'
    },

    openButtonUrl({url, type, product} = {}) {
      const urlToOpen =
        type === 'product'
          ? this.$router.resolve({name: 'payment.pay', params: {uid: product.uuid}}).href
          : url

      if (urlToOpen !== '/') {
        window.open(urlToOpen, '_blank')
      }
    },

    setState(button) {
      this.button = button
      this.showPopup = !!button
    },

    processWebinarButton(data, isEditMode = false) {
      if (isEditMode) {
        this.updateButton(data)
      } else {
        this.createButton({id: this.webinarId, params: data})
      }

      this.button = data
    },
  },
}
</script>

<style lang="scss">
.webinar-buttons {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .app-btn {
    height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: var(--btn-brrad, 16px);
    font-size: 14px;

    &__content {
      z-index: 1;
    }
  }
}

.webinar-button {
  position: relative;
  margin: 0 35px 30px 0;

  &__settings {
    position: absolute !important;
    top: 5px;
    right: 5px;
  }

  &__toggle {
    margin-top: 20px;

    span {
      font-size: 14px;
      font-weight: 600;
      padding-left: 60px;
    }
  }

  &__button {
    .button-title {
      @include button-blink;
    }

    .app-btn {
      $gradient: linear-gradient(
        264.61deg,
        rgba(255, 255, 255, 0.03) 3.09%,
        rgba(255, 255, 255, 0.4) 47.97%,
        rgba(255, 255, 255, 0.03) 99.43%
      );

      min-width: 200px;
      overflow: hidden;
      transition: 0.27s ease-out;
      box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.12) !important;
      overflow: hidden;

      &__content {
        letter-spacing: 0.3px;
      }
      /* simulate darken/lighten with pseudo element */
      &::before {
        content: '';
        @include coverdiv;
        opacity: 0;
        transition: 0.2s ease-out;
        background: rgba(black, 0.05);
      }

      &:active,
      &:hover {
        box-shadow: none !important;
      }

      &:hover,
      &:active {
        &::before {
          opacity: 1;
        }
      }

      &:active::before {
        background: rgba(white, 0.033);
      }

      &.light {
        &::before {
          background: rgba(black, 0.03);
        }
      }

      &.inactive {
        @include unselectable;
        box-shadow: inset 0px -2px 0px rgba(black, 0.06);

        &::before {
          opacity: 1;
          background: rgba(white, 0.45);
          z-index: 2;
        }

        &::after {
          transform: rotate(0);
        }
      }
    }
  }
}
</style>
