<template>
  <ModalWrapper>
    <div class="modal-common webinar-enter-password-modal" @keyup.enter="submit">
      <div class="modal-common__container">
        <div class="flex items-center justify-between mb-2">
          <Typography variant="h3" class="text-center">{{ $t('webinars.enter') }}</Typography>
          <div class="modal-close" @click="goTowebinarsList">
            <Icon src="icons/close.svg" size="s" />
          </div>
        </div>
        <div class="modal-flex">
          <Input
            v-model="password"
            :placeholder="$t('forms.labels.pwdForLogin')"
            :error="$v.password.$error"
            :error-message="passwordErrorMessage"
            @input="$v.$reset()"
          >
            <Icon slot="icon" src="/icons/input-password.svg" />
          </Input>

          <Button :label="$t('forms.enter')" class="mt-auto" :loading="loading" @click="submit" />
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import {mapActions} from 'vuex'

import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import ModalWrapper from '@/components/Modals/ModalWrapper'

export default {
  components: {
    ModalWrapper,
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    password: {
      required,
      password_correct(password, self) {
        if (!this.$v.password.required) return false

        return new Promise((resolve, reject) => {
          this.loading = true

          this.checkPassword({id: this.data.id, password})
            .then((success) => {
              resolve(success)
              this.$emit('auth')
            })
            .catch((error) => {
              reject(error)
            })
            .finally(() => (this.loading = false))
        })
      },
    },
  },
  data() {
    return {
      password: null,
      loading: false,
    }
  },
  computed: {
    passwordErrorMessage() {
      if (!this.$v.password.$dirty) return

      if (!this.$v.password.required) {
        return this.$t('forms.rules.requiredField')
      }

      return this.$t('forms.errors.pwd')
    },
  },
  methods: {
    ...mapActions({
      checkPassword: 'webinars/checkPassword',
    }),

    submit() {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.$emit('auth:success')
    },

    goTowebinarsList() {
      this.$router.push({name: 'webinars'})
    },
  },
}
</script>
