<template>
  <div class="webinar-participants-list">
    <!-- search -->
    <Input
      v-if="searchable && list.length"
      v-model="searchQuery"
      :placeholder="$t('webinars.searchParticipants')"
    >
      <Icon slot="icon" src="icons/search-loopa.svg"></Icon>
    </Input>

    <!-- list -->
    <TransitionGroup name="slide-right" mode="out-in" :style="vars">
      <UserGroupTile
        v-for="(usr, i) in displayList"
        :key="i"
        :user="usr"
        :role="$t(ROLE_TYPES[usr.role])"
        icon-size="xs"
        :class="{
          'select-none': usr.role !== 'viewer' && usr.role !== 'moderator',
          inactive: usr.role === 'left_request',
        }"
      >
        <Selector
          v-if="usr.role !== 'admin'"
          slot="role"
          :value="$t(ROLE_TYPES[usr.role])"
          :options="rolesOptions"
          open-direction="bottom"
          variant="white"
          :searchable="false"
          track-by="title"
          label="title"
          @input="changeRole($event, usr)"
        />
      </UserGroupTile>
    </TransitionGroup>
  </div>
</template>

<script>
/* TODO: переписать все обращения к роли через алиас */
import {mapActions} from 'vuex'
import {searchQueryFilter} from '@/utils'

import {
  webinarRoleOptions as ROLE_OPTIONS,
  webinarRolesAlias as ROLE_TYPES,
} from '@/features/Webinars/model/aliases'

import {Selector} from '@/components/ui'
import {UserGroupTile} from '@/components/UserGroupList'

export default {
  components: {
    Selector,
    UserGroupTile,
  },
  props: {
    searchable: {
      type: Boolean,
      default: false,
    },
    itemsToShow: {
      type: String,
      default: () => '1:7',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ROLE_TYPES,
      ROLE_OPTIONS,
      searchQuery: '',
    }
  },
  computed: {
    rolesOptions() {
      let roles = ROLE_OPTIONS
      roles.forEach((item) => {
        item.title = this.$t(item.title)
      })
      return roles
    },
    vars() {
      const [minItems, maxItems] = this.itemsToShow.split(':')

      return {
        '--min-items': minItems,
        '--max-items': maxItems,
      }
    },

    displayList() {
      const list = this.list

      if (this.searchable) {
        return searchQueryFilter(list, this.searchQuery, ['email', 'full_name'])
      }

      return list
    },
  },
  methods: {
    ...mapActions({
      changeParticipantRole: 'webinars/changeParticipantRole',
    }),

    async changeRole(option, user) {
      const prevState = user.role

      const id = user.id
      const role = option.value

      try {
        // $FIXME: store mutation, bad.
        user.role = role

        await this.changeParticipantRole({id, role})
      } catch {
        user.role = prevState
      }
    },
  },
}
</script>

<style lang="scss">
.webinar-participants-list {
  $userTileHeight: 62px;

  .scrollable {
    min-height: calc(#{$userTileHeight} * var(--min-items, 1));
    max-height: calc(#{$userTileHeight} * var(--max-items, 7));
    margin-right: -10px;
    padding-right: 10px;
  }

  .user-group-tile {
    height: $userTileHeight;
    transition: 0.5s cubic-bezier(0.55, 0, 0.1, 1);

    &:not(:last-child) {
      border-bottom: 1px solid $lightGrey;
    }

    &.inactive {
      .user-avatar {
        opacity: 0.8;
      }

      .typography-root {
        color: $textGrey !important;
      }
    }

    &__role {
      display: flex;

      .multiselect {
        &__tags {
          padding-right: 42px;
        }

        &__single {
          max-width: 100%;
        }
      }

      .option__title {
        color: #8f9aac;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}
</style>
