<template>
  <div class="chat-settings-popup-wrapper">
    <div v-if="$slots.activator" @click="showPopup = true">
      <slot name="activator"></slot>
    </div>

    <PopupWrapper :poptitle="poptitle" :isOpen="showPopup" @close="showPopup = false">
      <!-- attatch links -->
      <div class="input-group pt-0 pb-3">
        <Toggle
          :is-checked="allowAttachLinks"
          is-static-text
          :static-text="$t('chat.allowLink')"
          @update:isChecked="allowAttachLinks = !allowAttachLinks"
        />
      </div>

      <div class="hr"></div>

      <!-- rw access -->
      <div class="input-group py-3">
        <Toggle
          class="mb-1"
          :is-checked="!publicWriteAccess"
          is-static-text
          :static-text="$t('chat.onlyModerators')"
          @update:isChecked="publicWriteAccess = !publicWriteAccess"
        />

        <Toggle
          class="mt-2"
          :is-checked="!publicWatchAccess"
          is-static-text
          :static-text="$t('chat.seeOnlyModerators')"
          @update:isChecked="publicWatchAccess = !publicWatchAccess"
        />
      </div>

      <div class="hr"></div>

      <!-- excel history -->
      <div class="pointer py-3 flex-start">
        <Icon src="icons/excel.svg" size="mm" class="mr-xs" />
        <Typography class="excel-link" color="green">{{ $t('chat.saveHistory') }}</Typography>
      </div>

      <!-- save / clear history -->
      <Button class="mt-auto" @click="onSave">{{ $t('common.save') }}</Button>

      <Button class="mt-1" icon="icons/cross.svg" inverted @click="clearChatHistory">
        {{ $t('chat.deleteHistory') }}
      </Button>
    </PopupWrapper>
  </div>
</template>

<script>
import {Toggle, PopupWrapper} from '@/components/ui'

export default {
  components: {
    Toggle,
    PopupWrapper,
  },
  props: {
    poptitle: {
      type: String,
      default() {
        return this.$t('chat.settings')
      },
    },
  },
  data() {
    return {
      showPopup: false,
      allowAttachLinks: false,
      publicWriteAccess: true,
      publicWatchAccess: true,
    }
  },
  methods: {
    onSave() {},
    clearChatHistory() {},
  },
}
</script>
