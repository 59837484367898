<template>
  <div class="webinar-card" :class="{'webinar-card--create': create}" @click="onCardClick">
    <!-- create card -->
    <div v-if="create" class="webinar-card__container">
      <BoxedIcon
        class="webinar-card__icon"
        src="/icons/video-plus-ico.svg"
        svgClass="webinar-card__svg"
        size="m"
      >
      </BoxedIcon>
      <Typography variant="h5" color="grey">{{ $t('webinars.create') }}</Typography>
    </div>

    <!-- card -->
    <div v-else class="webinar-card__container">
      <!-- settings -->
      <div v-if="isAuthorList" class="webinar-card__settings" @click.stop.prevent>
        <DropdownMenu
          @edit="$nuxt.$emit('webinar:edit', webinar)"
          @delete="$nuxt.$emit('webinar:delete', webinar)"
        >
          <Button icon="icons/icon-cog.svg" size="40" iconSize="s" rounded inverted />
        </DropdownMenu>
      </div>

      <!-- preview -->
      <div class="webinar-card__preview">
        <WebinarPreview :preview="webinar.preview_image_url" />
      </div>

      <!-- info -->
      <div class="webinar-card__info">
        <Typography variant="h4" noWrap> {{ webinar.name }} </Typography>

        <Typography variant="paragraph_xs" color="grey" class="webinar-card__description">
          {{ webinar.description }}
        </Typography>

        <div class="webinar-card__date">
          <WebinarDate :date="webinar.start_date" :duration="webinar.duration" />
        </div>

        <WebinarLabels :key="webinar.id" class="webinar-card__labels" :webinar="webinar" />
      </div>
    </div>
  </div>
</template>

<script>
import {DropdownMenu} from '@/components/ui'
import {WebinarDate, WebinarLabels, WebinarPreview} from '../atoms'

export default {
  components: {
    DropdownMenu,
    WebinarDate,
    WebinarLabels,
    WebinarPreview,
  },

  props: {
    create: Boolean,
    isAuthorList: Boolean,
    webinar: {
      type: Object,
      default() {
        return {
          title: this.$t('webinars.one'),
        }
      },
    },
  },

  methods: {
    onCardClick() {
      if (this.create) {
        this.$nuxt.$emit('webinar:create')
      } else {
        this.$emit('webinar:open', this.webinar.id)
      }
    },
  },
}
</script>

<style lang="scss">
.webinar-card {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 285px;
  border-radius: 24px;
  display: flex;
  flex-flow: column;

  &--create {
    background-color: #f2f5f9;

    .webinar-card__icon {
      border-radius: 24px;
      margin-bottom: 10px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .app-ctx-menu {
    z-index: 1;
  }

  &__container {
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  &__info {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    @include media-min($mobile-l) {
      min-height: 100px;
    }
  }

  &__settings {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
  }

  &__preview {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    min-height: 164px;
    flex: 1 0 auto;
  }

  &__svg {
    fill: #96a2b4;
    opacity: 0.5;
  }

  &__description {
    @include text-truncate-multiline(16px, 2);
    margin: 10px 0;
    padding-right: 25px;
    word-wrap: break-word;
  }

  &__date {
    flex: 1;
    display: flex;
    align-items: flex-end;

    .webinar-date {
      width: 100%;
      padding-top: 5px;
      border-top: 1px solid #f2f5f9;
    }
  }

  &__labels {
    position: absolute;
    top: 20px;
    left: -10px;
  }
}
</style>
