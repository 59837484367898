import { render, staticRenderFns } from "./WebinarsList.vue?vue&type=template&id=f105a182&"
import script from "./WebinarsList.vue?vue&type=script&lang=js&"
export * from "./WebinarsList.vue?vue&type=script&lang=js&"
import style0 from "@/features/Webinars/styles/webinars-list.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports