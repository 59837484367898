/*
const debounce = (fn, time = 300) => {
  let timeout

  return function () {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export default debounce
*/

// lodash
module.exports = function debounce(fn, delay) {
  let timeout
  return function () {
    let ctx = this
    let args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(function () {
      fn.apply(ctx, args)
    }, delay)
  }
}

// ;) можно просить соискателей спрашивать, что делает функция
/* const debounce = (a, b = 300, c) => (...d) => clearTimeout(c, (c = setTimeout(a, b, ...d)))
export default debounce
*/
