import {prop, reject, isNil} from 'ramda'
import {PAYMENT_CONST} from './index'

const rejectNil = reject(isNil)

export const state = () => ({
  invoice: {},
  orderFormPayload: {},
  uid: null,
})

export const getters = {
  orderFormId: (state) => prop('order_form_id', state.orderFormPayload),
  confirmationUrl: (state) => prop('confirmation_url', state.orderFormPayload),
  uid: (state) => state.uid,
}

export const mutations = {
  SET_ORDER_FORM_PAYLOAD(state, data) {
    state.orderFormPayload = data
  },
  RESET_ORDER_FORM_PAYLOAD(state) {
    state.orderFormPayload = {}
  },
  SET_PRODUCT_UID(state, uid) {
    state.uid = uid
  },
}

/**
 * @typedef {object} OrderVariant
 * @property {'tinkoff' | 'rbk' | 'free' | 'has-user'} case - which payment case should handle next
 * @property {...object} res response data
 */

export const actions = {
  /**
   * @param {ActionContext} [vuexContext]
   * @param {object} payload
   * Returns a OrderVariant
   * @returns {Promise<OrderVariant>}
   */
  async CREATE_ORDER_FORM({commit, dispatch, rootGetters}, payload = {}) {
    const {uid, orderForm = {}, extendPlan} = payload
    const connectToken = this.$connectToken.getConnectToken()
    const promocode = rootGetters['promocodes/usePromocode']

    let params = rejectNil({
      uid,
      extend_tariff_plan: extendPlan || undefined,
      ...orderForm,
      connectToken,
      promo_code: promocode || null,
    })

    try {
      const res = await this.$axios.$post('/crm/order-forms', params)
      console.log('store order-forms', params)
      commit('SET_ORDER_FORM_PAYLOAD', res)
      const {payment_system, error_code} = res

      // when email is found in system.
      if (error_code === 202) {
        return {
          case: PAYMENT_CONST.hasUser,
        }
      }

      console.log('payment system', payment_system)

      switch (payment_system) {
        case PAYMENT_CONST.stripe: {
          return {
            ...res,
            case: PAYMENT_CONST.stripe,
          }
        }

        case PAYMENT_CONST.tinkoff: {
          /* res: {
        payment_system: tinkoff,
        order_form_id: n,
        receipt: obj,
        tinkoff_form: obj,
        confirmation_url: string,
        registration_token: string,
      } */
          return {
            ...res,
            case: PAYMENT_CONST.tinkoff,
          }
        }
        case PAYMENT_CONST.rbk: {
          // rbk more complicated, handle it separately
          return await dispatch('RBK_PREPARE', {
            orderFormId: res.order_form_id,
            isRecurrent: res.is_recurrent,
          })
        }
        case PAYMENT_CONST.none: {
          /* res: {
        payment_system: none,
        is_free: true,
        order_form_id: n,
        confirmation_url: string,
        registration_token: string,
      } */
          return {
            ...res,
            case: PAYMENT_CONST.free,
          }
        }
        default: {
          return {
            case: '',
          }
        }
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
  async RBK_PREPARE({commit, rootGetters}, {orderFormId, isRecurrent}) {
    const promocode = rootGetters['promocodes/usePromocode']

    const res = await this.$axios.$post('/rbk-payment/prepare', {
      order_form_id: orderFormId,
      promo_code: promocode,
    })
    console.log(res)

    const isLinkedCard = typeof res.is_card_linked === 'boolean' && res.is_card_linked

    if (isLinkedCard) {
      return {
        case: PAYMENT_CONST.rbkLinked,
        ...res,
      }
    }
    if (isRecurrent) {
      return {
        case: PAYMENT_CONST.rbk,
        customerData: {
          customerAccessToken: res.customer.customer_access_token,
          customerId: res.customer.customer_id,
          description: res.description,
          email: res.email,
          name: res.name,
        },
        isRecurrent,
      }
    }
    return {
      case: PAYMENT_CONST.rbk,
      ...res,
    }
  },
  async LINK_CARD({dispatch}) {
    try {
      const data = await this.$axios.$post('/rbk-payment/attach-card')
      return {
        customerData: {
          customerAccessToken: data.customer.customer_access_token,
          customerId: data.customer.customer_id,
          description: data.description,
          email: data.email,
          name: data.name,
        },
        isRecurrent: true,
      }
    } catch (err) {
      console.log(err)
    }
  },
  async DELETE_CARD({dispatch}) {
    try {
      const data = await this.$axios.$delete('/rbk-payment/delete-card')
      if (data.success) {
        dispatch('SHOW_TOAST', {text: data.message}, {root: true})
      }
      dispatch('user/FETCH', {}, {root: true})
    } catch (err) {
      console.log(err)
    }
  },
}
