<template>
  <div v-if="editable ? true : !isEmpty" class="webinar-social-links">
    <div class="webinar-social-links__head">
      <div class="flex items-center justify-between">
        <Typography variant="h2">{{ $t('webinars.socNet.title') }}:</Typography>

        <SocialLinksPopup v-if="editable" :data="socials" @save="save">
          <Button
            slot="activator"
            class="ml-1"
            size="40"
            icon="/icons/icon-cog.svg"
            iconSize="s"
            bgColor="#F2F5F9"
            inverted
            rounded
          />
        </SocialLinksPopup>
      </div>

      <Typography v-if="isEmpty" variant="paragraph_s" color="grey" class="mt-2">
        {{ $t('webinars.noAccount') }}
      </Typography>
    </div>

    <div v-if="!isEmpty" class="webinar-social-links__list buttons-group mt-2">
      <Button
        v-for="(s, name) in displaySocials"
        :key="name"
        :icon="s.icon"
        :bgColor="s.color"
        rounded
        size="48"
        iconSize="s"
        :href="s.link"
        target="_blank"
      />
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import {SocialLinksPopup} from '@/components/Popups'

const socials = {
  vk: {
    icon: '/icons/social/vk.svg',
    color: '#88B1DC',
  },
  telegram: {
    icon: '/icons/social/telegram.svg',
    color: '#8ECAE8',
  },
  /* facebook: {
    icon: '/icons/social/facebook.svg',
    color: '#3b5999',
  },
  instagram: {
    icon: '/icons/social/instagram-logo.svg',
    color: '#e4405f',
  }, */
  youtube: {
    icon: '/icons/social/youtube.svg',
    color: '#cd201f',
  },
}

export default {
  components: {
    SocialLinksPopup,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    editable: Boolean,
  },
  computed: {
    socials() {
      if (!this.data) return

      const {social_links} = this.data

      if (Array.isArray(social_links)) {
        const accum = {}

        social_links.forEach((s) => {
          const name = Object.keys(s)[0]
          const value = s[name]

          accum[name] = value
        })

        return accum
      }

      if (social_links && typeof social_links === 'object' && Object.keys(social_links).length) {
        return social_links
      }

      return []
    },
    displaySocials() {
      if (this.isEmpty) return

      const formatted = {}

      Object.keys(this.socials).forEach((key) => {
        if (!socials[key]) return

        const link = this.socials[key]
        const {icon, color} = socials[key]

        formatted[key] = {
          link,
          icon,
          color,
        }
      })

      return formatted
    },
    isEmpty() {
      return !this.data || !this.socials || !Object.keys(this.socials).length
    },
  },
  methods: {
    ...mapActions({
      updateWebinar: 'webinars/updateWebinar',
    }),

    async save(data) {
      if (!this.data.id) return

      const social_links = objectToArray(data)

      await this.updateWebinar({
        id: this.data.id,
        social_links,
      })
    },
  },
}

function objectToArray(obj) {
  return Object.keys(obj).map((key) => {
    return {
      [key]: obj[key],
    }
  })
}
</script>

<style lang="scss">
.webinar-social-links {
  &__head {
    .app-btn {
      &:hover {
        background: $purple;
        svg {
          fill: #fff;
        }
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;

    .app-btn {
      border-radius: 16px;
      svg path {
        fill: #fff;
      }
    }
  }
  .pre-lined-text {
    white-space: pre-line;
  }
}
</style>
