import {propEq} from 'ramda'
import loadScript from '@/utils/loadScript'
import {addMinutes} from 'date-fns'

export {createInviteUrl} from './inviteUrl'
export uuid from './uuid'
export password from './password'
export aliases from './aliases'
export * from './mediaConstraints'

/* 5 cекунд до перенаправления на страницу вебинара, когда тот начался, а студента ждал в гостевой */
export const REDIRECT_COUNTDOWN = 5
export const MAX_ALLOWED_PARTICIPANTS = 45

export const loadMandatoryScripts = () => {
  try {
    const scripts = [
      {
        src:
          'https://github.com/muaz-khan/RTCMultiConnection/releases/download/3.6.9/RTCMultiConnection.js',
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/socket.io/2.3.0/socket.io.js',
      },
      {
        src: 'https://webrtc.github.io/adapter/adapter-latest.js',
      },
      {
        src: `/js/webrec.js`,
      },
    ]

    return Promise.all(scripts.map(async (script) => await loadScript(script.src)))
  } catch (error) {}
}

export const enterFullscreen = (element) => {
  const fullscreenEnabled =
    document.fullscreenEnabled ||
    document.msFullscreenEnabled ||
    document.mozFullScreenEnabled ||
    document.webkitFullscreenEnabled

  if (fullscreenEnabled) {
    const rfs =
      element.requestFullscreen ||
      element.webkitRequestFullscreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullscreen

    rfs.call(element)
  } else {
    alert('Ваш браузер не поддерживает просмотр стрима в полноэкранном режиме')
  }
}

// webinar statuses
export const STATUSES = {
  notStarted: 'not-started',
  started: 'started',
  ended: 'ended',
  expired: 'expired',
}

export const isWebinarNotStarted = propEq('status', STATUSES.notStarted)

export const isWebinarShouldStart = (webinar) => {
  const startDate = webinar.start_date * 1000

  return (
    !webinar.is_active &&
    !webinar.is_ended &&
    Date.now() > startDate &&
    Date.now() < addMinutes(startDate, webinar.duration)
  )
}

export const isWebinarEnded = propEq('status', STATUSES.ended)

export const isWebinarExpired = propEq('status', STATUSES.expired)
