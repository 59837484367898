import Vue from 'vue'
import Zendesk from '@dansmaculotte/vue-zendesk'

const KEY = '3b1503ef-6d20-4638-b43c-34404b376b96'
const DISABLED = false
const HIDE_ON_LOAD = false
const SETTINGS = {"webWidget":{"zIndex":999999}}

export default function (context) {
  Vue.use(Zendesk, {
    key: KEY,
    disabled: DISABLED,
    hideOnLoad: HIDE_ON_LOAD,
    settings: SETTINGS
  })
}
