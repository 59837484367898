<template>
  <div :class="_containerClass">
    <TippyComponent
      ref="tippy"
      animation="shift-toward"
      :class="rootClasses"
      :arrow="arrow"
      ignoreAttributes
      :theme="theme"
      zIndex="20000"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template #trigger>
        <slot v-if="external"> </slot>
        <div v-else class="app-tooltip--default__trigger">?</div>
      </template>

      <slot v-if="isClient && (content || $slots.content)" name="content">
        <div class="app-tooltip__content-container" v-html="content"></div>
      </slot>
    </TippyComponent>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTippy, {TippyComponent} from 'vue-tippy'

Vue.use(VueTippy)

// docs
// https://kabbouchi.github.io/vue-tippy/4.0/all-options.html

export default {
  name: 'UiTooltip',
  components: {TippyComponent},
  inheritAttrs: false,
  props: {
    external: Boolean,
    content: {
      type: String,
      default: 'Дефолт',
    },
    arrow: {
      type: [Boolean, Number],
      default: () => true,
    },
    theme: {
      type: String,
      default: 'purple',
    },
    display: {
      type: String,
      default: 'initial',
      validator: (v) => ['initial', 'block', 'inline', 'flex'].includes(v),
    },
    align: {
      type: String,
      default: 'center',
    },
    containerClass: {
      type: [String, Object, Array],
      default: '',
    },
  },
  data: () => ({
    isClient: false,
  }),
  computed: {
    _containerClass() {
      return [
        ...[this.containerClass],
        {
          [`app-tooltip-display-${this.display}`]: this.display !== 'initial',
        },
      ]
    },
    rootClasses() {
      return {
        flex: !this.external,
      }
    },
  },
  mounted() {
    this.isClient = true
  },
  methods: {
    hide() {
      if (this.$refs.tippy && this.$refs.tippy.tip) {
        this.$refs.tippy.tip.hide()
      }
    },
  },
}
</script>

<style lang="scss">
.app-tooltip {
  &--default__trigger {
    user-select: none;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: #cdd2db;
    color: #fff;
    border-radius: 50%;
  }

  &-display {
    &-block {
      display: block;
    }
    &-inline {
      display: inline-block;
    }
    &-flex {
      display: flex;
    }
  }

  &__content-container {
    padding: 12px;
  }
}

// tippy styles

.tippy-tooltip {
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;

  &.purple-theme {
    background-color: $purple;

    p {
      color: #fff;
    }

    &[x-placement^='top'] > .tippy-arrow {
      border-top-color: $purple;
    }
    &[x-placement^='bottom'] > .tippy-arrow {
      border-bottom-color: $purple;
    }
    &[x-placement^='left'] > .tippy-arrow {
      border-left-color: $purple;
    }
    &[x-placement^='right'] > .tippy-arrow {
      border-right-color: $purple;
    }
  }
  &.dark-theme {
    $col: #96a2b4;
    background-color: $col;
    padding: 0;

    p {
      color: #fff;
    }

    &[x-placement^='top'] > .tippy-arrow {
      border-top-color: $col;
    }
    &[x-placement^='bottom'] > .tippy-arrow {
      border-bottom-color: $col;
    }
    &[x-placement^='left'] > .tippy-arrow {
      border-left-color: $col;
    }
    &[x-placement^='right'] > .tippy-arrow {
      border-right-color: $col;
    }
  }
}
</style>
