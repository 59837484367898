require('./roundedBlock.scss')

export default {
  name: 'RoundedBlock',
  functional: true,
  props: {
    styles: {
      type: Object,
      default: () => ({
        backgroundColor: '',
        borderRadius: '16px',
        padding: '',
        margin: '',
        width: '',
        maxWidth: '',
        border: '',
        borderColor: '',
      }),
    },
  },
  render(h, {props, slots, data, listeners}) {
    const svs = {
      backgroundColor: '--rb-background-color',
      borderRadius: '--rb-border-radius',
      padding: '--rb-padding',
      margin: '--rb-margin',
      maxWidth: '--rb-max-width',
      width: '--rb-width',
      border: '--rb-border',
      borderColor: '--rb-border-color',
    }
    const mapStylesToVars = () =>
      Object.entries(props.styles).map(([key, value]) => ({[svs[key]]: value}))

    return h(
      'div',
      {
        class: ['app-rounded-block', data.staticClass],
        style: [...mapStylesToVars(), data.style],
        attrs: data.attrs,
        on: Object.assign({}, listeners),
      },
      slots().default
    )
  },
}
