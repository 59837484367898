<template>
  <div class="app-input meleton-form__field">
    <Typography
      v-if="label"
      component="label"
      variant="paragraph_s"
      color="grey"
      :for="id"
      class="app-input__label"
      >{{ label }}</Typography
    >
    <div :class="wrapperClass" :style="wrapperStyles">
      <!-- icon slot -->
      <div v-show="$slots.icon" class="app-input__wrap--icon" @click="setFocus">
        <slot name="icon"></slot>
      </div>
      <!-- prepend slot -->
      <div v-show="$slots.prepend" class="app-input__wrap--prepend">
        <slot name="prepend"></slot>
      </div>

      <!-- mask -->

      <TheMask
        v-if="mask"
        :id="id"
        :ref="ref"
        class="app-input__field"
        :mask="mask"
        :name="$attrs.name || id"
        :value="value"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :aria-errormessage="errorMessageGen"
        v-bind="$attrs"
        v-on="events"
        @blur.native="onBlur"
        @focus="onFocus"
      ></TheMask>

      <!-- input -->
      <input
        v-else
        :id="id"
        :ref="ref"
        class="app-input__field"
        :class="inputClass"
        :name="$attrs.name || id"
        :value="value"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        :aria-errormessage="errorMessageGen"
        v-bind="$attrs"
        @focus="onFocus"
        @blur="onBlur"
        v-on="events"
      />

      <!-- error message -->
      <span v-show="error && errorMessageGen" :class="{'app-input__error': true}">
        {{ errorMessageGen }}
      </span>

      <!-- append slot -->
      <div v-show="$slots.append" class="app-input__wrap--append" @click="$emit('append')">
        <slot name="append"></slot>
      </div>

      <Spinner v-if="loading" class="app-input__loading" :margin="0" :width="18" :height="18" />
    </div>
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'
import Spinner from '../Spinner'

export default {
  name: 'UiInput',
  components: {TheMask, Spinner},
  props: {
    mask: {
      type: [String, Array],
      default: '',
    },
    value: [String, Number],
    id: {
      type: String,
      default: () => genId(),
    },
    wrapClass: {
      type: [Object, Array, String],
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    hideDetails: {
      type: Boolean,
      default: () => false,
    },
    small: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    loading: Boolean,
    label: String,
    placeholder: String,
    disabled: Boolean,
    type: String,
    required: Boolean,
    error: [String, Boolean],
    errorMessage: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
      default: () => ({bgColor: '', borderRadius: '16px', margin: '', height: null}),
    },
    flat: Boolean,
    readonly: Boolean,
    fontSize: {
      type: String,
      default: '14px',
    },
  },
  data: () => ({
    focused: false,
    inputLoading: false,
  }),
  computed: {
    errorMessageGen() {
      if (this.errorMessage) return this.errorMessage
      return this.$t('forms.rules.requiredField')
    },
    isActive() {
      return this.focused
    },
    events() {
      return Object.assign({}, this.$listeners, {
        input: (event) => {
          this.$emit('input', this.mask ? event : event.target.value, event)
          if (this.error) {
            this.$emit('error', '')
          }
        },
        change: (event) => {
          this.$emit('change', event.target.value, event)
        },
      })
    },
    ref() {
      return 'input' + this.id
    },
    wrapperClass() {
      return [
        {
          'app-input__wrap': true,
          'app-input__flat': this.flat,
          'app-input__active': this.isActive,
          'app-input__disabled': this.disabled,
          'app-input__wrap--error': this.error,
          'app-input-no-icon': !this.$slots.icon && !this.$slots.prepend,
        },
        this.wrapClass,
      ]
    },
    wrapperStyles() {
      const exist = (key) => this.styles[key] || undefined
      const omit = (obj) => {
        return Object.keys(obj).reduce((acc, key) => {
          const _acc = acc
          if (obj[key] !== undefined) _acc[key] = obj[key]
          return _acc
        }, {})
      }
      return omit({
        '--input-bgcolor': exist('bgColor'),
        '--input-brrad': this.styles.borderRadius,
        '--input-margin': exist('margin'),
        '--input-height': this.small ? '30px' : this.styles.height,
        '--input-nodetails': this.hideDetails ? 0 : '22px',
        '--input-font-size': this.small ? '10px' : this.fontSize,
      })
    },
  },
  methods: {
    setFocus() {
      if (this.mask) {
        this.$refs[this.ref].$el.focus()
        return
      }
      this.$refs[this.ref].focus()
    },
    setBlur() {
      if (this.mask) {
        this.$refs[this.ref].$el.blur()
        return
      }
      this.$refs[this.ref].blur()
    },
    onFocus() {
      this.focused = true

      if (this.$refs[this.ref]) {
        this.$refs[this.ref].focus()
      }
      this.$emit('focus')
    },
    onBlur() {
      this.focused = false

      if (this.$refs[this.ref]) {
        if (this.$refs[this.ref]._isVue === true) this.$refs[this.ref].$el.blur()
        else this.$refs[this.ref].blur()
      }
      this.$emit('blur')
    },
  },
}

function genId() {
  return `mel-input-${Math.random().toString(36).substring(2, 10)}`
}
</script>

<style lang="scss">
input {
  text-transform: unset;
  border: none;
  outline: 0;
  background: unset;
  margin: 0;
  appearance: none;
  line-height: 1.5;
}
input.app-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.app-input[type='number'] {
  -moz-appearance: textfield;
}

.app-input {
  position: relative;

  &__wrap {
    position: relative;
    background-color: var(--input-bgcolor, #f2f5f9);
    border-radius: var(--input-brrad, 16px);
    margin: var(--input-margin, 0 0 var(--input-nodetails));
    height: var(--input-height, 50px);
    display: flex;
    align-items: center;
    border: 1px solid #f2f5f9;
    will-change: border;
    transition-property: border-color, background-color;
    transition: 0.1s ease-out;

    &--error {
      border-color: $error !important;
      margin-bottom: var(--input-nodetails);
    }

    &--icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px;
      padding-right: 0;
    }

    &--prepend {
      margin-left: 14px;
    }

    &--append {
      margin-left: 14px;
      margin-right: 14px;
    }

    input {
      width: 100%;
      height: 100%;
      color: #2e3141;
      font-size: var(--input-font-size);
      padding: 0 16px;

      &[readonly] {
        @include unselectable;
        font-size: var(--input-font-size);
      }
    }
  }

  &__error {
    position: absolute;
    width: 100%;
    height: 30px;
    padding: 4px 0 0;
    bottom: -30px;
    left: 0;
    font-size: 12px;
    z-index: 10;
    color: $error;
  }

  &__label {
    margin-bottom: 16px !important;
    line-height: 1 !important;
    display: block;
    text-align: left;
  }

  &__loading {
    margin-right: 20px !important;
  }

  &__field {
    position: relative;
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__active {
    border: 1px solid #6666fe;
    background-color: #fff;
  }
  &__disabled {
    border: 1px solid #f2f5f9;
    background-color: var(--input-bgcolor, #f2f5f9);
  }

  .app-input__flat {
    border: 0;
    border-bottom: 1px solid #eef0f3;
    border-radius: 0;
    background-color: #fff;
    height: 40px;

    .app-input__wrap--icon {
      padding: 12px 0 12px 6px;
    }

    &.app-input-no-icon {
      input {
        padding: 0;
      }
    }

    &.app-input__active {
      border-color: #6666fe;
    }
    &.app-input__disabled {
      border-color: #f2f5f9;
      opacity: 0.8;
    }

    input {
      font-size: var(--input-font-size);
      font-weight: 600;
      padding: 0 12px;

      &::placeholder {
        font-weight: 500;
        font-family: 'Roboto', 'Helvetica', sans-serif;
      }
    }
  }
}
</style>
