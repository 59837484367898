const STR64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.split('')
const UUIDLength = 30

/** encode (to64String): Method to transform a radix 10 number to radix 64 number (as string)
 * @param input   Number for transform to radix 64 (as String)
 * @param current String data (don't needed in request)
 * @return String Number in radix 64 as String;
 *
 * @based http://stackoverflow.com/users/383780/monocle
 * @based base64( Method for number to string - NOT string part )
 */
const encode = (input, current = '') => {
  if (input < 0 && current.length === 0) {
    input = input * -1
  }
  const modify = input % 64
  const remain = Math.floor(input / 64)
  const result = STR64[modify] + current

  return remain <= 0 ? result : encode(remain, result)
}

/** decode (to64Parse): Method for transform a number in radix 64 (as string) in radix 10 number
 * @param input   Number in radix 64 (as String) to transform in radix 10
 * @return Number in radix 10
 *
 * @based http://stackoverflow.com/users/520997/reb-cabin
 * @based Base64.toNumber( Method for string to number )
 */
const decode = (input) => {
  let result = 0
  const toProc = input.slice(UUIDLength)
  let e

  for (e in toProc) {
    result = result * 64 + STR64.indexOf(toProc[e])
  }

  return result
}

export default {decode, encode}
