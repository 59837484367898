<template>
  <div class="app-datepicker">
    <DatePicker
      v-bind="$attrs"
      :value="value"
      :lang="lang"
      class="app-picker"
      v-on="$listeners"
      @confirm="handleDate"
    >
      <template v-for="(_, slot) in $scopedSlots" #[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
      <template #footer="{emit}">
        <div class="datepicker__clear">
          <button class="mx-btn mx-btn-text" @click="clearDates(emit)">
            {{ $t('common.clear') }}
          </button>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {DatePicker},
  inheritAttrs: false,
  props: {
    value: {
      type: [Array, Number, Date, Object, String],
      default: null,
    },
  },
  data: () => ({
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
      monthBeforeYear: false,
    },
  }),
  created() {
    const lang = [{ru: {}}, {en: {}}, {es: {}}, {de: {}}, {pt: {}}]
    lang.ru = require('vue2-datepicker/locale/ru')
    lang.en = require('vue2-datepicker/locale/en')
    lang.de = require('vue2-datepicker/locale/de')
    lang.es = require('vue2-datepicker/locale/es')
    lang.pt = require('vue2-datepicker/locale/pt')

    this.lang = {...lang[this.$i18n.locale]}
  },
  methods: {
    handleDate(val) {
      console.log(val)
    },
    clearDates(emit) {
      emit(null)
      this.$emit('dates:clear')
    },
  },
}
</script>

<style>
.app-picker {
  width: 100%;
}
.mx-icon-calendar {
  display: none;
}
.mx-datepicker-popup {
  z-index: 10001 !important;
}
</style>
