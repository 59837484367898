/**
 * Enum for webinar access type values.
 * @enum {string}
 */
export const webinarAccessAlias = {
  link: 'link',
  public: 'public',
  product: 'product',
}

/**
 * Webinar access type switch options.
 */
export const webinarAccessOptions = [
  {title: 'access.link', value: webinarAccessAlias.link},
  {title: 'access.public', value: webinarAccessAlias.public},
  {title: 'access.product', value: webinarAccessAlias.product},
]

/**
 * Enum for webinar participant role types values.
 */
export const webinarRolesAlias = {
  admin: 'profile.owner',
  moderator: 'profile.moderator',
  viewer: 'profile.viewer',
  left_request: 'profile.leftRequest',
}

/**
 * Webinar participant role type switch options.
 * TODO: переписать на объект
 */
export const webinarRoleOptions = [
  {title: 'profile.viewer', value: 'viewer'},
  {title: 'profile.moderator', value: 'moderator'},
]

export default {
  webinarAccessAlias,
  webinarAccessOptions,
  webinarRolesAlias,
}
