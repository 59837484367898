export const state = () => ({
  productInfo: {},
})

export const getters = {
  product: (state) => state.productInfo,
  productUid: (state) => state.productInfo?.uid,
}

export const mutations = {
  SET_PRODUCT(state, payload) {
    state.productInfo = payload
  },
  CLEAR_PRODUCT(state) {
    state.productInfo = {}
  },
}

export const actions = {
  async FETCH_PRODUCT({commit, getters}, {uid}) {
    if (uid === getters.productUid) return

    try {
      const URL = uid ? `/products/info/${uid}` : `/products/info`

      const data = await this.$axios.$get(URL)

      commit('SET_PRODUCT', data)
    } catch (err) {}
  },
}
