import {
  find,
  propEq,
  filter,
  prop,
  update,
  findIndex,
  pipe,
  omit,
  map,
  path,
  reduce,
  mapObjIndexed,
  head,
  isEmpty,
  length,
} from 'ramda'
import {findPropByProp} from '../lib/helpers'

const mapErrors = map(
  reduce((a, v) => {
    return typeof v !== 'object'
      ? {error: v}
      : {
          ...a,
          ...mapObjIndexed(head, v),
        }
  }, {})
)

/**
 * @typedef {Object} ElementParams
 * @property {number?} id - internal element id
 * @property {string} element_id - ID of element
 * @property {string} element_alias - Element alias
 * @property {object} params - Element params
 * @property {string} action_type_id
 * @property {string} action_group
 * @property {number} coords_x
 * @property {number} coords_y
 * @property {string} target_element_id
 * @property {string} target_no_element_id
 * @property {string} target_yes_element_id
 * @property {boolean?} is_new
 */

/**
 * @typedef {object} rootState
 * @property {ElementParams[]} elements
 * @property {object} activeElement
 * @property {object} errors
 */

/**
 * @type {rootState}
 */
const rootState = {
  // on board elements
  elements: [],
  // selected on board element
  activeElement: {},
  // current errors from api
  errors: {},
  hasChanges: false,
}

/** @type {import('vuex').Store<typeof rootState>} */
export const state = () => rootState

/** @type {import('vuex').GetterTree<typeof rootState>} */
export const getters = {
  hasChanges: (state) => state.hasChanges,
  // get element by alias from CURRENT ON BOARD ELEMENTS
  getElementByAliasFromBoard: (state) => (id) => {
    return find(propEq('element_id', id), state.elements) || {}
  },
  activeElement: (state) => state.activeElement,
  elements: (state) => state.elements,
  errors: (state) => state.errors || {},
  noDroppedElements: (state) => isEmpty(state.elements),
  hasMoreThanOneInputElements: (state) =>
    length(filter(propEq('action_group', 'input'), state.elements)) > 0,
}

const SET_ELEMENTS = 'SET_ELEMENTS'
const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT'
const SET_ERRORS = 'SET_ERRORS'
const CLEAR_STATE = 'CLEAR_STATE'
const SET_UNSAVED = 'SET_UNSAVED'
/** @type {import('vuex').MutationTree<typeof rootState>} */
export const mutations = {
  [SET_ELEMENTS](state, els) {
    state.elements = els
  },
  [SET_ACTIVE_ELEMENT](state, activeEl) {
    state.activeElement = activeEl
  },
  [SET_ERRORS](state, errs) {
    state.errors = errs
  },
  [CLEAR_STATE](state) {
    state.errors = {}
    state.elements = []
    state.activeElement = {}
    state.hasChanges = false
  },
  [SET_UNSAVED](state, bool) {
    state.hasChanges = bool
  },
}

const UPDATE_ELEMENT_PARAMS = 'UPDATE_ELEMENT_PARAMS'
const APPEND_NEW_ELEMENT = 'APPEND_NEW_ELEMENT'
const REMOVE_ELEMENT = 'REMOVE_ELEMENT'
const PREPARE = 'PREPARE'
const HANDLE_ERRORS = 'HANDLE_ERRORS'
const RESET_ERROR = 'RESET_ERROR'
const COMBINE_ERRORS = 'COMBINE_ERRORS'
/** @type {import('vuex').ActionTree<typeof rootState>} */
export const actions = {
  [RESET_ERROR]({state, commit}, {id}) {
    const omitError = omit([id])
    commit(SET_ERRORS, omitError(state.errors))
  },
  [HANDLE_ERRORS]({state, commit, dispatch}, error) {
    const rawErrs = path(['response', 'data', 'errors'], error)

    if (!rawErrs) return

    const errors = mapErrors(rawErrs)

    if ('joints' in errors) {
      dispatch(
        'SHOW_TOAST',
        {
          type: 'danger',
          /*
           * need to check if it works
           */
          text: this.$i18n.t('funnels.needToBeConnected'),
        },
        {root: true}
      )
    }
    if ('elements' in errors) {
      dispatch(
        'SHOW_TOAST',
        {
          type: 'danger',
          text: errors.elements.error,
        },
        {root: true}
      )
    }

    commit(SET_ERRORS, errors)
  },
  [COMBINE_ERRORS]({state, commit}, error) {
    const errors = state.errors
    commit(SET_ERRORS, {...errors, ...error})
  },
  [APPEND_NEW_ELEMENT]({rootState, state, commit}, payload = {}) {
    const initialParamsForElement = findPropByProp({
      take: 'params',
      by: 'element_alias',
      val: payload.elAlias,
      from: rootState.funnel.allElements,
    })
    const actionTypeId = findPropByProp({
      take: 'id',
      by: 'element_alias',
      val: payload.elAlias,
      from: rootState.funnel.allElements,
    })

    const element = {
      element_id: payload.blockId,
      action_group: payload.elGroup,
      action_type_id: actionTypeId,
      element_alias: payload.elAlias,
      coords_x: payload.posX,
      coords_y: payload.posY,
      params: getInitialParams(initialParamsForElement),
      is_new: true,
    }
    commit(SET_ELEMENTS, [...state.elements, element])
  },
  [UPDATE_ELEMENT_PARAMS]({state, commit}, {id, params} = {}) {
    const currentElements = state.elements || []

    const updateAtIndex = (index) => {
      const el = currentElements[index]
      const oldParams = prop('params', el) || {}

      return update(index, {...el, params: {...oldParams, ...params}}, currentElements)
    }

    const updateElement = pipe(findIndex(propEq('element_id', id)), updateAtIndex)

    commit(SET_ELEMENTS, updateElement(currentElements))
  },
  [REMOVE_ELEMENT]({state, commit}, {id} = {}) {
    commit(
      SET_ELEMENTS,
      filter((el) => el.element_id !== id, state.elements)
    )
    propEq('id', id, state.activeElement) && commit(SET_ACTIVE_ELEMENT, {})
  },
  [PREPARE]({state}, rawElements) {
    const els = state.elements

    const overElements = (el) => {
      const rawEl = find(propEq('element_id', el.element_id), rawElements)
      return {
        ...el,
        ...rawEl,
        params: rejectExcessParams(el.params),
      }
    }

    const preparedEls = map(overElements, els)

    return preparedEls
  },
}

// map params from default values to newly created element
function getInitialParams(data = []) {
  return data.reduce((acc, v) => {
    return {...acc, [v.field_name]: v.default_value}
  }, {})
}

function rejectExcessParams(params) {
  const paramsToOmit = ['selectedCourse', 'selectedProduct', 'switcher']
  return omit(paramsToOmit, params)
}
