<template>
  <Selector
    v-model="selectedCourse"
    :options="authorCourses()"
    :placeholder="placeholder"
    :loading="isLoading"
    :variant="variant"
    showIcon
    :icon="'/icons/product-icon.svg'"
  />
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {prop, isEmpty} from 'ramda'

export default {
  components: {
    Selector: () => import('@/components/ui/Selector'),
  },
  props: {
    courseId: null,
    variant: {
      type: String,
      default: 'white',
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('courses.all')
      },
    },
  },
  data: () => ({
    selectedCourse: '',
    isLoading: false,
  }),
  async fetch() {
    this.isLoading = true
    await this.fetchAuthorPublishedCourses()
    this.isLoading = false
  },

  computed: {},
  watch: {
    courseId(value) {
      this.fetchCourse(value)
    },
    selectedCourse(v) {
      this.emitCourse(prop('id', v))
    },
    /* '$route.query'(query) {
      if (isEmpty(query)) {
        this.selectedCourse = ''
      }
    }, */
  },
  methods: {
    ...mapGetters({
      authorCourses: 'courses/getCourses',
    }),
    ...mapActions({
      fetchAuthorPublishedCourses: 'courses/fetchAuthorPublishedCourses',
      fetchCourseItem: 'courseItem/fetchCourse',
    }),
    emitCourse(v) {
      this.$emit('course:id', v)
    },
    async fetchCourse(courseId) {
      if (!courseId) {
        this.selectedCourse = ''
        return
      }

      this.isLoading = true

      this.selectedCourse = await this.fetchCourseItem({courseId})
      this.isLoading = false
    },
  },
}
</script>
