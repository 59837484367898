import {find, propEq, map, mapObjIndexed, head, unless, path, isNil, pipe} from 'ramda'
import {updateObjectByKey} from '@/utils'

export const state = () => ({
  // list of funnels
  list: [],
  links: {},
  // the funnel on funnel builder route
  funnel: {},
  // all elements available to funnel builder
  allElements: [],

  listLoading: true,
})

export const getters = {
  list: (state) => state.list || [],
  listLoading: (state) => state.listLoading,
  hasMoreList: (state) => {
    return Boolean(state.links.next)
  },
  funnel: (state) => state.funnel,
  allElements: (state) => state.allElements,
  getElementByAliasFromAll: (state) => (alias) => {
    return find(propEq('element_alias', alias), state.allElements)
  },
}

const SET_FUNNELS = 'SET_FUNNELS'
const SET_FUNNEL = 'SET_FUNNEL'
const SET_ELEMENTS = 'SET_ELEMENTS'
const SET_LIST_LOADING = 'SET_LIST_LOADING'
export const mutations = {
  [SET_FUNNELS](state, data) {
    state.list = data.data
    if (data.links) state.links = data.links
  },
  [SET_FUNNEL](state, data) {
    state.funnel = data
  },
  [SET_ELEMENTS](state, data) {
    state.allElements = data
  },
  [SET_LIST_LOADING](state, p) {
    state.listLoading = p
  },
}

const GET_FUNNELS = 'GET_FUNNELS'
const GET_MORE_FUNNELS = 'GET_MORE_FUNNELS'
const GET_FUNNEL = 'GET_FUNNEL'
const GET_FUNNEL_ELEMENTS = 'GET_FUNNEL_ELEMENTS'
const CREATE_FUNNEL = 'CREATE_FUNNEL'
const UPDATE_FUNNEL = 'UPDATE_FUNNEL'
const DELETE_FUNNEL = 'DELETE_FUNNEL'
const SAVE_FUNNEL_ELEMENTS = 'SAVE_FUNNEL_ELEMENTS'
const GET_SELECTOR_PARAMS = 'GET_SELECTOR_PARAMS'
const TEST_ELEMENT_ACTION = 'TEST_ELEMENT_ACTION'
export const actions = {
  async [GET_FUNNELS]({commit, state}) {
    commit(SET_LIST_LOADING, true)
    const data = await this.$axios.$get(`/author/funnels/list`)
    commit(SET_FUNNELS, data)
    commit(SET_LIST_LOADING, false)
    return data
  },

  async [GET_MORE_FUNNELS]({commit, state}) {
    try {
      const data = await this.$axios.$get(state.links.next)
      commit(SET_FUNNELS, {data: [...state.list, ...data.data], links: data.links, meta: data.meta})
      commit(SET_LIST_LOADING, false)
    } catch (err) {
      console.log(err)
    }
  },

  async [GET_FUNNEL]({commit}, {id} = {}) {
    const data = await this.$axios.$get(`/author/funnels/${id}`).catch(console.log)
    commit(SET_FUNNEL, data)
    // set default params for elements that came from api (existed already, not the ones that user dragged in current session)
    commit('funnelBuilder/SET_ELEMENTS', markElementsOld(data.elements), {root: true})
  },
  async [GET_FUNNEL_ELEMENTS]({commit}) {
    const data = await this.$axios.$get('/funnel-elements/list').catch(console.log)
    commit(SET_ELEMENTS, data)
    return data
  },
  async [CREATE_FUNNEL]({commit, state}, params) {
    const data = await this.$axios.$post('author/funnels', params).catch(console.log)
    commit(SET_FUNNELS, {data: [...state.list, data]})
    return data
  },
  async [UPDATE_FUNNEL]({commit, state}, params) {
    try {
      const data = await this.$axios.$patch(`author/funnels/${params.id}`, params)
      commit(SET_FUNNELS, {data: updateObjectByKey('id', data, state.list)})
      commit(SET_FUNNEL, data)
    } catch (err) {
      console.log(err)
    }
  },

  async [DELETE_FUNNEL]({commit, state}, params) {
    try {
      await this.$axios.delete(`author/funnels/${params.id}`)
      const indexForDelete = state.list.findIndex((el) => el.id === params.id)
      if (indexForDelete > -1) {
        state.list.splice(indexForDelete, 1)
      }
      commit(SET_FUNNELS, {data: state.list})
    } catch (err) {
      console.log(err)
    }
  },

  async [SAVE_FUNNEL_ELEMENTS]({commit, state, dispatch}, {id, params} = {}) {
    const fullParams = {
      joints: params.joints,
      elements: await dispatch('funnelBuilder/PREPARE', params.elements, {root: true}),
    }

    try {
      const data = await this.$axios.$post(`author/funnels/${id}/elements`, fullParams)
      // console.log(data)
      commit(SET_FUNNEL, data)

      commit('funnelBuilder/SET_ELEMENTS', markElementsOld(data.elements), {root: true})
      commit('funnelBuilder/SET_ERRORS', {}, {root: true})
      commit('funnelBuilder/SET_UNSAVED', false, {root: true})

      dispatch('SHOW_TOAST', {text: this.$i18n.t('toast.funnelSaved')}, {root: true})

      return data
    } catch (err) {
      dispatch('SHOW_TOAST', {type: 'danger', text: this.$i18n.t('toast.check')}, {root: true})
      dispatch('funnelBuilder/HANDLE_ERRORS', err, {root: true})
    }
  },
  async [GET_SELECTOR_PARAMS]({dispatch}, {type} = {}) {
    const types = {
      AuthorTelegramBot: '/telegram-bot/list',
      StripoEmail: '/stripo-email/list',
      StripoEmailLink: 'stripo-email/links-list',
      LeadPage: '/author/lead-pages/list',
    }

    const currentApi = types[type]

    try {
      const data = await this.$axios.$get(currentApi)
      return data || []
    } catch (err) {
      console.error(err)
    }
  },
  async [TEST_ELEMENT_ACTION]({dispatch}, {type, params, elementId}) {
    const types = {
      email: 'author/funnels/test-email',
      sms: 'author/funnels/test-sms',
    }
    const texts = {
      email: 'email',
      sms: this.$i18n.t('toast.sms'),
    }

    try {
      const data = await this.$axios.$post(types[type], params)
      dispatch(
        'SHOW_TOAST',
        {
          text: `${this.$i18n.t('toast.test')} ${texts[type]} ${this.$i18n.t('toast.sent')}`,
        },
        {root: true}
      )
      return data
    } catch (err) {
      console.log(err)
      const handleErr = (error) => {
        dispatch(
          'funnelBuilder/COMBINE_ERRORS',
          {
            [`${elementId}`]: {
              ...error,
            },
          },
          {root: true}
        )
      }
      const hasErr = unless(isNil, pipe(mapObjIndexed(head), handleErr))

      hasErr(path(['response', 'data', 'errors'], err))
    }
  },
}

function markElementsOld(els = []) {
  return map((el) => {
    return {
      ...el,
      is_new: false,
    }
  }, els)
}
