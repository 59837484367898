<template>
  <div :class="previewClassess" :style="{background}">
    <div v-if="editable" :class="overlayEdit ? 'preview-image__overlay-edit' : ''">
      <div class="buttons-group">
        <FileUploadButton
          v-if="editable"
          class="preview-image__edit"
          icon="icons/image.svg"
          :label="false"
          :show-filename="false"
          :clearable="false"
          v-on="$listeners"
          @input="onFileUpload"
          @dataUrl="onImageUpload"
        />
      </div>

      <ImageCropperModal
        v-if="showCropper"
        :title="cropperTitle"
        :imgFile="originalFile"
        :imgSrc="originalImage"
        :rules="{...extras, ...imageUploadRules}"
        :viewMode="2"
        @cropped="savePreview"
        @dataUrl="updatePreview"
        @close="showCropper = false"
      />
    </div>

    <div
      v-if="actualPreview"
      :class="imgClassess"
      :style="{'background-image': 'url(' + actualPreview + ')'}"
    ></div>

    <div v-else class="preview-image__default">
      <Icon src="/icons/lesson-image-icon.svg" :size="iconSize" />
    </div>

    <div v-if="$slots.backdrop" class="preview-image__backdrop flex-center">
      <slot name="backdrop" />
    </div>
  </div>
</template>

<script>
import {FileUploadButton} from '~ui'

export default {
  components: {
    FileUploadButton,
    ImageCropperModal: () => import('~ui').then((m) => m.ImageCropperModal),
  },
  props: {
    error: Boolean,
    extras: {
      type: Object,
      default: () => {},
    },
    preview: {
      type: String,
      default: undefined,
    },
    editable: Boolean,
    background: {
      type: [String],
      default: 'linear-gradient(45deg, #d2e8ff, #ebe3ff)',
    },
    iconSize: {
      type: String,
      default: 'xl',
    },
    overlayEdit: Boolean,
    cover: Boolean,
  },
  data() {
    return {
      showCropper: false,
      cropperTitle: '',
      originalFile: null,
      originalImage: null,
      imageUploadRules: {
        minWidth: 100,
        minHeight: 100,
      },
    }
  },
  computed: {
    actualPreview() {
      return this.preview || this.originalImage
    },
    previewClassess() {
      return {
        'preview-image': true,
        'preview-image--error': this.error,
      }
    },
    imgClassess() {
      return {
        'preview-image__image': true,
        cover: this.cover,
      }
    },
  },
  methods: {
    onFileUpload(file) {
      this.originalFile = file
    },
    onImageUpload(image) {
      if (!image || !image.length) return

      this.originalImage = image

      this.cropperTitle = this.$t('webinars.uploadPic')
      this.showCropper = true
    },
    updatePreview(croppedData) {
      this.originalImage = croppedData
    },
    savePreview(croppedFile) {
      this.showCropper = false
      this.$emit('getPreview', {
        file: croppedFile,
        dataUrl: this.originalImage,
      })
    },
  },
}
</script>

<style lang="scss">
.preview-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 85px;

  &.preview-image--error {
    /* border: 1px solid $error;
     */
    box-shadow: 0 0 3px 1px $error;
  }

  button,
  label {
    z-index: 1;
  }

  &__image {
    @include coverdiv;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.cover {
      background-size: cover;
    }
  }

  @mixin rounded {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0;
  }

  .buttons-group {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;

    .app-btn {
      @include rounded;
      border: none;

      .icon {
        margin-right: 0 !important;
      }

      svg {
        fill: #cdd2db;
      }

      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }

  // if edit button is overlayed
  &__overlay-edit {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    .buttons-group {
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      &:hover {
        .preview-image__edit {
          display: flex;
        }
      }
      .preview-image__edit {
        display: none;
        background-color: rgba($purple, 0.3);
        width: 100%;
        height: 100%;
        max-height: 100%;
      }
    }
  }

  &__edit {
    .has-file {
      margin: 3px 0 0 1px;
    }
  }

  &__default {
    .app-icon {
      cursor: default;
      pointer-events: none;
      svg {
        fill: rgba(178, 185, 214, 0.4);
      }
    }
  }

  &__backdrop {
    @include coverdiv;
    background: rgba($black, 0.3);
  }
}
</style>
