<template>
  <div class="navigation-drawer">
    <Transition name="fade" mode="out-in">
      <div v-show="show" class="navigation-drawer__backdrop" @click="$emit('close')"></div>
    </Transition>

    <div class="navigation-drawer__panel" :class="{visible: show}" :style="{height}">
      <div class="navigation-drawer__header">
        <Typography class="navigation-drawer__title" variant="h6">{{ title }}</Typography>
        <div class="navigation-drawer__closer" @click="$emit('close')">
          <Icon src="icons/cross-icon.svg" svgClass="svg" size="s" />
        </div>
      </div>
      <div class="navigation-drawer__content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => 'Meleton',
    },
  },
  data() {
    return {
      show: this.value,
      height: '100vh',
    }
  },
  watch: {
    value(value) {
      this.show = this.value
      document.body.style.overflowY = value ? 'hidden' : ''
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onResize()

      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    document.body.style.overflowY = ''
  },
  methods: {
    onResize() {
      this.height = window.innerHeight
    },
  },
}
</script>

<style lang="scss">
.navigation-drawer {
  &__panel {
    background-color: #fff;
    position: absolute;
    left: -25em;
    top: 0;
    width: 300px;
    transition: 0.2s;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-flow: column;
    pointer-events: none;

    &.visible {
      left: 0;
      z-index: $zTop;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
      overflow-y: auto;
    }
  }

  &__header,
  &__content {
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $lightGrey;
  }

  &__title,
  &__closer {
    padding: 28px 30px;
  }

  &__title {
    color: $black;
  }

  &__closer {
    cursor: pointer;
    border-left: 1px solid $lightGrey;
    text-align: center;

    .svg {
      color: $elementsGrey;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &__backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: $zTop;
  }
}

@include media($mobile-l) {
  .navigation-drawer {
    &__panel {
      width: 75vw;
    }
  }
}
</style>
