<template>
  <PopupWrapper :title="$t('page.mainScreen')" v-bind="$attrs" @close="onClose">
    <ImageUploadPreviewBlock v-model="file" label :image="bgImgUrl" @dataUrl="fileDataUrl" />
    <Button @click="onSave">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'

export default {
  components: {
    PopupWrapper: () => import('@/components/ui/PopupWrapper'),
    ImageUploadPreviewBlock: () => import('../components/ImageUploadPreviewBlock'),
  },
  data: () => ({
    file: {},
  }),
  computed: {
    ...mapState({
      bgImgUrl: (state) => state.coursePage.course.landing_background,
    }),
  },
  methods: {
    ...mapActions('coursePage', ['updateCourseFromLanding']),
    ...mapMutations('coursePage', ['updateBgImageLink']),
    fileUpload(file) {
      this.file = file
    },
    fileDataUrl(dataUrl) {
      this.updateBgImageLink(dataUrl)
    },
    onClose() {
      this.file = {}
      this.$emit('close')
    },
    async onSave() {
      const params = {
        courseId: this.$route.params.courseId,
        file: this.file,
        action: 'landing.background.update',
      }

      try {
        await this.updateCourseFromLanding(params)
        this.onClose()
      } catch (err) {}
    },
  },
}
</script>
