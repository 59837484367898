<template>
  <PopupWrapper v-bind="$attrs" @close="onClose">
    <Typography variant="paragraph_xs" color="grey" class="mb-1 mt-3">{{
      $t('leadsPage.buttonName')
    }}</Typography>
    <Input
      v-model="pseudo_title"
      :error="$v.pseudo_title.$error"
      @blur="$v.pseudo_title.$touch()"
    />
    <Typography variant="paragraph_xs" color="grey" class="mb-1 mt-3">
      {{ $t('leadsPage.selectBotText') }}</Typography
    >

    <UISelector
      v-model="data.entity"
      label="user_name"
      :options="groupList"
      :allow-empty="false"
      :labeled="false"
      openDirection="bottom"
      :error="$v.data.entity.$error"
      :errorMessage="entityError"
      @select="changeSelector"
    >
      <template slot="option" slot-scope="props">
        <span>{{ props.option.user_name }}</span>
      </template>
    </UISelector>

    <Checkbox
      v-model="pseudo_is_show"
      :label="$t('leadsPage.showInPage')"
      class="mt-3 mb-1"
    ></Checkbox>

    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSaveButton">{{ btnText }}</Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input, Checkbox} from '@/components/ui'
import UISelector from '~ui/Selector'

export default {
  components: {
    UISelector,
    PopupWrapper,
    Button,
    Input,
    Checkbox,
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  validations: {
    pseudo_title: {required},
    data: {
      entity: {required},
    },
  },
  data: () => ({
    groupList: [],
    pseudo_is_show: false,
    pseudo_title: '',
  }),
  async fetch() {
    this.groupList = await this.$axios.$get(`/author/telegram-bot/list`)
    this.data.entity = this.groupList
      ? this.groupList.find((e) => e.entity_id === this.data.entity_id)
      : {}
  },
  computed: {
    entityError() {
      if (this.$v.data.entity.$error) {
        return this.$t('leadsPage.selectBot')
      }
    },
    data: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pseudo_is_show = this.value.is_show
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pseudo_title = this.value.title

        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    btnText() {
      return this.$t('common.save')
    },
  },
  methods: {
    changeSelector(v) {
      this.pseudo_is_show = true
    },
    onClose() {
      this.$emit('change', this.data)
      this.$emit('close')
      this.$v.$reset()
    },
    async onSaveButton() {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.data.is_show = this.pseudo_is_show
      this.data.title = this.pseudo_title

      const params = {
        button_type: this.data.button_type,
        title: this.data.title,
        is_show: this.data.is_show,
        is_run_funnel: false,
        entity_id: this.data.entity.id,
      }

      await this.$axios.$put(`/author/lead-page-buttons/${this.data.id}`, params)
      this.$emit('change', this.data)
      this.onClose()
    },
  },
}
</script>
