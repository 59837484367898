import {load} from './util'

export const landingRoutes = [
  {
    path: '/course/:courseId/landing',
    component: load('CourseLanding/CoursePage'),
    name: 'courses.landing',
    redirect: {name: 'course-page.description'},
    meta: {
      disableScroll: true,
      roles: ['author', 'admin', 'moderator', 'user'],
      title: 'page.courseLanding',
      breadcrumbs: [
        {
          title: 'courses.myCourses',
          name: 'courses.list',
        },
      ],
    },
    children: [
      {
        path: 'description',
        component: load('CourseLanding/CourseDescription'),
        name: 'course-page.description',
        meta: {
          title: 'page.courseLandingDesc',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'teachers',
        component: load('CourseLanding/CourseTeachers'),
        name: 'course-page.teachers',
        meta: {
          title: 'page.courseLandingTeachers',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'program',
        component: load('CourseLanding/CourseContent'),
        name: 'course-page.program',
        meta: {
          title: 'page.courseLandingSyllabus',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'review',
        component: load('CourseLanding/CourseReview'),
        name: 'course-page.review',
        meta: {
          title: 'page.courseLandingReviews',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
      {
        path: 'payment',
        component: load('CourseLanding/CoursePayment'),
        name: 'course-page.payment',
        meta: {
          title: 'page.courseLandingPayment',
          breadcrumbs: [
            {
              title: 'page.main',
              name: 'main',
            },
          ],
        },
      },
    ],
  },
]
