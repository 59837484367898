var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-ring",style:({
    width: ((_vm.size || _vm.width) + "px"),
    height: ((_vm.size || _vm.height) + "px"),
    margin: (_vm.margin + "px auto"),
  })},_vm._l((4),function(n){return _c('div',{key:n,staticClass:"circle",style:({
      borderColor: (_vm.color + " transparent transparent transparent"),
      width: ((_vm.size || _vm.width) + "px"),
      height: ((_vm.size || _vm.height) + "px"),
    })})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }