import {uniq, complement, all, isEmpty, values} from 'ramda'

const mapCategories = (c) => {
  if (c && c.length > 0) {
    return c.map((val, idx) => {
      return {
        ...val,
        number: idx + 1 < 10 ? `0${idx + 1}` : String(idx + 1),
      }
    })
  }
  return c
}

const isNotEmpty = all(complement(isEmpty))
const exist = (state) => isNotEmpty(values(state))

export const state = () => ({
  categories: [],
  best: [],
  gain_popularity: [],
})

export const getters = {
  isDataExist: (state) => exist(state),
  categories: (state) => mapCategories(state.categories),
  best: (state) => state.best,
  gainPopularity: (state) => state.gain_popularity,
}

export const mutations = {
  SET(state, payload) {
    state.categories = uniq(payload.categories)
    state.best = uniq(payload.best)
    state.gain_popularity = uniq(payload.gain_popularity)
  },
}

export const actions = {
  async FETCH({commit, dispatch}) {
    try {
      const response = await this.$axios.get('/main/index/')
      commit('SET', response.data)
    } catch (err) {}
  },
}

export const namespaced = true
