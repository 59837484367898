import {NOTIFICATIONS} from '@/consts'

export const registerUserController = ($echo, store, userId) => {
  $echo.private('App.User.' + userId).notification((notification) => {
    if (process.env.APP_ENV !== 'production') {
      console.log(notification)
    }

    switch (notification.type) {
      case NOTIFICATIONS.authorApproveHomework:
        store.dispatch('notifications/insertNotification', notification)
        break
      case NOTIFICATIONS.authorRejectHomework:
        store.dispatch('notifications/insertNotification', notification)
        break
      case NOTIFICATIONS.chat_new_msg:
        store.dispatch('chat/INSERT_MESSAGE', notification.data.chatMessage)
        break
      case NOTIFICATIONS.chat_new_review:
      case NOTIFICATIONS.chat_student_send_homework:
        if (notification.data.chatMessage) {
          store.dispatch('chat/INSERT_MESSAGE', notification.data.chatMessage)
        }
        break
      case NOTIFICATIONS.user_created_order:
        store.dispatch('crm/UPDATE_FROM_SOCKET', notification)
        break
      case NOTIFICATIONS.user_bought_product:
        store.dispatch('crm/UPDATE_FROM_SOCKET', notification)
        break

      case NOTIFICATIONS.course_declined:
        store.commit('notifications/updateList', notification)
        store.commit('notifications/incrCount')

        break
      case NOTIFICATIONS.course_published:
        store.commit('notifications/updateList', notification)
        store.commit('notifications/incrCount')

        break

      case NOTIFICATIONS.user_requested_moderation:
        store.commit('moderation/updateModerationList', notification.course)
        // store.commit('notifications/incrCount')
        break

      case NOTIFICATIONS.legalDetailsExpires:
        store.dispatch('notifications/insertNotification', notification)
        store.dispatch('modal/toggle', 'legal_details_note')
        break

      case NOTIFICATIONS.legalDetailsRejected:
        store.dispatch('notifications/insertNotification', notification)
        break

      case NOTIFICATIONS.legalDetailsApproved:
        store.dispatch('notifications/insertNotification', notification)
        break

      case NOTIFICATIONS.firstSell:
        store.dispatch('sendFirstSell')
        break
      case NOTIFICATIONS.authorCompletedImportVkGroups:
        if (notification.data.status) {
          store.dispatch('SHOW_TOAST', {text: notification.data.message})
          store.dispatch('funnelBots/GET_VK_LIST')
        } else {
          store.dispatch('SHOW_TOAST', {
            type: 'danger',
            text: notification.data.message,
          })
        }
        break

      case NOTIFICATIONS.webinarIsStarted:
        store.dispatch('notifications/insertNotification', notification)

        store.commit('webinars/SET_WEBINAR_STATUS', {
          is_active: true,
          id: notification.data.online_webinar_url,
        })
        break

      case NOTIFICATIONS.webinarIsStartingSoon:
        store.dispatch('notifications/insertNotification', notification)
        break

      case NOTIFICATIONS.webinarShouldStart:
        store.dispatch('notifications/insertNotification', notification)
        break
      case NOTIFICATIONS.webinarScreenAlert:
        store.commit('webinars/SET_SCREEN_SHARING', true)
        break

      default:
        console.log('unknown notification type id ' + notification.id)
    }
  })
}
