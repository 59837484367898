import {load} from './util'
import {ROLES} from '~/consts'

export const commonRoutes = [
  {
    path: '/',
    component: load('landing'),
    name: 'main',
    meta: {
      title: 'page.main',
      requiresAuth: false,
      disableScroll: true,
      rolesRedirect: 'courses',
    },
  },
  {
    path: '/courses/',
    name: 'courses',
    component: load('courses'),
    meta: {
      title: 'courses.title',
      requiresAuth: false,
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/dialogs/',
    component: load('dialogs'),
    name: 'dialogs',
    meta: {
      requiresAuth: true,
      roles: [ROLES.student],
      title: 'dialogs.title',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/dialogs/:chatId/',
    component: load('dialogs/Chat'),
    name: 'dialogs.chat',
    meta: {
      requiresAuth: true,
      title: 'dialogs.title',
    },
  },
  {
    path: '/promocodes',
    component: load('promocodes'),
    name: 'promocodes',
    meta: {
      requiresAuth: true,
      roles: [ROLES.partner, ROLES.author],
      title: 'common.promocodes',
    },
  },
  {
    path: '/requisites-form',
    component: load('requisitesForm'),
    name: 'requisites.form',
    meta: {
      requiresAuth: true,
      roles: [ROLES.partner, ROLES.author],
      title: 'page.requisites',
    },
  },
  {
    path: '/requisites-success',
    component: load('requisitesForm/FormSuccess'),
    name: 'requisites.success',
    meta: {
      requiresAuth: true,
      title: 'page.requisites',
    },
  },
  {
    path: '/profile',
    component: load('profile'),
    name: 'profile',
    meta: {
      requiresAuth: true,
      title: 'page.profile',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },
  {
    path: '/licence-agreement',
    component: load('general/LicenceAgreement'),
    name: 'licence-agreement',
  },
  {
    path: '/about',
    component: load('general/AboutPage'),
    name: 'about',
  },
  {
    path: '/support',
    component: load('general/SupportPage'),
    name: 'support',
  },

  {
    path: '/become-partner',
    component: load('general/BecomePartnerPage'),
    name: 'become-partner',
  },
  {
    path: '/test/icons',
    component: load('testRoute/IconsPage'),
    name: 'icons.page',
  },
  {
    path: '/test/ui',
    component: load('testRoute/ui'),
    name: 'ui.page',
  },
  {
    path: '/test/table',
    component: load('testRoute/table'),
    name: 'table.page',
  },
  {
    path: '/dali',
    redirect: '/course/184/landing/description',
  },
  {
    path: '/test/selector',
    component: load('testRoute/selector'),
    name: 'test.selector.page',
  },
  {
    path: '/sub/:uuid',
    name: 'subscription',
    component: () =>
      import(/* webpackChunkName: "PageSubscription" */ '@/pages/subscription').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/leads/:uuid',
    name: 'leadsgenerator',
    component: () => import('@/pages/leads').then((m) => m.default || m),
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    component: () =>
      import(/* webpackChunkName: "PageOnboarding" */ '@/pages/onboarding').then(
        (m) => m.default || m
      ),
  },
  {
    path: '/partner',
    name: 'partner',
    component: () =>
      import(/* webpackChunkName: "PagePartner" */ '@/pages/partner').then((m) => m.default || m),
    meta: {
      requiresAuth: true,
      roles: [ROLES.partner],
    },
  },
]
