<template>
  <div class="input-range" :class="{disabled}">
    <div v-if="$slots.prepend" class="input-range__prepend mr-2">
      <slot name="prepend"></slot>
    </div>

    <input
      ref="range"
      class="input-range__range"
      type="range"
      :style="vars"
      :step="step"
      :min="min"
      :max="max"
      :value="value"
      v-bind="$attrs"
      @change="onChange"
      @input="onChange"
    />

    <div v-if="$slots.append" class="input-range__append ml-2">
      <slot name="append"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 100,
    },
    color: {
      type: String,
      default: '#f2f5f9',
    },
    activeColor: {
      type: String,
      default: '#6666fe',
    },
    thumbColor: {
      type: String,
      default: '#6666fe',
    },
    height: {
      type: String,
      default: '6px',
    },
    size: {
      type: String,
      default: '15px',
    },
  },
  computed: {
    vars() {
      const {color, activeColor, thumbColor, height: trackSize, size: thumbSize} = this

      return {
        '--color': color,
        '--activeColor': activeColor,
        '--thumbColor': thumbColor,
        '--trackSize': trackSize,
        '--thumbSize': thumbSize,
        '--progressPercent': '0%',
      }
    },
  },
  watch: {
    value(value) {
      this.onChange()
    },
  },
  mounted() {
    this.onChange()
  },
  methods: {
    onChange(event = {}) {
      const value = event.target ? event.target.value : this.value
      const percent = ((value - this.min) / (this.max - this.min)) * 100

      this.$refs.range.style.setProperty('--progressPercent', `${percent}%`)

      this.$emit('input', value)
    },
  },
}
</script>
<style lang="scss" scoped>
.input-range {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  &__append,
  &__prepend,
  &.disabled {
    @include unselectable;
  }

  &.disabled {
    opacity: 0.6;
  }
}

/* styles */
input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  flex: 1;
  height: var(--thumbSize);
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  /* Thumb */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbColor);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  &::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbColor);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  &::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbColor);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }

  /* Track */
  &::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background: linear-gradient(
      90deg,
      var(--activeColor) var(--progressPercent),
      var(--color) var(--progressPercent)
    );
    border-radius: calc(var(--trackSize) / 2);
  }
  &::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--color);
    border-radius: calc(var(--trackSize) / 2);
  }
  &::-ms-track {
    height: var(--trackSize);
    background-color: var(--color);
    border-radius: calc(var(--trackSize) / 2);
  }

  /* Progress */
  &::-moz-range-progress {
    height: var(--trackSize);
    background-color: var(--activeColor);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
  &::-ms-fill-lower {
    height: var(--trackSize);
    background-color: var(--activeColor);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
}
</style>
