<template>
  <textarea class="app-textarea-field" v-bind="$attrs" v-on="events"></textarea>
</template>

<script>
export default {
  props: {
    autoHeight: Boolean,
    maxHeight: {
      type: Number,
      default: 120,
    },
    minHeight: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    events() {
      return Object.assign({}, this.$listeners, {
        input: (event) => {
          this.calcHeight()
          this.$emit('input', event.target.value, event)
        },
        change: (event) => {
          this.$emit('change', event.target.value, event)
        },
      })
    },
  },
  mounted() {
    this.calcHeight()
  },
  methods: {
    calcHeight() {
      if (!this.autoHeight) return
      const el = this.$el

      if (el.value === '') this.setHeight(this.minHeight)
      if (parseInt(el.style.height, 10) >= this.maxHeight) return

      el.style.height = 'auto'
      el.style.height = el.scrollHeight + 'px'
    },
    setHeight(h) {
      const el = this.$el
      el.style.height = h + 'px'
    },
    resetHeight() {
      this.setHeight(this.minHeight)
    },
  },
}
</script>

<style lang="scss">
.app-textarea {
  &-field {
    position: relative;
    flex: 1;
    font-size: 16px;
    padding: 0;
    margin: 0;
    border: 0;
    resize: none;
    appearance: none;
    -webkit-appearance: none;
    &::placeholder {
      color: #8f9aac;
    }
  }
}
</style>
