<template>
  <Button
    ref="fileUploadBtn"
    tabindex="0"
    block
    class="file-upload-button"
    :icon="icon"
    tag="label"
    :for="id"
    inverted
    v-bind="$attrs"
  >
    <slot v-if="!file && label">
      {{ label }}
    </slot>

    <div v-if="file && clearable" class="has-file" @click.prevent.stop>
      <div v-if="showFilename" class="filename">{{ file.name }}</div>
      <Icon src="icons/close.svg" size="xs" @click.prevent.stop="clearFile" />
    </div>

    <input :id="id" :ref="id" :name="id" type="file" :accept="accept" @change="onUpload" />
  </Button>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: () => genId(),
    },
    icon: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '.png,.gif,.jpeg,.jpg',
    },
    label: {
      type: [String, Boolean],
      default() {
        return this.$t('forms.buttons.uploadFile')
      },
    },
    showFilename: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    file: null,
  }),
  methods: {
    onUpload(ev) {
      this.file = ev.target.files[0]
      this.$emit('input', this.file, ev)
      this.getDataUrl(this.file)
      this.$refs?.fileUploadBtn.$el.blur()
    },
    clearFile() {
      this.$refs[this.id].value = ''
      this.file = null
      this.getDataUrl(this.file)
      // this.$emit('input', this.file)
      this.$emit('clear', this.file)
    },
    getDataUrl(file) {
      if (!file) return this.$emit('dataUrl', '')
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        this.$emit('dataUrl', event.target.result)
      }
    },
  },
}

function genId() {
  return `mel-fileinput-${Math.random().toString(36).substring(2, 10)}`
}
</script>

<style lang="scss">
.file-upload-button {
  user-select: none;
  input[type='file'] {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: all;
    appearance: none;
  }

  .has-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    .filename {
      overflow: hidden;
      max-width: 95%;
      text-overflow: ellipsis;
      margin-right: 20px;
      white-space: nowrap;
    }
  }
}
</style>
