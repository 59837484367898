<template>
  <label class="slide-checkbox">
    <input
      :checked="isChecked"
      :value="isChecked"
      :disabled="disabled"
      type="checkbox"
      @change="onCheckboxInput"
    />
    <div class="slide-checkbox__track" :style="color ? 'background-color: ' + color : ''">
      <div v-if="!isSlider" class="slide-checkbox__circle"></div>
      <div v-else class="slide-checkbox__circle">xx</div>

      <div v-if="$slots.icon" class="slide-checkbox__icon">
        <slot name="icon"></slot>
      </div>
    </div>
    <span
      v-if="isStaticText"
      :class="{'slide-checkbox__label--lg': isHeaderToggle}"
      class="slide-checkbox__label"
    >
      <slot name="staticText">{{ staticText }}</slot>
    </span>
    <span
      v-else-if="isChecked"
      :class="{'slide-checkbox__label--lg': isHeaderToggle}"
      class="slide-checkbox__label"
    >
      {{ checkedText }}
    </span>
    <span
      v-else
      :class="{'slide-checkbox__label--lg': isHeaderToggle}"
      class="slide-checkbox__label"
    >
      {{ uncheckedText }}
    </span>

    <span v-if="$slots.tooltip" class="toggle-tooltip">
      <slot name="tooltip"></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    color: {type: [String, Boolean], default: false},
    disabled: Boolean,
    isSlider: Boolean,
    isChecked: [Boolean, Number],
    isHeaderToggle: Boolean,
    isStaticText: Boolean,
    staticText: {type: String, default: ''},
    checkedText: {type: String, default: ''},
    uncheckedText: {type: String, default: ''},
  },

  methods: {
    onCheckboxInput() {
      const p = !this.isChecked
      this.$emit('update:isChecked', p)
    },
  },
}
</script>

<style lang="scss">
.toggle-tooltip {
  display: inline-block;
  vertical-align: sub;
  margin: 7px 0 0 8px;
}

.slide-checkbox-wrapper {
  width: 50px;
  height: 30px;
  position: relative;
}

.slide-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  min-width: 50px;
  min-height: 30px;

  input {
    opacity: 0;
    position: absolute;

    &:checked {
      & + .slide-checkbox__track {
        background-color: $ui-toggle--active;

        .slide-checkbox {
          &__circle {
            transform: translate3d(20px, 0, 0);
            background-color: #fff;
          }
          &__icon {
            transform: translate3d(-22px, 0, 0);
          }
        }
      }
    }
  }

  &__track {
    position: absolute;
    left: 0;
    top: 0;
    height: 30px;
    width: 50px;
    border-radius: 20px;
    vertical-align: middle;
    background-color: $ui-toggle--inactive;
    transition: 0.3s all ease;
  }

  &__circle {
    float: left;
    height: 26px;
    width: 26px;
    border-radius: 18px;
    margin: 2px;
    background: #fff;
    transition: 0.3s all ease;
    pointer-events: none;
  }

  &__icon {
    height: 30px;
    line-height: 30px;
  }

  &__label {
    display: inline-block;
    vertical-align: top;
    padding-left: 65px;
    line-height: 30px;
    font-size: 16px;
    color: #2e3141;
  }

  &__label--lg {
    font-weight: 700;
    font-size: 30px;
  }

  &__tooltip {
    vertical-align: middle;
    margin-left: 24px;
  }

  @include media($tablet - 1) {
    .slide-checkbox__label--lg {
      font-size: 20px;
    }
  }
}
</style>
