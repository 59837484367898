import Vue from 'vue'
import debounce from '~/utils/debounce'

const breakpoints = [
  {min: 0, max: 479, alias: 'xs'},
  {min: 480, max: 767, alias: 'sm'},
  {min: 768, max: 1023, alias: 'md'},
  {min: 1024, max: 1439, alias: 'lg'},
  {min: 1440, max: 16000, alias: 'xl'},
]

function muiGetWidth() {
  if (self.innerWidth) {
    return self.innerWidth
  }

  if (document.documentElement && document.documentElement.clientWidth) {
    return document.documentElement.clientWidth
  }

  if (document.body) {
    return document.body.clientWidth
  }
}

Vue.mixin({
  data: () => ({
    muiBreakpoint: '',
  }),

  mounted() {
    const calcBreakpoint = (e) => {
      const w = muiGetWidth()
      this.muiBreakpoint = breakpoints.find((e) => w >= e.min && w <= e.max).alias
    }
    calcBreakpoint()

    const debounced = debounce(calcBreakpoint, 200)
    window.addEventListener('resize', debounced)

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    const sat = window.getComputedStyle(document.documentElement).getPropertyValue('--sat')
    document.documentElement.style.setProperty('--off-sat', sat)

    const onScroll = (sat) =>
      debounce((event) => {
        // const sat = 40

        if (window.pageYOffset > sat) {
          document.body.style.marginTop = 0
          document.documentElement.style.setProperty('--off-sat', `${0}px`)
        }
        if (window.pageYOffset < sat) {
          document.body.style.marginTop = sat + 'px'
          document.documentElement.style.setProperty('--off-sat', sat)
        }
      })

    window.addEventListener('scroll', onScroll(sat))
  },
})
