import Vue from 'vue'

import {Input, Button, Typography, Spinner} from '@/components/ui'
import Icon from '@/components/Icon/'
import BoxedIcon from '@/components/Icon/BoxedIcon'

const components = {
  BoxedIcon,
  Icon,
  Input,
  Button,
  Typography,
  Spinner,
}

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})
