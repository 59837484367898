<template>
  <div class="webinar-edit">
    <div @click="showEditPopup(webinar)">
      <slot name="activator"></slot>
    </div>

    <WebinarEditPopup
      :webinar="webinar"
      :poptitle="isEditMode ? $t('webinars.editWbr') : $t('webinars.create')"
      :isOpen="show"
      :isPublic="isPublic"
      :saveFn="webinarSaveFn"
      @close="onPopupClose"
    />
  </div>
</template>

<script>
import {mapActions} from 'vuex'

import {aliases} from '@/features/Webinars/model'
import {WebinarEditPopup} from '@/components/Popups'

export default {
  components: {
    WebinarEditPopup,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    create: Boolean,
  },
  data() {
    return {
      show: false,
      webinar: this.data,
      isEditMode: !this.create,
      isPublic: false,
    }
  },
  computed: {
    isPublicWebinar() {
      return this.webinar.access_level === aliases.webinarAccessAlias.public
    },
  },
  watch: {
    data(e) {
      this.webinar = e
    },
  },
  mounted() {
    if (!this.$slots.activator) {
      this.$nuxt.$on('webinar:create', () => {
        this.isEditMode = false
        this.showEditPopup()
      })

      this.$nuxt.$on('webinar:edit', (data) => {
        this.isEditMode = true
        this.showEditPopup(data)
      })
    }
  },
  destroyed() {
    this.$nuxt.$off('webinar:edit')
    this.$nuxt.$off('webinar:create')
  },
  methods: {
    ...mapActions({
      createWebinar: 'webinars/createWebinar',
      updateWebinar: 'webinars/updateWebinar',
    }),

    showEditPopup(data = {}) {
      this.webinar = data
      this.show = true
    },

    onPopupClose() {
      this.show = false
    },

    webinarSaveFn(data) {
      const hasPasswordForPublic = this.isEditMode && this.isPublicWebinar && data.is_password_set

      if (hasPasswordForPublic) {
        return this.$store.dispatch('SHOW_TOAST', {
          text: this.$t('webinars.cannotSetPwd'),
          type: 'danger',
        })
      }
      if (this.isEditMode) {
        return this.updateWebinar(data)
      } else {
        return this.createWebinar(data)
      }
    },
  },
}
</script>
