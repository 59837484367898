import {createModule, ADD_TOAST_MESSAGE} from 'vuex-toast'
import 'vuex-toast/dist/vuex-toast.css'

const toast = createModule({
  dismissInterval: 5000,
})

// fix errors then set true
export const strict = false

export const state = () => ({
  APP_BASEURL: '',
  ...toast.state,
})

export const getters = {
  ...toast.getters,
}

export const mutations = {
  ...toast.mutations,
}

let called = false
export const actions = {
  nuxtClientInit({state}, context) {
    let parts = location.host.split('.')
    let lastItem = parts[parts.length - 1]

    let suffixs = lastItem.split(':')

    if (state.user.user.is_stripe && suffixs[0] === 'ru') {
      suffixs[0] = 'com'
      if (suffixs[1]) parts[parts.length - 1] = suffixs.join(':')
      else parts[parts.length - 1] = suffixs[0]
    }

    state.APP_BASEURL = parts.join('.')
  },

  async nuxtServerInit({state, commit, dispatch, getters}, {app, req, route}) {
    // req.headers.host
    if (req && req.headers) state.APP_BASEURL = req.headers.host
    else state.APP_BASEURL = 'meleton.com'

    // because when nuxt runs 'generate' cmd, it calls this action, idk why
    if (process.env.NUXT_GENERATE) return

    // on spa mode call this only once, not on every route
    if (app.$isIos && called) return

    const token = app.$authUtils.getToken()
    if (token) {
      await dispatch('auth/SET_AUTH', {token, shouldVerify: true})
      await dispatch('user/INIT')
      called = true
    } else {
      await dispatch('auth/SET_AUTH', {token: undefined})
      called = true
    }
  },
  makeSocketConnection({commit, dispatch, getters}, {token} = {}) {
    if (process.server) return

    this.$echo.options.auth.headers.Authorization = 'Bearer ' + token
    this.$echo.connect()
  },
  sendFirstSell() {
    this.$gtm.push({
      event: 'kurs_prodan',
      kurs_prodan: true,
      'gtm.elementId': `first_course_sold_click`,
    })
    this.$yandexMetrika.reachGoal('kurs_prodan')
  },
  SHOW_TOAST({dispatch}, {text, type = 'success'}) {
    dispatch(ADD_TOAST_MESSAGE, {
      type,
      text,
      dismissAfter: 4000,
    })
  },
  ...toast.actions,
}
