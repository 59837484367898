<template>
  <div class="webinar-preview" :style="{background}">
    <div v-if="editable">
      <div class="buttons-group">
        <FileUploadButton
          v-if="editable"
          class="webinar-preview__edit"
          icon="icons/image.svg"
          :label="false"
          :show-filename="false"
          :clearable="false"
          v-on="$listeners"
          @dataUrl="onImageUpload"
        />
      </div>

      <ImageCropperModal
        v-if="showCropper"
        :title="cropperTitle"
        :imgSrc="uploadedImage"
        :rules="imageUploadRules"
        :viewMode="2"
        @cropped="savePreview"
        @close="showCropper = false"
      />
    </div>

    <div
      v-if="actualPreview"
      class="webinar-preview__image"
      :style="{'background-image': 'url(' + actualPreview + ')'}"
    ></div>

    <div v-else class="webinar-preview__default">
      <Icon src="/icons/video-ico.svg" :size="iconSize" />
    </div>

    <div v-if="$slots.backdrop" class="webinar-preview__backdrop flex-center">
      <slot name="backdrop" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {FileUploadButton} from '~ui'

export default {
  components: {
    FileUploadButton,
    ImageCropperModal: () => import('~ui').then((m) => m.ImageCropperModal),
  },
  props: {
    webinarId: {
      type: Number,
      default: undefined,
    },
    preview: {
      type: String,
      default: undefined,
    },
    editable: Boolean,
    background: {
      type: [String],
      default: 'linear-gradient(45deg, #d2e8ff, #ebe3ff)',
    },
    iconSize: {
      type: String,
      default: 'xl',
    },
  },
  data() {
    return {
      showCropper: false,
      cropperTitle: '',
      uploadedImage: null,
      imageUploadRules: {
        minWidth: 300,
        minHeight: 300,
      },
    }
  },
  computed: {
    ...mapGetters({
      previewImage: 'webinars/webinarPreview',
    }),
    actualPreview() {
      if (typeof this.preview === 'string') return this.preview
      return this.previewImage
    },
  },
  methods: {
    ...mapActions({
      updateWebinarPreview: 'webinars/updateWebinarPreview',
    }),
    onImageUpload(image) {
      if (!image || !image.length) return

      this.uploadedImage = image

      this.cropperTitle = this.$t('webinars.uploadPic')
      this.showCropper = true
    },
    async savePreview(imageFile) {
      await this.getPhotoPreview(imageFile)

      await this.updateWebinarPreview({
        id: this.webinarId,
        file: imageFile,
      })
    },
    getPhotoPreview(imageFile) {
      return new Promise((resolve) => {
        const reader = new FileReader()

        reader.onload = (event) => {
          this.uploadedImage = event.target.result
          this.showCropper = false

          resolve(event.target.result)
        }

        reader.readAsDataURL(imageFile)
      })
    },
  },
}
</script>

<style lang="scss">
.webinar-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 85px;

  button,
  label {
    z-index: 1;
  }

  &__image {
    @include coverdiv;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  @mixin rounded {
    width: 40px;
    height: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0;
  }

  .buttons-group {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;

    .app-btn {
      @include rounded;
      border: none;

      .icon {
        margin-right: 0 !important;
      }

      svg {
        fill: #cdd2db;
      }

      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }

  &__edit {
    .has-file {
      margin: 3px 0 0 1px;
    }
  }

  &__default {
    .app-icon {
      cursor: default;
      pointer-events: none;
      svg {
        fill: rgba(178, 185, 214, 0.4);
      }
    }
  }

  &__backdrop {
    @include coverdiv;
    background: rgba($black, 0.3);
  }
}
</style>
