<template>
  <PopupWrapper :title="$t('tariffs.setting')" v-bind="$attrs" @close="onClose">
    <TabSwitcher class="mb-6" :tabs="tabs" @tab:change="onChangeTab"></TabSwitcher>
    <div v-if="activeTab === 'main'" class="flex flex-col">
      <div ref="package-title-input">
        <Input
          v-model="packageTitle"
          :label="$t('common.name')"
          :error="$v.packageTitle.$error"
          :errorMessage="$t('forms.rules.requiredField')"
          :placeholder="$t('tariffs.enterName')"
        />
      </div>
      <ColorPicker v-model="selectedColor" class="sidebar-block widget" />

      <!-- {{ $store.state.user.user.is_legal_filled }}
      {{ $store.state.user.user.is_stripe }} -->
      <PriceToggler
        v-model="packagePrice"
        :disabled="!$store.state.user.user.is_legal_filled"
        :currency.sync="currentCurrency"
        :isPaid.sync="isPaid"
        :title="$t('tariffs.showPrice')"
      />
      <Typography
        v-if="!$store.state.user.user.is_legal_filled"
        variant="paragraph_s"
        color="red"
        class="mb-4"
        v-html="$t('payment.pnav')"
      >
      </Typography>

      <ModuleCardList
        :modulesList="modulesList"
        :defaultSelected="selectedModulesList"
        @selected="selectedModulesList = $event"
      />

      <Checkbox v-model="isHidden" class="mb-4" :label="$t('common.hidfrall')"></Checkbox>
    </div>
    <div v-if="activeTab === 'anal'" class="flex flex-col flex-1">
      <div class="paragraph-s mb-4">
        {{ $t('common.anpretxt') }} <br /><span class="font-mono text-purple-dark my-1">{{
          packageData.analytics_event_name
        }}</span
        ><br />
        {{ $t('common.anposttxt') }}
        <a class="text-purple hover:underline" href="#">{{ $t('common.ancnt1') }}</a>
        {{ $t('common.and') }}
        <a class="text-purple hover:underline" href="#">{{ $t('common.ancnt2') }}</a>
      </div>
      <Input v-model="ymId" :label="$t('common.ancntlab1')" placeholder="124131231"></Input>
      <Input v-model="gaId" :label="$t('common.ancntlab2')" placeholder="GTM-XXXXXXX"></Input>
    </div>

    <Button :loading="saveInProgress" @click="onSave">{{ $t('common.save') }}</Button>
  </PopupWrapper>
</template>

<script>
import {mapActions} from 'vuex'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, ColorPicker} from '@/components/ui'
import TabSwitcher from '~ui/TabSwitcher'
import Checkbox from '~ui/Input/Checkbox'

function getInitialData() {
  return {
    packageTitle: '',
    isHidden: false,
    packagePrice: '',
    currentCurrency: '',
    isPaid: false,
    selectedColor: '',
    selectedModulesList: [],
    saveInProgress: false,
    ymId: '',
    gaId: '',
    activeTab: 'main',
  }
}

export default {
  components: {
    ColorPicker,
    Checkbox,
    PriceToggler: () => import('../components/PriceToggler'),
    ModuleCardList: () => import('../components/ModuleCardList'),
    PopupWrapper,
    TabSwitcher,
  },
  validations: {
    packageTitle: {
      required,
    },
  },
  props: {
    packageData: {type: Object, default: () => ({})},
    modulesList: {type: Array, default: () => []},
    courseType: {type: String, default: ''},
  },
  data() {
    return {
      ...getInitialData(),
    }
  },
  computed: {
    tabs() {
      return [
        {
          title: this.$t('common.tilMain'),
          id: 'main',
        },
        {
          title: this.$t('common.tilAnal'),
          id: 'anal',
        },
      ]
    },
  },
  watch: {
    packageData(value) {
      if (!value?.id) {
        Object.assign(this.$data, getInitialData())
        return
      }

      this.initData()
    },
  },
  mounted() {
    console.log('refresh')
    this.fetchUser()
  },
  methods: {
    ...mapActions({
      createBundle: 'courseBundles/createBundle',
      updateBundle: 'courseBundles/updateBundle',
      callToast: 'SHOW_TOAST',
      fetchUser: 'user/FETCH',
    }),
    genParams() {
      const params = {
        title: this.packageTitle,
        is_visible: !this.isHidden,
        module_ids: this.selectedModulesList,
        is_free: !this.isPaid,
        is_active: true,
        color: this.selectedColor,
        google_id: this.gaId,
        yandex_id: this.ymId,
      }
      if (this.isPaid) {
        params.price = this.packagePrice
      }
      return params
    },
    onSave() {
      const type = this.courseType
      this.$v.$touch()

      if (this.$v.$invalid) {
        this.$refs[`package-title-input`].scrollIntoView({behavior: 'smooth'})
        return
      }

      if (!this.selectedColor) {
        return this.callToast({
          text: this.$t('popups.chooseColor'),
          type: 'danger',
        })
      }

      if (this.selectedModulesList.length < 1) {
        return this.callToast({
          text: this.$t('popups.chooseModule'),
          type: 'danger',
        })
      }

      const payload = {
        params: {...this.genParams(), ...{currency: {...this.currentCurrency}}},
        courseId: this.$route.params.courseId,
        packageId: this.packageData.id,
      }

      console.log('CURR', payload)

      this.saveInProgress = true
      if (type === 'new') {
        this.createPackage(payload)
      } else {
        this.updatePackage(payload)
      }
    },
    async updatePackage(params) {
      try {
        await this.updateBundle(params)
        this.saveInProgress = false
        this.onClose()
      } catch (err) {
        this.saveInProgress = false
      }
    },
    async createPackage(params) {
      try {
        await this.createBundle(params)
        this.saveInProgress = false
        this.onClose()
      } catch (err) {
        this.saveInProgress = false
      }
    },
    onClose() {
      Object.assign(this.$data, getInitialData())
      this.$emit('close')
    },
    initData() {
      if (!this.packageData) return
      this.packageTitle = this.packageData.title
      this.isHidden = !this.packageData.is_visible
      this.packagePrice = this.packageData.price
      this.currentCurrency = this.packageData.currency
        ? this.packageData.currency
        : this.currentCurrency
      this.selectedColor = this.packageData.color
      this.isPaid = !this.packageData.is_free
      this.gaId = this.packageData.google_id
      this.ymId = this.packageData.yandex_id

      this.packageData.modules.forEach(({id}) => {
        this.selectedModulesList.push(id)
      })
    },
    onChangeTab(tab) {
      this.activeTab = tab.id
    },
  },
}
</script>
