<template>
  <div class="webinar-guestroom">
    <div class="webinar-guestroom__top">
      <Typography variant="int_xl" class="webinar-guestroom__title">
        {{ webinar.name }}
      </Typography>

      <div class="webinar-guestroom__buttons buttons-group">
        <WebinarEdit v-if="!isPreviewMode" :data="webinar">
          <Button
            slot="activator"
            icon="icons/pencil.svg"
            :label="$t('course.edit')"
            inverted
            class="ml-2"
          />
        </WebinarEdit>

        <WebinarDelete
          v-if="!isPreviewMode"
          :data="webinar"
          @delete="$router.push({name: 'author.webinars'})"
        >
          <Button slot="activator" icon="icons/close.svg" :label="$t('course.delete')" inverted />
        </WebinarDelete>

        <Button
          :icon="isPreviewMode ? 'icons/eye-closed.svg' : 'icons/eye.svg'"
          :label="$t('webinars.preview')"
          inverted
          iconSize="m"
          @click="isPreviewMode = !isPreviewMode"
        />
      </div>

      <WebinarPreview
        class="webinar-guestroom__preview"
        :webinarId="webinar.id"
        :editable="!isPreviewMode"
      />

      <div class="webinar-guestroom__info">
        <WebinarInfo :data="webinar">
          <Button
            v-if="!webinarIsEnded"
            :label="$t('webinars.enter')"
            @click="$router.push({name: 'webinars.webinar-room', params: {uuid: webinarUrl}})"
          />
        </WebinarInfo>
      </div>

      <WebinarTimer class="webinar-guestroom__timer" />
    </div>

    <div class="webinar-guestroom__bottom">
      <WebinarSpeakers
        class="webinar-guestroom__speakers"
        :webinar="webinar"
        :editable="!isPreviewMode"
      />

      <WebinarParticipantsAccessBlock
        v-if="!isPreviewMode"
        class="webinar-guestroom__participants"
        :webinar="webinar"
      />

      <WebinarSocials
        class="webinar-guestroom__socials"
        :data="webinar"
        :editable="!isPreviewMode"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {uuid} from '@/features/Webinars/model'

import {
  WebinarInfo,
  WebinarTimer,
  WebinarPreview,
  WebinarSocials,
  WebinarSpeakers,
  WebinarParticipantsAccessBlock,
  WebinarEdit,
  WebinarDelete,
} from '@/features/Webinars/components'

export default {
  components: {
    WebinarInfo,
    WebinarTimer,
    WebinarPreview,
    WebinarSocials,
    WebinarSpeakers,
    WebinarParticipantsAccessBlock,

    WebinarEdit,
    WebinarDelete,
  },
  data() {
    return {
      isPreviewMode: false,
      webinarIsPending: false,
    }
  },
  computed: {
    ...mapGetters({
      webinar: 'webinars/webinar',
      webinarIsEnded: 'webinars/webinarIsEnded',
    }),
    webinarUrl() {
      return this.webinar.uuid + uuid.encode(this.webinar.id)
    },
  },
}
</script>

<style src="@/features/Webinars/styles/guest-room.scss" lang="scss"></style>
