<template>
  <div class="color-picker">
    <!-- <div v-if="$slots.title">
      <slot name="title" />
    </div>

    <div v-if="title && !$slots.title" class="sidebar-block__title">{{ title }}</div> -->

    <Typography variant="paragraph_s" color="grey" bottomGutter>{{ titleComputed }}</Typography>

    <ul class="color-list">
      <!-- preset list -->
      <li
        v-for="(color, key) in displayColors"
        :key="key"
        :class="{active: isActive(color)}"
        :style="{backgroundColor: color}"
        @click="onClick(color)"
      ></li>

      <!-- fallback -->
      <li
        v-if="showFallback"
        :class="{
          fallback: true,
          active: isActive(fallbackValue),
          filled: fallbackValue !== 'inherit',
        }"
        :style="{backgroundColor: fallbackValue}"
        @click="onClick(fallbackValue)"
      >
        <input v-model="fallbackValue" type="color" @change="$emit('input', fallbackValue)" />
      </li>
    </ul>

    <div v-if="$slots.error">
      <slot name="error" />
    </div>
  </div>
</template>

<script>
import presets from './presets'

export default {
  props: {
    value: null,
    colors: null,
    title: {
      type: [String, Boolean],
      default() {
        return this.titleComputed
      },
    },
    preset: {
      type: String,
      default: 'standard',
    },
    showFallback: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fallbackValue: 'inherit',
    }
  },
  computed: {
    titleComputed() {
      return this.$t('course.sidebar.color')
    },
    displayColors() {
      return this.colors || presets[this.preset]
    },
  },
  mounted() {
    /* assign an initial value if it is not listed */
    if (this.value && this.showFallback && !this.displayColors.find((c) => this.isActive(c))) {
      this.fallbackValue = this.value
    }
  },
  methods: {
    onClick(color) {
      if (!color || typeof color !== 'string') return

      this.$emit('input', color)
    },
    isActive(color) {
      if (!this.value || !color) return

      return color.toLowerCase() === this.value.toLowerCase()
    },
  },
}
</script>

<style lang="scss">
.color-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  padding: 0;
  list-style: none;

  li {
    cursor: pointer;
    margin: 4px;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    transition: 0.3s all ease;

    input {
      @include coverdiv;
      opacity: 0;
    }

    &::before {
      content: '';
      border-radius: 50%;
      opacity: 0;
      top: 50%;
      left: 50%;
    }

    &.active,
    &:hover {
      box-shadow: 0 5px 10px 0 rgba(147, 180, 213, 0.2);
      opacity: 1;
    }

    /* rounded outer */
    &:not(.fallback) {
      &::before {
        border: 1px solid;
        min-width: 33px;
        min-height: 33px;
        width: 34px;
        height: 34px;
        position: relative;
        margin: -17px 0 0 -17px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s all ease;
      }

      &.active,
      &:hover {
        &::before {
          opacity: 0.4;
        }
      }
    }

    /* rounded inner */
    &.fallback {
      &::before {
        border: 2.5px solid #fff;
        width: 10px;
        height: 10px;
        position: absolute;
        transform: translate(-50%, -50%);
        transition: 0.14s cubic-bezier(0.39, 0.575, 0.565, 1);
      }

      &:not(.filled) {
        background: linear-gradient(
          135deg,
          #ff0000 15.01%,
          #ff8b00 22.97%,
          #ffed00 27.63%,
          #17ff05 39.84%,
          #00fdff 50.52%,
          #050dff 60.66%,
          #ff00c2 75.01%,
          #ff0030 85%
        );
      }

      &::before {
        opacity: 1;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
