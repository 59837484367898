<template>
  <!-- select access type -->
  <div class="webinar-access-level">
    <Selector
      v-model="accessLevel"
      :options="accessLevels"
      open-direction="bottom"
      :allow-empty="false"
      @input="updateAccessLevel"
    />

    <ProductSelectPopup
      ref="productPopup"
      :title="$t('webinars.access')"
      :subtitle="$t('webinars.showStudents')"
      multiple
      :multipleCourses="false"
      :value="courseProducts"
      @save="saveProductList"
    >
      <Typography
        v-if="isProductLevel"
        slot="activator"
        variant="int_s"
        color="blue"
        class="pointer mt-2"
      >
        {{ $t('webinars.setUp') }}
      </Typography>
    </ProductSelectPopup>

    <CopyLink
      class="mt-1"
      :label="$t('webinars.linkForStudents')"
      :message="$t('webinars.linkCopied')"
      :value="inviteUrl"
      :show-button="true"
      selectable
    />
  </div>
</template>

<script>
import {Selector, CopyLink} from '@/components/ui'
import {ProductSelectPopup} from '@/components/Popups'

import {
  webinarAccessAlias,
  webinarAccessOptions as ACCESS_LEVELS,
} from '@/features/Webinars/model/aliases'

const {product: PRODUCT_LEVEL, public: PUBLIC_LEVEL} = webinarAccessAlias

export default {
  components: {
    CopyLink,
    Selector,
    ProductSelectPopup,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          title: this.$t('webinars.one'),
        }
      },
    },
    inviteUrl: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      webinar: this.data,
      accessLevel: null,
      courseProducts: null,
      ACCESS_LEVELS,
    }
  },

  computed: {
    accessLevels() {
      let levels = ACCESS_LEVELS
      levels.forEach((item) => {
        item.title = this.$t(item.title)
      })
      return levels
    },
    isProductLevel() {
      return this.accessLevel && this.accessLevel.value === PRODUCT_LEVEL
    },
  },

  mounted() {
    this.getAccessLevel()
    this.getProductList()
  },

  methods: {
    getAccessLevel() {
      const level = this.data.access_level
      const accessLevel = ACCESS_LEVELS.find((e) => e.value === level) || ACCESS_LEVELS[0]

      this.accessLevel = accessLevel
    },

    async getProductList() {
      const {course_id, product_ids} = this.webinar.access_settings

      if (!product_ids.length) return {}

      try {
        const url = `/author/courses/${course_id}/products-list`

        const {modules, packs} = await this.$axios.$get(url)
        const products = [...modules, ...packs]

        this.courseProducts = products.filter((e) => product_ids.includes(e.id))
      } catch {}
    },

    saveProductList(selected, coursesProducts) {
      if (selected) {
        this.courseProducts = selected
      }

      const course_id = Object.keys(coursesProducts)[0]
      const product_ids = coursesProducts[course_id].map((e) => e.id)

      this.updateAccessLevel(PRODUCT_LEVEL, {course_id, product_ids})
    },

    updateAccessLevel(level, {course_id, product_ids} = {}) {
      const {id} = this.webinar

      const access_level = (level && level.value) || level // optional chaining
      const params = {id, access_level}

      if (!access_level) return

      if (access_level === PRODUCT_LEVEL) {
        if (!course_id) {
          course_id = this.webinar.access_settings.course_id
          product_ids = this.webinar.access_settings.product_ids

          if (!course_id) return
        }

        params.access_settings = {
          course_id,
          product_ids,
        }
      }

      /* reset password after public access type selected */
      if (access_level === PUBLIC_LEVEL) {
        params.is_password_set = false
      }

      this.$emit('update', params)
    },
  },
}
</script>
