import {reject, propEq} from 'ramda'

export const state = () => ({
  emails: [],
  token: '',
  placeholders: [],
})

export const getters = {
  token: (state) => state.token,
  emails: (state) => state.emails,
  email: (state) => (id) => state.emails.find((v) => v.id === Number(id)),
  placeholders: (state) => state.placeholders,
}

export const mutations = {
  SET(state, emails) {
    state.emails = emails
  },
  APPEND(state, email) {
    state.emails = [...state.emails, email]
  },
  SET_STRIPO_TOKEN(state, t) {
    state.token = t
  },
  SET_PLACEHOLDERS(state, p) {
    state.placeholders = p
  },
}

export const actions = {
  async GET_PLACEHOLDERS({commit}) {
    try {
      const data = await this.$axios.$get('/stripo-email/placeholder/list')
      commit('SET_PLACEHOLDERS', data)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async GET({commit}) {
    const emails = await this.$axios.$get('/stripo-email/list')
    commit('SET', emails)

    return emails
  },
  async GET_BY_ID({commit}, {id}) {
    const email = await this.$axios.$get(`/stripo-email/${id}`)
    commit('APPEND', email)
    return email || {}
  },
  async CREATE({commit}, params) {
    const email = await this.$axios.$post('/stripo-email', params)
    commit('APPEND', email)
    return email || {}
  },
  async GET_STRIPO_TOKEN({commit}) {
    const token = await this.$axios.$post('/stripo-email/auth')
    commit('SET_STRIPO_TOKEN', token)
    return token
  },
  async UPDATE_EMAIL({dispatch, commit, state}, {params = {}, id}) {
    const email = await this.$axios.$patch(`/stripo-email/${id}`, params)
    dispatch('SHOW_TOAST', {text: 'Шаблон письма успешно сохранен!'}, {root: true})
    const updated = state.emails.map((v) => (v.id === email.id ? email : v))
    commit('SET', updated)
  },
  async DELETE({commit, state}, {id}) {
    await this.$axios.$delete(`/stripo-email/${id}`)
    commit('SET', reject(propEq('id', id))(state.emails))
  },
}
