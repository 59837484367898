<template>
  <div class="speaker-item" :class="{create}" @click="onCardClick">
    <div v-if="create" class="flex items-center justify-start">
      <Button
        size="50"
        rounded
        icon="/icons/plus-in-circle.svg"
        iconSize="m"
        custom
        :styles="{
          iconColor: '#96a2b4',
          backgroundColor: '#fff',
          iconHoverColor: '#fff',
          backgroundHoverColor: '#6666fe',
        }"
      />
      <Typography variant="int_s" color="grey" class="ml-1">{{
        $t('webinars.speakers.add')
      }}</Typography>
    </div>

    <div v-else class="flex items-center justify-between">
      <UserGroupAvatar :user="speaker" size="s">
        <span slot="description">{{ speaker.description }}</span>
      </UserGroupAvatar>

      <DropdownMenu v-if="editable" @edit="$emit('edit')" @delete="$emit('delete')">
        <Button
          size="40"
          rounded
          icon="/icons/icon-cog.svg"
          iconSize="s"
          custom
          :styles="{
            iconColor: '#96a2b4',
            backgroundColor: '#f2f5f9',
            iconHoverColor: '#fff',
            backgroundHoverColor: '#6666fe',
          }"
        />
      </DropdownMenu>
    </div>
  </div>
</template>

<script>
import {DropdownMenu} from '@/components/ui'
import {UserGroupAvatar} from '@/components/UserGroupList'

export default {
  components: {
    DropdownMenu,
    UserGroupAvatar,
  },
  props: {
    speaker: {
      type: Object,
      default: () => ({}),
    },
    create: Boolean,
    editable: Boolean,
  },
  methods: {
    onCardClick() {
      if (this.create) {
        this.$emit('speaker:create')
      }
    },
  },
}
</script>

<style lang="scss">
.speaker-item {
  padding: 20px;
  border-radius: 16px;
  border: 1px solid $lightGrey;

  &.create {
    cursor: pointer;
    background: $lightGrey;

    &:hover {
      .app-btn {
        background: #6666fe;
        svg {
          fill: #fff;
        }
      }
    }
  }
}
</style>
