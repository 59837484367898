<template>
  <div
    class="w-full py-2 flex items-center justify-between border-b border-solid border-grey-100"
    @click="$emit('user:click', user)"
  >
    <UserGroupAvatar class="mr-2 w-48" :user="user" />

    <!-- user-list-tile role slot -->
    <div v-if="$slots.role" class="user-group-tile__role flex-1">
      <slot name="role"></slot>
    </div>

    <div v-else class="user-group-tile__role flex-1 px-4">
      <Typography variant="int_s" color="grey">{{ role || user.role }}</Typography>
    </div>

    <Icon
      v-if="icon"
      :src="icon"
      :size="iconSize"
      @click.native.stop="$emit('user:action', user)"
    ></Icon>
  </div>
</template>

<script>
import UserGroupAvatar from './UserGroupAvatar'

export default {
  components: {
    UserGroupAvatar,
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        avatar: '',
        full_name: '',
        email: '',
        role: '',
      }),
    },
    role: {
      type: [String, Boolean],
      default: false,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    iconSize: {
      type: String,
      default: 's',
    },
  },
}
</script>
