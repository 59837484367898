const SET_LOADING = 'SET_LOADING'
const SET_PROGRESS = 'SET_PROGRESS'
const SET = 'SET_MESSAGES'
const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST'
const INSERT = 'INSERT_MESSAGE'
const RESET_MESSAGES = 'RESET_MESSAGES'
const DELETE_MESSAGE = 'DELETE_MESSAGE'
const EDIT_MESSAGE = 'EDIT_MESSAGE'
const SET_ONLINE_USERS = 'SET_ONLINE_USERS'
const ADD_JOINED_USER = 'ADD_JOINED_USER'
const REMOVE_LEFT_USER = 'REMOVE_LEFT_USER'

export const mutationTypes = {
  SET_LOADING,
  SET_PROGRESS,
  SET,
  UPDATE_MESSAGE_LIST,
  INSERT,
  RESET_MESSAGES,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  SET_ONLINE_USERS,
  ADD_JOINED_USER,
  REMOVE_LEFT_USER,
}

export const actionTypes = {
  POST_MESSAGE: 'POST_MESSAGE',
  RESET_CHAT: 'RESET_CHAT',
  FETCH_HOMEWORK_MESSAGES: 'FETCH_HOMEWORK_MESSAGES',
  FETCH_WEBINAR_MESSAGES: 'FETCH_WEBINAR_MESSAGES',
  FETCH_MORE_MESSAGES: 'FETCH_MORE_MESSAGES',
  SEND_MESSAGE: 'SEND_MESSAGE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  UPDATE_HOMEWORK_MESSAGE: 'UPDATE_HOMEWORK_MESSAGE',
  INSERT_MESSAGE: 'INSERT_MESSAGE',
  ATTACH_FILE_TO_MESSAGE: 'ATTACH_FILE_TO_MESSAGE',
  FETCH_STUDENT_PROGRESS: 'FETCH_STUDENT_PROGRESS',
}
