import {flip, prop, path, includes, not, unless, when, isNil, pipe} from 'ramda'

const includesFlipped = flip(includes)
const getRole = (user) => prop('current_role', user)
const getRouteRoles = (route) => path(['meta', '0', 'roles'], route)

export default function ({route, store, redirect}) {
  const getUser = () => store.getters['user/getUser']
  const redirectMain = () => redirect('/')

  const userWithoutAccess = (roles) => pipe(getRole, includesFlipped(roles), not)(getUser())

  const passOrRedirect = when(userWithoutAccess, redirectMain)
  const checkRoles = unless(isNil, passOrRedirect)

  checkRoles(getRouteRoles(route))

  return true
}
