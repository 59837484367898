<template>
  <PopupWrapper
    v-if="form"
    :title="poptitle"
    v-bind="$attrs"
    class="webinar-popup"
    @close="onClose"
  >
    <div class="webinar-popup__form">
      <!-- title -->
      <Input
        v-model="form.name"
        :label="$t('common.name')"
        :placeholder="$t('webinars.webinarName')"
        :error="$v.form.name.$error"
        :errorMessage="$t('forms.rules.requiredField')"
        flat
      />

      <!-- description -->
      <TextArea
        v-model="form.description"
        auto-height
        rows="1"
        variant="flat"
        :error="$v.form.description.$error"
        :error-message="descriptionError"
        :placeholder="$t('webinars.webinarDesc')"
      >
        {{ $t('webinars.description') }}
      </TextArea>

      <!-- date -->

      <!--
        :disabled-date="notBeforeToday"
        :disabled-time="(e) => e < new Date()"
        :minute-options="Array.from({length: 60 / 5}).map((_, i) => i * 5)"
       -->
      <Datepicker
        v-model="date"
        class="webinar-datepicker webinar-datepicker--date"
        popup-class="webinar-datepicker__popup"
        type="datetime"
        :show-second="false"
        value-type="timestamp"
        :default-value="tomorrow"
        :clearable="false"
        :append-to-body="false"
        :disabled-date="notBeforeToday"
      >
        <template #input>
          <Input
            :value="displayDate"
            :label="$t('webinars.time')"
            :error="$v.displayDate.$error"
            :errorMessage="$t('forms.rules.requiredField')"
            readonly
            flat
          >
            <Icon slot="icon" src="icons/input-date.svg" size="s" />
          </Input>
        </template>

        <template #header="{emit}">
          <button class="mx-btn mx-btn-text" @click="emit(tomorrow)">
            {{ $t('common.tomorrow') }}
          </button>
        </template>
      </Datepicker>

      <!-- duration -->
      <Datepicker
        v-model="duration"
        class="webinar-datepicker webinar-datepicker--duration"
        popup-class="webinar-datepicker__popup"
        type="time"
        format="HH:mm"
        value-type="format"
        :clearable="false"
        :append-to-body="false"
        :minute-options="[0, 30]"
      >
        <template #input>
          <Input
            :value="displayDuration"
            :label="$t('webinars.length') + ':'"
            :error="$v.displayDuration.$error"
            :errorMessage="$t('forms.rules.requiredField')"
            readonly
            flat
          >
            <Icon slot="icon" src="icons/clock.svg" size="s" />
            <Icon slot="append" src="icons/arrow-bottom.svg" size="s" />
          </Input>
        </template>
      </Datepicker>

      <!-- password -->
      <div class="input-group">
        <Toggle
          :is-checked="form.is_password_set"
          is-static-text
          :static-text="$t('webinars.setPwd')"
          @update:isChecked="form.is_password_set = !form.is_password_set"
        />

        <Input
          v-if="form.is_password_set"
          v-model="form.password"
          :error="$v.form.password.$error"
          :errorMessage="$t('forms.rules.requiredField')"
          :label="$t('webinars.showPsw')"
          class="mt-1"
        >
          <Icon slot="icon" src="icons/input-password.svg" size="s" />
        </Input>
      </div>
    </div>

    <Button
      block
      class="mt-auto"
      :icon="isEditMode ? '' : '/icons/plus-in-circle.svg'"
      :loading="loading"
      @click="submit"
    >
      {{ isEditMode ? $t('common.save') : poptitle }}
    </Button>
  </PopupWrapper>
</template>

<script>
import {formatters} from '@/utils/date'
import {set, sub, format, fromUnixTime, addHours, getMinutes, getHours, getSeconds} from 'date-fns'
import {validationMixin} from 'vuelidate'
import {required, maxLength} from 'vuelidate/lib/validators'

import {PopupWrapper, TextArea, Toggle, Datepicker} from '@/components/ui'
const localeData = require('date-fns/locale')

const DESC_MAX_LENGTH = 255

export default {
  components: {
    Datepicker,
    PopupWrapper,
    TextArea,
    Toggle,
  },
  mixins: [validationMixin],
  props: {
    poptitle: {
      type: String,
      default() {
        return this.$t('webinars.create')
      },
    },
    webinar: {
      type: [Object, Boolean],
      default: false,
    },
    saveFn: {
      type: Function,
      default: () => Promise.resolve(),
    },
  },
  validations() {
    const form = {
      name: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(DESC_MAX_LENGTH),
      },
    }

    if (this.form.is_password_set) {
      Object.assign(form, {password: {required}})
    }

    return {
      form,
      displayDate: {required},
      displayDuration: {required},
    }
  },
  data() {
    return {
      form: {},
      loading: false,
    }
  },
  computed: {
    isEditMode() {
      /* ниже генерится пароля для бекенда, так что по кол-ву ключей проверку делать нельзя */
      return Boolean(this.webinar && this.webinar.name)
    },
    tomorrow() {
      const today = new Date()
      const tomorrow = new Date(today)

      tomorrow.setDate(tomorrow.getDate() + 1)
      tomorrow.setHours(12, 0, 0, 0)

      return tomorrow
    },
    displayDate() {
      if (!this.form) return
      // console.log(formatters.when(this.date))
      // console.log(fromUnixTime(this.date))

      const part1 = format(fromUnixTime(this.date / 1000), 'dd MMMM', {
        locale: localeData[this.$i18n.locale],
      })
      const part2 = format(fromUnixTime(this.date / 1000), 'p', {
        locale: localeData[this.$i18n.locale],
      })

      return part1 + ' ' + this.$t('common.in') + ' ' + part2

      // return formatters.when(this.date)
    },
    displayDuration() {
      if (!this.form) return

      return this.convertToDuration(this.form.duration)
    },
    duration: {
      get() {
        let [h, m] = String(this.form.duration).split(':')

        if (typeof this.form.duration === 'number') {
          ;[h, m] = formatters.duration(this.form.duration, true)
        }

        h = ('0' + h).slice(-2)
        m = ('0' + m).slice(-2)

        return `${h}:${m}`
      },
      set(e) {
        const [hours, minutes] = String(e).split(':')
        this.form.duration = hours * 60 + minutes * 1
      },
    },
    date: {
      get() {
        return this.form.start_date * 1000
      },
      set(e) {
        this.form.start_date = Math.round(e / 1000)
      },
    },
    descriptionError() {
      if (!this.$v.form.description.$dirty) return

      if (!this.$v.form.description.maxLength) {
        return `${this.$t('webinars.max')} ${DESC_MAX_LENGTH} ${this.$t('webinars.symbols')}`
      }

      return this.$t('forms.rules.requiredField')
    },
  },
  watch: {
    webinar() {
      this.reset()
    },
  },
  mounted() {
    this.reset()
  },
  methods: {
    convertToDuration(secondsAmount) {
      const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

      const SECONDS_TO_MILLISECONDS_COEFF = 1000
      const MINUTES_IN_HOUR = 60

      const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF

      const date = new Date(milliseconds)
      const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR
      const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

      const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
      const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
      const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

      const hoursOutput = hours !== '00' ? `${hours}:` : ''

      return `${hoursOutput}${minutes}:${seconds}`
    },
    notBeforeToday(d) {
      const dayBefore = sub(set(new Date(), {hours: 0, minutes: 0, seconds: 0}), {days: 1})
      return d <= dayBefore
    },
    onClose() {
      this.$emit('close')
      this.reset()
    },
    reset() {
      const defaultDate = this.tomorrow / 1000

      this.form = this.isEditMode
        ? {
            id: this.webinar.id,
            name: this.webinar.name,
            description: this.webinar.description,
            is_password_set: this.webinar.is_password_set,
            password: this.webinar.password,
            start_date: this.webinar.start_date,
            duration: this.webinar.duration,
          }
        : {
            name: null,
            description: null,
            is_password_set: false,
            password: '',
            start_date: defaultDate,
            duration: 90,
          }

      this.$v.$reset()
    },
    async submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true

      try {
        const data = await this.saveFn(this.form)

        if (data) {
          this.onClose()
        }
      } catch {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.webinar-popup {
  .mx-btn:disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  &__select {
    margin-top: 16px;

    .app-selector2--error_container {
      margin-top: 3px !important; // in accordance to default input styles
    }
  }

  .input-group {
    margin-bottom: 28px;

    .app-input__wrap {
      margin-bottom: 0;
    }
  }
}

.webinar-datepicker {
  .mx-icon-calendar {
    display: none;
  }

  &__popup {
    top: 80px !important; // input height
    left: 0 !important;
  }

  &--date {
    width: 100% !important;
  }
}
</style>
