import {append, map, filter, propEq} from 'ramda'
import {updateObjectByKey, removeObjectByKey} from '@/utils'

const mapTelegramData = map((x) => {
  return {
    title: x.user_name,
    description: x.description,
    ...x,
  }
})
const mapVkData = map((x) => {
  return {
    title: x.name,
    link: x.url,
    img: x.image_url,
    users: x.members_count,
    ...x,
  }
})

export const state = () => ({
  tgList: [],
  vkList: [],
  vkImporting: false,
  loading: false,
})

export const getters = {
  tgList: (state) => mapTelegramData(state.tgList) || [],
  vkList: (state) => mapVkData(state.vkList) || [],
  vkListOnlyActive: (state) => filter(propEq('is_active', true), mapVkData(state.vkList)),
  isLoading: (state) => state.loading,
  vkImporting: (state) => state.vkImporting,
}

export const mutations = {
  SET_TG_LIST(state, xs) {
    state.tgList = xs
  },
  SET_VK_LIST(state, xs) {
    state.vkList = xs
  },
  SET_LOADING(state, loading) {
    state.loading = loading
  },
  SET_IMPORTING(state, imp) {
    state.vkImporting = imp
  },
}

export const actions = {
  async GET_ACCESS_TO_GROUP({commit, dispatch}, {groupId}) {
    try {
      const data = await this.$axios.$get(`/author/vk-group/${groupId}/get-confirmation-url`)

      window.location.href = data.confirmation_url
    } catch (err) {}
  },
  async IMPORT_VK_GROUPS({commit}) {
    try {
      await this.$axios.$post('/author/vk-group/run-import')
      commit('SET_IMPORTING', true)
    } catch (err) {}
  },
  async GET_VK_LIST({commit}) {
    commit('SET_LOADING', true)
    try {
      const data = await this.$axios.$get('/author/vk-group/list')
      commit('SET_VK_LIST', data)
      commit('SET_IMPORTING', false)
      return data
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async DELETE_VK_GROUP({commit, state}, {groupId}) {
    const data = await this.$axios.$delete(`/author/vk-group/${groupId}`)

    commit('SET_VK_LIST', removeObjectByKey('id', groupId, state.vkList))
    return data
  },
  async TOGGLE_VK_GROUP({commit, state}, {groupId}) {
    const data = await this.$axios.$post(`/author/vk-group/${groupId}/toggle-deactivate`)

    commit('SET_VK_LIST', updateObjectByKey('id', data, state.vkList))
    return data
  },
  async GET_TG_LIST({commit}) {
    commit('SET_LOADING', true)
    try {
      const data = await this.$axios.$get('/author/telegram-bot/list')
      commit('SET_TG_LIST', data)
      return data
    } catch (err) {
      console.log(err)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async CREATE_TG_BOT({commit, state}, {params}) {
    try {
      const data = await this.$axios.$post('/author/telegram-bot', params)

      commit('SET_TG_LIST', append(data, state.tgList))
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async UPDATE_TG_BOT({commit, state}, {params, id}) {
    try {
      const data = await this.$axios.$patch(`/author/telegram-bot/${id}`, params)

      commit('SET_TG_LIST', updateObjectByKey('id', data, state.tgList))
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async DELETE_TG_BOT({commit, state}, {params, id}) {
    const data = await this.$axios.$delete(`/author/telegram-bot/${id}`)

    commit('SET_TG_LIST', removeObjectByKey('id', id, state.tgList))
    return data
  },
}
