import {ROLES} from '../consts'

const PageWebinars = () =>
  import(/* webpackChunkName: "PageWebinars" */ '@/pages/webinars').then((m) => m.default || m)
const PageWebinarRoom = () =>
  import(/* webpackChunkName: "PageWebinarRoom" */ '@/pages/webinars/webinar-room').then(
    (m) => m.default || m
  )
const PageWebinarsGuestRoom = () =>
  import(/* webpackChunkName: "PageWebinarsGuestRoom" */ '@/pages/webinars/guest-room').then(
    (m) => m.default || m
  )
const PageWebinarInvite = () =>
  import(/* webpackChunkName: "PageWebinarInvite" */ '@/pages/webinars/invite').then(
    (m) => m.default || m
  )

const PageAuthorWebinarList = () =>
  import(/* webpackChunkName: "PageAuthorWebinarList" */ '@/pages/author/webinars/').then(
    (m) => m.default || m
  )
const PageAuthorWebinarGuestRoom = () =>
  import(
    /* webpackChunkName: "PageAuthorWebinarGuestRoom" */ '@/pages/author/webinars/guest-room'
  ).then((m) => m.default || m)

export const webinarsRoutes = [
  /* Список вебинаров */
  {
    path: '/webinars',
    component: PageWebinars,
    name: 'webinars',
    meta: {
      requiresAuth: false,
      title: 'webinars.title',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },

  /* Вебинарная комната */
  {
    path: '/webinars/:uuid',
    component: PageWebinarRoom,
    name: 'webinars.webinar-room',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author, ROLES.student],
      title: 'page.webinarRoom',
      breadcrumbs: [
        {
          title: 'webinars.title',
          name: 'webinars',
        },
      ],
    },
  },

  /* Гостевая */
  {
    path: '/webinars/guest-room/:id',
    component: PageWebinarsGuestRoom,
    name: 'webinars.guest-room',
    meta: {
      title: 'page.webinarGuest',
      breadcrumbs: [
        {
          title: 'webinars.title',
          name: 'webinars',
        },
      ],
    },
  },

  /* Редирект-страниа для вебинарa с типом доступа "по ссылке" */
  {
    path: '/webinars/invite/:uuid',
    component: PageWebinarInvite,
    meta: {
      title: 'page.webinarInvite',
      breadcrumbs: [
        {
          title: 'webinars.title',
          name: 'webinars',
        },
      ],
    },
  },

  /* Автор - Список вебинаров */
  {
    path: '/author/webinars',
    component: PageAuthorWebinarList,
    name: 'author.webinars',
    meta: {
      roles: [ROLES.author],
      requiresAuth: true,
      title: 'webinars.title',
      breadcrumbs: [
        {
          title: 'page.main',
          name: 'main',
        },
      ],
    },
  },

  /* Автор - Гостевая */
  {
    path: '/author/webinars/guest-room/:id',
    component: PageAuthorWebinarGuestRoom,
    name: 'author.webinars.guest-room',
    meta: {
      requiresAuth: true,
      roles: [ROLES.author],
      title: 'page.webinarGuest',
      breadcrumbs: [
        {
          title: 'webinars.title',
          name: 'author.webinars',
        },
      ],
    },
  },
]
