<template>
  <div class="social-links-popup-wrapper">
    <div v-if="$slots.activator" @click="showPopup">
      <slot name="activator"></slot>
    </div>

    <PopupWrapper :title="poptitle" :isOpen="show" v-bind="$attrs" @close="onClose">
      <Input
        v-for="(social, name) in fullList"
        :key="name"
        :value="social.link"
        :placeholder="$t('webinars.showLinkTo') + ' ' + $t(social.plh)"
        :error="$v.editedList[name] && $v.editedList[name].$error"
        :error-message="$t('webinars.linkInFormat') + ' https://example.com/*'"
        @input="(e) => onInput(name, e)"
      >
        <Icon slot="icon" :src="social.icon" />
      </Input>

      <Button class="mt-auto" :loading="loading" @click="onSave">{{ $t('common.save') }}</Button>
    </PopupWrapper>
  </div>
</template>

<script>
import {url} from 'vuelidate/lib/validators'
import {validationMixin} from 'vuelidate'
const fullList = {
  vk: {
    link: '',
    icon: '/icons/social/vk.svg',
    plh: 'webinars.socNet.vk',
  },
  telegram: {
    link: '',
    icon: '/icons/social/telegram.svg',
    plh: 'webinars.socNet.telegram',
  },
  /* facebook: {
    link: '',
    icon: '/icons/social/facebook.svg',
    plh: 'webinars.socNet.facebook',
  },
  instagram: {
    link: '',
    icon: '/icons/social/instagram-logo.svg',
    plh: 'webinars.socNet.instagram',
  }, */
  youtube: {
    link: '',
    icon: '/icons/social/youtube.svg',
    plh: 'webinars.socNet.youtube',
  },
}
export default {
  components: {
    PopupWrapper: () => import('@/components/ui/PopupWrapper'),
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: Object,
      default: () => ({
        vk: '',
        telegram: '',
        facebook: '',
        instagram: '',
        youtube: '',
      }),
    },
    poptitle: {
      type: String,
      default() {
        return this.$t('webinars.socNet.title')
      },
    },
    isOpen: Boolean,
  },
  validations() {
    const editedList = {}

    Object.keys(this.editedList).forEach((social) => {
      editedList[social] = {url}
    })

    return {
      editedList,
    }
  },
  data() {
    return {
      passedList: {},
      show: this.isOpen,
      loading: false,
      fullList,
    }
  },
  computed: {
    editedList() {
      const list = {}

      Object.keys(this.fullList).forEach((social) => {
        const {link} = this.fullList[social]

        if (link.length) {
          list[social] = link
        }
      })

      return list
    },
  },
  watch: {
    data: {
      handler(data) {
        if (!Object.keys(data).length) return

        this.merge(data)
      },
      deep: true,
    },
  },
  methods: {
    merge(data) {
      Object.keys(data).forEach((social) => {
        if (!fullList[social]) return

        const link = data[social]
        const {icon, plh} = fullList[social]

        this.passedList[social] = {
          link,
          icon,
          plh,
        }
      })

      this.fullList = {...this.fullList, ...this.passedList}
    },

    onInput(social, value) {
      this.merge({[social]: value})
    },

    showPopup() {
      this.merge(this.data)
      this.show = true
    },

    onClose() {
      this.passedList = {}
      this.show = false

      this.$emit('close')
    },

    async onSave() {
      this.$v.$touch()

      if (this.$v.$invalid) return

      this.loading = true

      try {
        // this.$emit('save', this.edited)
        if (this.$listeners.save) {
          await this.$listeners.save(this.editedList)
        }
      } catch (err) {
      } finally {
        this.loading = false
        this.onClose()
      }
    },
  },
}
</script>
