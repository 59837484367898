const standard = [
  '#AEB0B9',
  '#D99C9C',
  '#D9A9C4',
  '#C4AED1',
  '#A5AAD1',
  '#93B4D5',
  '#96BEC7',
  '#93C6C1',
  '#ABBC94',
  '#C4CB86',
  '#E0C194',
  '#E39D87',
  '#94AFBC',
  '#B6BABE',
  '#A1AEC6',
  '#BFA392',
]

const mini = [
  '#F2F5F9',
  '#4B4F63',
  '#96A2B4',
  '#959BCB',
  '#84ACD5',
  '#7ABFD3',
  '#ABBC94',
  '#CBAA88',
  '#DBB987',
  '#EA9CBF',
]

export default {standard, mini}
