<template>
  <div class="webinar-info">
    <div class="webinar-info__date">
      <WebinarDate
        :date="data.start_date"
        :duration="data.duration"
        :duration-prefix="$t('webinars.length') + ' ~ '"
        large
      />
    </div>
    <div class="webinar-info__content">
      <Typography variant="int_xs" color="grey">{{ $t('webinars.description') }}</Typography>

      <Typography variant="paragraph_m" class="description mt-2">
        {{ data.description }}
      </Typography>

      <slot />
    </div>
  </div>
</template>

<script>
import {WebinarDate} from '../atoms'

export default {
  components: {
    WebinarDate,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="scss">
.webinar-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid $lightGrey;

  &__date,
  &__content {
    padding: 16px;
  }

  &__date {
    border-bottom: 1px solid $lightGrey;
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @include mobile-small {
      align-items: center;
    }

    p {
      overflow-wrap: anywhere;
    }
  }

  button {
    margin-top: 24px;

    &:not(.app-btn__block) {
      width: 170px;
    }
  }
}
</style>
