<template>
  <div v-if="isLoaded" class="timer">
    <Typography v-if="timerTitle" variant="int_m" :color="color" v-html="timerTitle" />

    <ul v-if="showExpired" class="timer__countdown">
      <li v-for="(d, i) in parts" :key="i">
        <span class="bold">{{ d.value }}</span>
        <span>{{ d.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import {difference, localeRu} from '@/utils/date'
import wordsDeclension from '@/utils/wordsDeclension'
import ru from 'date-fns/locale/ru'
import {formatRelative, addSeconds, isPast} from 'date-fns'

let interval = 0

export default {
  props: {
    date: {
      type: [Number, String, Date],
      default: '',
    },
    color: {
      type: String,
      default: 'grey',
    },
    title: {
      type: [String, Boolean],
      default() {
        return this.$t('webinars.remainToStart')
      },
    },
    expiredTitle: {
      type: [String, Boolean],
      default: false,
    },
    showExpired: {
      type: Boolean,
      default: true,
    },
    isSeconds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parts: {},
      isLoaded: false,
      expired: true,
      displayDate: null,
    }
  },
  computed: {
    timerTitle() {
      return this.expired && this.expiredTitle ? this.expiredTitle : this.title
    },
  },
  watch: {
    date(e) {
      this.setDisplayDate()

      this.expired = isPast(this.displayDate)

      this.run()
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoaded = true
      this.setDisplayDate()

      this.run()
    })
  },
  beforeDestroy() {
    clearInterval(interval)
  },
  methods: {
    setDisplayDate() {
      this.displayDate = this.isSeconds ? addSeconds(new Date(), this.date) : this.date
    },
    run() {
      if (interval) clearInterval(interval)
      interval = setInterval(this.countdown, 1000)

      if (this.expired && this.showExpired) {
        this.countdown()
      }
    },
    check() {
      this.expired = isPast(this.displayDate)

      if (this.expired) {
        clearInterval(interval)
        /* убрать, чтобы видеть негативную разницу */
        Object.keys(this.parts).forEach((key) => (this.parts[key].value = '00'))

        const date = formatRelative(this.displayDate, new Date(), {locale: ru})

        this.$emit('timer:expired', date)
        interval = 0
      }
    },
    countdown() {
      let parts = difference(new Date(), this.displayDate)

      this.parts = Object.keys(parts).map((key) => {
        const value = parts[key]
        const displayValue = ('0' + value).slice(-2) // 01

        return {
          value: displayValue,
          label: wordsDeclension(value, ...localeRu[key]),
        }
      })

      this.check()
    },
  },
}
</script>

<style lang="scss" scoped>
.timer {
  text-align: center;

  ul {
    width: 250px;
    margin: 0 auto;
    margin-top: 15px;
    display: flex;

    li {
      flex: 1;
    }
  }

  .bold {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: #4b4f63;
    display: block;
  }
}
</style>
