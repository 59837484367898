import {reject, isNil, isEmpty, omit, pipe} from 'ramda'
import debounce from '@/utils/debounce'

const dates = ['created_at_start', 'created_at_end']
const filters = {
  dates: 'dates',
  course_id: 'course_id',
  multiple: 'multiple',
  email: 'email',
  full_name: 'full_name',
  phone_number: 'phone_number',
  tag: 'tag',
  status: 'status',
  currency: 'currency',
  page: 'page',
}

const setQueryToLS = (query) => {
  localStorage.setItem('crm-query', JSON.stringify(query))
}

const replaceRouteQuery = (query, $r) => {
  $r.replace({
    name: 'crm',
    query,
  }).catch(() => ({}))
}

const emptyOrNil = (v) => isNil(v) || isEmpty(v)
const rejectIfNil = (obj) => reject(emptyOrNil, obj)
const resetPage = (type) => (obj) => type !== 'page' ? omit(['page'], obj) : obj

const genFilter = (type, value) => {
  if (type === 'page') return {page: value}
  if (type === 'dates')
    return {
      [`filter[${dates[0]}]`]: value.start,
      [`filter[${dates[1]}]`]: value.end,
    }

  return {[`filter[${type}]`]: value}
}
const omitPrevFilter = (type, obj) => (type ? omit([`filter[${type}]`], obj) : obj)

export const state = () => ({
  query: {},
})

/** @type {import('vuex').ActionTree<typeof state>} */
export const actions = {
  INIT_QUERY({commit, dispatch}, query) {
    if (isEmpty(query)) return

    commit('SET', query)
    dispatch('crm/FILTER_USERS', query, {root: true})
  },
  SET_QUERY({commit, state, dispatch}, {type, value, prevFilter, currency, currencyType}) {
    const q = state.query
    const currencyData =
      currency && currency.id ? genFilter(filters[currencyType], currency.id) : {}
    const generateQuery = (oldQuery) => ({
      ...omitPrevFilter(prevFilter, q),
      ...currencyData,
      ...genFilter(filters[type], value),
    })

    const newQuery = pipe(generateQuery, resetPage(type), rejectIfNil)(q)

    // replaceRouteQuery(newQuery, this.$router)
    commit('SET', newQuery)

    const debouncedFilter = debounce(function () {
      dispatch('crm/FILTER_USERS', newQuery, {root: true})
    }, 500)
    debouncedFilter()
  },
  RESET({commit, dispatch}) {
    commit('SET', {})
    // replaceRouteQuery({}, this.$router)
    dispatch('crm/FILTER_USERS', {}, {root: true})
  },
}

/** @type {import('vuex').MutationTree<typeof state>} */
export const mutations = {
  SET(state, payload) {
    state.query = payload
    setQueryToLS(payload)
  },
}

export const getters = {
  crmFilterQuery: (state) => state.query,
}
