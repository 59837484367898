<template>
  <div
    :style="{
      width: `${size || width}px`,
      height: `${size || height}px`,
      margin: `${margin}px auto`,
    }"
    class="spinner-ring"
  >
    <div
      v-for="n in 4"
      :key="n"
      :style="{
        borderColor: `${color} transparent transparent transparent`,
        width: `${size || width}px`,
        height: `${size || height}px`,
      }"
      class="circle"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    color: {
      type: String,
      default: '#6666fe',
    },
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    size: {
      type: [Number, String, Boolean],
      default: false,
    },
    margin: {
      type: Number,
      default: 100,
    },
  },
}
</script>

<style scoped>
.spinner-ring {
  display: flex;
  justify-content: center;
  position: relative;
}

.spinner-ring .circle {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid #6666fe;
  border-radius: 50%;
  animation: spinner-anim-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6666fe transparent transparent transparent;
}

.spinner-ring .circle:nth-child(1) {
  animation-delay: -0.45s;
}

.spinner-ring .circle:nth-child(2) {
  animation-delay: -0.3s;
}

.spinner-ring .circle:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spinner-anim-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
