<template>
  <div class="line-with-text">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.line-with-text {
  margin: 15px 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #96a2b4;
  font-size: 12px;
  line-height: 20px;
  font-family: sans-serif;
  font-weight: bold;
  min-height: 20px;
  > span {
    display: inline-block;
    vertical-align: top;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      border-top: 1px solid rgba(#96a2b4, 0.15);
      top: 50%;
    }
    &:before {
      right: 100%;
      margin-right: 25px;
      left: -9999px;
    }
    &:after {
      left: 100%;
      margin-left: 25px;
      right: -9999px;
    }
  }
}
</style>
