import VuexPersist from 'vuex-persist'

export default ({store}) => {
  new VuexPersist({
    storage: window.localStorage,
    supportCircular: true,
    reducer: (state) => {
      return {
        auth: {token: state.auth.token},
        // authorPlan: {plan: state.authorPlan.plan},
        user: {user: state.user.user},
      }
    },
    filter: (mutation) => true,
  }).plugin(store)
}
