export const state = () => ({
  messages: [],
  usersOnline: [],
  links: {
    first: '',
    last: '',
    prev: '',
    next: '',
  },
  meta: {},
  isLoading: true,
  progress: {},
})
