<template>
  <div class="webinars-list">
    <div class="webinars-list-grid">
      <WebinarCard
        v-for="webinar in webinars"
        :key="webinar.id"
        :webinar="webinar"
        @webinar:open="goToGuestRoom"
      />
    </div>
  </div>
</template>

<script>
import {WebinarCard} from '@/features/Webinars/components'

export default {
  components: {
    WebinarCard,
  },
  props: {
    webinars: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToGuestRoom(id) {
      this.$router.push({name: 'webinars.guest-room', params: {id}})
    },
  },
}
</script>

<style src="@/features/Webinars/styles/webinars-list.scss" lang="scss"></style>
