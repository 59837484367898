<template>
  <div :class="rootClasses">
    <slot name="title"
      ><Typography bottomGutter variant="paragraph_s">{{ $t('crm.showTags') }}</Typography></slot
    >
    <div class="app-tags__selector">
      <Input
        id="tag-selector-input"
        v-model="tagName"
        :placeholder="$t('crm.addTag') + '...'"
        :custom="{bgColor: '#F2F5F9', borderRadius: '16px', margin: '0'}"
        :error="error"
        :errorMessage="error || ''"
        @keyup.enter="addTag"
        @keydown.tab.prevent="addTag"
      >
        <Icon slot="icon" src="icons/icon-hash.svg" size="s" />
      </Input>
    </div>
    <div class="app-tags__tags-list">
      <div
        v-for="(t, k) in selectedTags"
        :key="k"
        class="app-tags__tags-list__tag"
        @click="removeTag(t)"
      >
        <span>{{ t }}</span>
        <Icon slot="icon" src="icons/cross-icon.svg" size="xs" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {without} from 'ramda'

export default {
  name: 'UiTagsSelector',
  components: {
    Input: () => import('@/components/ui/Input'),
    Typography: () => import('@/components/ui/Typography'),
    Icon: () => import('@/components/Icon'),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    max: {
      type: Number,
      default: 10,
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
  },
  data: () => ({
    tagName: '',
    selectedTags: [],
  }),
  computed: {
    isMax() {
      return this.selectedTags.length === this.max
    },
    rootClasses() {
      return {
        'app-tags': true,
        'app-tags--error': this.error,
      }
    },
  },
  watch: {
    value(tags) {
      this.selectedTags = tags
    },
  },
  beforeMount() {
    this.selectedTags = this.value
  },
  methods: {
    ...mapActions({
      showToast: 'SHOW_TOAST',
    }),
    addTag(e) {
      if (this.isMax) {
        return this.showToast({text: this.$t('ui.tagErr'), type: 'danger'})
      }
      const v = e.target.value
      if (v.length < 2) return
      this.selectedTags = [v, ...this.selectedTags]
      this.tagName = ''
      this.emitTags()
    },
    removeTag(t) {
      this.selectedTags = without([t], this.selectedTags)
      this.emitTags()
    },
    emitTags() {
      this.$emit('input', this.selectedTags)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-tags {
  display: flex;
  flex-flow: column;

  &__selector {
    margin-bottom: 12px;
  }

  &__tags-list {
    display: flex;
    flex-wrap: wrap;

    &__tag {
      cursor: pointer;
      margin: 0 8px 8px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      border-radius: 20px;
      border: 1px solid #eef0f3;
      padding: 16px;
      color: #2e3141;
      > span {
        margin-right: 8px;
      }
    }
  }
}
</style>
