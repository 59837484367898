<template>
  <div class="copy-link">
    <Input
      :value="value"
      :label="label"
      class="copy-link__input"
      :class="{unselectable: showButton && !selectable}"
      :input-class="selectable ? '' : 'unselectable'"
      :readonly="readonly"
      :styles="{bgColor: '#fff', height: '48px'}"
    >
      <Icon
        slot="icon"
        src="icons/link-icon.svg"
        size="s"
        class="pointer"
        @click.native.stop="copy"
      />
    </Input>

    <Button
      v-if="showButton"
      :label="$t('popups.copy')"
      class="copy-link__button"
      inverted
      :title="value"
      @click="copy"
    />
  </div>
</template>

<script>
import copy from 'clipboard-copy'

export default {
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    label: {
      type: String,
      default() {
        return this.$t('popups.link') + ':'
      },
    },
    message: {
      type: String,
      default() {
        return this.$t('popups.linkCopied')
      },
    },
    showButton: {
      type: [Boolean],
      default: false,
    },
    selectable: {
      type: [Boolean],
      default: false,
    },
    readonly: {
      type: [Boolean],
      default: true,
    },
  },
  methods: {
    copy() {
      if (!this.value) return

      copy(this.value)

      this.$store.dispatch('SHOW_TOAST', {
        text: this.message,
        type: 'success',
      })
    },
  },
}
</script>

<style>
.copy-link {
  display: flex;
  align-items: center;
}

.copy-link__input {
  flex: 1;
  margin-right: 12px;
}
</style>
