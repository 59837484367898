<template>
  <div class="webinar-participants-count">
    <div class="flex items-center justify-between w-full">
      <slot name="title"></slot>

      <Typography v-if="!$slots.title" variant="h2">{{
        $t('webinars.participants.more')
      }}</Typography>

      <div class="flex items-center justify-center">
        <Icon
          v-if="showIcon"
          src="/icons/profile-user-2.svg"
          :fill="isLinkStyle && '#6666fe'"
          size="xs"
          class="mr-xs"
        />

        <Typography
          variant="int_m"
          :color="color"
          class="webinar-participants-count__active"
          :class="{pointer: isLinkStyle}"
        >
          <b :class="{bold: showTotal && !showIcon}"> {{ count }} </b>
          <template v-if="showTotal">&nbsp; / {{ allowedCount }} </template>
          <template v-if="showIcon">&nbsp; {{ countDeclesion }} </template>
        </Typography>

        <div v-if="increase" class="webinar-participants-count__increase" @click="onIncreaseClick">
          <Icon src="/icons/plus-in-circle.svg" size="xs" />
          <Typography variant="int_s">{{ $t('webinars.zoom') }}</Typography>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wordsDeclension from '@/utils/wordsDeclension'
import {MAX_ALLOWED_PARTICIPANTS as allowedCount} from '@/features/Webinars/model'

export default {
  props: {
    increase: {
      type: Boolean,
      default: false,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'grey',
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      allowedCount,
    }
  },
  computed: {
    isLinkStyle() {
      return this.color === 'purple'
    },

    countDeclesion() {
      return wordsDeclension(
        this.count,
        this.$t('webinars.participants.one'),
        this.$t('webinars.participants.two'),
        this.$t('webinars.participants.five')
      )
    },
  },
  methods: {
    onIncreaseClick() {
      this.$store.dispatch('modal/toggle', 'upgrade_plan')
    },
  },
}
</script>

<style lang="scss">
.webinar-participants-count {
  width: 100%;

  &__active {
    display: flex;
    position: relative;

    b.bold {
      font-size: 18px;
      color: #4b4f63;
    }

    &.typography-root {
      font-size: 12px;
    }
  }

  &__increase {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    margin-left: 16px;

    p {
      color: $purple;
      margin-left: 6px;
    }

    svg {
      fill: $purple !important;
    }
  }
}
</style>
