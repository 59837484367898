<template>
  <div class="flex flex-1 flex-col items-center justify-center">
    <Typography>
      {{ $t('funnels.vkDesc') }}
    </Typography>
    <Button class="mt-3" :href="vkLink">{{ $t('funnels.vkConnect') }}</Button>
  </div>
</template>

<script>
import {VK_AUTH} from '@/consts'
import {Typography, Button} from '@/components/ui'

export default {
  components: {Typography, Button},
  data: () => ({
    VK_AUTH,
  }),
  computed: {
    vkLink() {
      return VK_AUTH + `?token=${this.$store.getters['auth/token']}`
    },
  },
}
</script>
