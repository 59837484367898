import {path, prop} from 'ramda'
import CryptoJS from 'crypto-js'

const CryptoJSAesJson = {
  encrypt(value, password) {
    return CryptoJS.AES.encrypt(JSON.stringify(value), password, {
      format: CryptoJSAesJson,
    }).toString()
  },

  decrypt(jsonStr, password) {
    return JSON.parse(
      CryptoJS.AES.decrypt(jsonStr, password, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8)
    )
  },

  stringify(cipherParams) {
    let j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)}
    if (cipherParams.iv) j.iv = cipherParams.iv.toString()
    if (cipherParams.salt) j.s = cipherParams.salt.toString()
    return JSON.stringify(j).replace(/\s/g, '')
  },

  parse(jsonStr) {
    let j = JSON.parse(jsonStr)
    let cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    })
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
    return cipherParams
  },
}
let a = []

const defaultState = () => ({
  email: '',
  name: '',
  pwd: '',
  controlSum: '',
  device_id: '',
  role: 'author',
  phone: {},
  promocode: null,
})

export const state = () => ({
  form: defaultState(),
})

export const getters = {
  form: (state) => state.form,
  displayPhone: (state) => path(['form', 'phone', 'masked'], state),
  hasPromocode: (state) => prop('promocode', state.form),
}

const SET_FORM = 'SET_FORM'
const SET_PROMOCODE = 'SET_PROMOCODE'
const RESET_FORM = 'RESET_FORM'
export const mutations = {
  [SET_FORM](state, form) {
    state.form = {...state.form, ...form}
  },
  [SET_PROMOCODE](state, promocode) {
    state.form = {
      ...state.form,
      promocode,
    }
  },

  [RESET_FORM](state) {
    state.form = defaultState()
  },
}

const createPhoneParams = (phone = {}) => {
  return {
    phone_number: phone.value,
    country_code: phone.country,
    phone_code: `+${phone.code}`,
  }
}

const createParams = ({name, pwd, email, device_id, role, phone = {}, promocode} = {}) => {
  const [first, last] = name.trim().split(' ')

  const params = {
    first_name: first,
    last_name: last,
    email,
    device_id,
    password: pwd,
    role,
    ...(promocode ? {promo_code: promocode} : {}),
    ...createPhoneParams(phone),
  }
  return params
}

export const actions = {
  async CHECK_PROMOCODE({state, commit}, promocode) {
    try {
      const params = {
        promo_code: promocode,
      }
      const data = await this.$axios.$post('/promo-codes/verify', params)

      commit(SET_PROMOCODE, promocode)
      return data
    } catch (err) {
      const promoErr = path(['response', 'data', 'errors', 'promo_code', 0], err)
      throw promoErr
    }
  },
  async CHECK_PHONE({state}, extradata) {
    const e = JSON.parse(CryptoJSAesJson.encrypt(state.form.email, extradata.checkSum))
    a.push(e.iv)
    a.push(e.s)
    a.push(e.ct)
    a.push(extradata.checkSum)

    const params = {
      ...createPhoneParams(state.form.phone),
      email: state.form.email,
      control_sum: extradata.rc, // a.join(':'),
    }
    const data = await this.$axios.$post('/register/send-phone-confirmation-code', params)

    return data
  },
  async REGISTER({dispatch, commit, state}, code) {
    let utmArray = this.$utm.getUtmFromCookie()
    if (!utmArray) utmArray = []
    utmArray.push({utm_referrer: document.referrer})
    const params = {
      ...createParams(state.form),
      code,
      // plugins/utm.js
      utm_tags: utmArray,

      connect_token: this.$connectToken.getConnectToken(),
    }

    const data = await this.$axios.$post('/register/create-user', params)

    if (data && data.success) {
      await dispatch('auth/SET_AUTH', {token: data.access_token}, {root: true})
      await dispatch('user/FETCH', {}, {root: true})
    }
    return data
  },
}
