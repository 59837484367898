<template>
  <PopupWrapper :title="$t('crm.addProposal')" v-bind="$attrs" @close="onClose">
    <!-- username -->
    <Input
      v-model="username"
      flat
      :label="$t('forms.labels.firstnameLastname') + ':'"
      :placeholder="$t('forms.labels.enterFirstnameLastname')"
      :error="$v.username.$error"
      :errorMessage="usernameError"
    >
      <Icon slot="icon" src="icons/profile-user.svg" />
    </Input>

    <!-- email -->
    <Input
      v-model="email"
      flat
      label="Email:"
      placeholder="E-mail"
      type="email"
      :error="$v.email.$error"
      :errorMessage="emailError"
    >
      <Icon slot="icon" src="icons/icon-mail.svg" size="s" />
    </Input>

    <UiInputPhone
      v-model="phone"
      :errorMessage="$t('forms.rules.requiredField')"
      :label="$t('forms.labels.phone') + ':'"
      return-object
    ></UiInputPhone>

    <!-- course product select -->
    <CourseProductSelect
      ref="courseProductSelect"
      class="mx-1 mt-4"
      @course:select="(e) => (selectedCourse = e)"
      @product:select="(e) => (selectedProduct = e)"
    >
      <Typography slot="productSubLabel" variant="paragraph_xs" color="grey" class="mb-1">
        {{ $t('widgets.chooseProductDesc') }}
      </Typography>
    </CourseProductSelect>

    <!-- amount -->
    <Input
      v-model="sumAmount"
      type="number"
      flat
      :label="$t('crm.totals') + ':'"
      :placeholder="$t('forms.labels.enterPurchaseAmount')"
    >
      <Icon slot="icon" src="icons/icon-rub.svg" size="s" />
    </Input>

    <!-- submit -->
    <Button :loading="isAdded" :icon="'/icons/plus-in-circle.svg'" @click="addOrder">
      {{ $t('crm.addProposal') }}
    </Button>
  </PopupWrapper>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import {fullName, splitFullName} from '@/utils/validators'

import {PopupWrapper} from '@/components/ui'
import CourseProductSelect from '@/components/CourseProductSelect'
import UiInputPhone from '~ui/Input/InputPhone'

export default {
  components: {
    PopupWrapper,
    CourseProductSelect,
    UiInputPhone,
  },
  validations: {
    username: {
      required,
      fullName,
    },
    email: {
      required,
      email,
    },
    selectedCourse: {required},
    selectedProduct: {required},
  },
  data: () => ({
    username: '',
    email: '',
    phone: {
      value: '',
      masked: '',
      name: 'Россия',
      country: 'RU',
      code: '7',
      priority: 0,
      areaCodes: null,
    },
    selectedCourse: null,
    selectedProduct: null,
    sumAmount: '',
    isAdded: false,
  }),
  computed: {
    emailError() {
      if (!this.$v.email.$dirty) return

      if (!this.$v.email.email) {
        return this.$t('forms.errors.email')
      }

      return this.$t('forms.rules.requiredField')
    },
    usernameError() {
      if (!this.$v.username.$dirty) return

      if (!this.$v.username.fullName) {
        return this.$t('forms.rules.enterName')
      }

      return this.$t('forms.rules.requiredField')
    },
  },
  methods: {
    onClose() {
      this.$emit('close')

      this.reset()
    },
    reset() {
      this.username = ''
      this.email = ''
      this.selectedCourse = null
      this.selectedProduct = null

      this.$v.$reset()
      this.$refs.courseProductSelect.reset()
    },
    async addOrder() {
      this.$v.$touch()
      this.$refs.courseProductSelect.touch()

      if (this.$v.$invalid) return

      this.isAdded = true

      const [first, last] = splitFullName(this.username)

      const insertPhone = () => {
        return !this.phone || !this.phone.value
          ? {}
          : {
              phone_code: '+' + this.phone.code,
              country_code: this.phone.country,
              phone_number: this.phone.value,
            }
      }
      const insertAmount = () => (this.sumAmount ? {amount: this.sumAmount} : {})

      const params = {
        first_name: first,
        last_name: last,
        email: this.email,
        uid: this.selectedProduct.uid,
        ...insertPhone(),
        ...insertAmount(),
      }

      try {
        await this.$axios.$post('crm/users/attach-product', params)
        this.onClose()
      } catch (err) {
      } finally {
        this.isAdded = false
      }
    },
  },
}
</script>
