import {map, prop, pick, take, lensProp, reject, over, propEq} from 'ramda'
import {PROMOCODE_DISCOUNT_MAP} from './model'
import {removeObjectByKey, updateObjectByKey} from '@/utils'

const setPromocodesFilters = (isActive = undefined) => ({
  [`filter[is_active]`]: isActive,
  per_page: 20,
  page: 1,
})

const removePromocodeFromPartner = (xs, pId) => {
  const promoLens = lensProp('promo_codes')
  const removeById = reject(propEq('id', pId))
  return map(over(promoLens, removeById), xs)
}

export const state = () => ({
  promocodesList: [],
  promocodes: {
    links: {},
    meta: {},
  },
  promocodesFilters: setPromocodesFilters(),
  showMorePromocodes: false,
  partners: {
    data: [],
    links: {},
    meta: {},
  },
  totalPromocodes: 0,
  showFirstN: 5,
  usePromocode: undefined,
  verifiedPromo: undefined,
})

export const getters = {
  rawPromocodesList: (state) => state.promocodesList,
  list: (state) =>
    state.showMorePromocodes ? take(state.showFirstN, state.promocodesList) : state.promocodesList,
  partners(state) {
    return state.partners.data
  },
  hasMorePromocodes: (state) => Boolean(prop('next', state.promocodes.links)),
  promocodesCurrentPage: (state) => prop('current_page', state.promocodes.meta),
  totalPromocodes: (state) => state.totalPromocodes - state.showFirstN,
  showMorePromocodes: (state) => state.showMorePromocodes,
  hasMorePartners: (state) => Boolean(prop('next', state.partners.links)),
  partnersCurrentPage: (state) => prop('current_page', state.partners.meta),
  usePromocode: (state) => state.usePromocode,
  verifiedPromo: (state) => state.verifiedPromo,
}

export const mutations = {
  SET_PROMOCODES(state, obj) {
    state.promocodes = {
      links: obj.links,
      meta: obj.meta,
    }
    state.totalPromocodes = obj.meta.total
  },
  SET_TOTAL_PROMOCODES(state, n) {
    state.totalPromocodes = n
  },
  SET_PROMOCODES_LIST(state, xs) {
    state.promocodesList = xs
  },
  SET_PARTNERS(state, obj) {
    state.partners = obj
  },
  SET_PARTNERS_LIST(state, xs) {
    state.partners.data = xs
  },
  SET_PROMOCODES_FILTER_ACTIVE_STATE(state, b) {
    state.promocodesFilters = setPromocodesFilters(b)
  },
  SET_PROMOCODES_FILTER_PAGE(state, page) {
    state.promocodesFilters = {
      ...state.promocodesFilters,
      page,
    }
  },
  SET_SHOW_MORE_PROMOCODES(state, b) {
    state.showMorePromocodes = b
  },
  SET_USE_PROMOCODE(state, code) {
    state.usePromocode = code
  },
  SET_PROMOCODE_DATA(state, d) {
    console.log('store promo')
    state.verifiedPromo = d
  },
}

export const actions = {
  async FETCH_LIST({commit, state}, payload) {
    try {
      const data = await this.$axios.$get('/author/promo-codes/list', {
        params: state.promocodesFilters,
      })
      console.log(data)
      commit('SET_PROMOCODES', data)
      commit('SET_PROMOCODES_LIST', data.data)
      if (data.meta.total > 5) {
        commit('SET_SHOW_MORE_PROMOCODES', true)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async DELETE_PROMOCODE({commit, state}, id) {
    try {
      await this.$axios.$delete(`/author/promo-codes/${id}`)

      commit('SET_PROMOCODES_LIST', removeObjectByKey('id', id, state.promocodesList))
      commit('SET_TOTAL_PROMOCODES', state.totalPromocodes - 1)
      // remove promocode from promo_codes of partner
      commit('SET_PARTNERS_LIST', removePromocodeFromPartner(state.partners.data, id))
    } catch (err) {
      console.log(err)
    }
  },
  async UPDATE_PROMOCODE({commit, state}, payload) {
    const {id} = payload
    try {
      await this.$axios.$patch(`/author/promo-codes/${id}`, createPromocodeParams(payload))
    } catch (err) {
      console.log(err)
    }
  },
  async LOAD_MORE_PROMOCODES({commit, state}) {
    try {
      const data = await this.$axios.$get('/author/promo-codes/list', {
        params: state.promocodesFilters,
      })
      commit('SET_PROMOCODES', data)
      commit('SET_PROMOCODES_LIST', [...state.promocodesList, ...data.data])
    } catch (err) {
      console.log(err)
    }
  },
  async CHECK_IS_PROMOCODE_VALID({state, commit}, {uid, promo_code} = {}) {
    try {
      const res = await this.$axios.$post(`/products/check-promo-code`, {uid, promo_code})

      console.log('check pro stat')
      if (res) {
        commit('SET_PROMOCODE_DATA', res)
        return res
      }
    } catch (err) {
      console.log(err)
    }
    return undefined
  },
  async CREATE_PROMO({commit}, payload) {
    try {
      await this.$axios.$post('/author/promo-codes', createPromocodeParams(payload))
    } catch (err) {
      console.log(err)
    }
  },
  async FETCH_PARTNERS_LIST({commit, state}, {params, merge} = {}) {
    try {
      const data = await this.$axios.$get('/author/promo-partners/list', {params})

      if (merge) {
        commit('SET_PARTNERS', {
          data: [...state.partners.data, ...data.data],
          meta: data.meta,
          links: data.links,
        })
      } else {
        commit('SET_PARTNERS', data)
      }
    } catch (err) {
      console.log(err)
    }
  },
  async INVITE_PARTNER({commit, state}, payload) {
    try {
      const res = await this.$axios.$post('/author/promo-partners', createPartnerParams(payload))
      commit('SET_PARTNERS_LIST', [res, ...state.partners.data])
    } catch (err) {
      console.log(err)
    }
  },
  async UPDATE_PARTNER({commit, state}, payload = {}) {
    const {id} = payload
    try {
      const res = await this.$axios.$patch(
        `/author/promo-partners/${id}`,
        createPartnerParams(payload)
      )
      commit('SET_PARTNERS_LIST', updateObjectByKey('id', res, state.partners.data))
    } catch (err) {
      console.log(err)
    }
  },
}

function createPromocodeParams(params = {}) {
  const {promocode, discount, currency, promocodesCount, products, date} = params

  return {
    usage_limit: promocodesCount || 0,
    discount_type: PROMOCODE_DISCOUNT_MAP[currency],
    discount_amount: discount,
    promo_code: promocode,
    is_active: true,
    product_ids: map(prop('id'), products),
    valid_to: date,
  }
}

function createPartnerParams(params = {}) {
  const {email, promocodes, stopPartner} = params
  return {
    email,
    promo_codes: map(pick(['id', 'partner_commission']), promocodes),
    is_active: !stopPartner,
  }
}
