import {registerUserController} from '@/utils/SocketController'

export default function ({$echo, app, store}) {
  const token = store.getters['auth/token']
  const userId = store.getters['user/userId']

  $echo.options.auth.headers.Authorization = 'Bearer ' + token
  $echo.connect()

  if (!token) return

  // register private notifications only for authenticated user
  registerUserController($echo, store, userId)
}
