<template>
  <div class="tabs" :style="vars">
    <div class="tabs__nav">
      <ul ref="nav">
        <li
          v-for="(tab, index) in tabs"
          :key="tab.id || index"
          :class="{'is-active': tab.isActive}"
          :style="{'--tab-color': tab.color, '--tab-icon-color': tab.iconColor}"
          @click="selectTab(tab, index)"
        >
          <span v-if="tab.icon" class="icon">
            <svg>
              <use :xlink:href="tab.icon"></use>
            </svg>
          </span>

          <span class="int_s">
            {{ tab.label }}

            <span class="bd"></span>
          </span>
        </li>
      </ul>
    </div>
    <!-- tabs__content -->
    <slot></slot>
  </div>
</template>

<script>
const ACTIVE_COLOR = '#6666fe'

const DARK_PRIMARY_COLOR = '#2e3141'
const LIGTH_PRIMARY_COLOR = '#ffffff'

export default {
  props: {
    activeColor: {
      // active color
      type: String,
      default: ACTIVE_COLOR,
    },
    dark: {
      // text color
      type: Boolean,
      default: true,
    },
    flex: {
      // stretch items
      type: Boolean,
      default: true,
    },
    justify: {
      // position
      type: String,
      default: 'center',
    },
  },
  data() {
    return {
      tabs: [],
    }
  },
  computed: {
    vars() {
      return {
        '--tabs-justify': this.justify,
        '--tabs-primary-color': this.dark ? DARK_PRIMARY_COLOR : LIGTH_PRIMARY_COLOR,
        '--tabs-active-color': this.activeColor,
        '--tab-flex-mode': this.flex ? '1' : 'initial',
      }
    },
  },
  created() {
    this.tabs = this.$children
  },
  methods: {
    selectTab(selectedTab, index) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.name === selectedTab.name

        if (tab.isActive) {
          this.$emit('changed', tab.name, index)

          if (tab.href) {
            this.$router.push(tab.href)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tabs {
  ul {
    padding: 0;
    display: flex;
    border-bottom: 1px solid rgba(150, 162, 180, 0.15);
    margin-bottom: 24px;
    justify-content: var(--tabs-justify);
  }

  li {
    cursor: pointer;
    transition: color 0.15s ease;
    position: relative;
    margin-right: 28px;
    flex: var(--tab-flex-mode);
    text-align: justify;

    // Typography styles (thrown "infinite update loop" error)
    .int_s {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      padding-bottom: 20px;
      color: var(--tabs-primary-color);
      display: inline-block;
      letter-spacing: -0.1px;
      position: relative;
    }

    .icon {
      width: 48px;
      height: 48px;
      border-radius: 16px;
      background-color: $lightGrey;
      color: rgba(150, 162, 180, 0.48);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;
      transition: 0.2s;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .bd {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: transparent;
      // transition: 0.2s;
    }

    &.is-active {
      .int_s {
        color: var(--tab-color, var(--tabs-active-color));
        // border-bottom: 2px solid var(--tab-color, var(--tabs-active-color));
      }

      .icon {
        color: #fff;
        position: relative;
        overflow: hidden;
        background-color: var(--tab-color, var(--tabs-active-color));
      }

      .bd {
        background: var(--tab-color, var(--tabs-active-color));
      }
    }
    &:not(.is-active) {
      &:hover {
        .icon {
          color: var(--tab-color, var(--tabs-active-color));
        }
      }
    }
  }

  @include tablet {
    li {
      .int_s {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
