import {prop, length} from 'ramda'
import {chatActionTypes} from '@/features/Chat/model/store'

const root = {root: true}
const SET_DRAFT = 'SET_DRAFT'
const FILE_UPLOADING = 'FILE_UPLOADING'

export const state = () => ({
  draft: {},
  isFileUploading: false,
})
export const getters = {
  isDraft: (state) => Boolean(prop('id', state.draft)),
  msgId: (state) => prop('id', state.draft),
  isFileUploading: (state) => state.isFileUploading,
  filesNum: (state) => length(prop('medias', state.draft) || []),
}
export const mutations = {
  [SET_DRAFT](state, data) {
    state.draft = data
  },
  [FILE_UPLOADING](state, data) {
    state.isFileUploading = data
  },
}
export const actions = {
  reset({commit}) {
    commit(SET_DRAFT, {})
  },
  async sendMessage({commit, dispatch, getters}, payload) {
    try {
      const {chatId, message} = payload
      const url = `/chat-messages/student-courses/${chatId}/send`
      const params = {
        message,
        ...(getters.isDraft ? {chat_message_id: getters.msgId} : {}),
      }
      const {data} = await this.$axios.post(url, params)

      dispatch(`chat/${chatActionTypes.INSERT_MESSAGE}`, data, root)
      dispatch('reset')
      return data
    } catch (err) {}
  },
  async attachFileToMsg({commit, dispatch, getters}, payload) {
    commit(FILE_UPLOADING, true)
    const formData = new FormData()
    formData.append('file', payload.file)

    const url = getters.isDraft ? `/chat-messages/${getters.msgId}/attach` : '/chat-messages/attach'

    try {
      const {data} = await this.$axios.post(url, formData)

      commit(SET_DRAFT, data)
    } catch (err) {
    } finally {
      commit(FILE_UPLOADING, false)
    }
  },
}
export const namespaced = true
