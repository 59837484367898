import {map, filter, prop, split, pipe, equals} from 'ramda'

export const checkMobSubdomain = () => {
  if (typeof window === 'undefined') {
    return
  }

  return pipe(split('.'), prop(0), equals('m'))(window.location.hostname)
}

export const transformCourseToAnalytic = (course = {}) => {
  return {
    id: course.id,
    name: course.title,
    price: course.minimal_price,
    brand: course.author,
    position: course.id,
    category: course.categories,
    quantity: 1,
  }
}

export const formatCurrency = (n, currency = 'RUB') => {
  if (isNaN(n)) {
    console.error('Provided param is not a valid number')
    return 0
  }

  return new Intl.NumberFormat('ru-RU', {
    currency,
    style: 'currency',
    minimumFractionDigits: 0,
  }).format(Number(n))
}

/**
 * @param {string} key key of object by which it'll be update
 * @param {object} data object to replace
 * @param {array} source source list
 */
export const updateObjectByKey = (key, data, source) => {
  return map((x) => (x[key] === data[key] ? data : x), source)
}

/**
 * @param {string} key key of object to find
 * @param {object} value value of the key
 * @param {array} source source list
 */
export const removeObjectByKey = (key, value, xs) => {
  return filter((v) => v[key] !== value, xs)
}

/**
 * searchQueryFilter - filter array`s objects by multiple properties
 *
 * @param {array} list search list
 * @param {string} query search query
 * @param {array} props search key list
 */
export const searchQueryFilter = (list = [], query = '', props = ['email', 'full_name']) => {
  if (!query) return list

  return list.filter((item) => {
    return Object.keys(item).some((key) => {
      if (props.includes(key) && item[key]) {
        return item[key].toLowerCase().includes(query.toLowerCase())
      }
    })
  })
}
