import {prop, length, values} from 'ramda'

const accountTypes = {
  // физ лицо
  1: 'individual.person',
  // ип
  3: 'business.person',
  // юр лицо
  4: 'legal.person',
  // пользовательская система оплаты
  5: 'foreign.person',
}
const formTypes = {
  'individual.person': 1,
  'business.person': 3,
  'legal.person': 4,
  'foreign.person': 5,
}

export const state = () => ({
  currentStep: 1,
  availableStep: 0,
  formType: 5,
  paymentType: 1,
  form: {},
  formCountry: 'ru',
})

export const getters = {
  step: (state) => state.currentStep,
  formType: (state) => state.formType,
  paymentType: (state) => state.paymentType,
  formCountry: (state) => state.formCountry,
  availableStep: (state) => state.availableStep,
  formStep2: (state) => prop('step2', state.form) || {},
  formStep3: (state) => prop('step3', state.form) || {},
  hasData: (state) => length(values(state.form)) > 0,
  formData: (state) => state.form,
}

export const mutations = {
  setStep(state, step) {
    state.currentStep = step
  },
  setType(state, type) {
    state.formType = type
  },
  setPay(state, type) {
    state.paymentType = type
  },
  setFormData(state, data) {
    state.form = data
  },
  setAvailableStep(state, step) {
    state.availableStep = step
  },
  setCountry(state, country) {
    state.formCountry = country
  },
}

export const actions = {
  async getFormData({commit, dispatch}) {
    try {
      const {data} = await this.$axios.get('/author/legal-details')

      commit('setFormData', data)
      if (data.last_step > 0) {
        if (data.status === 'rejected') {
          commit('setAvailableStep', data.last_step)
          commit('setStep', 1)
        } else if (data.status === 'draft') {
          commit('setAvailableStep', data.last_step)
          await dispatch('changeStep', data.last_step + 1 || 1)
        } else if (data.status === 'verified') {
          commit('setAvailableStep', data.last_step)
          commit('setStep', 2)
        }
        commit('setCountry', data.step1.country_code)
        await dispatch('setFormType', formTypes[data.step1.account_type])
      }
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async sendFormStripeId({commit, dispatch, getters}, extradata) {
    try {
      const params = {
        step: 1,
        account_type: accountTypes[5],
        country_code: getters.formCountry,
        stripe_api_key: extradata.stripeId,
      }
      const {data} = await this.$axios.post('/author/legal-details', params)
      return data
    } catch (err) {
      console.log(err)
    }
  },

  async sendFormStep1({commit, dispatch, getters}) {
    try {
      const params = {
        step: 1,
        account_type: accountTypes[getters.formType],
        country_code: getters.formCountry,
      }
      const {data} = await this.$axios.post('/author/legal-details', params)
      commit('setAvailableStep', data.last_step)
      commit('setFormData', data)
      await dispatch('changeStep', 2)
      return data
    } catch (err) {
      console.log(err)
    }
  },
  async sendFormStep2({commit, dispatch, getters}, data) {
    try {
      const params = getFormData({
        step: 2,
        account_type: accountTypes[getters.formType],
        ...data,
      })
      const res = await this.$axios.post('/author/legal-details', params)
      commit('setAvailableStep', res.data.last_step)
      commit('setFormData', res.data)
      await dispatch('changeStep', 3)
      return res.data
    } catch (err) {
      console.log(err)
    }
  },

  async sendFormStep3({commit, dispatch, getters}, data) {
    try {
      const params = getFormData({
        step: 3,
        account_type: accountTypes[getters.formType],
        ...data,
      })
      const res = await this.$axios.post('/author/legal-details', params)
      commit('setAvailableStep', res.data.last_step)
      commit('setFormData', res.data)
      return res
    } catch (err) {
      console.log(err)
    }
  },
  async sendFormStep4({commit, dispatch, getters}, data) {
    const params = getFormData({
      step: 4,
      account_type: accountTypes[getters.formType],
      ...data,
    })
    const res = await this.$axios.post('/author/legal-details', params)
    commit('setFormData', res.data)
    return res
  },
  changeStep({commit}, step) {
    commit('setStep', step)
  },
  setFormType({commit}, type) {
    commit('setType', type)
  },
  setPayType({commit}, type) {
    commit('setPay', type)
  },
  setFormCountry({commit}, country) {
    commit('setCountry', country)
  },
}

export const namespaced = true

function getFormData(obj) {
  return Object.keys(obj).reduce((formData, key) => {
    formData.append(key, obj[key])
    return formData
  }, new FormData())
}
