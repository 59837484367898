import {uniq} from 'ramda'

const SET_LOADING = 'SET_LOADING'
const SET_COURSES = 'SET_COURSES'

export const state = () => ({
  courses: [],
  links: {},
  isLoading: true,
})

export const getters = {
  courses: (state) => state.courses,
  hasMoreCourses: (state) => Boolean(state.links.next),
  isLoading: (state) => state.isLoading,
}

export const mutations = {
  [SET_LOADING](state, loading) {
    state.isLoading = loading
  },
  [SET_COURSES](state, {links, courses} = {}) {
    state.courses = uniq(courses || [])
    state.links = links
  },
}

export const actions = {
  async FETCH_COURSES({commit, dispatch}, {query} = {}) {
    commit(SET_LOADING, true)

    const data = await this.$axios.$get('/courses/list', {params: query})

    commit(SET_COURSES, {courses: data.data, links: data.links})

    commit(SET_LOADING, false)
    return data
  },
  async FETCH_MORE_COURSES({state, commit}, {query} = {}) {
    try {
      const data = await this.$axios.$get(state.links.next, {params: query})

      commit(SET_COURSES, {
        courses: [...state.courses, ...data.data],
        links: data.links,
      })

      return data
    } catch (err) {
      throw err
    }
  },
}
