import {prop, isEmpty} from 'ramda'
import {ADD_TOAST_MESSAGE} from 'vuex-toast'

export const state = () => ({
  user: {},
})

export const getters = {
  isVkConnected: (state) => prop('is_vk_connected', state.user),
  hasUser: (state) => !isEmpty(state.user),
  getUser: (state) => state.user,
  userId: (state) => prop('id', state.user),
  role: (state) => prop('current_role', state.user),
  isPhoneVerified: (state) => prop('is_phone_confirmed', state.user),
  isLegalVerified: (state) => prop('status', state.user.legal_details) === 'verified',
  isCrmDemoUsersVisible: (state) => state.user.is_crm_demo_data_visible,
  isEmailConfirmed: (state) => state.user.is_email_verified,
  userEmail: (state) => state.user.email,
  cardDetails: (state) => state.user.rbk_customer || {},
  stripe_payment_status: (state) =>
    state.user.latest_plan ? state.user.latest_plan.stripe_payment_status : 0,
}

export const mutations = {
  SET(state, user) {
    if (user) {
      state.user = user
      this.$authUtils.setUser(user)
    } else {
      state.user = {}
      this.$authUtils.setUser(undefined)
    }
  },

  SET_AVATAR(state, payload) {
    state.user.avatar = payload
  },
}

export const actions = {
  async INIT({commit, dispatch}) {
    await dispatch('FETCH')
  },
  async FETCH({commit, dispatch}) {
    try {
      const data = await this.$axios.$post('/auth/me?include=balance,legalDetails,latestPlan')

      commit('SET', data)
      // setUser.call(this, data)

      if (data.latest_plan && isEmpty(data.latest_plan)) {
        await dispatch('authorPlan/GET', {}, {root: true})
      } else {
        commit('authorPlan/SET', data.latest_plan, {root: true})
      }

      return data
    } catch (err) {
      dispatch('auth/CLEAR_AUTH', {}, {root: true})
    }
  },
  async UPDATE({commit, dispatch}, payload) {
    try {
      const data = await this.$axios.$patch('/user', payload)

      commit('SET', data)
      dispatch(
        'SHOW_TOAST',
        {
          text: 'Данные успешно обновлены',
        },
        {root: true}
      )
    } catch (err) {}
  },
  async CHANGE_PASSWORD({commit, dispatch}, payload) {
    const data = await this.$axios.$patch('/user/change-password', payload)

    if (data) {
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.passwordChanged'),
          type: 'success',
          dismissAfter: 3000,
        },
        {root: true}
      )
      dispatch('FETCH')
    }
    return data
  },
  async CHANGE_AVATAR({commit, dispatch}, {image, name}) {
    const formData = new FormData()

    formData.append('file', image)
    formData.append('name', name)
    formData.append('type', 'image/jpg')

    const config = {
      header: {
        'Content-Type': 'image/jpg',
      },
    }

    const response = await this.$axios.post('/user/upload-avatar', formData, config)
    const imgUrl = response.data.path

    commit('SET_AVATAR', imgUrl)
    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: this.$i18n.t('toast.pictureUploaded'),
        type: 'success',
        dismissAfter: 3000,
      },
      {root: true}
    )
    // upd user
    dispatch('FETCH')
  },
  async SWITCH_ROLE({commit, dispatch}, newRole) {
    const data = await this.$axios.$patch('/user/switch-role', {newRole})
    commit('SET', data)
    this.$authUtils.setUser(data)

    await dispatch('authorPlan/GET', {}, {root: true})

    return data
  },

  async CONFIRM_EMAIL({dispatch}) {
    const data = await this.$axios.$post('/user/confirm-email-claim')
    if (data.success) {
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: data.message,
          type: 'success',
          dismissAfter: 4000,
        },
        {root: true}
      )
    }
  },
  async CHANGE_LANGUAGE({dispatch, getters}, {code}) {
    if (getters.hasUser) {
      const data = await this.$axios.$patch('/user/switch-lang', {lang_code: code})
      console.log(data)
    }
  },
}
