<template>
  <div class="webinar-delete">
    <div @click="showConfirm">
      <slot name="activator"></slot>
    </div>

    <ConfirmModal
      :title="deleteConfirmTitle"
      :confirmText="$t('course.delete')"
      :isOpen="show"
      :loading="loading"
      @confirm="onDeleteConfirm(true)"
      @cancel="onDeleteConfirm(false)"
    >
      <img slot="image" src="/assets/img/webinars/confirm-delete.png" />
    </ConfirmModal>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  components: {
    ConfirmModal: () => import('@/components/Modals/ConfirmModal'),
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
      deleteConfirmTitle: '',
      webinar: this.data,
      loading: false,
    }
  },
  mounted() {
    if (!this.$slots.activator) {
      this.$nuxt.$on('webinar:delete', (data) => {
        this.webinar = data
        this.showConfirm()
      })
    }
  },
  destroyed() {
    this.$nuxt.$off('webinar:delete')
  },
  methods: {
    ...mapActions({
      removeWebinar: 'webinars/removeWebinar',
    }),

    showConfirm(show = true) {
      this.deleteConfirmTitle = this.$t('webinars.confirmDelete')

      this.show = show
    },

    async onDeleteConfirm(confirmed) {
      if (confirmed) {
        this.loading = true

        try {
          await this.removeWebinar(this.webinar.id)
          this.$emit('delete')
        } catch {
        } finally {
          this.loading = false
        }
      }

      this.show = false
    },
  },
}
</script>
