import {MODALS} from '@/consts'

export const state = () => ({
  current: '',
})

export const getters = {
  active: (state) => state.current,
  profilePhone: (state) => state.current === MODALS.phone_modal,
}

export const mutations = {
  toggle(state, name) {
    state.current = name
  },
}

export const actions = {
  toggle({commit}, name) {
    commit('toggle', name)
  },
}

export const namespaced = true
