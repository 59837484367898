// import {formatDate} from '@/utils/dateFormat'

/*
export const partnerTableHeaders = [
  {text: 'E-mail партнера', value: 'email', disabledSort: true},
  {text: 'Промокоды', disabledSort: true, value: 'promocodes'},
  {text: 'Выручка с продаж', disabledSort: true, value: 'earnings'},
  {text: 'Комиссия партнера', value: 'commission'},
  {text: 'Продаж', disabledSort: true, value: 'sales'},
  {text: 'Последняя продажа', value: 'lastSale'},
  {text: '', disabledSort: true, value: 'settings', width: '32px'},
]
*/
export const mapPartnerItem = (ctx) => (val) => ({
  email: val.email,
  promocodes: val.promo_codes,
  earnings: val.sale_earnings || 0,
  sales: val.sales_amount || 0,
  commission: val.partner_commission || 0,
  lastSale: val.last_sale_at,
  id: val.id,
  is_active: val.is_active,
})

export const PROMOCODE_DISCOUNT_MAP = {
  RUB: 'RUB',
  USD: 'USD',
  EUR: 'EUR',
  '%': 'percentage',
}
