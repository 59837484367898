<template>
  <div class="app__popup-wrapper native-offset">
    <Transition name="fade">
      <div v-if="isOpen" class="app-popup-overlay" @click.self="close"></div>
    </Transition>

    <Transition name="slide-left-popup ">
      <div v-if="isOpen" class="app-popup-side native-offset">
        <div class="flex flex-col overflow-y-auto w-full h-full max-h-full md:p-10 sm:p-8 xs:p-4">
          <header class="flex justify-between items-start mb-6">
            <slot name="header"></slot>

            <div v-if="!$slots.header">
              <Typography variant="h2">{{ title || poptitle }}</Typography>
              <p v-if="subtitle" class="mt-2">{{ subtitle }}</p>
            </div>
            <Icon class="mt-3 ml-2" src="icons/close.svg" size="s" @click="close" />
          </header>

          <div class="flex flex-1 flex-col">
            <slot></slot>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    poptitle: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    isOpen: Boolean,
  },
  watch: {
    isOpen(v) {
      if (!v) {
        document.body.classList.toggle('overflow-hidden')
        document.removeEventListener('keyup', this.escListener)
      } else {
        document.body.classList.toggle('overflow-hidden')
        document.addEventListener('keyup', this.escListener)
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', this.escListener)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.escListener)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    escListener(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    },
  },
}
</script>

<style>
.app-popup-overlay {
  @apply fixed w-full h-full bg-opacity-60 transition-all inset-0 z-modal bg-black;
}
.app-popup-side {
  width: 520px;
  @apply fixed z-modal max-w-full block inset-0 left-auto bg-white rounded-tl-3xl transition-all;
}
</style>
