import {ROLES, MODALS} from '@/consts'
import {path, not} from 'ramda'

export const state = () => ({
  plan: {
    is_valid: true,
  },
})

export const getters = {
  plan: (state) => state.plan,
  isActive: (state) => state.plan.is_valid,
  isTest: (state) => state.plan.is_test_period,
  isExpired: (state) => state.plan.is_expired,
  daysLeft: (state) => state.plan.days_left,
  alias: (state) => path(['plan', 'alias'], state.plan),
}

export const mutations = {
  SET(state, plan) {
    state.plan = plan
  },
}

export const actions = {
  CHECK_PLAN({dispatch, state, rootGetters}, {force = false} = {}) {
    const removeCooks = () => {
      this.$cookies.remove('plandays')
      this.$cookies.remove('seen')
    }
    if (not(rootGetters['user/isPhoneVerified'])) {
      removeCooks()
      return
    }

    if (force) removeCooks()

    const getLastDay = (days) => {
      const lastDay = this.$cookies.get('plandays')
      if (lastDay == null && days != null) return days
      return lastDay
    }
    const setDays = (d) => this.$cookies.set('plandays', d, {maxAge: 24 * 120 * 1000, path: '/'})
    const toggleModal = () => {
      if (state.APP_BASEURL === 'meleton.ru') {
        this.$cookies.set('seen', true, {path: '/'})
        dispatch('modal/toggle', MODALS.plan_reminder, {root: true})
      }
    }

    const isActive = state.plan.is_valid
    const isTest = state.plan.is_test_period
    const daysLeft = state.plan.days_left
    const lastDay = getLastDay(daysLeft)

    if (!isActive) {
      removeCooks()
      toggleModal()
      return
    }

    if (daysLeft == null) {
      removeCooks()
      return
    }

    if (daysLeft !== lastDay) this.$cookies.set('seen', false)
    if (this.$cookies.get('seen')) return

    if (daysLeft <= lastDay || daysLeft >= lastDay) {
      if (!isTest && daysLeft > 3) return
      toggleModal()
    }
    setDays(daysLeft)
  },
  async GET({commit, rootGetters, getters, dispatch}) {
    if (rootGetters['user/role'] !== ROLES.author) return

    try {
      const plan = await this.$axios.$get('/author/my-plan')
      commit('SET', plan)

      return plan
    } catch (err) {
      console.log(err)
    }
  },
}
