import Vue from 'vue'
import convertJsonToFormData from '@/utils/json2formdata'

const prefix = ''

export const state = () => ({
  request: {},
  response: {},
})

export const mutations = {
  SET_ApiParams(state, {apiName, params}) {
    Vue.set(state.request, apiName, params, params)
  },
  SET_ApiAdapter(state, {apiName, response}) {
    Vue.set(state.response, apiName, response)
  },
}

export const actions = {
  async query({commit, state}, {url, methods, params, type, merge = false}) {
    const apiName = url.split('/').reduce(function (accumulator, currentValue, index, array) {
      if (!currentValue) return accumulator
      return accumulator + (currentValue[0].toUpperCase() + currentValue.slice(1))
    }, methods)

    commit('SET_ApiParams', {
      apiName,
      params: {url, methods, params},
    })
    const apiPath = prefix + state.request[apiName].url

    const headers = {}
    let queryParams = null
    if (type === 'formdata') {
      headers.headers = {
        'Content-Type': 'multipart/form-data',
      }

      queryParams = convertJsonToFormData(params)
    } else {
      queryParams = state.request[apiName].methods === 'GET' ? {params} : params
    }

    let response
    try {
      switch (state.request[apiName].methods) {
        case 'GET':
          response = await this.$axios.$get(apiPath, {
            ...queryParams,
            ...headers,
          })
          break
        case 'POST':
          response = await this.$axios.$post(apiPath, queryParams, headers)
          break
        case 'DELETE':
          response = await this.$axios.$delete(apiPath, queryParams, headers)
          break
        case 'PUT':
          response = await this.$axios.$put(apiPath, queryParams, headers)
          break
        case 'PATCH':
          response = await this.$axios.$patch(apiPath, queryParams, headers)
          break
        default:
          console.log('Incorrect method')
      }
    } catch (e) {
      console.log(e)
    }

    if (response) {
      commit('SET_ApiAdapter', {
        apiName,
        response,
      })
      return response
    } else {
      // todo обрабатывать ошибки как-то иначе
      commit('SET_ApiAdapter', {
        apiName,
        response: {results: []},
      })
    }
  },
}
