<template>
  <RoundedBlock :styles="{backgroundColor: '#fff'}" class="bot-card">
    <div class="flex">
      <PreviewWithInfo :data="data"></PreviewWithInfo>
      <div>
        <BoxedIcon
          radius="50%"
          :src="actionIcn"
          :size="actionIcnSize"
          @click="$emit('actionClick')"
        />
      </div>
    </div>
    <div v-if="data.description" class="flex mt-1">
      <Typography bottomGutter variant="paragraph_xs" noWrap>{{ data.description }}</Typography>
    </div>
    <div v-if="data.users" class="flex items-center">
      <Icon class="mr-4" src="/icons/profile-user.svg" size="xs"></Icon>
      <Typography variant="paragraph_xs" color="grey">{{ data.users }}</Typography>
    </div>
  </RoundedBlock>
</template>

<script>
import {RoundedBlock, Typography} from '~ui'
import PreviewWithInfo from './PreviewWithInfo'

export default {
  name: 'BotCard',
  components: {
    RoundedBlock,
    Typography,
    PreviewWithInfo,
  },
  props: {
    actionIcn: {
      type: String,
      default: '/icons/icon-cog.svg',
    },
    actionIcnSize: {
      type: String,
      default: 'mm',
    },
    data: {
      type: Object,
      default: () => ({
        name: 'Rive School',
        link: '@riveschool',
        description: '',
        users: 123213,
      }),
    },
  },
}
</script>

<style lang="scss">
.bot-card {
  max-width: 300px;
  background-color: #fff;
  border: 1px solid #f2f5f9;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}
</style>
