<template>
  <div class="user-group-list">
    <UserItemTile
      v-for="(user, i) in users"
      :key="i"
      icon-size="xs"
      :icon="tileIcon"
      @user:click="$emit('user-group:click')"
      @user:action="$emit('user-group:action')"
    />
  </div>
</template>

<script>
/* Note: скорее всего бесполезная прослойка */
export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    tileIcon: {
      type: [String, Boolean],
      default: 'icons/close.svg',
    },
  },
}
</script>
