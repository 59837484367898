<template>
  <div class="webinar-access-block">
    <!-- head -->
    <WebinarParticipantsCount
      class="webinar-access-block__head"
      :increase="true"
      :show-total="true"
      :count="participantsCount"
    />

    <!-- select access type -->
    <WebinarAccessSelector :data="webinar" :invite-url="inviteUrl" @update="updateWebinar" />

    <!-- participants list -->
    <WebinarParticipantsList class="webinar-access-block__participants" :list="participants" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import {createInviteUrl} from '@/features/Webinars/model'

import {WebinarParticipantsList} from './'
import {WebinarParticipantsCount} from '../atoms'
import {WebinarAccessSelector} from '../molecules'

export default {
  components: {
    WebinarAccessSelector,
    WebinarParticipantsList,
    WebinarParticipantsCount,
  },
  props: {
    webinar: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      participants: 'webinars/participants',
    }),
    participantsCount() {
      return this.participants.length
    },
    inviteUrl() {
      return createInviteUrl(this.webinar)
    },
  },

  methods: {
    ...mapActions({
      updateWebinar: 'webinars/updateWebinar',
    }),
  },
}
</script>

<style lang="scss">
.webinar-access-block {
  width: 100%;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__participants {
    margin-top: 25px;
  }
}
</style>
