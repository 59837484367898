const allCountries = [
  {
    name: 'United States (United States)',
    dial_code: '1',
    code: 'EN',
  },
  {
    name: 'United Kingdom (United Kingdom)',
    dial_code: '44',
    code: 'GB',
  },
  {
    name: 'Canada (Canada)',
    dial_code: '1',
    code: 'CA',
  },
  {
    name: 'Afghanistan (افغانستان)',
    dial_code: '93',
    code: 'AF',
  },
  {
    name: 'Aland Islands (Ahvenanmaan maakunta)',
    dial_code: '358',
    code: 'AX',
  },
  {
    name: 'Albania (Shqipëria)',
    dial_code: '355',
    code: 'AL',
  },
  {
    name: 'Algeria (Algérie)',
    dial_code: '213',
    code: 'DZ',
  },
  {
    name: 'AmericanSamoa (Amerika Sāmoa)',
    dial_code: '1684',
    code: 'AS',
  },
  {
    name: 'Andorra (Principat d’Andorra)',
    dial_code: '376',
    code: 'AD',
  },
  {
    name: 'Angola (República de Angola)',
    dial_code: '244',
    code: 'AO',
  },
  {
    name: 'Anguilla (Anguilla)',
    dial_code: '1264',
    code: 'AI',
  },
  {
    name: 'Antarctica (Antarctica)',
    dial_code: '672',
    code: 'AQ',
  },
  {
    name: 'Antigua and Barbuda (Antigua and Barbuda)',
    dial_code: '1268',
    code: 'AG',
  },
  {
    name: 'Argentina (Argentina)',
    dial_code: '54',
    code: 'AR',
  },
  {
    name: 'Armenia (Հայաստան)',
    dial_code: '374',
    code: 'AM',
  },
  {
    name: 'Aruba (Aruba)',
    dial_code: '297',
    code: 'AW',
  },
  {
    name: 'Australia (Australia)',
    dial_code: '61',
    code: 'AU',
  },
  {
    name: 'Austria (Österreich)',
    dial_code: '43',
    code: 'AT',
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    dial_code: '994',
    code: 'AZ',
  },
  {
    name: 'Bahamas (The Bahamas)',
    dial_code: '1242',
    code: 'BS',
  },
  {
    name: 'Bahrain (البحرين)',
    dial_code: '973',
    code: 'BH',
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    dial_code: '880',
    code: 'BD',
  },
  {
    name: 'Barbados (Barbados)',
    dial_code: '1246',
    code: 'BB',
  },
  {
    name: 'Belarus (Беларусь)',
    dial_code: '375',
    code: 'BY',
  },
  {
    name: 'Belgium (Belgique)',
    dial_code: '32',
    code: 'BE',
  },
  {
    name: 'Belize (Belize)',
    dial_code: '501',
    code: 'BZ',
  },
  {
    name: 'Benin (Bénin)',
    dial_code: '229',
    code: 'BJ',
  },
  {
    name: 'Bermuda (the Bermuda Islands)',
    dial_code: '1441',
    code: 'BM',
  },
  {
    name: 'Bhutan (འབྲུག་ཡུལ)',
    dial_code: '975',
    code: 'BT',
  },
  {
    name: 'Bolivia, Plurinational State of (Estado Plurinacional de Bolivia)',
    dial_code: '591',
    code: 'BO',
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    dial_code: '387',
    code: 'BA',
  },
  {
    name: 'Botswana (Botswana)',
    dial_code: '267',
    code: 'BW',
  },
  {
    name: 'Brazil (Brasil)',
    dial_code: '55',
    code: 'BR',
  },
  {
    name: 'British Indian Ocean Territory (British Indian Ocean Territory)',
    dial_code: '246',
    code: 'IO',
  },
  {
    name: 'Brunei Darussalam (Negara Brunei Darussalam)',
    dial_code: '673',
    code: 'BN',
  },
  {
    name: 'Bulgaria (България)',
    dial_code: '359',
    code: 'BG',
  },
  {
    name: 'Burkina Faso (Burkina Faso)',
    dial_code: '226',
    code: 'BF',
  },
  {
    name: 'Burundi (Burundi)',
    dial_code: '257',
    code: 'BI',
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    dial_code: '855',
    code: 'KH',
  },
  {
    name: 'Cameroon (Cameroun)',
    dial_code: '237',
    code: 'CM',
  },

  {
    name: 'Cape Verde (Cabo Verde)',
    dial_code: '238',
    code: 'CV',
  },
  {
    name: 'Cayman Islands (Cayman Island)',
    dial_code: ' 345',
    code: 'KY',
  },
  {
    name: 'Central African Republic (République Centrafricaine)',
    dial_code: '236',
    code: 'CF',
  },
  {
    name: 'Chad (تشاد)',
    dial_code: '235',
    code: 'TD',
  },
  {
    name: 'Chile (Chile)',
    dial_code: '56',
    code: 'CL',
  },
  {
    name: 'China (中国)',
    dial_code: '86',
    code: 'CN',
  },
  {
    name: 'Christmas Island (Christmas Island)',
    dial_code: '61',
    code: 'CX',
  },
  {
    name: 'Cocos (Keeling) Islands (Cocos Keeling Islands)',
    dial_code: '61',
    code: 'CC',
  },
  {
    name: 'Colombia (Colombia)',
    dial_code: '57',
    code: 'CO',
  },
  {
    name: 'Comoros (Komori)',
    dial_code: '269',
    code: 'KM',
  },
  {
    name: 'Congo (Congo)',
    dial_code: '242',
    code: 'CG',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo (République Démocratique du Congo)',
    dial_code: '243',
    code: 'CD',
  },
  {
    name: 'Cook Islands (Cook Islands)',
    dial_code: '682',
    code: 'CK',
  },
  {
    name: 'Costa Rica (Costa Rica)',
    dial_code: '506',
    code: 'CR',
  },
  {
    name: "Cote d'Ivoire (Côte d’Ivoire)",
    dial_code: '225',
    code: 'CI',
  },
  {
    name: 'Croatia (Hrvatska)',
    dial_code: '385',
    code: 'HR',
  },
  {
    name: 'Cuba (Cuba)',
    dial_code: '53',
    code: 'CU',
  },
  {
    name: 'Cyprus (Κύπρος)',
    dial_code: '357',
    code: 'CY',
  },
  {
    name: 'Czech Republic (Česko)',
    dial_code: '420',
    code: 'CZ',
  },
  {
    name: 'Denmark (Danmark)',
    dial_code: '45',
    code: 'DK',
  },
  {
    name: 'Djibouti (جيبوتي)',
    dial_code: '253',
    code: 'DJ',
  },
  {
    name: 'Dominica (Dominica)',
    dial_code: '1767',
    code: 'DM',
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    dial_code: '1849',
    code: 'DO',
  },
  {
    name: 'Ecuador (Ecuador)',
    dial_code: '593',
    code: 'EC',
  },
  {
    name: 'Egypt (مصر)',
    dial_code: '20',
    code: 'EG',
  },
  {
    name: 'El Salvador (El Salvador)',
    dial_code: '503',
    code: 'SV',
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    dial_code: '240',
    code: 'GQ',
  },
  {
    name: 'Eritrea (إرتريا)',
    dial_code: '291',
    code: 'ER',
  },
  {
    name: 'Estonia (Eesti)',
    dial_code: '372',
    code: 'EE',
  },
  {
    name: 'Ethiopia (ኢትዮጵያ)',
    dial_code: '251',
    code: 'ET',
  },
  {
    name: 'Falkland Islands (Malvinas) (Falkland Islands)',
    dial_code: '500',
    code: 'FK',
  },
  {
    name: 'Faroe Islands (Føroyar)',
    dial_code: '298',
    code: 'FO',
  },
  {
    name: 'Fiji (Viti)',
    dial_code: '679',
    code: 'FJ',
  },
  {
    name: 'Finland (Suomi)',
    dial_code: '358',
    code: 'FI',
  },
  {
    name: 'France (France)',
    dial_code: '33',
    code: 'FR',
  },
  {
    name: 'French Guiana (Guyane Française)',
    dial_code: '594',
    code: 'GF',
  },
  {
    name: 'French Polynesia (Polynésie française)',
    dial_code: '689',
    code: 'PF',
  },
  {
    name: 'Gabon (Gabon)',
    dial_code: '241',
    code: 'GA',
  },
  {
    name: 'Gambia (The Gambia)',
    dial_code: '220',
    code: 'GM',
  },
  {
    name: 'Georgia (საქართველო)',
    dial_code: '995',
    code: 'GE',
  },
  {
    name: 'Germany (Deutschland)',
    dial_code: '49',
    code: 'DE',
  },
  {
    name: 'Ghana (Ghana)',
    dial_code: '233',
    code: 'GH',
  },
  {
    name: 'Gibraltar (Gibraltar)',
    dial_code: '350',
    code: 'GI',
  },
  {
    name: 'Greece (Ελλάδα)',
    dial_code: '30',
    code: 'GR',
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    dial_code: '299',
    code: 'GL',
  },
  {
    name: 'Grenada (Grenada)',
    dial_code: '1473',
    code: 'GD',
  },
  {
    name: 'Guadeloupe (Guadeloupe)',
    dial_code: '590',
    code: 'GP',
  },
  {
    name: 'Guam (Guam)',
    dial_code: '1671',
    code: 'GU',
  },
  {
    name: 'Guatemala (Guatemala)',
    dial_code: '502',
    code: 'GT',
  },
  {
    name: 'Guernsey (Guernsey)',
    dial_code: '44',
    code: 'GG',
  },
  {
    name: 'Guinea (Guinée)',
    dial_code: '224',
    code: 'GN',
  },
  {
    name: 'Guinea-Bissau (Guiné-Bissau)',
    dial_code: '245',
    code: 'GW',
  },
  {
    name: 'Guyana (Guyana)',
    dial_code: '595',
    code: 'GY',
  },
  {
    name: 'Haiti (Haïti)',
    dial_code: '509',
    code: 'HT',
  },
  {
    name: 'Holy See (Vatican City State) (Civitas Vaticana,Status Civitatis Vaticanæ)',
    dial_code: '379',
    code: 'VA',
  },
  {
    name: 'Honduras (Honduras)',
    dial_code: '504',
    code: 'HN',
  },
  {
    name: 'Hong Kong (香港)',
    dial_code: '852',
    code: 'HK',
  },
  {
    name: 'Hungary (Magyárország)',
    dial_code: '36',
    code: 'HU',
  },
  {
    name: 'Iceland (Ísland)',
    dial_code: '354',
    code: 'IS',
  },
  {
    name: 'India (भारत)',
    dial_code: '91',
    code: 'IN',
  },
  {
    name: 'Indonesia (Indonesia)',
    dial_code: '62',
    code: 'ID',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf (جمهوری اسلامی ایران)',
    dial_code: '98',
    code: 'IR',
  },
  {
    name: 'Iraq (العراق)',
    dial_code: '964',
    code: 'IQ',
  },
  {
    name: 'Ireland (Éire)',
    dial_code: '353',
    code: 'IE',
  },
  {
    name: 'Isle of Man (Isle of Man)',
    dial_code: '44',
    code: 'IM',
  },
  {
    name: 'Israel (ישראל)',
    dial_code: '972',
    code: 'IL',
  },
  {
    name: 'Italy (Italia)',
    dial_code: '39',
    code: 'IT',
  },
  {
    name: 'Jamaica (Jamaica)',
    dial_code: '1876',
    code: 'JM',
  },
  {
    name: 'Japan (日本)',
    dial_code: '81',
    code: 'JP',
  },
  {
    name: 'Jersey (Jersey)',
    dial_code: '44',
    code: 'JE',
  },
  {
    name: 'Jordan (الأردن)',
    dial_code: '962',
    code: 'JO',
  },
  {
    name: 'Kazakhstan (Қазақстан)',
    dial_code: '7',
    code: 'KZ',
  },
  {
    name: 'Kenya (Kenya)',
    dial_code: '254',
    code: 'KE',
  },
  {
    name: 'Kiribati (Kiribati)',
    dial_code: '686',
    code: 'KI',
  },
  {
    name: "Korea, Democratic People's Republic of Korea (조선,조선민주주의인민공화국)",
    dial_code: '850',
    code: 'KP',
  },
  {
    name: 'Korea, Republic of South Korea (남한)',
    dial_code: '82',
    code: 'KR',
  },
  {
    name: 'Kuwait (الكويت)',
    dial_code: '965',
    code: 'KW',
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    dial_code: '996',
    code: 'KG',
  },
  {
    name: 'Laos (ລາວ)',
    dial_code: '856',
    code: 'LA',
  },
  {
    name: 'Latvia (Latvija)',
    dial_code: '371',
    code: 'LV',
  },
  {
    name: 'Lebanon (لبنان)',
    dial_code: '961',
    code: 'LB',
  },
  {
    name: 'Lesotho (Lesotho)',
    dial_code: '266',
    code: 'LS',
  },
  {
    name: 'Liberia (Liberia)',
    dial_code: '231',
    code: 'LR',
  },
  {
    name: 'Libyan Arab Jamahiriya (ليبيا)',
    dial_code: '218',
    code: 'LY',
  },
  {
    name: 'Liechtenstein (Liechtenstein)',
    dial_code: '423',
    code: 'LI',
  },
  {
    name: 'Lithuania (Lietuva)',
    dial_code: '370',
    code: 'LT',
  },
  {
    name: 'Luxembourg (Lëtzebuerg)',
    dial_code: '352',
    code: 'LU',
  },
  {
    name: 'Macao (澳門)',
    dial_code: '853',
    code: 'MO',
  },
  {
    name: 'Macedonia (Македонија)',
    dial_code: '389',
    code: 'MK',
  },
  {
    name: 'Madagascar (Madagasikara)',
    dial_code: '261',
    code: 'MG',
  },
  {
    name: 'Malawi (Malawi)',
    dial_code: '265',
    code: 'MW',
  },
  {
    name: 'Malaysia (Malaysia)',
    dial_code: '60',
    code: 'MY',
  },
  {
    name: 'Maldives (Dhivehi Raajje)',
    dial_code: '960',
    code: 'MV',
  },
  {
    name: 'Mali (Mali)',
    dial_code: '223',
    code: 'ML',
  },
  {
    name: 'Malta (Malta)',
    dial_code: '356',
    code: 'MT',
  },
  {
    name: 'Marshall Islands (Aorōkin M̧ajeļ)',
    dial_code: '692',
    code: 'MH',
  },
  {
    name: 'Martinique (Martinique)',
    dial_code: '596',
    code: 'MQ',
  },
  {
    name: 'Mauritania (موريتانيا)',
    dial_code: '222',
    code: 'MR',
  },
  {
    name: 'Mauritius (Mauritius)',
    dial_code: '230',
    code: 'MU',
  },
  {
    name: 'Mayotte (Mayotte)',
    dial_code: '262',
    code: 'YT',
  },
  {
    name: 'Mexico (México)',
    dial_code: '52',
    code: 'MX',
  },
  {
    name: 'Micronesia, Federated States of Micronesia (Federated States of Micronesia)',
    dial_code: '691',
    code: 'FM',
  },
  {
    name: 'Moldova (Moldova)',
    dial_code: '373',
    code: 'MD',
  },
  {
    name: 'Monaco (Monaco)',
    dial_code: '377',
    code: 'MC',
  },
  {
    name: 'Mongolia (Монгол Улс)',
    dial_code: '976',
    code: 'MN',
  },
  {
    name: 'Montenegro (Црна Гора)',
    dial_code: '382',
    code: 'ME',
  },
  {
    name: 'Montserrat (Montserrat)',
    dial_code: '1664',
    code: 'MS',
  },
  {
    name: 'Morocco (المغرب)',
    dial_code: '212',
    code: 'MA',
  },
  {
    name: 'Mozambique (Moçambique)',
    dial_code: '258',
    code: 'MZ',
  },
  {
    name: 'Myanmar (မြန်မာ)',
    dial_code: '95',
    code: 'MM',
  },
  {
    name: 'Namibia (Namibia)',
    dial_code: '264',
    code: 'NA',
  },
  {
    name: 'Nauru (Naoero)',
    dial_code: '674',
    code: 'NR',
  },
  {
    name: 'Nepal (नेपाल)',
    dial_code: '977',
    code: 'NP',
  },
  {
    name: 'Netherlands (Nederland)',
    dial_code: '31',
    code: 'NL',
  },
  {
    name: 'Netherlands Antilles (Nederlandse Antillen)',
    dial_code: '599',
    code: 'AN',
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    dial_code: '687',
    code: 'NC',
  },
  {
    name: 'New Zealand (New Zealand)',
    dial_code: '64',
    code: 'NZ',
  },
  {
    name: 'Nicaragua (Nicaragua)',
    dial_code: '505',
    code: 'NI',
  },
  {
    name: 'Niger (Niger)',
    dial_code: '227',
    code: 'NE',
  },
  {
    name: 'Nigeria (Nigeria)',
    dial_code: '234',
    code: 'NG',
  },
  {
    name: 'Niue (Niue)',
    dial_code: '683',
    code: 'NU',
  },
  {
    name: 'Norfolk Island (Norfolk Island)',
    dial_code: '672',
    code: 'NF',
  },
  {
    name: 'Northern Mariana Islands (Siha Na Islas Mariånass)',
    dial_code: '1670',
    code: 'MP',
  },
  {
    name: 'Norway (Norge)',
    dial_code: '47',
    code: 'NO',
  },
  {
    name: 'Oman (عُمان)',
    dial_code: '968',
    code: 'OM',
  },
  {
    name: 'Pakistan (پاکستان)',
    dial_code: '92',
    code: 'PK',
  },
  {
    name: 'Palau (Belau)',
    dial_code: '680',
    code: 'PW',
  },
  {
    name: 'Palestinian Territory, Occupied (فلسطين)',
    dial_code: '970',
    code: 'PS',
  },
  {
    name: 'Panama (Panamá)',
    dial_code: '507',
    code: 'PA',
  },
  {
    name: 'Papua New Guinea (Papua Niugini)',
    dial_code: '675',
    code: 'PG',
  },
  {
    name: 'Paraguay (Paraguay)',
    dial_code: '595',
    code: 'PY',
  },
  {
    name: 'Peru (Perú)',
    dial_code: '51',
    code: 'PE',
  },
  {
    name: 'Philippines (Pilipinas)',
    dial_code: '63',
    code: 'PH',
  },
  {
    name: 'Pitcairn (Pitcairn Islands)',
    dial_code: '872',
    code: 'PN',
  },
  {
    name: 'Poland (Polska)',
    dial_code: '48',
    code: 'PL',
  },
  {
    name: 'Portugal (Portugal)',
    dial_code: '351',
    code: 'PT',
  },
  {
    name: 'Puerto Rico (Puerto Rico)',
    dial_code: '1939',
    code: 'PR',
  },
  {
    name: 'Qatar (قطر)',
    dial_code: '974',
    code: 'QA',
  },
  {
    name: 'Romania (România)',
    dial_code: '40',
    code: 'RO',
  },
  {
    name: 'Russia (Россия)',
    dial_code: '7',
    code: 'RU',
  },
  {
    name: 'Rwanda (Rwanda)',
    dial_code: '250',
    code: 'RW',
  },
  {
    name: 'Reunion (Réunion)',
    dial_code: '262',
    code: 'RE',
  },
  {
    name: 'Saint Barthelemy (Saint-Barthélemy)',
    dial_code: '590',
    code: 'BL',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha (Saint Helena, Ascension and Tristan da Cunha)',
    dial_code: '290',
    code: 'SH',
  },
  {
    name: 'Saint Kitts and Nevis (Saint Kitts and Nevis)',
    dial_code: '1869',
    code: 'KN',
  },
  {
    name: 'Saint Lucia (Saint Lucia)',
    dial_code: '1758',
    code: 'LC',
  },
  {
    name: 'Saint Martin (Saint-Martin)',
    dial_code: '590',
    code: 'MF',
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre et Miquelon)',
    dial_code: '508',
    code: 'PM',
  },
  {
    name: 'Saint Vincent and the Grenadines (Saint Vincent and the Grenadines)',
    dial_code: '1784',
    code: 'VC',
  },
  {
    name: 'Samoa (Sāmoa)',
    dial_code: '685',
    code: 'WS',
  },
  {
    name: 'San Marino (San Marino)',
    dial_code: '378',
    code: 'SM',
  },
  {
    name: 'Sao Tome and Principe ()',
    dial_code: '239',
    code: 'ST',
  },
  {
    name: 'Saudi Arabia (العربية السعودية)',
    dial_code: '966',
    code: 'SA',
  },
  {
    name: 'Senegal (Sénégal)',
    dial_code: '221',
    code: 'SN',
  },
  {
    name: 'Serbia (Србија)',
    dial_code: '381',
    code: 'RS',
  },
  {
    name: 'Seychelles (Sesel)',
    dial_code: '248',
    code: 'SC',
  },
  {
    name: 'Sierra Leone (Sierra Leone)',
    dial_code: '232',
    code: 'SL',
  },
  {
    name: 'Singapore (Singapura)',
    dial_code: '65',
    code: 'SG',
  },
  {
    name: 'Slovakia (Slovensko)',
    dial_code: '421',
    code: 'SK',
  },
  {
    name: 'Slovenia (Slovenija2)',
    dial_code: '386',
    code: 'SI',
  },
  {
    name: 'Solomon Islands (Solomon Islands)',
    dial_code: '677',
    code: 'SB',
  },
  {
    name: 'Somalia (Soomaaliya)',
    dial_code: '252',
    code: 'SO',
  },
  {
    name: 'South Africa (Suid-Afrika)',
    dial_code: '27',
    code: 'ZA',
  },
  {
    name: 'South Sudan (جنوب السودان)',
    dial_code: '211',
    code: 'SS',
  },
  {
    name: 'South Georgia and the South Sandwich Islands (South Georgia and the South Sandwich Islands)',
    dial_code: '500',
    code: 'GS',
  },
  {
    name: 'Spain (España)',
    dial_code: '34',
    code: 'ES',
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    dial_code: '94',
    code: 'LK',
  },
  {
    name: 'Sudan (السودان)',
    dial_code: '249',
    code: 'SD',
  },
  {
    name: 'Suriname (Suriname)',
    dial_code: '597',
    code: 'SR',
  },
  {
    name: 'Svalbard and Jan Mayen (Svalbard)',
    dial_code: '47',
    code: 'SJ',
  },
  {
    name: 'Swaziland (Eswatini)',
    dial_code: '268',
    code: 'SZ',
  },
  {
    name: 'Sweden (Sverige)',
    dial_code: '46',
    code: 'SE',
  },
  {
    name: 'Switzerland (Schweiz)',
    dial_code: '41',
    code: 'CH',
  },
  {
    name: 'Syrian Arab Republic (الجمهوريّة العربيّة السّوريّة)',
    dial_code: '963',
    code: 'SY',
  },
  {
    name: 'Taiwan (臺灣)',
    dial_code: '886',
    code: 'TW',
  },
  {
    name: 'Tajikistan (Тоҷикистон)',
    dial_code: '992',
    code: 'TJ',
  },
  {
    name: 'Tanzania, United Republic of Tanzania (Tanzania ,Jamhuri ya Muungano wa Tanzania)',
    dial_code: '255',
    code: 'TZ',
  },
  {
    name: 'Thailand (เมืองไทย)',
    dial_code: '66',
    code: 'TH',
  },
  {
    name: 'Timor-Leste (Timor Lorosa’e)',
    dial_code: '670',
    code: 'TL',
  },
  {
    name: 'Togo (Togo)',
    dial_code: '228',
    code: 'TG',
  },
  {
    name: 'Tokelau (Tokelau)',
    dial_code: '690',
    code: 'TK',
  },
  {
    name: 'Tonga (Tonga)',
    dial_code: '676',
    code: 'TO',
  },
  {
    name: 'Trinidad and Tobago (Trinidad and Tobago)',
    dial_code: '1868',
    code: 'TT',
  },
  {
    name: 'Tunisia (تونس)',
    dial_code: '216',
    code: 'TN',
  },
  {
    name: 'Turkey (Türkiye)',
    dial_code: '90',
    code: 'TR',
  },
  {
    name: 'Turkmenistan (Türkmenistan)',
    dial_code: '993',
    code: 'TM',
  },
  {
    name: 'Turks and Caicos Islands (Turks and Caicos Islands)',
    dial_code: '1649',
    code: 'TC',
  },
  {
    name: 'Tuvalu (Tuvalu)',
    dial_code: '688',
    code: 'TV',
  },
  {
    name: 'Uganda (Uganda)',
    dial_code: '256',
    code: 'UG',
  },
  {
    name: 'Ukraine (Україна)',
    dial_code: '380',
    code: 'UA',
  },
  {
    name: 'United Arab Emirates (الإمارات العربيّة المتّحدة)[',
    dial_code: '971',
    code: 'AE',
  },
  {
    name: 'Uruguay (Uruguay)',
    dial_code: '598',
    code: 'UY',
  },
  {
    name: 'Uzbekistan (O’zbekiston)',
    dial_code: '998',
    code: 'UZ',
  },
  {
    name: 'Vanuatu (Vanuatu)',
    dial_code: '678',
    code: 'VU',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela (Venezuela, República Bolivariana de Venezuela)',
    dial_code: '58',
    code: 'VE',
  },
  {
    name: 'Vietnam (Việt Nam)',
    dial_code: '84',
    code: 'VN',
  },
  {
    name: 'Virgin Islands, British (British Virgin Islands)',
    dial_code: '1284',
    code: 'VG',
  },
  {
    name: 'Virgin Islands, U.S. (United States Virgin Islands)',
    dial_code: '1340',
    code: 'VI',
  },
  {
    name: 'Wallis and Futuna (Wallis et Futuna)',
    dial_code: '681',
    code: 'WF',
  },
  {
    name: 'Yemen (اليمن)',
    dial_code: '967',
    code: 'YE',
  },
  {
    name: 'Zambia (Zambia)',
    dial_code: '260',
    code: 'ZM',
  },
  {
    name: 'Zimbabwe (Zimbabwe)',
    dial_code: '263',
    code: 'ZW',
  },
]

export default allCountries.map((country) => ({
  name: country.name,
  country: country.code.toUpperCase(),
  code: country.dial_code,
  priority: 0,
  areaCodes: null,
}))
