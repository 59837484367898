<template>
  <Input
    id="code-verification-input"
    :value="value"
    :disabled="disabled"
    pattern="[0-9]"
    type="number"
    maxlength="4"
    @input="onInput"
  >
    <Icon slot="icon" :src="icon" size="s" />
  </Input>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    icon: {
      type: String,
      default: 'icons/icon-message.svg',
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    onInput(e) {
      if (e.length > 4) return

      if (e.length === 4) {
        this.$emit('complete', e)
      }

      this.$emit('change', e)
    },
  },
}
</script>
