<template>
  <PopupWrapper v-bind="$attrs" @close="onClose">
    <Typography variant="paragraph_xs" color="grey" class="mb-1 mt-3">{{
      $t('leadsPage.buttonName')
    }}</Typography>
    <Input
      v-model="pseudo_title"
      :error="$v.pseudo_title.$error"
      @blur="$v.pseudo_title.$touch()"
    />
    <Checkbox
      v-model="pseudo_is_show"
      :label="$t('leadsPage.showInPage')"
      class="mt-3 mb-1"
    ></Checkbox>
    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSaveButton">{{ btnText }}</Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input, Checkbox} from '@/components/ui'

export default {
  components: {
    PopupWrapper,
    Button,
    Input,
    Checkbox,
  },
  mixins: [validationMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
      required: false,
    },
  },
  validations: {
    data: {},
    pseudo_title: {required},
  },
  data: () => ({
    pseudo_is_show: false,
    pseudo_title: '',
  }),
  computed: {
    data: {
      get() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pseudo_is_show = this.value.is_show
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.pseudo_title = this.value.title
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    btnText() {
      return this.$t('common.save')
    },
  },
  mounted() {
    console.log(this.data)
  },
  methods: {
    onClose() {
      this.$emit('change', this.data)
      this.$emit('close')
      this.$v.$reset()
    },
    async onSaveButton() {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.data.is_show = this.pseudo_is_show
      this.data.title = this.pseudo_title

      const params = {
        button_type: this.data.button_type,
        title: this.data.title,
        is_show: this.data.is_show,
        is_run_funnel: false,
      }

      await this.$axios.$put(`/author/lead-page-buttons/${this.data.id}`, params)
      this.$emit('change', this.data)
      this.onClose()
    },
  },
}
</script>
