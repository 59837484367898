<template>
  <span
    v-if="src"
    ref="iconRef"
    :style="style"
    :class="[size, classes]"
    class="app-icon"
    v-on="$listeners"
  />
</template>

<script>
let cache = new Map()

const hasLeadSlashRE = /^\//
const hasTailSlashRE = /\/$/
const hasTailSvgRE = /\.svg\/?$/

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 's',
      validator: (s) => {
        return ['xxs', 'xs', 's', 'm', 'mm', 'l', 'll', 'xl', 'large'].includes(s)
      },
    },
    svgClass: {
      type: [String, Object],
      default: 'default-svg-class',
    },
    fab: Boolean,
    white: Boolean,
    fill: {
      type: [String, Boolean],
      default: false,
    },
    opacity: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      fillColor: this.fill,
    }
  },
  computed: {
    classes() {
      const defaultColor = [
        'initial',
        'inherit',
        'dark',
        'light',
        'grey',
        'green',
        'success',
        'error',
        'purple',
      ].includes(this.fillColor)

      return {
        [this.svgClass]: true,
        fab: this.fab,
        white: this.white && this.svgClass === 'default-svg-class',
        fill: !defaultColor && this.fillColor,
        [`fill-${this.fillColor}`]: defaultColor && this.fillColor,
      }
    },
    style() {
      return {
        ...(this.classes.fill && {'--icon-fill': this.fillColor}),
        '--icon-opacity': this.opacity,
      }
    },
  },
  watch: {
    src() {
      this.load()
    },
    fill(newValue) {
      this.fillColor = newValue
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    setSvgClass() {
      if (this.$el.children && this.$el.children.length) {
        const svg = this.$el.children[0]

        if (!this.svgClass || typeof this.svgClass !== 'string') {
          console.log('COMPONENT - Icon: svgClass should be a string')
          return
        }
        const classess = this.svgClass.trim().split(' ')

        classess.forEach((cls) => {
          svg.classList.add(cls)
        })
      }
    },
    async load() {
      if (!this.src.includes('/')) return
      // $TODO: CHANGE TO REAL STATIC PATH FROM ENV!!!
      // const STATIC_PATH = process.env.STATIC_PATH
      const STATIC_PATH = '/'
      const sep = !hasTailSlashRE.test(STATIC_PATH) ? '/' : ''
      let src = this.src

      if (hasTailSlashRE.test(src)) {
        src = src.slice(0, -1)
      }
      if (!hasTailSvgRE.test(src)) {
        src += '.svg'
      }
      if (hasLeadSlashRE.test(src)) {
        src = src.slice(1)
      }
      src = STATIC_PATH + sep + src

      if (!cache.has(src)) {
        try {
          cache.set(
            src,
            fetch(src).then((r) => r.text())
          )
        } catch (e) {
          cache.delete(src)
        }
      }
      if (cache.has(src)) {
        this.$el.innerHTML = await cache.get(src)
        this.$el.firstElementChild.style = 'height: inherit; width: inherit;'
      }

      this.setSvgClass()
    },
  },
}
</script>

<style lang="scss">
.app-icon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  opacity: var(--icon-opacity, inherit);

  &:hover {
    opacity: 1;
  }

  &.default-svg-class svg {
    fill: #cdd4dd;
  }

  &.white svg {
    fill: #fff;
  }

  &.fab {
    cursor: pointer;
    padding: 16px;
    border-radius: 50%;
    transition: 0.2s;

    svg {
      flex-shrink: 0;
    }

    &:hover,
    &:focus-within {
      background: $purple;
      svg {
        fill: #fff;
      }
    }
  }

  &.fill {
    svg {
      fill: var(--icon-fill);
    }

    &-dark {
      svg {
        fill: $black;
      }
    }
    &-light {
      svg {
        fill: #fff;
      }
    }
    &-grey {
      svg {
        fill: $textGrey;
      }
    }
    &-green,
    &-success {
      svg {
        fill: $green;
      }
    }
    &-purple {
      svg {
        fill: $purpleText;
      }
    }
    &-error {
      svg {
        fill: $error;
      }
    }
    &-inherit {
      svg {
        fill: inherit;
      }
    }
  }

  &.xxs {
    width: 8px;
    height: 8px;
  }
  &.xs {
    height: 12px;
    width: 12px;
  }
  &.s {
    height: 16px;
    width: 16px;
  }
  &.m {
    height: 20px;
    width: 20px;
  }
  &.mm {
    height: 24px;
    width: 24px;
  }
  &.l {
    height: 32px;
    width: 32px;
  }
  &.ll {
    width: 40px;
    height: 40px;
  }
  &.xl {
    height: 53px;
    width: 53px;
  }
  &.large {
    width: 128px;
    height: 128px;
  }
}
</style>
