export const namespaced = true

export const state = () => ({
  uploadFilePercentage: 0,
  vimeoIframe: null,
  currentBlockItem: {},
})

export const getters = {
  getCurrentBlockItem: (state) => state.currentBlockItem,
}

export const actions = {
  async getVimeoVideo({commit, dispatch}, vimeoUrl) {
    try {
      const instance = this.$axios.create()
      const config = {
        crossDomain: true,
        transformRequest: [
          (data, headers) => {
            delete headers.common.Authorization
            delete headers.Authorization
            return data
          },
        ],
      }

      const response = await instance.get(
        `https://vimeo.com/api/oembed.json?url=${vimeoUrl}`,
        config
      )

      commit('setLessonVimeo', response.data)
    } catch (error) {}
  },
}

export const mutations = {
  setUploadFileLessonPercentage(state, payload) {
    state.uploadFilePercentage = payload
  },
  setLessonVimeo(state, payload) {
    // todo add logic here
    state.currentBlockItem.content.details.video_url = payload.provider_url + payload.video_id
    state.vimeoIframe = payload
  },
}
