<template>
  <PopupWrapper :title="titleText" v-bind="$attrs" @close="onClose">
    <Input
      v-model="lead.name"
      :error="$v.lead.name.$error"
      :label="$t('subscribe.headerName')"
      @blur="$v.lead.name.$touch()"
    />

    <Button class="mt-1" icon="/icons/bg-plus.svg" @click="onSave">{{ btnText }}</Button>
  </PopupWrapper>
</template>

<script>
import {isEmpty} from 'ramda'
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import {PopupWrapper, Button, Input} from '@/components/ui'

const defState = () => ({
  name: '',
})

export default {
  components: {
    PopupWrapper,
    Button,
    Input,
  },
  mixins: [validationMixin],
  props: {
    editLead: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    lead: {
      name: {required},
    },
  },
  data: () => ({
    lead: defState(),
  }),
  computed: {
    leadNameErr() {
      if (!this.$v.name.name.required) return this.$t('forms.rules.requiredField')
      return ''
    },
    isEditing() {
      return !isEmpty(this.editLead)
    },
    btnText() {
      return this.isEditing ? this.$t('widget2.padedt') : this.$t('widgets.add')
    },
    titleText() {
      return this.isEditing ? this.$t('widget2.padedt') : this.$t('widgets.add')
    },
    canDelete() {
      // is editing
      return this.isEditing && this.botId
    },
    hardCodePolicy() {
      return (
        '<br>' +
        this.$t('company.privacy.agree') +
        ' <a href="https://meleton.com/docs/user-agreement.pdf">' +
        this.$t('company.privacy.offer') +
        '</a> ' +
        this.$t('common.and') +
        '<br /><a href="https://meleton.com/docs/privacy-policy.pdf">' +
        this.$t('company.privacy.personalDataProcessingPolicy') +
        '</a>'
      )
    },
  },
  watch: {
    editLead(v) {
      this.lead = {
        name: this.editLead.name,
      }
      this.leadId = this.editLead.id
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.lead = defState()
      this.leadId = null
      this.$v.lead.$reset()
    },
    async onSave() {
      this.$v.lead.$touch()
      if (this.$v.lead.$invalid) return

      const lead = this.lead
      const params = {
        title: lead.name,
        theme_color: 'light',
        base_color: '#ffffff',
        font_color: '#000000',
        display: 'horizontal',
        user_policy: this.hardCodePolicy,
        fields: [
          {
            type: 'title_field',
            is_visible: true,
            content: this.$t('minilanding.subscribe'),
            extra_style: {
              isHtml: true,
              foreColor: {title: this.$t('widget2.padtclr'), value: ''},
              fontClass: 'font-sans',
              size: 1,
              alignClass: 'text-center',
            },
          },
          {
            type: 'description_field',
            is_visible: true,
            content: this.$t('widget2.txt1'),
            extra_style: {
              isHtml: true,
              foreColor: {title: this.$t('widget2.padtclr'), value: ''},
              fontClass: 'font-sans',
              size: 1,
              alignClass: 'text-center',
            },
          },
          {
            type: 'name_field',
            is_visible: true,
            content: this.$t('widget2.txt2'),
            extra_style: {
              isHtml: false,
            },
          },
          {
            type: 'email_field',
            is_visible: true,
            content: this.$t('widget2.txt3'),
            extra_style: {
              isHtml: false,
            },
          },
          {
            type: 'phone_field',
            is_visible: true,
            content: this.$t('widget2.txt4'),
            extra_style: {
              isHtml: false,
            },
          },
          {
            type: 'button_field',
            is_visible: true,
            content: this.$t('minilanding.subscribe'),
            extra_style: {
              isHtml: false,
              urlToRedirect: '',
              fontClass: 'font-sans',
              size: 1,
              backColor: {title: this.$t('popups.btnColor'), value: '#959bcb'},
              foreColor: {title: this.$t('widget2.padtclr'), value: ''},
            },
          },
          {
            type: 'agreement_field',
            is_visible: true,
            content: this.$t('widget2.txt5'),
            extra_style: {
              isHtml: true,
              foreColor: {title: this.$t('widget2.padtclr'), value: ''},
              fontClass: 'font-sans',
              size: 1,
              alignClass: 'text-center',
            },
          },
        ],
      }

      if (!this.isEditing) {
        try {
          let data = await this.$axios.$post('/author/course-widgets', params)
          this.$router.push(`/widgetswizard/${data.id}/editor`)
        } catch (err) {
          console.log(err)
        }
      }
      this.onClose()
    },
  },
}
</script>
