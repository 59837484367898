const locales = {
  'es.meleton.com': 'es',
  'de.meleton.com': 'de',
  'meleton.ru': 'ru',
  'meleton.com': 'en',
}

const isAppProd = process.env.APP_ENV === 'production'

export const setLocale = ({app, req, res}) => {
  if (!isAppProd) return
  if (req) {
    console.log(req.headers)
    console.log(res)
    const lc = locales[req.headers.host]
    if (!lc) {
      app.i18n.defaultLocale = 'en'
      return
    }
    app.i18n.setLocale(lc)
    app.i18n.defaultLocale = lc
    req.headers['Accept-Language'] = lc
  }
}
