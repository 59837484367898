<template>
  <div :class="['user-avatar', dimensions[size], {initials, light}]" :data-name="initials">
    <AsyncImage
      v-if="!isStatic && avatar && !imgErr"
      :src="{image: avatar}"
      @imageError="handleErr"
    />
    <img v-if="isStatic" class="user-avatar__static" :src="avatar" />

    <Icon
      v-if="!avatar && !initials && !imgErr"
      size="xs"
      src="/icons/profile-user-2.svg"
      class="user-avatar__icon"
    ></Icon>
  </div>
</template>

<script>
import AsyncImage from '~ui/Image/AsyncImage'
import {head, prop, take, split, join, pipe, map} from 'ramda'

export default {
  components: {
    AsyncImage,
  },
  props: {
    isStatic: Boolean,
    user: {
      type: Object,
      default: () => ({
        full_name: '',
        avatar: '',
      }),
    },
    data: {
      type: Object,
      default: () => ({name: '', avatar: ''}),
    },
    size: {
      type: String,
      default: 's',
      validator: (v) => ['auto', 'xxs', 'xs', 's', 'm', 'l'].includes(v),
    },
    light: Boolean,
  },
  data: () => ({
    imgErr: false,
  }),
  computed: {
    avatar() {
      return prop('avatar', this.user) || prop('avatar', this.data)
    },
    initials() {
      const source = this.user.full_name || this.data.name
      if (!source) return ''

      return pipe(split(' '), take(2), map(head), join(''))(source)
    },
    dimensions() {
      return {
        xxs: 'xxs',
        xs: 'xs',
        s: 's',
        m: 'm',
        l: 'l',
        auto: '',
      }
    },
  },
  methods: {
    handleErr(e) {
      this.imgErr = true
    },
  },
}
</script>

<style lang="scss" scoped>
.user-avatar {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  background-color: #96a2b4;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: attr(data-name);
    position: absolute;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }

  &__static {
    z-index: 10;
  }
  &.light {
    background-color: $lightGrey;

    &:before {
      color: $elementsGrey;
    }
  }

  &__icon {
    cursor: default;
  }

  &.xxs {
    width: 24px;
    height: 24px;
    font-size: 10px;
  }
  &.xs {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  &.xs {
    width: 32px;
    height: 32px;
  }

  &.s {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
  &.m {
    width: 90px;
    height: 90px;
    font-size: 24px;
    @media screen and (max-width: 425px) {
      width: 50px;
      height: 50px;
      font-size: 16px;
    }
  }

  &.l {
    width: 128px;
    height: 128px;
    font-size: 28px;
  }
}
</style>
