/* video res */
export const RESOLUTIONS = [
  {
    title: 'Low',
    value: {
      height: {exact: 240},
      width: {exact: 320},
    },
  },

  {
    title: 'Medium',
    value: {
      height: {max: 480},
      width: {max: 640},
    },
  },
  {
    title: 'HD',
    value: {
      height: {min: 720, ideal: 720},
      width: {min: 1280, ideal: 1280},
    },
  },
  {
    title: 'Full HD',
    value: {
      height: {ideal: 1080, min: 1080},
      width: {ideal: 1920, min: 1920},
    },
  },
]

export const DEFAULT_CONSTRAINTS = Object.freeze({
  video: {
    ...RESOLUTIONS[2].value,
    facingMode: 'user',
  },
  audio: {
    autoGainControl: false,
    channelCount: 1,
    echoCancellation: false,
    latency: 0.005,
    noiseSuppression: false,
    sampleRate: 48000,
    sampleSize: 16,
    volume: 0.7,

    googEchoCancellation: true,
    googEchoCancellation2: true,
    googNoiseSuppression: true,
    googNoiseSuppression2: true,
    googAutoGainControl: true,
    googAutoGainControl2: true,
    googHighpassFilter: true,
    googTypingNoiseDetection: true,
    googAudioMirroring: false,
  },
})
