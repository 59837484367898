import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {prop} from 'ramda'

const root = {root: true}

export const state = () => ({})

export const actions = {
  async requestCode({commit, dispatch}) {
    try {
      const data = await this.$axios.$post('/register/phone-confirmation')
      return data
    } catch (err) {}
  },
  async confirmCode({commit, dispatch}, code) {
    const data = await this.$axios.$post('/register/verify-sms-code', {code})

    if (prop('success', data)) {
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.phoneVerified'),
          type: 'success',
          dismissAfter: 4000,
        },
        root
      )
    }
    return data
  },
  async change({commit, dispatch}, payload) {
    const data = await this.$axios.$patch('/user/change-phone', {
      phone_number: payload.phone_number,
      country_code: payload.country_code,
      phone_code: payload.phone_code,
    })

    commit('user/SET', data, root)
    return data
  },
}
