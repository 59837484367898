<template>
  <div class="widget-search__list" :class="{removable}" @click.self.prevent>
    <div class="backdrop" @click.self.prevent></div>
    <!-- item  -->

    <div v-if="!isEmpty && !manyProducts" class="search-options">
      <div v-for="(item, k) in renderData" :key="k" class="options fixsize">
        <div v-if="item.category_title" class="flex items-center label mt-1">
          <Icon class="mr-2" src="icons/product-icon.svg" size="xs"></Icon>
          <Typography variant="int_xs" color="light">{{ item.category_title }}</Typography>
        </div>
        <div v-if="notEmpty(item.packs)" class="options-item">
          <div class="label">{{ $t('minilanding.packages') }}:</div>
          <ProductItem
            v-for="pack in item.packs"
            v-show="!filter.includes(pack)"
            :key="pack.id"
            :item="pack"
            :removable="removable"
            type="packet"
            @action="onAction"
          />
        </div>
        <div v-if="notEmpty(item.modules)" class="options-item">
          <div class="label">{{ $t('module.more') }}:</div>
          <ProductItem
            v-for="modul in item.modules"
            v-show="!filter.includes(modul)"
            :key="modul.id"
            :item="modul"
            :removable="removable"
            @action="onAction"
          />
        </div>
      </div>
    </div>
    <div v-else-if="!isEmpty && manyProducts" class="search-options">
      <div class="options fixsize">
        <ProductItem
          v-for="(item, k) in renderData"
          :key="k"
          :item="item"
          :removable="removable"
          manyProducts
          @action="onAction"
        />
      </div>
    </div>
    <div v-if="isEmpty && !removable" class="options fixsize">
      <div class="options-empty">
        <span class="mb-1">{{ emptyLabel }}</span>
        <NuxtLink class="underline" target="_blank" rel="noopener" :to="{name: 'courses.list'}">
          {{ $t('common.goToCreateCourse') }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script>
import {isEmpty} from 'ramda'

export default {
  components: {
    ProductItem: () => import('./ProductListItem'),
  },
  props: {
    manyProducts: Boolean,
    products: {
      type: [Array, Object],
      default: () => [],
    },
    filter: {
      type: Array,
      default: () => [],
    },
    emptyLabel: {
      type: String,
      default() {
        return this.$t('widgets.nothingHave')
      },
    },
    removable: Boolean,
  },

  computed: {
    isEmpty() {
      return isEmpty(this.products)
    },
    renderData() {
      return this.products
    },
  },
  methods: {
    onAction(action, product) {
      this.$emit(action, product)
    },
    notEmpty(xs) {
      return !isEmpty(xs)
    },
  },
}
</script>

<style lang="scss" scoped>
.fixsize {
  @media (max-width: 485px) {
    width: 85vw !important;
  }
}
.widget-search__list {
  &.removable {
    @include scrollable;
    max-height: 290px;
    margin-top: 24px;

    .label {
      display: none;
    }

    .options {
      border-top: 1px solid $lightGrey;
    }

    .options-item {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
  }

  &:not(.removable) {
    position: absolute;
    width: 100%;
    left: 0;
    top: 60px;
    z-index: 200;

    .options {
      z-index: 100;
    }

    .backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      background-color: #96a2b4;
      opacity: 0.9;
    }

    .label {
      padding: 0 20px;
      color: #fff;
      margin-bottom: 12px;
    }
  }

  .search-options {
    position: relative;
    max-height: 300px;
    overflow: auto;
  }

  .options {
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 16px;
    display: flex;
    flex-flow: column;

    &:last-child {
      padding-bottom: 12px;
    }

    .options-item {
      font-size: 12px;
      display: flex;
      flex-flow: column;
      padding-bottom: 12px;
      &:first-child {
        padding-top: 12px;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }

    .options-empty {
      padding: 20px;
      padding-bottom: 8px;
      text-align: center;
    }
  }
}
</style>
