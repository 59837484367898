<template>
  <div :class="{...rootClasses, focused}">
    <label :for="id" class="app-textarea-label">
      <slot>{{ label }}</slot>
      <UiTooltip v-if="Boolean(tooltip)" class="ml-2" display="inline" :content="tooltip" />
    </label>
    <div class="app-textarea-wrap">
      <RawTextarea
        :id="id"
        :ref="id"
        :value="value"
        :autoHeight="autoHeight"
        v-bind="$attrs"
        v-on="$listeners"
        @blur="onBlur"
      ></RawTextarea>
      <span v-show="error" class="app-textarea-error">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import UiTooltip from '@/components/ui/UiTooltip'
import RawTextarea from './RawTextarea'

function genId() {
  return `mel-textarea-${Math.random().toString(36).substring(2, 10)}`
}

export default {
  name: 'UiTextarea',
  components: {UiTooltip, RawTextarea},
  inheritAttrs: false,
  props: {
    id: {type: String, default: genId()},
    value: {type: String, default: ''},
    label: {type: String, default: ''},
    error: {type: [String, Boolean], default: false},
    errorMessage: {
      type: String,
      default() {
        return this.$t('forms.rules.requiredField')
      },
    },
    autoHeight: Boolean,
    focused: Boolean,
    variant: {
      type: String,
      default: 'flat',
      validator: (v) => ['default', 'flat'].includes(v),
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        'app-textarea': true,
        [`app-textarea--${this.variant}`]: Boolean(this.variant),
        'app-textarea--error': this.error,
      }
    },
  },
  methods: {
    blur() {
      this.$refs[this.id].blur()
    },
    focus() {
      this.$refs[this.id].focus()
    },
    onBlur(e) {
      this.$emit('blur', e)
    },
  },
}
</script>

<style lang="scss">
.app-textarea {
  position: relative;
  margin: 0 0 30px;
  color: $black;
  width: 100%;

  &-label {
    display: block;
    margin: 0 0 16px;
    color: $textGrey;
    font-size: 14px;
    line-height: 18px;
  }
  &-wrap {
    position: relative;
    display: flex;
    padding: 0 0 16px;
    margin-bottom: 16px;
    transition: 0.2s;
  }

  &-error {
    position: absolute;
    bottom: -30px;
    height: 26px;
    left: 0;
    color: $error;
    font-size: 12px;
  }

  &--flat,
  &--flat.focused {
    .app-textarea-wrap {
      border-bottom: 1px solid rgba(150, 162, 180, 0.15);
    }
  }

  &--default,
  &--default.focused {
    .app-textarea-wrap {
      background-color: #f2f5f9;
      padding: 16px;
      border-radius: 16px;
    }
  }

  &--error {
    &.app-textarea--flat {
      .app-textarea-wrap {
        border-bottom-color: $error;
      }
    }
    &.app-textarea--default {
      .app-textarea-wrap {
        border: 1px solid $error;
      }
    }
  }
}
</style>
