<template>
  <div class="search-products-selector">
    <Input
      ref="widget-search-input"
      v-model="searchText"
      :loading="isLoading"
      :error="error"
      :errorMessage="errorMessage"
      :placeholder="$t('common.srcbyprod')"
      @input="debouncedSearch"
      @focus="setActive(true)"
      @blur="onSearchBlur"
    >
      <Icon slot="icon" src="/icons/search-loopa.svg" />
    </Input>

    <ProductList
      v-show="savedProducts"
      :products="savedProducts"
      removable
      :manyProducts="true"
      @remove="removeFromSaved"
    />

    <Transition name="fade">
      <ProductList
        v-show="isInputActive"
        :products="prod"
        :filter="savedProducts"
        @select="selectItem"
        @mousedown.native.prevent="onMousedown"
        @mouseup.native="onMouseUp"
      />
    </Transition>
  </div>
</template>

<script>
import ProductList from './ProductSelect/ProductList'
import debounce from '@/utils/debounce'

export default {
  components: {ProductList},
  props: {
    error: Boolean,

    errorMessage: {
      type: String,
      default: '',
    },
    currencyFilter: {
      type: Object,
      default: () => {},
    },
    initialList: {
      type: Array,
      default: () => [],
    },
    prefetch: Boolean,
  },
  data: () => ({
    prod: [],
    searchText: '',
    isLoading: false,
    isInputActive: false,
    mousedown: false,
    savedProducts: [],
  }),
  watch: {
    currencyFilter() {
      this.savedProducts = []
      this.searchProducts()
    },
  },
  created() {
    if (this.initialList.length) {
      this.savedProducts = this.initialList
    }
  },
  mounted() {
    if (this.prefetch) {
      this.searchProducts()
    }
  },
  methods: {
    debouncedSearch: debounce(function (q) {
      this.searchProducts(q)
    }, 1000),
    async searchProducts(query) {
      try {
        this.isLoading = true
        const data = await this.$axios.$get(`/author/products/search`, {
          params: {search: query, filter_by_currency: this.currencyFilter},
        })
        this.prod = data
      } catch (err) {
        console.log(err)
      } finally {
        this.isLoading = false
      }
    },
    onMousedown() {
      this.mousedown = true
    },
    onSearchBlur() {
      if (this.mousedown) {
        this.mousedown = false
      } else {
        this.setActive(false)
      }
    },
    onMouseUp() {
      this.mousedown = false
    },
    setActive(b) {
      this.isInputActive = b
      if (b) this.$emit('open')
    },
    selectItem(item) {
      this.setActive(false)
      this.addToSaved(item)
      this.$emit('select', item)
    },
    addToSaved(item) {
      this.savedProducts = [...this.savedProducts, item]
      this.$refs['widget-search-input'].setBlur()
      this.updateProducts()
    },
    removeFromSaved(item) {
      this.savedProducts = this.savedProducts.filter((val) => val.id !== item.id)

      this.updateProducts()
    },
    updateProducts() {
      this.$emit('update', this.savedProducts)
    },
  },
}
</script>

<style lang="scss">
.search-products-selector {
  width: 100%;

  position: relative;
  box-sizing: border-box;
  margin-top: 20px;
  border-radius: 16px;
  color: #2e3141;
  transition: 0.2s ease;
  transition-property: background-color, border;
  will-change: background-color, border;

  .app-input__wrap {
    margin-bottom: 0;
  }

  .app-input svg {
    fill: $black;
  }
}
</style>
