import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {prop} from 'ramda'
import {formatCurrency} from '@/utils'

const root = {root: true}

const SET_USERS = 'SET_USERS'
const SET_LOADING = 'SET_LOADING'
const SET_META = 'SET_META'
const SET_STATS = 'SET_STATS'
const UPD_USER_LIST = 'UPD_USER_LIST'

const FETCH_USERS = 'FETCH_USERS'
const FETCH_USERCARD = 'FETCH_USERCARD'
const FILTER_USERS = 'FILTER_USERS'
const ASSIGN_TAGS = 'ASSIGN_TAGS'
const UPDATE_FROM_SOCKET = 'UPDATE_FROM_SOCKET'
const EXPORT_EXCEL = 'EXPORT_EXCEL'

const sumObjectsByKey = (...objs) => {
  return objs.reduce((a, b) => {
    for (let k in b) {
      // eslint-disable-next-line
      if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k]
    }
    return a
  }, {})
}

export const state = () => ({
  stats: {},
  usersList: [],
  userCard: null,
  isLoading: true,
  meta: {},
})

const applyIfExist = (val, fn) => (val ? fn(val) : val)

export const getters = {
  hasData: (state) => state.usersList.length > 0,
  getUsersList: (state) => state.usersList,
  userCard: (state) => state.userCard,
  userTags: (state) => prop('tags', state.userCard),
  isLoading: (state) => state.isLoading,
  totalContacts: (state) => state.meta.total,
  totalNotifications: (state) => prop('total_notifications_counter', state.stats),
  pagination: (state) => state.meta,

  totalBuyAmount: (state) => {
    return /* applyIfExist( */ prop('total_buy_amount', state.stats) /*, formatCurrency ) */
  },

  overallAmount: (state) => {
    return /* applyIfExist( */ prop('overall_amount', state.stats) /*, formatCurrency ) */
  },

  unpaidOrders: (state) => {
    return /* applyIfExist( */ prop('not_paid_amount', state.stats) /*, formatCurrency ) */
  },

  outsidePlatformAmount: (state) => {
    return /* applyIfExist( */ prop('outside_platform_amount', state.stats) /*, formatCurrency ) */
  },

  currencySet: (state) => {
    return /* applyIfExist( */ prop('available_currencies', state.stats) /*, formatCurrency ) */
  },
}

export const mutations = {
  [SET_LOADING](state, bool) {
    state.isLoading = bool
  },

  [SET_USERS](state, payload) {
    state.usersList = payload
  },
  [SET_META](state, payload) {
    state.meta = payload
  },
  [SET_STATS](state, payload) {
    state.stats = payload
  },

  setUserCard(state, payload) {
    state.userCard = payload
  },

  [UPD_USER_LIST](state, payload) {
    const newUserList = [payload, ...state.usersList]

    state.usersList = Array.from(new Set(newUserList.map(({id}) => id)), (id) =>
      newUserList.find((user) => user.id === id)
    )
  },

  updateTotalSales(state, payload) {
    if (state.stats) {
      state.stats.total_buy_amount = payload
    }
  },

  updateTotalNotifications(state, payload) {
    if (state.stats) {
      state.stats.total_notifications_counter = payload
    }
  },
}

export const actions = {
  [UPDATE_FROM_SOCKET]({commit, state, getters, dispatch}, notification) {
    if (!getters.hasData) return

    commit(SET_STATS, sumObjectsByKey(state.stats, notification.stats))
    commit(UPD_USER_LIST, notification.user)

    dispatch('crmUserLog/UPDATE_LOG_LIST', notification.activityLog, {root: true})
  },
  async [FETCH_USERS]({commit, dispatch}) {
    commit(SET_LOADING, true)
    try {
      const data = await this.$axios.$get('/crm/users')
      const usersList = data.data.collection

      commit(SET_USERS, usersList)
      commit(SET_META, data.meta)
      commit(SET_STATS, data.data.stats)
    } catch (err) {
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [FILTER_USERS]({commit, dispatch}, filterQuery) {
    commit(SET_LOADING, true)
    try {
      const response = await this.$axios.get('/crm/users', {
        params: filterQuery,
      })

      commit(SET_META, response.data.meta)
      commit(SET_USERS, response.data.data.collection)
      commit(SET_STATS, response.data.data.stats)
    } catch (err) {
    } finally {
      commit(SET_LOADING, false)
    }
  },
  async [ASSIGN_TAGS]({commit, dispatch}, {id, tags}) {
    const data = await this.$axios.$patch(`/crm/users/${id}/assign-tags`, {
      tags,
    })
    commit('setUserCard', data)
  },

  async switchPage({commit, dispatch}, pageNum) {
    commit(SET_LOADING, true)

    try {
      const response = await this.$axios.get('/crm/users', {
        params: pageNum,
      })

      commit(SET_USERS, response.data.data.collection)
    } catch (err) {
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async sortUsersList({commit, dispatch}, sortBy) {
    commit(SET_LOADING, true)

    try {
      const response = await this.$axios.get('/crm/users', {params: sortBy})

      const responseData = response.data
      const usersList = responseData.data.collection

      commit(SET_USERS, usersList)
    } catch (err) {
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [FETCH_USERCARD]({commit, dispatch}, id) {
    try {
      const response = await this.$axios.get(`/crm/users/${id}`)

      commit('setUserCard', response.data)
    } catch (err) {}
  },

  async postContactForm({commit, dispatch}, data) {
    try {
      const params = {
        name: data.name,
        phone_number: data.phone,
        message: data.message,
      }

      await this.$axios.post('/users/contact-form', params)

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.msgSent'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },
  async [EXPORT_EXCEL]({rootGetters}) {
    const filterQuery = rootGetters['crmFilter/crmFilterQuery']
    try {
      const response = await this.$axios.post(
        '/crm/export',
        {
          params: filterQuery,
        },
        {
          responseType: 'arraybuffer',
        }
      )
      downloadFile(response)
    } catch (err) {
      console.log(err)
    }
  },
}

function downloadFile(response) {
  const data = response.data
  const type = response.headers['content-type'] || 'application/octet-stream'

  const blob = new Blob([data], {type})
  const furl = window.URL.createObjectURL(blob)
  const flink = document.createElement('a')

  flink.href = furl
  flink.setAttribute('download', 'crm-export.xlsx')
  document.body.appendChild(flink)
  flink.click()

  document.body.removeChild(flink)
}
