import Vue from 'vue'
import {reject, propEq, prop} from 'ramda'
import {isWebinarShouldStart, isWebinarEnded, isWebinarExpired} from '@/features/Webinars/'
import {removeObjectByKey, updateObjectByKey} from '@/utils/'

/* TODO:
  - разделить на саб-модули
  - хранить в инстансе закодированную и декодированную часть ссылки encode/decode(uuid + id)
  - переписать обращения к спискам и инстансу на mapState, в геттерах хранить только calculated значения
*/

export const state = () => ({
  webinars: [], // webinars list (author/student)
  publicWebinars: [], // public webinars
  webinar: {}, // current webinar (guest-room, webinar room)
  participants: [], // webinars participants
  isScreenSharing: false,
})

export const getters = {
  webinars: (state) => state.webinars, // (student/author)
  publicWebinars: (state) => state.publicWebinars,
  webinar: (state) => state.webinar,
  participants: (state) => state.participants || [],
  speakers: (state) => state.webinar.speakers || [],
  buttons: (state) => state.webinar.buttons || [],
  webinarIsActive: (state) => state.webinar.is_active,
  webinarShouldStart: (state) => isWebinarShouldStart(state.webinar),
  webinarIsEnded: (state, getters) => isWebinarEnded(state.webinar),
  webinarExpired: (state) => isWebinarExpired(state.webinar),
  webinarStatus: (state) => prop('status', state.webinar),
  isScreenSharing: (state) => state.isScreenSharing,
  webinarPreview: (state) => prop('preview_image_url', state.webinar),
}

export const mutations = {
  /* webinar */
  SET_WEBINARS(state, payload) {
    state.webinars = payload
  },

  SET_PUBLIC_WEBINARS(state, payload) {
    state.publicWebinars = payload
  },

  /* todo - заменить на UPDATE_WEBINAR */
  SET_WEBINAR(state, payload) {
    state.webinar = payload
  },

  UPDATE_WEBINAR(state, payload) {
    Object.keys(payload).forEach((key) => {
      Vue.set(state.webinar, key, payload[key])
    })
  },

  SET_WEBINAR_STATUS(state, payload) {
    const {is_active = false, id} = payload || {}

    state.webinar = {...state.webinar, is_active}

    state.webinars = [...state.webinars.map((w) => (w.id !== id ? w : {...w, is_active}))]
    state.publicWebinars = [
      ...state.publicWebinars.map((w) => (w.id !== id ? w : {...w, is_active})),
    ]
  },

  ADD_WEBINAR(state, payload) {
    state.webinars.unshift(payload)
  },

  /* participants */
  SET_PARTICIPANTS(state, payload) {
    state.participants = payload
  },

  SET_PARTICIPANT(state, payload) {
    const participant = state.participants.find((s) => s.id === payload.id)

    if (participant) {
      Object.assign(participant, payload)
    } else {
      state.participants.unshift(payload)
    }
  },

  /* speakers */
  CREATE_SPEAKER(state, payload) {
    state.webinar.speakers.unshift(payload)
  },

  UPDATE_SPEAKER(state, payload) {
    const speaker = state.webinar.speakers.find((s) => s.id === payload.id)
    Object.assign(speaker, payload)
  },

  SET_SPEAKERS(state, payload) {
    state.webinar.speakers = payload
  },

  /* buttons */
  CREATE_BUTTON(state, payload) {
    state.webinar.buttons = [...state.webinar.buttons, payload]
  },

  UPDATE_BUTTON(state, payload) {
    const button = state.webinar.buttons.find((s) => s.id === payload.id)
    Object.assign(button, payload)
  },

  SET_BUTTONS(state, payload) {
    state.webinar.buttons = payload
  },

  SET_SCREEN_SHARING(state, payload) {
    state.isScreenSharing = payload
  },
}

export const actions = {
  /* lists */
  async fetchAuthorWebinars({commit}) {
    const url = `/author/online-webinars/list`
    const data = await this.$axios.$get(url)

    commit('SET_WEBINARS', data)

    return data || []
  },

  async fetchStudentWebinars({commit}) {
    const url = `/online-webinars/list`
    const data = await this.$axios.$get(url)

    commit('SET_WEBINARS', data)

    return data || []
  },

  async fetchPublicWebinars({commit}) {
    const url = `/online-webinars/public/list`
    const data = await this.$axios.$get(url)

    commit('SET_PUBLIC_WEBINARS', data)

    return data || []
  },

  /* instance */
  async getWebinar({commit}, id) {
    const url = `/online-webinars/${id}`
    const data = await this.$axios.$get(url)

    commit('SET_WEBINAR', data)

    return data || {}
  },

  async getAuthorWebinar({commit}, id) {
    const url = `/author/online-webinars/${id}`
    const data = await this.$axios.$get(url)

    commit('SET_WEBINAR', data)

    return data || {}
  },

  async createWebinar({dispatch, commit}, params) {
    try {
      const url = `/author/online-webinars`
      const data = await this.$axios.$post(url, params)

      commit('ADD_WEBINAR', data)

      return data || {}
    } catch (error) {
      dispatch('SHOW_TOAST', {type: 'danger', text: error.message}, {root: true})
    }
  },

  async updateWebinar({commit, state}, params = {}) {
    try {
      if (!params.id) return

      const url = `/author/online-webinars/${params.id}`
      const data = await this.$axios.$put(url, params)

      commit('UPDATE_WEBINAR', params)
      commit('SET_WEBINARS', updateObjectByKey('id', data, state.webinars))

      return data || {}
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async updateWebinarPreview({commit, state}, {file, id} = {}) {
    try {
      const formData = new FormData()
      formData.append('file', file)

      const url = `/author/online-webinars/${id}/set-preview?_method=PUT`
      const data = await this.$axios.$post(url, formData)

      const updated = state.webinars.map((v) => (v.id === data.id ? data : v))

      commit('SET_WEBINAR', data)
      commit('SET_WEBINARS', updated)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  async removeWebinar({commit, state}, id) {
    try {
      await this.$axios.$delete(`/author/online-webinars/${id}`)

      commit('SET_WEBINARS', reject(propEq('id', id))(state.webinars))
    } catch (error) {
      console.log(error)
    }
  },

  async checkPassword({commit, dispatch}, {id, password} = {}) {
    const url = `/online-webinars/${id}/check-password`
    const data = await this.$axios.$post(url, {password})

    if (data.password_correct) {
      // commit('UPDATE_WEBINAR', {is_authorized: true, uuid: data.uuid})
      await dispatch('getWebinar', id)
    }
    console.log(data)
    return data
  },

  /* speakers */
  async createSpeaker({commit}, {id, params} = {}) {
    try {
      const formData = new FormData()

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key])
      })

      const url = `/author/online-webinars/${id}/speakers`
      const data = await this.$axios.$post(url, formData)

      commit('CREATE_SPEAKER', data)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  async updateSpeaker({commit}, {id, params} = {}) {
    try {
      const formData = new FormData()

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key])
      })

      const url = `/author/online-webinars/speakers/${id}?_method=PUT`
      const data = await this.$axios.$post(url, formData)

      commit('UPDATE_SPEAKER', data)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  async removeSpeaker({commit, dispatch, state}, {id} = {}) {
    try {
      const url = `/author/online-webinars/speakers/${id}`
      const data = await this.$axios.$delete(url)

      commit('SET_SPEAKERS', reject(propEq('id', id))(state.webinar.speakers))

      return data || {}
    } catch (error) {
      dispatch('SHOW_TOAST', {type: 'danger', text: error.message}, {root: true})
    }
  },

  /* participants */
  async fetchParticipants({commit}, {id, is_connected = false} = {}) {
    const url = `/online-webinars/${id}/participants/list${
      is_connected ? '?is_connected=true' : ''
    }`
    const data = await this.$axios.$get(url)

    commit('SET_PARTICIPANTS', data)

    return data || {}
  },

  async addParticipant({state, commit}) {
    try {
      const {id} = state.webinar

      if (!id) return

      const url = `/online-webinars/${id}/participants`
      const data = await this.$axios.$post(url)

      const {participant, uuid} = data || {}

      commit('SET_PARTICIPANT', participant)
      commit('UPDATE_WEBINAR', {uuid})

      return data
    } catch (error) {
      console.log(error)
    }
  },

  // async removeParticipant({commit, state}) {
  //   try {
  //     const {id} = state.webinar

  //     if (!id) return

  //     const url = `/online-webinars/${id}/participants`
  //     const data = await this.$axios.$delete(url)

  //     commit('SET_PARTICIPANTS', reject(propEq('id', id))(state.participants))

  //     return data || {}
  //   } catch (error) {
  //     console.log(error)
  //   }
  // },

  async changeParticipantRole({commit}, {id, role} = {}) {
    const url = `/author/online-webinars/participants/${id}/change-role`
    const data = await this.$axios.$put(url, {role})

    commit('SET_PARTICIPANT', data)

    return data || {}
  },

  async toggleSubscribe({commit}, id) {
    try {
      const url = `/online-webinars/${id}/subscribe`
      const data = await this.$axios.$post(url)

      commit('SET_WEBINAR', data)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  /* buttons */
  async createButton({commit}, {id, params} = {}) {
    try {
      const url = `/author/online-webinars/${id}/buttons`
      const data = await this.$axios.$post(url, params)

      commit('CREATE_BUTTON', data)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  async updateButton({commit}, params) {
    try {
      const url = `/author/online-webinars/buttons/${params.id}`
      const data = await this.$axios.$put(url, params)

      commit('UPDATE_BUTTON', data)

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },

  async removeButton({state, commit}, {id} = {}) {
    try {
      const url = `/author/online-webinars/buttons/${id}`
      const data = await this.$axios.$delete(url)

      commit('SET_BUTTONS', reject(propEq('id', id))(state.webinar.buttons))

      return data || {}
    } catch (error) {
      console.log(error)
    }
  },
}
