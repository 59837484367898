import {ADD_TOAST_MESSAGE} from 'vuex-toast'

const root = {root: true}

export const state = () => ({
  studentCourseProgress: {},
  studentCourseModules: [],
  studentLesson: null,
})

export const getters = {
  info: (state) => state.studentCourseProgress,
  modules: (state) => state.studentCourseModules,
  progress: (state) => state.studentCourseProgress || {},
  lesson: (state) => state.studentLesson,
}

export const mutations = {
  setStudentCourseProgress(state, payload) {
    state.studentCourseProgress = payload
  },
  setStudentCourseModules(state, payload) {
    state.studentCourseModules = payload
  },
  setStudentLesson(state, payload) {
    state.studentLesson = payload
  },
  setStudentHomework(state, payload) {
    state.studentLesson.student_homework = payload
  },
  removeHomeworkFile(state, fileId) {
    const newFiles = state.studentLesson.student_homework.files.filter((i) => i.id !== fileId)

    state.studentLesson.student_homework.files = newFiles
  },
  setLessonStatusComplete(state, payload) {
    state.studentCourseModules.forEach((el) =>
      el.items.forEach((i) => {
        if (i.id === Number(payload)) {
          i.is_completed = true
        }
      })
    )
  },
}

export const actions = {
  async fetchStudentCourseProgress({commit, dispatch}, studentCourseId) {
    try {
      const {data} = await this.$axios.get(`/student-courses/${studentCourseId}/progress`)
      commit('setStudentCourseProgress', data)
    } catch (err) {}
  },
  async fetchStudentCourseModules({commit, dispatch}, studentCourseId) {
    try {
      const response = await this.$axios.get(`/student-courses/${studentCourseId}/modules`)

      commit('setStudentCourseModules', response.data)
    } catch (err) {}
  },
  async fetchStudentLesson({commit, dispatch}, studentLessonId) {
    try {
      const response = await this.$axios.get(`/student-lessons/${studentLessonId}`)

      commit('setStudentLesson', response.data)
      commit('courseLesson/set', response.data, {root: true})
    } catch (err) {}
  },

  async uploadHomeworkFile({commit, dispatch}, data) {
    try {
      const formData = new FormData()

      formData.append('file', data.file)
      formData.append('name', data.file.name)
      formData.append('type', data.file.type)
      formData.append('extension', data.file.extension)
      formData.append('collection', data.collection)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const res = await this.$axios.$post(
        `/student-lessons/${data.studentLesson}/homework/attach-file`,
        formData,
        config
      )
      commit('setStudentHomework', res)

      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('course.fileUploaded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async sendHomeworkText({commit, dispatch}, data) {
    try {
      const response = await this.$axios.post(
        `/chat-messages/${data.studentLesson}/send-homework`,
        {
          message: data.message,
        }
      )

      commit('setStudentHomework', response.data.student_homework)
    } catch (err) {}
  },

  async completeLesson({commit, dispatch}, studentLesson) {
    // $FIXME: REFACT THIS SHIT
    try {
      await this.$axios.patch(`/student-lessons/${studentLesson}/complete`)
      commit('setLessonStatusComplete', studentLesson)
    } catch (err) {}
  },

  async deleteFile({commit, dispatch}, fileId) {
    try {
      await this.$axios.delete(`/medias/${fileId}`)

      commit('removeHomeworkFile', fileId)
    } catch (err) {}
  },
}
export const namespaced = true
