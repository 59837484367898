import {load} from './util'
import {ROLES} from '../consts'

export const studentRoutes = [
  {
    path: '/student/courses',
    component: load('student/courses'),
    name: 'user-courses',
    meta: {
      requiresAuth: true,
      roles: [ROLES.student],
      title: 'courses.myCourses',
    },
  },
  {
    path: '/student/courses/:studentCourseId/:courseId',
    component: load('student/course'),
    name: 'student.course',
    meta: {
      requiresAuth: true,
      roles: [ROLES.student],
      breadcrumbs: [
        {
          title: 'courses.myCourses',
          name: 'user-courses',
        },
      ],
    },
  },
  {
    path: '/student/:studentCourseId/lessons/:studentLessonId',
    component: load('student/lessons'),
    name: 'student.course-lesson',
    meta: {
      requiresAuth: true,
      roles: [ROLES.student],
    },
  },
]
