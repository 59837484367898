import {groupBy, pipe, values, reverse, uniqWith, prop, map, pick} from 'ramda'
import {fromUnixTime, set, getUnixTime} from 'date-fns'

export const namespaced = true

export const state = () => ({
  payouts: [],
  payoutDemandStep: 1,
  currentPage: 1,
  hasUpdates: false,
  dateFilter: null,
  typeFilter: null,
  links: {
    next: null,
  },
})

const groupByDay = (xs) => {
  const resetTime = (date) => set(date, {hours: 0, minutes: 0, seconds: 0})
  const group = (a) => {
    return getUnixTime(resetTime(fromUnixTime(a.created_at)))
  }
  return pipe(groupBy(group), values, reverse)(xs)
}

const mapPromoPayouts = map((val) => ({
  ...val,
  ...pick(['buyer_user_id', 'promo_partner_income', 'promo_partner_percent'], val.json_data),
}))

export const getters = {
  rawPayoutsList: (state) => state.payouts,
  partnerPayouts: (state) => mapPromoPayouts(state.payouts),
  payouts: (state) => groupByDay(state.payouts),
  fetchLink: (state) => {
    const dateFilter = state.dateFilter
    const typeFilter = state.typeFilter
    let action = '/transactions/list?include=user.legalDetails&perPage=30'

    if (dateFilter !== null) {
      action += `&filter[created_at_start]=${dateFilter.start}&filter[created_at_end]=${dateFilter.end}`
    }
    if (typeFilter !== null) {
      action += `&filter[type]=${typeFilter}`
    }

    return action
  },
  dateFilter: (state) => state.dateFilter,
  typeFilter: (state) => state.typeFilter,
  hasMore: (state) => Boolean(state.links.next),
  hasUpdates: (state) => state.hasUpdates,
}

export const mutations = {
  setPayouts(state, payload) {
    state.payouts = payload
  },
  changeStep(state, payload) {
    state.payoutDemandStep = payload
  },
  setDateFilter(state, payload) {
    if (payload !== null) {
      state.dateFilter = {
        start: payload[0],
        end: payload[1],
      }
    } else {
      state.dateFilter = null
    }
  },
  setTypeFilter(state, payload) {
    state.typeFilter = payload
  },
  setLinks(state, payload) {
    state.links = payload
  },
  increaseCurrentPage(state) {
    state.currentPage++
  },
  resetCurrentPage(state) {
    state.currentPage = 1
  },
  setUpdatesStatus(state, payload) {
    state.hasUpdates = payload
  },
}

export const actions = {
  async fetchPayouts({getters, commit, dispatch}) {
    const data = await this.$axios.$get(getters.fetchLink)

    commit('setPayouts', data && data.data)
    commit('setLinks', data && data.links)
    commit('resetCurrentPage')
    commit('setUpdatesStatus', false)
  },
  async fetchMore({state, getters, commit, dispatch}) {
    const fetchLink = getters.fetchLink
    const fetchMoreLink = `${fetchLink}&page=${state.currentPage + 1}`

    const data = await this.$axios.$get(fetchMoreLink)

    const newData = [...state.payouts, ...data.data]

    commit('setLinks', data && data.links)
    commit('setPayouts', newData)
    commit('increaseCurrentPage')
  },

  async checkUpdates({state, getters, commit, dispatch}) {
    const fetchLink = getters.fetchLink

    const data = await this.$axios.$get(fetchLink)

    const propId = prop('id')
    const hasUpdates = propId(data.data[0]) !== propId(state.payouts[0])

    if (hasUpdates) {
      commit('setUpdatesStatus', true)
    } else {
      commit('setUpdatesStatus', false)
    }
  },

  async fetchUpdates({state, getters, commit, dispatch}) {
    const fetchLink = getters.fetchLink

    const data = await this.$axios.$get(fetchLink)

    const newData = uniqWith((a, b) => a.id === b.id)([...data.data, ...state.payouts])

    commit('setLinks', data && data.links)
    commit('setPayouts', newData)
    commit('setUpdatesStatus', false)
  },

  async demandPayout({commit, dispatch}, amount) {
    try {
      await this.$axios.$post(`/user/demand-payment`, {
        amount,
      })
      commit('changeStep', 2)
      dispatch('fetchUpdates')
      dispatch('user/FETCH', {}, {root: true})
    } catch (err) {
      console.log(err)
    }
  },
}
