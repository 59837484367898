<script>
const handleValidationText = (p) => (val) => {
  return typeof p === 'function' ? p(val) : p
}

export default {
  props: {
    validations: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    validationMap() {
      return {
        alphaDashPassport: this.$t('validmap.alphaDashPassport'),
        alphaDashSpace: this.$t('validmap.alphaDashSpace'),
        alpha: this.$t('validmap.alpha'),
        minLength: ({min}) => `${this.$t('validmap.alpha')}${min}.`,
        required: this.$t('forms.errors.required'),
        numeric: this.$t('validmap.numeric'),
        dateFormatSlash: this.$t('validmap.dateFormatSlash'),
        email: this.$t('forms.errors.email'),
        isFile: this.$t('forms.errors.invalidFile'),
        isPromoValid: this.$t('forms.errors.code'),
      }
    },
    firstError() {
      if (this.fieldErrs.length) return this.fieldErrs[0]
      else return ''
    },
    fieldErrs() {
      if (!this.validations) return []

      if (!this.invalid) {
        return []
      }

      return Object.keys(this.validations.$params).reduce((errors, validator) => {
        if (!this.validations[validator]) {
          const validationParam = handleValidationText(this.validationMap[validator])
          errors.push(validationParam(this.validations.$params[validator]))
        }
        return errors
      }, [])
    },
    invalid() {
      if (!this.validations) return false

      return this.validations.$dirty && this.validations.$invalid
    },
  },
  render() {
    return this.$scopedSlots.default({
      firstError: this.firstError,
      fieldErrs: this.fieldErrs,
      invalid: this.invalid,
    })
  },
}
</script>
