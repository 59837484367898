<template>
  <div class="product-select-popup-wrapper">
    <div v-if="$slots.activator" @click="openPopup">
      <slot name="activator"></slot>
    </div>

    <PopupWrapper
      class="access-by-product-popup"
      :title="title"
      :subtitle="subtitle"
      :isOpen="show"
      v-bind="$attrs"
      @close="onClose"
    >
      <CourseProductSelect
        ref="courseProductSelect"
        :multiple="multiple"
        :multiple-courses="multipleCourses"
        :value="selected"
        @product:select="updateSelected"
        @products:update="updateSelected"
      />

      <Button class="mt-auto" @click="onSave">{{ $t('common.save') }}</Button>
    </PopupWrapper>
  </div>
</template>

<script>
import {PopupWrapper} from '@/components/ui'
import CourseProductSelect from '@/components/CourseProductSelect'

export default {
  components: {
    PopupWrapper,
    CourseProductSelect,
  },
  props: {
    title: {
      type: String,
      default() {
        return this.$t('widgets.chooseProduct')
      },
    },
    subtitle: {
      type: String,
      default() {
        return this.$t('widgets.chooseProductDesc')
      },
    },
    value: {
      type: [Object, Array],
      default: () => [],
    },
    multiple: Boolean,
    multipleCourses: {
      type: Boolean,
      default: true,
    },
    isOpen: Boolean,
  },
  data() {
    return {
      inProgress: false,
      selected: this.value,
      courseId: null,
      used: null,
      show: this.isOpen,
    }
  },
  computed: {
    isEmpty() {
      let isEmpty = !this.selected || !Object.keys(this.selected).length

      if (this.multiple) {
        isEmpty =
          !this.used || Object.keys(this.used).every((productId) => !this.used[productId].length)
      }

      return isEmpty
    },
  },
  watch: {
    value(e) {
      this.selected = e
    },
  },
  methods: {
    updateSelected(selected, used) {
      this.selected = selected
      this.used = used
    },

    async onSave() {
      if (this.isEmpty) {
        this.$refs.courseProductSelect.touch()

        return
      }

      this.inProgress = true

      try {
        // this.$emit('save', this.product)
        if (this.$listeners.save) {
          await this.$listeners.save(this.selected, this.used)
        }

        this.onClose()
      } catch (err) {
      } finally {
        // this.inProgress = false
      }
    },
    openPopup() {
      this.show = true
      this.$emit('open')
    },

    onClose() {
      // this.selected = {}
      this.show = false
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.product-select-popup-wrapper {
  .popup-subtitle {
    white-space: nowrap;
  }
}
</style>
