<template>
  <div class="webinar-guestroom">
    <div class="webinar-guestroom__top">
      <Typography variant="int_xl" class="webinar-guestroom__title" noWrap :title="webinar.name">
        {{ webinar.name }}
      </Typography>

      <WebinarPreview class="webinar-guestroom__preview">
        <template slot="backdrop">
          <Button
            v-if="showEntryButton"
            class="student-enter-button"
            :label="$t('webinars.enter')"
            bgColor="white"
            color="#2e3141"
            icon="icons/video-ico.svg"
            iconSize="s"
            @click="goToWebinarRoom"
          />

          <WebinarStatus class="mr-4" dark :showLabel="true" :showTime="false" :active="isActive" />
        </template>
      </WebinarPreview>

      <div class="webinar-guestroom__info">
        <WebinarInfo :data="webinar">
          <Button
            v-if="showSubscribeButton"
            :block="true"
            :loading="subscribeLoading"
            :label="webinar.is_subscribed ? $t('webinars.unsubscribe') : $t('webinars.subscribe')"
            :aria-label="
              webinar.is_subscribed ? $t('webinars.unsubscribe') : $t('webinars.subscribe')
            "
            :disabled="participantsLimitExceed"
            @click="subscribe"
          />
          <Typography
            v-if="showSubscribeButton && participantsLimitExceed"
            class="text-center mt-2"
            color="grey"
          >
            {{ $t('webinars.limited') }}
          </Typography>
        </WebinarInfo>
      </div>

      <WebinarTimer
        v-if="showEntryButton || !showSubscribeButton"
        class="webinar-guestroom__timer"
        :redirectCountdown="redirectCountdown"
        :showWatchButton="allowWatchOrEntry"
        @redirect="goToWebinarRoom"
      />
    </div>

    <div class="webinar-guestroom__bottom">
      <WebinarSpeakers class="webinar-guestroom__speakers" :webinar="webinar" />

      <WebinarSocials class="webinar-guestroom__socials" :data="webinar" />
    </div>

    <WebinarAuthentification v-if="showAuthentification" :data="webinar" />
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import {provideParticipantMixin} from '@/features/Webinars/mixins'
import {uuid, REDIRECT_COUNTDOWN, MAX_ALLOWED_PARTICIPANTS} from '@/features/Webinars/model'

import {
  WebinarStatus,
  WebinarPreview,
  WebinarInfo,
  WebinarTimer,
  WebinarSpeakers,
  WebinarSocials,
  WebinarAuthentification,
} from '@/features/Webinars/components'

export default {
  components: {
    WebinarStatus,
    WebinarPreview,
    WebinarInfo,
    WebinarTimer,
    WebinarSpeakers,
    WebinarSocials,
    WebinarAuthentification,
  },
  mixins: [provideParticipantMixin],
  data() {
    return {
      webinarIsPending: false,
      subscribeLoading: false,
      redirectCountdown: false,
    }
  },

  computed: {
    ...mapGetters({
      isActive: 'webinars/webinarIsActive',
      participants: 'webinars/participants',
    }),
    webinarUrl() {
      if (!this.webinar.uuid) return

      return this.webinar.uuid + uuid.encode(this.webinar.id)
    },
    allowWatchOrEntry() {
      return !this.webinar.is_password_set || this.webinar.is_authorized
    },
    showEntryButton() {
      return this.webinarUrl && this.allowWatchOrEntry && this.isActive
    },
    isPublicWebinar() {
      return this.webinar.access_level === 'public'
    },
    showSubscribeButton() {
      return this.isPublicWebinar && !this.webinarIsEnded
    },
    participantsLimitExceed() {
      return this.participants >= MAX_ALLOWED_PARTICIPANTS
    },

    showAuthentification() {
      return this.webinar.is_password_set && !this.webinar.is_authorized
    },
  },

  watch: {
    isActive(active) {
      if (active) {
        this.redirectCountdown = REDIRECT_COUNTDOWN
      }
    },
  },

  mounted() {
    if (this.isActive) {
      this.redirectCountdown = REDIRECT_COUNTDOWN
    }
  },

  methods: {
    ...mapActions({
      toggleSubscribe: 'webinars/toggleSubscribe',
    }),

    goToWebinarRoom() {
      if (!this.webinarUrl) return

      this.$router.push({name: 'webinars.webinar-room', params: {uuid: this.webinarUrl}})
    },

    async subscribe(e) {
      this.subscribeLoading = true

      try {
        const {is_subscribed} = await this.toggleSubscribe(this.webinar.id)
        this.showSubscribeStatusMessage(is_subscribed)
      } catch (error) {
        console.log(error)
      } finally {
        this.subscribeLoading = false
      }
    },

    showSubscribeStatusMessage(subscribe) {
      const message = subscribe
        ? `${this.$t('webinars.haveSubscribed')} "${this.webinar.name}". ${this.$t(
            'webinars.haveSubscribedDesc'
          )} `
        : `${this.$t('webinars.subscriptionFor')} "${this.webinar.name}" ${this.$t(
            'webinars.cancelled'
          )}`

      this.$store.dispatch('SHOW_TOAST', {type: 'success', text: message}, {root: true})
    },
  },
}
</script>

<style src="@/features/Webinars/styles/guest-room.scss" lang="scss"></style>
