import {ADD_TOAST_MESSAGE} from 'vuex-toast'
import {prop} from 'ramda'
import {COURSE_STATUS} from '@/consts'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const root = {root: true}

const SET_COURSE = 'SET_COURSE'
const SHOULD_UPDATE = 'SHOULD_UPDATE'

export const namespaced = true

const course = {
  id: 0,
  title: '',
  is_creator: true,
  author: '',
  author_avatar: '',
  status: COURSE_STATUS.draft,
  is_visible_to_market: true,
  color: '',
  description: '',
  short_description: '',
  course_link: '',
  categories: [],
  course_duration: '00:00',
  course_type: 10,
  course_img: '',
  background_img: '',
  description_img: '',
  landing_background: '',
  description_video: '',
  description_file_type: null,
  price_is_fair: {},
  minimal_price: null,
  rates: {},
  counters: {},
  last_course_status: {},
  is_content_protection_enabled: 0,
  is_students_derive_course_tags: false,
}

export const state = () => ({
  // TODO -> refactor name
  courseSettingsData: course,
  courseContent: null,
  draftStatus: null,
  shouldUpdate: true,
})

export const getters = {
  shouldUpdate: (state) => state.shouldUpdate,
  course: (state) => state.courseSettingsData,
  courseModules: (state) => prop('modules', state.courseContent),
  packages: (state) => prop('packages', state.courseSettingsData),
  courseSettingsData: (state) => state.courseSettingsData,
  courseStatus: (state) => prop('status', state.courseSettingsData),
  draftStatus: (state) => state.draftStatus === 'draft',
  isContentProtected: (state) => prop('is_content_protection_enabled', state.courseSettingsData),
  isStudentsDeriveCourseTags: (state) => state.courseSettingsData?.is_students_derive_course_tags,
}

export const mutations = {
  setDraftStatus(state, status) {
    state.draftStatus = status || null
  },

  setCourseTitle(state, payload) {
    state.courseSettingsData.title = payload
  },
  setCourseDescription(state, payload) {
    state.courseSettingsData.description = payload
  },
  setCourseShortDescription(state, payload) {
    state.courseSettingsData.short_description = payload
  },
  setCoursePrice(state, payload) {
    if (Number.isNaN(Number(payload))) return
    state.courseSettingsData.price = payload
  },
  setCourseCategories(state, payload) {
    state.courseSettingsData.categories = payload
  },
  setCourseMarketplace(state, payload) {
    state.courseSettingsData.is_visible_to_market = payload
  },
  setFreeCourse(state, payload) {
    state.courseSettingsData.is_free = payload
  },
  setCourseImage(state, payload) {
    state.courseSettingsData.course_img = payload
  },
  setCourseBg(state, payload) {
    state.courseSettingsData.background_img = payload
  },
  setCourseBgColor(state, payload) {
    state.courseSettingsData.color = payload
  },
  [SET_COURSE](state, payload) {
    state.courseSettingsData = payload
  },
  setCourseContent(state, payload) {
    state.courseContent = payload
  },
  addCourseModule(state, payload) {
    state.courseContent.modules = [...state.courseContent.modules, payload]
  },
  removeCourseModule(state, payload) {
    const newModulesList = state.courseContent.modules.filter((item) => {
      return item.id !== payload
    })

    state.courseContent.modules = newModulesList
  },
  changeModuleTitle(state, payload) {
    const items = state.courseContent.modules
    const itemPos = items.findIndex(({id}) => id === payload.moduleId)

    items[itemPos].title = payload.title
  },
  removeLesson(state, payload) {
    const module = state.courseContent.modules.find((item) => item.id === payload.moduleId)

    const newLessonList = module.items.filter((item) => {
      return item.id !== payload.lessonId
    })

    module.items = newLessonList
  },
  [SHOULD_UPDATE](state, p) {
    state.shouldUpdate = p
  },
  setContentProtection(state, p) {
    state.courseSettingsData.is_content_protection_enabled = p
  },
  setStudentsDeriveTags(state, p) {
    state.courseSettingsData.is_students_derive_course_tags = p
  },
}

export const actions = {
  async PUBLISH({commit, dispatch}, {id}) {
    commit(SHOULD_UPDATE, false)
    try {
      const data = await this.$axios.$put(`/author/courses/${id}/publish`)
      commit(SET_COURSE, data)
    } catch (err) {
    } finally {
      await sleep(1001)
      commit(SHOULD_UPDATE, true)
    }
  },
  async UNPUBLISH({commit, dispatch}, {id}) {
    commit(SHOULD_UPDATE, false)

    try {
      const data = await this.$axios.$put(`/author/courses/${id}/unpublish`)
      commit(SET_COURSE, data)
    } catch (err) {
    } finally {
      await sleep(1100)
      commit(SHOULD_UPDATE, true)
    }
  },
  async fetchCourse({commit, dispatch}, data) {
    const response = await this.$axios.get(`/courses/${data.courseId}`, {
      params: data.queryParams,
    })

    commit(SET_COURSE, response.data)
    return response.data
  },

  async fetchCourseContent({commit, dispatch}, id) {
    const response = await this.$axios.get(`/courses/${id}/modules`)

    commit('setCourseContent', response.data)
  },

  async saveCourseSettings({commit, dispatch, state}, courseId) {
    const courseCategories = state.courseSettingsData.categories

    try {
      const params = {
        color: state.courseSettingsData.color,
        title: state.courseSettingsData.title,
        description: state.courseSettingsData.description,
        short_description: state.courseSettingsData.short_description,
        is_active: state.courseSettingsData.is_active,
        is_free: state.courseSettingsData.is_free,
        is_visible_to_market: state.courseSettingsData.is_visible_to_market,
        categories: courseCategories,
        course_type: state.courseSettingsData.course_type,
        is_content_protection_enabled: state.courseSettingsData.is_content_protection_enabled,
        is_students_derive_course_tags: state.courseSettingsData.is_students_derive_course_tags,
      }

      await this.$axios.patch(`/courses/${courseId}`, params)
    } catch (err) {}
  },

  async createCourseModule({commit, dispatch}, data) {
    try {
      const response = await this.$axios.post(`/courses/${data.id}/modules`, {
        title: data.title,
      })

      commit('addCourseModule', response.data)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.moduleCreated'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (err) {}
  },

  async deleteCourseModule({commit, dispatch}, moduleId) {
    try {
      await this.$axios.delete(`/courses/modules/${moduleId}`)

      commit('removeCourseModule', moduleId)
    } catch (err) {}
  },

  async editCourseModule({commit, dispatch}, {id, params}) {
    try {
      await this.$axios.patch(`/courses/modules/${id}`, params)
    } catch (err) {}
  },

  async createCourseLesson({commit, dispatch}, moduleId) {
    try {
      const response = await this.$axios.post(`/courses/modules/${moduleId}/lesson`)

      return response.data.id
    } catch (err) {}
  },
  async deleteCourseLesson({commit, dispatch}, data) {
    try {
      await this.$axios.delete(`/courses/lessons/${data.lessonId}/`)
      commit('removeLesson', data)
    } catch (err) {}
  },

  async editModuleTitle({commit, dispatch}, data) {
    try {
      await this.$axios.patch(`/courses/modules/${data.moduleId}`, {
        title: data.title,
      })

      commit('changeModuleTitle', data)
    } catch (err) {}
  },

  async uploadCourseImg({state, commit, dispatch}, {image, name, type, mutationName}) {
    const id = state.courseSettingsData.id
    const formData = new FormData()

    formData.append('file', image)
    formData.append('name', name)
    formData.append('type', 'image/jpeg')
    formData.append('collection', type)

    const config = {
      header: {
        'Content-Type': 'image/jpeg',
      },
      timeout: null,
    }

    try {
      const response = await this.$axios.post(`/courses/${id}/upload-file`, formData, config)
      const imgUrl = response.data.path

      commit(mutationName, imgUrl)
      dispatch(
        ADD_TOAST_MESSAGE,
        {
          text: this.$i18n.t('toast.pictureUploaded'),
          type: 'success',
          dismissAfter: 3000,
        },
        root
      )
    } catch (error) {}
  },

  async ORDER_ITEMS({state, dispatch}, {type = 'modules', itemId, dir}) {
    const courseId = state.courseSettingsData.id
    // type = 'modules' | 'lessons'
    const url = `/courses/${type}/${itemId}/item-order`

    const data = await this.$axios.$patch(url, {direction: String(dir)})
    await dispatch('fetchCourseContent', courseId)
    return data
  },
}
