import {ADD_TOAST_MESSAGE} from 'vuex-toast'

export const namespaced = true

export const state = () => ({
  uploaders: [],
  documentUploaders: [],
})

export const getters = {
  getUploader: (state) => (id) => {
    return state.uploaders.find((uploader) => id === uploader.id)
  },

  getUploaderIndex: (state) => (id) => {
    return state.uploaders.findIndex((uploader) => id === uploader.id)
  },

  getDocumentUploaders: (state) => (moduleItemId) => {
    return state.documentUploaders.filter((uploader) => moduleItemId === uploader.moduleItemId)
  },

  getDocumentUploaderIndex: (state) => (id) => {
    return state.documentUploaders.findIndex((uploader) => id === uploader.id)
  },
}

export const mutations = {
  addUploader(state, payload) {
    state.uploaders.push(payload)
  },

  changeUploader(state, {index, progress}) {
    state.uploaders[index].progress = progress
  },

  removeUploader(state, index) {
    state.uploaders.splice(index, 1)
  },

  addDocumentUploader(state, payload) {
    state.documentUploaders.push(payload)
  },

  changeDocumentUploader(state, {index, progress}) {
    state.documentUploaders[index].progress = progress
  },

  removeDocumentUploader(state, index) {
    state.documentUploaders.splice(index, 1)
  },
}

export const actions = {
  updateUploader({getters, commit}, {id, progressEvent}) {
    const uploaderIndex = getters.getUploaderIndex(id)
    const size = Math.round(progressEvent.total / (1024 * 1024))
    const progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))

    if (uploaderIndex === -1) {
      commit('addUploader', {id, size, progress})
    } else {
      commit('changeUploader', {index: uploaderIndex, progress})
    }
  },

  deleteUploader({getters, commit}, id) {
    const uploaderIndex = getters.getUploaderIndex(id)
    commit('removeUploader', uploaderIndex)
  },

  updateDocumentUploader({getters, commit}, {id, moduleItemId, progressEvent}) {
    const uploaderIndex = getters.getDocumentUploaderIndex(id)
    const size = Math.round(progressEvent.total / (1024 * 1024))
    const progress = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))

    if (uploaderIndex === -1) {
      commit('addDocumentUploader', {id, moduleItemId, size, progress})
    } else {
      commit('changeDocumentUploader', {index: uploaderIndex, progress})
    }
  },

  deleteDocumentUploader({getters, commit}, id) {
    const uploaderIndex = getters.getDocumentUploaderIndex(id)
    commit('removeDocumentUploader', uploaderIndex)
  },

  cancelUploader({dispatch}, id) {
    // TODO: refact axios cancel to nuxt-axios cancel
    this.$axios.cancel(id)

    dispatch(
      ADD_TOAST_MESSAGE,
      {
        text: this.$i18n.t('toast.fileCancelled'),
        type: 'success',
        dismissAfter: 3000,
      },
      {root: true}
    )
  },
}
