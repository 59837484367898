import {Events} from './events'

export const registerConfirmDialogPlugin = () => {
  const confirm = (params) => {
    Events.emit('open', params)

    return new Promise((resolve) => {
      Events.once('action', (confirm) => {
        resolve(confirm)
      })
    })
  }

  return {
    confirm,
  }
}
