<template>
  <PopupWrapper :title="$t('funnels.addVk')" v-bind="$attrs" @close="onClose">
    <div class="flex mb-2 items-center">
      <Button :loading="vkImporting" height="40" @click="runImport">{{
        $t('common.refresh')
      }}</Button>
    </div>
    <div v-if="vkList.length" class="flex flex-col flex-shrink-0">
      <div v-for="group in vkList" :key="group.id" class="flex mb-1">
        <PreviewWithInfo :data="group"></PreviewWithInfo>
        <Button :inverted="group.is_active" @click="handleConnect(group)">
          {{ group.is_active ? $t('funnels.turnOff') : $t('funnels.turnOn') }}
        </Button>
      </div>
    </div>
    <div v-else class="vk-empty-groups flex items-center justify-center">
      {{ $t('funnels.hereAppearsList') }}
    </div>
  </PopupWrapper>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {PopupWrapper, Button} from '@/components/ui'
import {PreviewWithInfo} from '@/components/BotCard/'

export default {
  components: {
    PopupWrapper,
    Button,
    PreviewWithInfo,
  },
  props: {
    vkList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      vkImporting: 'funnelBots/vkImporting',
    }),
  },
  methods: {
    ...mapActions({
      runImport: 'funnelBots/IMPORT_VK_GROUPS',
      toggleGroup: 'funnelBots/TOGGLE_VK_GROUP',
      getAccessToGroup: 'funnelBots/GET_ACCESS_TO_GROUP',
    }),
    onClose() {
      this.$emit('close')
    },
    handleConnect(group) {
      if (group.is_connected) {
        this.toggleGroup({groupId: group.id})
      } else {
        this.getAccessToGroup({groupId: group.id})
      }
    },
  },
}
</script>

<style lang="scss">
.vk-empty-groups {
  text-align: center;
  width: 100%;
  height: 100%;
}
</style>
