<template>
  <div class="webinar-room">
    <div class="webinar-room__canvas">
      <slot name="mediaStream"></slot>
    </div>

    <div class="webinar-room__stream">
      <slot name="videoStream"></slot>
    </div>

    <WebinarButtons class="webinar-room__buttons" :webinar-id="webinar.id" :editable="isAuthor" />

    <div class="webinar-room__chat">
      <slot name="chat"></slot>
    </div>
  </div>
</template>

<script>
import {provideParticipantMixin} from '@/features/Webinars/mixins'
import {WebinarButtons} from '@/features/Webinars/components'

export default {
  components: {
    WebinarButtons,
  },
  mixins: [provideParticipantMixin],
}
</script>

<style src="@/features/Webinars/styles/webinar-room.scss" lang="scss"></style>
