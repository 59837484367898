<template>
  <div class="webinar-stream-window" :class="{active: isActive, unselectable: !complexLoaded}">
    <video
      v-if="showVideoEl"
      ref="stream"
      class="webinar-video-element webinar-stream"
      playsinline
      :controls="isAuthor ? false : 'controls'"
      :muted="isAuthor ? true : false"
    ></video>

    <WebinarPreview v-if="showPreview" class="webinar-stream-window__preview" :preview="''" />

    <!-- controls -->
    <div v-if="!webinarEnded" class="webinar-stream-window__controls">
      <Transition name="slow-fade">
        <!-- start button -->
        <Button
          v-if="isAuthor && complexLoaded"
          class="webinar-stream-window__start-button"
          :class="{active: isActive, invisible: isActive}"
          :icon="isActive ? 'icons/stop.svg' : 'icons/play.svg'"
          @click="toggleStream"
        >
          {{ isActive ? $t('webinars.stop') : $t('webinars.play') }}
        </Button>

        <!-- student connect button -->
        <Button
          v-if="!isAuthor && isActive && !showVideoEl"
          class="webinar-stream-window__start-button"
          :class="{active: isActive, invisible: isActive}"
          @click="connectToStream"
        >
          {{ $t('webinars.joing') }}
        </Button>
      </Transition>

      <div v-if="isAuthor" class="webinar-stream-window__controls-inner">
        <div class="flex items-center justify-start mr-auto">
          <WebinarStreamSettings @save="setStreamConstraints">
            <Button
              slot="activator"
              class="transparent"
              size="40"
              icon="icons/icon-cog.svg"
              icon-size="s"
              rounded
            />
          </WebinarStreamSettings>

          <WebinarStatus class="flex-auto" :class="{'ml-s': isActive}" :active="isActive" />
        </div>

        <div class="flex items-center justify-center mx-auto" :class="{invisible: isActive}">
          <Button
            class="webinar-stream-window__media-button"
            icon-size="s"
            :class="{active: isVideoEnabled}"
            :icon="isVideoEnabled ? 'icons/video-ico.svg' : 'icons/video-ico-disabled.svg'"
            rounded
            size="40"
            :disabled="!isActive"
            @click="toggleVideo"
          />

          <Button
            class="webinar-stream-window__media-button"
            icon-size="s"
            :class="{active: isAudioEnabled}"
            :icon="isAudioEnabled ? 'icons/microphone.svg' : 'icons/microphone-disabled.svg'"
            rounded
            size="40"
            :disabled="!isActive"
            @click="toggleMicrophone"
          />
        </div>

        <div class="flex items-center justify-end ml-auto">
          <Button
            class="transparent"
            size="40"
            icon="icons/fullscreen.svg"
            icon-size="s"
            rounded
            @click="enterFullscreen"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {isNil} from 'ramda'
import {mapGetters, mapActions} from 'vuex'
import {WebinarStatus, WebinarPreview} from '../atoms'
import {WebinarStreamSettings} from './activators'
import {enterFullscreen, DEFAULT_CONSTRAINTS} from '@/features/Webinars/model'

export default {
  name: 'WebinarStreamWindow',

  components: {
    WebinarStatus,
    WebinarPreview,
    WebinarStreamSettings,
  },

  props: {
    webinarData: {
      type: Object,
      default: () => ({}),
    },
    allLoaded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      constraints: null,
      videoLoaded: true,
      streamConstraints: null,
      isAudioEnabled: true,
      isVideoEnabled: true,
      WebRecorder: null,
      webinarEnded: false,
    }
  },

  computed: {
    ...mapGetters({
      isActive: 'webinars/webinarIsActive',
      webinarIsEnded: 'webinars/webinarIsEnded',
    }),

    showPreview() {
      return !this.isActive || !this.isVideoEnabled
    },

    showVideoEl() {
      return this.videoLoaded && !this.webinarEnded
    },

    token() {
      return this.$store.getters['auth/token']
    },
    complexLoaded() {
      return this.allLoaded && !isNil(this.WebRecorder)
    },
    webinarId() {
      return this.webinarData.id
    },
  },

  watch: {
    allLoaded(v) {
      if (!v) return
      this.initWebRecorder()
    },
  },

  mounted() {
    if (this.isAuthor) {
      // const reset = this.isActive && !this.webinarIsEnded

      // if (reset) {
      //   this.updateStatus(false)
      // }

      this.bindEvents()
    }
  },

  beforeDestroy() {
    if (this.isAuthor && this.isActive) {
      this.stopStream()
    }
  },

  methods: {
    ...mapActions({
      updateWebinar: 'webinars/updateWebinar',
      showToast: 'SHOW_TOAST',
    }),

    initWebRecorder() {
      if (this.WebRecorder || !this.allLoaded) return

      this.createWebRecorder()
      setTimeout(() => {
        this.connectToStream()
      }, 5000)
    },

    async toggleStream() {
      if (this.isActive) {
        if (
          await this.$confirm({
            content: this.$t('webinars.confirmStop'),
          })
        ) {
          this.stopStream()
        }
      } else {
        this.startStream()
      }
    },

    async startStream() {
      const {uuid, hash} = this.webinarData
      const config = {
        broadcastId: uuid,
        secret: hash,
        speed: null,
      }

      await this.updateStatus(true)
      try {
        await this.WebRecorder.startBroadcast(config)
        this.videoLoaded = true

        if (!this.isAuthor) return

        // change status after successfull stream start

        setTimeout(() => {
          this.applyStreamConstraints()
        }, 4000)
      } catch (err) {
        console.log(err)
        this.showToast({
          text: this.$t('webinars.needAllowMic'),
          type: 'danger',
        })
        await this.updateStatus(false)
      }
    },
    stopStream() {
      this.updateStatus(false, true)
      this.WebRecorder.stopRecording()
      this.webinarEnded = true
      this.$root.$emit('webinar:stop', true)
    },

    toggleVideo() {
      if (this.WebRecorder && this.isActive) {
        this.WebRecorder.muteVideo()
        this.isVideoEnabled = !this.isVideoEnabled
      }
    },
    toggleMicrophone() {
      if (this.WebRecorder && this.isActive) {
        if (this.isAudioEnabled) {
          this.WebRecorder.muteAudio('mute')
        } else {
          this.WebRecorder.muteAudio('unmute')
        }
        this.isAudioEnabled = !this.isAudioEnabled
      }
    },
    enterFullscreen() {
      if (!this.$refs.stream) return

      enterFullscreen(this.$refs.stream)
    },
    setStreamConstraints(constraints = {}) {
      this.streamConstraints = constraints

      if (this.isActive) {
        this.applyStreamConstraints(constraints)
      }
    },

    applyStreamConstraints(constraints = this.streamConstraints) {
      if (!constraints || !this.WebRecorder) return

      this.WebRecorder.applyVideoConstraints(constraints.video)
      this.WebRecorder.applyAudioConstraints(constraints.audio)
    },

    updateStatus(is_active, is_ended = false) {
      if (!this.isAuthor) return

      const actual_start_date = Math.round(Date.now() / 1000)

      return this.updateWebinar({
        id: this.webinarId,
        is_sharing_started: false,
        is_active,
        is_ended,
        ...(is_active && {actual_start_date}),
      })
    },

    createWebRecorder() {
      const WebRec = window.WebRecording
      if (!window.RTCMultiConnection || !WebRec) return

      console.log('%c%s', 'color: #6666fe; font: 16px Tahoma;', 'createWebRecorder')
      const webinarId = this.webinarId

      this.WebRecorder = new WebRec('.webinar-stream', {
        broadcastId: this.webinarData.uuid,
        token: this.token,
        chunksDuration: 15,
        socketURL: 'https://webrtc-dev.meleton.ru/',
        chunksSavePath: `${process.env.API_BASE_URL}/author/online-webinars/${webinarId}/save/from-camera`,
        onUploadingProgress: this.onUploadingProgress,
        onConnectionError: this.onConnectionError,
        videoStateChanged: this.onVideoStateChanged,
        audioStateChanged: this.onAudioStateChanged,
        onStreamEnded: this.onStreamEnded,
        mediaConstraints: DEFAULT_CONSTRAINTS,
      })
    },

    onStreamEnded(event) {
      this.videoLoaded = false
      if (event.type === 'remote' && !this.isAuthor) {
        this.$router
          .push({
            name: 'webinars.guest-room',
            params: {
              id: this.webinarId,
            },
          })
          .catch({})
      }
    },

    bindEvents() {
      window.addEventListener('beforeunload', () => this.updateStatus(false))

      this.$once('hook:beforeDestroy', () => {
        window.removeEventListener('beforeunload', () => this.updateStatus(false))
      })
    },

    connectToStream() {
      if (this.isAuthor || !this.webinarData.uuid) return
      this.WebRecorder.connectToBroadcast(this.webinarData.uuid)
    },

    onUploadingProgress(e) {
      if (this.isAuthor && !this.webinarData.is_active) {
        this.updateStatus(true)
      }
    },

    onConnectionError(error) {
      this.showToast({
        text: `Error "${error}"`,
        type: 'danger',
      })

      this.updateStatus(false)
    },

    onVideoStateChanged(state) {
      if (state !== this.isVideoEnabled) {
        this.isVideoEnabled = state
      }
    },

    onAudioStateChanged(state) {
      if (state !== this.isAudioEnabled) {
        this.isAudioEnabled = state
      }
    },
  },
}
</script>

<style src="@/features/Webinars/styles/stream-window.scss" lang="scss"></style>
