import wordsDeclension from './wordsDeclension'

/*
 * problem5 change logic for translation
 */

export const localeRu = {
  day: ['день', 'дня', 'дней'],
  hour: ['час', 'часа', 'часов'],
  minute: ['минута', 'минуты', 'минут'],
  second: ['секунда', 'секунды', 'секунд'],
}

/**
 * difference - Date difference
 *
 * @param  {int} start - Date timestamp, date start
 * @param  {int} end   - Date timestamp, date end
 *
 * @return {object} - { hours, minutes, seconds }
 */
const difference = (start, end, extract = true) => {
  if (!start || !end) {
    return false
  }

  const result = {}

  let delta = Math.abs(start - end) / 1000

  const structure = {
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  Object.keys(structure).forEach((key) => {
    const value = Math.floor(delta / structure[key])

    result[key] = extract
      ? Math.floor(delta / structure[key])
      : `${value} ${wordsDeclension(value, ...localeRu[key])}`

    delta -= value * structure[key]
  })

  return result
}

const formatters = {
  /**
   * when
   *
   * @param  {int|string} date - Date timestamp
   * @param  {boolean} extract - Extract value
   *
   * @return {string} - Eg. 3 августа в 19:20 // extract - [3 августа, в, 19:20]
   */
  when: (date, extract = false) => {
    const options = {
      // year: '2-digit',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }

    if (typeof date === 'number') date = new Date(date)

    date = new Intl.DateTimeFormat('ru', options).format(date)
    date = date.replace(',', ' в')

    if (extract) {
      return date.split(/( в )/)
    }

    return date
  },

  /**
   * duration
   *
   * @param  {int|string} d - Minutes or Date timestamp
   * @param  {boolean} isTms - Date timestamp flag
   * @param  {boolean} extract - Extract value
   *
   * @return {string} - Eg. 1 час 15 минут || extract - [1, 30]
   */
  duration: (d, extract = false, isTms = false) => {
    let hours
    let minutes

    if (isTms) {
      d = new Date(d)
      hours = d.getHours()
      minutes = d.getMinutes()
    } else {
      hours = Math.floor(d / 60)
      minutes = d % 60
    }

    if (extract) {
      return [hours, minutes]
    }

    let result = ''

    if (hours) {
      result += `${hours} ${wordsDeclension(hours, ...localeRu.hour)} `
    }

    if (minutes) {
      result += `${minutes} ${wordsDeclension(minutes, ...localeRu.minute)}`
    }

    return result
  },
}

export {difference, formatters}
