const reg =
  /^\s*[A-Za-zА-Яа-яÀ-ÿёË]+(?:\s*[A-Za-zА-Яа-яÀ-ÿёË-]*)(?:\s*[A-Za-zА-Яа-яÀ-ÿёË-]*)(?:\s*[A-Za-zА-Яа-яÀ-ÿёË-]*)\s*$/
export function fullName(v) {
  if (typeof v === 'undefined' || v === null || v === '') {
    return true
  }

  return reg.test(v)
}

export const splitFullName = (str) => {
  if (typeof str !== 'string') {
    throw Error('splitFullName expects string as argument')
  }

  if (str.substring(0, str.indexOf(' ')))
    return [str.substring(0, str.indexOf(' ')), str.substring(str.indexOf(' ') + 1)]
  else return [str.substring(str.indexOf(' ') + 1)]
  /*
  return str
    .trim()
    .split(' ')
    .filter((v) => v)

   */
}
