<template>
  <div class="box-icon" :style="stylesObj" v-on="$listeners">
    <Icon v-bind="$attrs"></Icon>
  </div>
</template>

<script>
import {reject, isNil} from 'ramda'
import Icon from './index'
export default {
  components: {Icon},
  props: {
    styles: {
      type: Object,
      default: () => ({
        radius: null,
        backgroundColor: null,
        color: null,
      }),
    },
    radius: {
      type: String,
      default: '16px',
    },
    width: {
      type: [String, Number],
      default: '40px',
    },
    height: {
      type: [String, Number],
      default: '40px',
    },
  },
  computed: {
    stylesObj() {
      return reject(isNil, {
        '--bg-color': this.styles.backgroundColor,
        '--color': this.styles.color,
        '--box-radius': this.styles.radius || this.radius,
        '--box-width': isNaN(this.width) ? this.width : this.width + 'px',
        '--box-height': isNaN(this.height) ? this.height : this.height + 'px',
      })
    },
  },
}
</script>

<style lang="scss">
.box-icon {
  position: relative;
  width: var(--box-width);
  height: var(--box-height);
  color: var(--color, inherit);
  background-color: var(--bg-color, #fff);
  padding: 8px;
  border-radius: var(--box-radius, 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: 0px 6px 12px rgba(150, 162, 180, 0.36);
  }
}
</style>
