export default function ({route, store, redirect}) {
  // If the user is not authenticated

  const tok = localStorage.getItem('jwt-token')
  const pageForAuthOnly = route?.meta[0]?.requiresAuth && !store.getters['auth/isLoggedIn']

  console.log('AUTH', pageForAuthOnly, tok)

  // localStorage.setItem('auth._token_expiration.local', false)
  localStorage.setItem('auth.strategy', 'local')
  // localStorage.setItem('auth._token.laravelSanctum', 'Bearer ' + tok)
  localStorage.setItem('auth._token.local', 'Bearer ' + tok)
  localStorage.setItem(
    'auth._token_expiration.local',
    // eslint-disable-next-line new-cap
    parseInt(new Date().getTime()) + 3600000
  )
  // localStorage.setItem('auth._token.local', false)
  // localStorage.setItem("auth.redirect", false);

  // path(['meta', '0', 'requiresAuth'], route) && !store.getters['auth/isLoggedIn']

  // Redirect after login
  const next = redirectAfterLogin(route)

  if (pageForAuthOnly) {
    redirect({name: 'login', query: {redirect: next}})

    return
  }

  if (route.fullPath === '/author/webinars') document.location.href = '/webinars/'
  if (route.fullPath === '/author/webinars/') document.location.href = '/webinars/'

  return true
}

function redirectAfterLogin(route) {
  let {redirectAfterLogin: goto, redirectBackAfterLogin: back} = route.meta[0]

  if (route.params.id && goto) {
    goto = goto.replace(':id', route.params.id)
  }

  return back ? route.path : goto
}
