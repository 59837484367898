<template>
  <div :class="rootClasses" :style="vars">
    <Typography
      v-if="$slots.label"
      :variant="labelTextSize"
      color="grey"
      component="label"
      display="block"
      :for="id"
    >
      <slot name="label"></slot>
    </Typography>
    <Multiselect
      :id="id"
      ref="Multiselect"
      :label="(labeled && label) || null"
      :showLabels="false"
      :loading="loading"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template slot="singleLabel" slot-scope="props">
        <div class="app-selector2__selected-container app-selector2__with-icon">
          <div v-if="showIcon" class="option__icon">
            <Icon :src="icon"></Icon>
          </div>
          <span class="option__title">{{ props.option[label] || props.option }}</span>
        </div>
      </template>
      <template slot="placeholder">
        <div class="app-selector2__with-icon">
          <div v-if="showIcon" class="option__icon">
            <Icon :src="icon"></Icon>
          </div>
          <Typography variant="paragraph_s" noWrap color="grey">
            {{ $attrs.placeholder }}
          </Typography>
        </div>
      </template>
      <span slot="caret" class="app-selector2__arrow">
        <span v-show="!loading" class="arrow arrow-up" />
        <span v-show="!loading" class="arrow arrow-down" />
      </span>
      <template slot="noOptions">
        <div class="no-options-block">
          <slot name="noOptions"> {{ $t('ui.emptyList') }}! </slot>
        </div>
      </template>
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </Multiselect>
    <div v-show="error && errorMessage" class="app-selector2--error_container">
      <Typography variant="paragraph_xs" color="error">
        {{ errorMessage || '' }}
      </Typography>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'UISelector',
  components: {Multiselect},
  inheritAttrs: false,
  props: {
    showIcon: Boolean,
    icon: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: () => genId(),
    },
    labeled: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'title',
    },
    labelTextSize: {
      type: String,
      default: 'paragraph_s',
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'default',
      validator: (v) => ['white', 'default'].includes(v),
    },
    error: {
      type: [Boolean, String],
      default: false,
    },
    errorMessage: {
      type: [Boolean, String],
      default: false,
    },
    small: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    dense: {
      type: Boolean,
      default: () => false,
      required: false,
    },
    loading: Boolean,
  },
  data: () => ({
    multiselector: null,
  }),
  computed: {
    vars() {
      const {small, dense} = this
      if (dense)
        return {
          '--selector-font-size': '14px',
          '--input-pd': '14px 16px 8px 16px',
        }
      else if (small)
        return {
          '--selector-font-size': '10px',
          '--input-pd': '7px 16px',
        }
      else
        return {
          '--selector-font-size': '14px',
          '--input-pd': '14px 16px',
        }
    },
    rootClasses() {
      return {
        'app-selector2': true,
        [`app-selector2--${this.variant}`]: this.variant,
        [`app-selector2--loading`]: this.loading,
        [`app-selector2--invalid`]: Boolean(this.error),
      }
    },
  },
  mounted() {
    this.multiselector = this.$refs.Multiselect
  },
  beforeDestroy() {
    this.multiselector.deactivate()
  },
}

function genId() {
  return `mel-selector-${Math.random().toString(36).substring(2, 10)}`
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.app-selector2 {
  font-family: Roboto, sans-serif;
  color: #2e3141;
  max-width: 100%;
  width: 100%;

  &__arrow {
    position: absolute;
    width: 40px;
    height: 100%;
    right: 1px;
    top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: transform 0.2s ease;
    > .arrow {
      width: 11px;
      height: 12px;
      background-repeat: no-repeat;
    }
    > .arrow-up {
      display: none;

      background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.99992 -2.1802e-07C4.73472 5.6431e-05 4.48041 0.10545 4.29292 0.293L0.292919 4.293C0.110761 4.4816 0.00996662 4.7342 0.0122451 4.9964C0.0145235 5.2586 0.119692 5.50941 0.305101 5.69482C0.490509 5.88023 0.741321 5.9854 1.00352 5.98767C1.26571 5.98995 1.51832 5.88916 1.70692 5.707L4.99992 2.414L8.29292 5.707C8.38517 5.80251 8.49551 5.87869 8.61751 5.9311C8.73952 5.98351 8.87074 6.0111 9.00352 6.01225C9.1363 6.0134 9.26798 5.9881 9.39087 5.93782C9.51377 5.88754 9.62542 5.81329 9.71931 5.71939C9.81321 5.6255 9.88746 5.51385 9.93774 5.39095C9.98802 5.26806 10.0133 5.13638 10.0122 5.0036C10.011 4.87082 9.98343 4.7396 9.93102 4.6176C9.87861 4.49559 9.80243 4.38525 9.70692 4.293L5.70692 0.293C5.51943 0.10545 5.26511 5.64078e-05 4.99992 -2.1802e-07V-2.1802e-07Z' fill='%2396A2B4'/%3E%3C/svg%3E%0A");
    }

    > .arrow-down {
      display: block;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.00008 7C6.26528 6.99994 6.51959 6.89455 6.70708 6.707L10.7071 2.707C10.8892 2.5184 10.99 2.2658 10.9878 2.0036C10.9855 1.7414 10.8803 1.49059 10.6949 1.30518C10.5095 1.11977 10.2587 1.0146 9.99648 1.01233C9.73429 1.01005 9.48168 1.11084 9.29308 1.293L6.00008 4.586L2.70708 1.293C2.61483 1.19749 2.50449 1.12131 2.38249 1.0689C2.26048 1.01649 2.12926 0.988904 1.99648 0.98775C1.8637 0.986596 1.73202 1.0119 1.60913 1.06218C1.48623 1.11246 1.37458 1.18671 1.28069 1.2806C1.18679 1.3745 1.11254 1.48615 1.06226 1.60905C1.01198 1.73194 0.986676 1.86362 0.98783 1.9964C0.988984 2.12918 1.01657 2.2604 1.06898 2.3824C1.12139 2.50441 1.19757 2.61475 1.29308 2.707L5.29308 6.707C5.48057 6.89455 5.73489 6.99994 6.00008 7V7Z' fill='%2396A2B4'/%3E%3C/svg%3E%0A");
    }
  }

  &__selected-container {
    display: flex;
    overflow: hidden;
  }

  &__with-icon {
    display: flex;
    align-items: center;
    max-width: 100%;

    .option__icon {
      margin-right: 16px;
      width: 16px;
      min-width: 16px;
      height: 16px;
    }
  }

  label {
    margin-bottom: 12px;
    line-height: 1 !important;
  }

  .multiselect__tags {
    border-radius: 16px;
    border: 1px solid transparent;

    background-color: #f2f5f9;
    min-height: auto;

    padding: var(--input-pd) !important;

    .multiselect__spinner {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      right: 0;
      top: 0;
      &:before,
      &:after {
        border-top-color: $purple;
      }
    }

    .multiselect__placeholder {
      padding: 0;
      margin: 0;
      color: #8f9aac;
      max-width: 95%;
    }
    .multiselect__input,
    .multiselect__single {
      display: flex;
      background: transparent;
      margin-bottom: 0;
      max-width: 95%;
      padding: 0;
      font-size: 14px;
    }

    .multiselect__tag {
      background: $purple;

      .multiselect__tag-icon {
        &:hover {
          background: transparent;
          &::after {
            color: #fff;
          }
        }
        &::after {
          color: #000;
          opacity: 0.5;
        }
      }
    }
  }

  .multiselect__content-wrapper {
    background: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid $purple;
    border-top-color: transparent;

    @include scrollable($purple, 2px, 0, 15px);

    .multiselect__content {
      display: block;
      max-width: 100%;
    }
    .multiselect__option {
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        display: block;
        font-size: var(--selector-font-size);
      }
    }
    .multiselect__option--highlight {
      background: $purple;
    }

    .multiselect__option--selected.multiselect__option--highlight {
      background: $elementsGrey;
    }

    .no-options-block {
      white-space: normal;
    }
  }

  .multiselect--active {
    z-index: 10000;
    .arrow-up {
      display: block;
    }
    .arrow-down {
      display: none;
    }

    .multiselect__tags {
      background-color: #fff;
      border: 1px solid $purple;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .multiselect--above {
    .multiselect__tags {
      border-bottom-right-radius: 16px;
      border-bottom-left-radius: 16px;
    }
    .multiselect__content-wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-color: transparent;
      border-top-color: $purple;
    }
  }

  .multiselect--disabled {
    background: transparent;
  }

  // white variant
  &--white {
    .multiselect__tags {
      background: #fff;
    }
    .multiselect__content-wrapper {
      background-color: #fff;
    }
  }
  &--invalid {
    .multiselect__tags {
      border-color: $error;
    }
  }

  &--error_container {
    margin-top: 4px;
  }
}
</style>
