import {length, prop, omit} from 'ramda'
import {chatActionTypes} from '@/features/Chat/model/store'

const root = {root: true}

const SET_STUDENTS = 'SET_STUDENTS'
const SET_COURSES = 'SET_COURSES'
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_COURSE = 'SET_COURSE'
const SET_LINKS = 'SET_LINKS'

export const state = () => ({
  students: [],
  course: {},
  courses: [],
  notifications: 0,
  links: {
    next: null,
  },
})

export const getters = {
  homeworkList: (state) => state.students,
  homeworkNotif: (state) => prop('notifications_count', state.course) || 0,
  courseName: (state) => prop('course_title', state.course),
  hasCourse: (state) => length(Object.values(state.course)) > 0,
  hasHomeworks: (state) => length(state.courses) > 0,
  courses: (state) => state.courses,
  notifCount: (state) => state.notifications || 0,
  hasMore: (state) => Boolean(state.links.next),
}

export const mutations = {
  [SET_STUDENTS](state, p) {
    state.students = p
  },
  [SET_LINKS](state, p) {
    state.links = p
  },
  [SET_COURSES](state, p) {
    state.courses = p
  },
  [SET_NOTIFICATIONS](state, p) {
    state.notifications = p
  },
  [SET_COURSE](state, p) {
    state.course = p
  },
}
export const actions = {
  async fetchCourses({commit, dispatch}) {
    try {
      const {data} = await this.$axios.get('/author/courses/review')
      commit(SET_COURSES, data.courses)
      commit(SET_NOTIFICATIONS, data.notifications_count)
      return data
    } catch (err) {}
  },
  async fetchCourse({commit, dispatch}, {id, sort, email}) {
    const res = await this.$axios.$get(`/author/courses/${id}/students-list`, {
      params: {sort, 'filter[email]': email},
    })

    commit(SET_COURSE, omit(['students'], res.data))
    commit(SET_STUDENTS, res.data.students)
    commit(SET_LINKS, res.links)
    return res
  },
  async getMore({state, commit}) {
    const res = await this.$axios.$get(state.links.next)
    if (!res) return
    const studs = state.students
    const newStudents = res.data.students || []

    commit(SET_COURSE, omit(['students'], res.data))
    commit(SET_STUDENTS, [...studs, ...newStudents])
    commit(SET_LINKS, res.links)

    return res
  },
  async rateHomework({commit, dispatch}, p) {
    const {scores_amount, hwId, verifyStep} = p
    try {
      const {data} =
        verifyStep === 2
          ? await this.$axios.post(`/author/student-homeworks/${hwId}/change-scores`, {
              scores_amount,
            })
          : await this.$axios.post(`/author/student-homeworks/${hwId}/approve`, {
              scores_amount,
            })

      data && dispatch(`chat/${chatActionTypes.UPDATE_HOMEWORK_MESSAGE}`, data, root)
    } catch (err) {}
  },
  async rejectHomework({commit, dispatch}, p) {
    const {hwId} = p
    const {data} = await this.$axios.post(`/author/student-homeworks/${hwId}/reject`)

    data && dispatch(`chat/${chatActionTypes.UPDATE_HOMEWORK_MESSAGE}`, data, root)
  },
}

export const namespaced = true
