<template>
  <div
    class="app-tab-switcher overflow-x-auto overflow-y-hidden whitespace-nowrap"
    :class="[{disabled: disabled}]"
  >
    <div class="flex">
      <template v-if="router">
        <NuxtLink
          v-for="tab in normalizedTabs"
          :key="tab.id"
          :to="getTabTo(tab)"
          :class="setClass(tab)"
          @click.native="onTabClick(tab)"
        >
          <span class="int-s">{{ tab.title }}</span>
        </NuxtLink>
      </template>
      <template v-else>
        <span
          v-for="tab in normalizedTabs"
          :key="tab.id"
          class="int-s"
          :class="setClass(tab)"
          @click="onTabClick(tab)"
          >{{ tab.title }}
        </span>
      </template>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import {find, propEq} from 'ramda'
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    defaultActive: {
      type: [String, Number],
      default: '',
    },
    router: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    active: '',
  }),
  computed: {
    normalizedTabs() {
      if (this.tabs.some((v) => !v.id)) {
        return this.tabs.map((v, k) => ({...v, id: k}))
      }
      return this.tabs
    },
  },
  watch: {
    defaultActive(v) {
      this.active = v
    },
  },
  mounted() {
    this.setDefaultActiveTab()
  },
  methods: {
    setDefaultActiveTab() {
      if (this.router) {
        if (this.defaultActive) {
          this.active = this.defaultActive
          return
        }
        const active = find(propEq('to', this.$route.name), this.normalizedTabs)
        this.active = active ? active.id : this.normalizedTabs[0].id
      } else {
        this.active = this.defaultActive || this.normalizedTabs[0].id
      }
    },
    getTabTo(tab) {
      if (tab.to === null || tab.to === undefined) {
        throw Error(`Tab must have 'to' property in router mode!`)
      }
      if (typeof tab.to === 'string') {
        return {name: tab.to}
      }
      return tab.to
    },
    onTabClick(tab) {
      if (tab) {
        this.active = tab.id
        this.$emit('tab:change', tab)
      }
    },
    setClass(tab) {
      return {
        'tab-element': true,
        'tab-element__active': this.active === tab.id,
        hidden: Boolean(tab.hideScreen),
      }
    },
  },
}
</script>

<style lang="scss">
.app-tab-switcher {
  padding: 0px;
  display: flex;
  border-bottom: 1px solid rgba(150, 162, 180, 0.15);

  &.disabled {
    pointer-events: none;
    .tab-element > * {
      color: #888 !important;
    }
  }

  .tab-element {
    cursor: pointer;
    transition-property: color;
    transition: 0.15s ease;
    position: relative;
    margin: 0 28px 20px 0;

    &.tab-element__active {
      color: $purple;
      &:before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        height: 3px;
        border-radius: 3px;
        background: #5858f5;
        box-shadow: 0 5px 10px rgba(102, 102, 254, 0.2);
        transition-property: background;
        transition: 0.15s ease;
      }
    }
  }
}
</style>
