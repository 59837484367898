import Vue from 'vue'
import {map, prop, append} from 'ramda'
import {mutationTypes} from './types'
import {removeObjectByKey, updateObjectByKey} from '@/utils/'

const getUser = prop('user')
const mergeWithRole = (u) => ({...getUser(u), ...u})

export const mutations = {
  [mutationTypes.SET](state, data) {
    Object.assign(state, data)
  },
  [mutationTypes.SET_LOADING](state, l) {
    state.isLoading = l
  },
  [mutationTypes.INSERT](state, msg) {
    Vue.set(state, 'messages', [msg].concat(state.messages))
  },
  [mutationTypes.DELETE_MESSAGE](state, {id} = {}) {
    Vue.set(state, 'messages', removeObjectByKey('id', id, state.messages))
  },
  [mutationTypes.EDIT_MESSAGE](state, msg) {
    Vue.set(state, 'messages', updateObjectByKey('id', msg, state.messages))
  },
  [mutationTypes.RESET_MESSAGES](state) {
    state.messages = []
  },
  [mutationTypes.UPDATE_MESSAGE_LIST](state, data) {
    state.messages = data
  },
  [mutationTypes.SET_ONLINE_USERS](state, users) {
    state.usersOnline = map(mergeWithRole, users)
  },
  [mutationTypes.ADD_JOINED_USER](state, user) {
    state.usersOnline = append(mergeWithRole(user), state.usersOnline)
  },
  [mutationTypes.REMOVE_LEFT_USER](state, user) {
    state.usersOnline = removeObjectByKey('id', user.id, state.usersOnline)
  },
  [mutationTypes.SET_PROGRESS](state, data) {
    Object.assign(state, {progress: data})
  },
}
