<template>
  <div class="webinar-labels">
    <div
      v-if="!active && !webinarShouldStart"
      class="webinar-label webinar-label--tts"
      :class="{'webinar-label--inactive': endedOrExpired}"
      :style="{backgroundColor: color}"
    >
      <div v-if="webinarIsEnded">
        <Icon src="icons/video-ico.svg" />
        {{ LABELS.finished }}
      </div>
      <div v-else-if="webinarExpired">
        <Icon src="icons/clock.svg" size="xs" />
        {{ LABELS.expired }}
      </div>
      <div v-else>
        <Icon src="icons/clock.svg" size="xs" />
        {{ LABELS.beforeStart }} {{ timeToStart }}
      </div>
    </div>

    <div v-if="webinarShouldStart" class="webinar-label webinar-label--soon">
      <Icon src="icons/clock.svg" size="xs" />
      {{ LABELS.startingSoon }}
    </div>

    <div v-if="active" class="webinar-label webinar-label--active">
      <Icon src="icons/play.svg" size="xs" />
      {{ LABELS.active }}
    </div>

    <div v-if="subscribed" class="webinar-label webinar-label--subscribe">
      <Icon src="icons/flag-down-icon.svg" size="xs" />
      {{ LABELS.subscribed }}
    </div>
  </div>
</template>

<script>
import {formatDistanceToNowStrict} from 'date-fns'
import {isWebinarShouldStart, isWebinarEnded, isWebinarExpired} from '@/features/Webinars/'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/uk'
import de from 'date-fns/locale/de'
import pt from 'date-fns/locale/pt'
import es from 'date-fns/locale/es'
const langs = [{ru}, {en}, {es}, {de}, {pt}]
export default {
  props: {
    webinar: {
      type: Object,
      default: () => ({}),
    },
    color: {
      type: String,
      default: '#6666fe',
    },
  },
  data: () => ({
    timeToStart: '',
    webinarIsEnded: '',
    webinarShouldStart: '',
    webinarExpired: false,
  }),
  computed: {
    LABELS() {
      return {
        beforeStart: this.$t('webinars.toStart'),
        startingSoon: this.$t('webinars.nowStarts'),
        active: this.$t('webinars.beingHeld'),
        finished: this.$t('webinars.watchRecord'),
        subscribed: this.$t('webinars.subscribed'),
        expired: this.$t('webinars.wasNotHeld'),
      }
    },
    active() {
      return this.webinar.is_active
    },
    subscribed() {
      return this.webinar.is_subscribed
    },

    endedOrExpired() {
      return this.webinarIsEnded || this.webinarExpired
    },
  },
  created() {
    this.initDates()
  },
  mounted() {
    this.ttsInterval = setInterval(() => {
      this.initDates()

      if (this.endedOrExpired) {
        clearInterval(this.ttsInterval)
      }
    }, 1000 * 15)
  },
  beforeDestroy() {
    clearInterval(this.ttsInterval)
  },
  methods: {
    initDates() {
      this.webinarIsEnded = isWebinarEnded(this.webinar)
      this.webinarShouldStart = isWebinarShouldStart(this.webinar)
      this.webinarExpired = isWebinarExpired(this.webinar)

      this.updateTimeToStart()
    },
    updateTimeToStart() {
      const startDate = this.webinar.start_date * 1000
      this.timeToStart = formatDistanceToNowStrict(startDate, {locale: langs[this.$i18n.locale]})
    },
  },
}
</script>

<style lang="scss">
.webinar-label {
  display: flex;
  align-items: center;
  padding: 5px 12px 6px;
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.035);
  line-height: 1;
  height: 26px;

  > div {
    display: flex;
    align-items: center;
  }

  .app-icon {
    margin-right: 8px;
    svg {
      fill: #fff;
    }
  }

  &--tts,
  &--active,
  &--soon {
    background: $purple;
    color: #fff;
  }

  &--inactive {
    background: $lightIndigo !important;
    color: $black;
    .app-icon {
      svg {
        fill: $elementsGrey;
      }
    }
  }

  &--subscribe {
    background: $success;
    color: #fff;
  }
}
</style>
