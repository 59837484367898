<template>
  <div v-if="date" class="webinar-date" :class="{large}">
    <div class="webinar-date__start">
      <div v-if="!large" class="flex items-center">
        <Icon src="/icons/input-date.svg" size="xs" />

        <span> {{ displayDate.join(' ') }} </span>
      </div>

      <div v-else>
        <span class="text-black">
          {{ displayDate[0] }}
        </span>
        <span class="text-indigo">
          {{ displayDate[1] + ' ' + displayDate[2] }}
        </span>
      </div>
    </div>
    <div class="webinar-date__duration">
      <Icon src="/icons/clock.svg" size="xs" />
      <span>{{ displayDuration }}</span>
    </div>
  </div>
</template>

<script>
import {formatters} from '@/utils/date'
import {format, fromUnixTime, addHours, getMinutes, getHours, getSeconds} from 'date-fns'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/uk'
import de from 'date-fns/locale/de'
import pt from 'date-fns/locale/pt'
import es from 'date-fns/locale/es'
const langs = [{ru}, {en}, {es}, {de}, {pt}]

export default {
  props: {
    date: null,
    duration: [String, Number],
    durationPrefix: {
      type: [String, Number],
      default: '',
    },
    large: Boolean,
  },
  computed: {
    displayDate() {
      if (!this.date) return

      /*  return format(fromUnixTime(this.date * 1000), 'dd MMMM HH:mm', {
        locale: langs[this.$i18n.locale],
      }) */

      return [
        format(fromUnixTime(this.date), 'dd MMMM', {locale: langs[this.$i18n.locale]}),
        this.$t('common.in'),
        format(fromUnixTime(this.date), 'p', {locale: langs[this.$i18n.locale]}),
      ]
    },

    displayDuration() {
      if (!this.duration) return

      return this.durationPrefix + this.convertToDuration(this.duration)
    },
  },
  methods: {
    convertToDuration(secondsAmount) {
      const normalizeTime = (time) => (time.length === 1 ? `0${time}` : time)

      const SECONDS_TO_MILLISECONDS_COEFF = 1000
      const MINUTES_IN_HOUR = 60

      const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF

      const date = new Date(milliseconds)
      const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR
      const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

      const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
      const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
      const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

      const hoursOutput = hours !== '00' ? `${hours}:` : ''

      return `${hoursOutput}${minutes}:${seconds}`
    },
  },
}
</script>

<style lang="scss" scoped>
.webinar-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.large {
    flex-direction: column;
    align-items: flex-start;

    .webinar-date__start {
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;
    }

    .webinar-date__duration {
      font-size: 14px;
    }

    .app-icon + span {
      margin-left: 8px;
    }
  }

  &__start,
  &__duration {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 24px;
    color: $textGrey;
  }

  &__start {
    margin-right: 16px;
  }

  .app-icon {
    cursor: default;

    & + span {
      margin-left: 5px;
    }
  }
}
</style>
