import {path, length, reverse} from 'ramda'
// import {splitByDate} from '@/utils/splitByDate'

export const getters = {
  // messages: (state) => splitByDate({xs: state.messages, shouldReverse: true}),
  messages: (state) => reverse(state.messages),
  usersOnline: (state) => length(state.usersOnline),
  usersList: (state) => state.usersOnline,
  hasMore: (state) => Boolean(state.links.next),
  isLoading: (state) => state.isLoading,
  progress: (state) => state.progress || {},
  courseId: (state) => path(['progress', 'course_id'], state),
}
